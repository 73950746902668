import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Checkbox, Radio, Input } from 'semantic-ui-react';

import Editor from '../CkEditor/index';
import { localization } from '../../../../localization';
import FileUpload from '../FileUpload/index';
import {
  handleInputChange,
  handleCheckboxChange,
  handleRadioChange,
  handleEditorChange,
  handleSaveAndExit,
} from '../../../../actions';
import { fetched, fetchedNullData } from '../../../../reducers/form/ProjectSelection/action';
import { cloneDeep } from 'lodash';
import DatePicker from '../DatePicker';
import * as ProjectAction from '../../../../reducers/form/action';

const ProjectSelection = ({ urlParams }) => {
  const selectedLanguage = useSelector((state) => state.localization.selectedLanguage);
  const lang = localization[selectedLanguage].selection;
  const { projectSelection } = useSelector((state) => state);
  const { projectInfo } = useSelector((state) => state.projectBasicInfo);
  const dispatch = useDispatch();

  const [projectError, setProjectError] = useState({
    isMasterPlan_error: false,
    isMasterPlanApproved_error: false,
    approvingAuthority_error: false,
    approvalDate_error: false,
    directBeneficiaries_error: false,
    indirectBeneficiaries_error: false,
  });

  const handleSaveAndContinue = (type) => {
    let canSubmit = true;
    const errors = [];
    const data = cloneDeep(projectSelection);
    delete data['step'];
    const requiredFields = [
      'isMasterPlan',
      'isMasterPlanApproved',
      'approvingAuthority',
      'approvalDate',
      'directBeneficiaries',
      'indirectBeneficiaries',
      'targetBeneficiaries',
    ];

    for (let d in data) {
      if (requiredFields.includes(d) && (data[d] === '' || data[d].length === 0)) {
        if (d === 'isMasterPlanApproved' && data.isMasterPlan === 'Yes') {
          errors[`${d}_error`] = true;
          canSubmit = false;
        }
        if (
          d !== 'isMasterPlanApproved' &&
          d !== 'targetBeneficiaries' &&
          d !== 'approvingAuthority' &&
          d !== 'approvalDate'
        ) {
          errors[`${d}_error`] = true;
          canSubmit = false;
        }
        if (d === 'approvingAuthority' || d === 'approvalDate') {
          if (data.isMasterPlanApproved === 'Yes') {
            errors[`${d}_error`] = true;
            canSubmit = false;
          }
        }
      }
      if (d === 'targetBeneficiaries') {
        for (let beneficary in data[d]) {
          let regexNumber = /^[+]?\d+(\.\d+)?$/;
          if (
            data[d][beneficary] !== '' &&
            beneficary !== 'beneficiaries' &&
            !regexNumber.test(data[d][beneficary])
          ) {
            errors[`${beneficary}_error`] = true;
            canSubmit = false;
          }
        }
      }
    }
    setProjectError(errors);

    if (canSubmit) {
      handleSaveAndExit({ basis_of_project_selection: data }, type, dispatch, 6, urlParams);
    }
  };

  useEffect(() => {
    if (projectInfo.basis_of_project_selection) {
      dispatch(
        fetched({
          ...projectInfo.basis_of_project_selection,
        })
      );
    }
    if (!projectInfo.basis_of_project_selection) {
      dispatch(fetchedNullData());
    }
  }, [projectInfo]);

  const sustainableGoals = [
    { label: lang.no_poverty, value: 'noPoverty' },
    { label: lang.zero_hunger, value: 'zeroHunger' },
    { label: lang.good_health, value: 'goodHealth' },
    { label: lang.quality_education, value: 'qualityEducation' },
    { label: lang.gender_equality, value: 'genderEquality' },
    { label: lang.clean_water, value: 'cleanWater' },
    { label: lang.affordable_and_clean_energy, value: 'affordableAndCleanEnergy' },
    { label: lang.decent_work_and_economic_growth, value: 'decentWorkAndEconomicgrowth' },
    { label: lang.industry_innovation, value: 'industryInnovation' },
    { label: lang.reducing_inequality, value: 'reducingInequality' },
    { label: lang.sustainable_cities, value: 'sustainableCities' },
    { label: lang.consumption_and_production, value: 'consumptionAndProduction' },
    { label: lang.climate_action, value: 'climateAction' },
    { label: lang.life_below_meter, value: 'lifeBelowMeter' },
    { label: lang.life_on_land, value: 'lifeOnLand' },
    { label: lang.peace_justice, value: 'peaceJustice' },
    { label: lang.partnerships, value: 'partnerships' },
  ];

  return (
    <Form className="form-new">
      {urlParams.stage !== 'identification' && (
        <div className="form-group-container">
          <Form.Group>
            <Form.Field width={16}>
              <label>{lang.brief_description_of_salient_feature}</label>
              <Editor
                formData={projectSelection.projectDescription}
                formInfo={projectSelection}
                field={false}
                name="projectDescription"
                handleEditorChange={handleEditorChange}
                fetched={fetched}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <FileUpload
              formName="selection"
              label="upload_brief_description_of_salient_feature"
              fileData={projectSelection.projectDescriptionFile}
              name="projectDescriptionFile"
              form={projectSelection}
              fetched={fetched}
              projectId={urlParams.projectId}
            />
          </Form.Group>
        </div>
      )}
      <div className="form-group-container">
        <Form.Group grouped>
          <Form.Field
            label={lang.is_this_project_part_of_any_master_plan}
            required
            className={projectError.isMasterPlan_error ? 'error' : ''}
          />
          <Form.Group inline>
            <Form.Field
              label={lang.yes}
              control={Radio}
              name="isMasterPlan"
              value="Yes"
              checked={projectSelection.isMasterPlan === 'Yes'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectSelection,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, isMasterPlan_error: false });
              }}
            />
            <Form.Field
              label={lang.no}
              control={Radio}
              name="isMasterPlan"
              value="No"
              id="isMasterPlan"
              checked={projectSelection.isMasterPlan === 'No'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectSelection,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, isMasterPlan_error: false });
              }}
            />
          </Form.Group>
        </Form.Group>
      </div>
      {projectSelection.isMasterPlan && projectSelection.isMasterPlan === 'Yes' && (
        <div className="form-group-container">
          <Form.Group>
            <FileUpload
              formName="selection"
              label="upload_master_plan"
              fileData={projectSelection.masterPlanFile}
              name="masterPlanFile"
              form={projectSelection}
              fetched={fetched}
              projectId={urlParams.projectId}
            />
          </Form.Group>
          <Form.Group grouped>
            <Form.Field
              label={lang.has_the_master_plan}
              className={projectError.isMasterPlanApproved_error ? 'error' : ''}
              required
            />
            <Form.Group inline>
              <Form.Field
                label={lang.cha}
                control={Radio}
                name="isMasterPlanApproved"
                value="Yes"
                checked={projectSelection.isMasterPlanApproved === 'Yes'}
                onChange={(event, { value, name }) => {
                  dispatch(
                    handleRadioChange(
                      {
                        info: projectSelection,
                        value,
                        name,
                      },
                      fetched
                    )
                  );
                  setProjectError({ ...projectError, isMasterPlanApproved_error: false });
                }}
              />
              <Form.Field
                label={lang.chaina}
                control={Radio}
                name="isMasterPlanApproved"
                value="No"
                checked={projectSelection.isMasterPlanApproved === 'No'}
                width={2}
                onChange={(event, { value, name }) => {
                  dispatch(
                    handleRadioChange(
                      {
                        info: projectSelection,
                        value,
                        name,
                      },
                      fetched
                    )
                  );
                  setProjectError({ ...projectError, isMasterPlanApproved_error: false });
                }}
              />
            </Form.Group>
          </Form.Group>
          {projectSelection.isMasterPlanApproved &&
            projectSelection.isMasterPlanApproved === 'Yes' && (
              <>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    className={
                      projectError.approvingAuthority_error ? 'error' : 'field-align-bottom'
                    }
                    label={lang.name_the_approving_authority}
                    id="approvingAuthority"
                    name="approvingAuthority"
                    value={projectSelection.approvingAuthority}
                    type="text"
                    width={8}
                    onChange={(event) => {
                      dispatch(
                        handleInputChange(
                          {
                            event,
                            info: projectSelection,
                          },
                          fetched
                        )
                      );
                      setProjectError({ ...projectError, approvingAuthority_error: false });
                    }}
                    required
                  />
                  <DatePicker
                    lang={lang}
                    label="approval_date"
                    name="approvalDate"
                    formName={projectSelection}
                    fetched={fetched}
                    required={true}
                    error={projectError.approvalDate_error}
                  />
                </Form.Group>
              </>
            )}
        </div>
      )}
      {projectSelection.isMasterPlan && projectSelection.isMasterPlan === 'No' && (
        <div className="form-group-container">
          <Form.Group>
            <Form.Field width={16}>
              <label>{lang.on_what_basis_is_the_project}</label>
              <Editor
                formData={projectSelection.selectionCriteria}
                formInfo={projectSelection}
                field={false}
                name="selectionCriteria"
                handleEditorChange={handleEditorChange}
                fetched={fetched}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <FileUpload
              formName="selection"
              label="selection_criteria"
              fileData={projectSelection.selectionCriteriaFile}
              name="selectionCriteriaFile"
              form={projectSelection}
              fetched={fetched}
              projectId={urlParams.projectId}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={16}>
              <label> {lang.is_project_derived_from_national}</label>
              <Editor
                formData={projectSelection.projectDerivation}
                formInfo={projectSelection}
                field={false}
                name="projectDerivation"
                handleEditorChange={handleEditorChange}
                fetched={fetched}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <FileUpload
              formName="selection"
              label="upload_is_project_derived_from_national"
              fileData={projectSelection.projectDerivationFile}
              name="projectDerivationFile"
              form={projectSelection}
              fetched={fetched}
              projectId={urlParams.projectId}
            />
          </Form.Group>

          <Form.Group grouped>
            <Form.Field
              label={lang.is_there_any_plan_to_develop_master_plan}
              // required
              // className={projectError.anyPlanToDevelopMasterPlan_error ? 'error' : ''}
            />
            <Form.Group inline>
              <Form.Field
                label={lang.yes}
                control={Radio}
                name="anyPlanToDevelopMasterPlan"
                value="Yes"
                checked={projectSelection.anyPlanToDevelopMasterPlan === 'Yes'}
                onChange={(event, { value, name }) => {
                  dispatch(
                    handleRadioChange(
                      {
                        info: projectSelection,
                        value,
                        name,
                      },
                      fetched
                    )
                  );
                  // setProjectError({ ...projectError, anyPlanToDevelopMasterPlan_error: false });
                }}
              />
              <Form.Field
                label={lang.no}
                control={Radio}
                name="anyPlanToDevelopMasterPlan"
                value="No"
                checked={projectSelection.anyPlanToDevelopMasterPlan === 'No'}
                onChange={(event, { value, name }) => {
                  dispatch(
                    handleRadioChange(
                      {
                        info: projectSelection,
                        value,
                        name,
                      },
                      fetched
                    )
                  );
                  // setProjectError({ ...projectError, anyPlanToDevelopMasterPlan_error: false });
                }}
              />
            </Form.Group>
            {projectSelection.anyPlanToDevelopMasterPlan &&
              projectSelection.anyPlanToDevelopMasterPlan === 'Yes' && (
                <Form.Group>
                  <DatePicker
                    lang={lang}
                    label="date_to_master_plan"
                    name="dateToMasterPlan"
                    formName={projectSelection}
                    fetched={fetched}
                  />
                </Form.Group>
              )}
          </Form.Group>
        </div>
      )}

      <div className="form-group-container">
        <Form.Group>
          <Form.Field width={16}>
            <label>{lang.national_goal}</label>
            <Editor
              formData={projectSelection.nationalGoal}
              formInfo={projectSelection}
              field={false}
              name="nationalGoal"
              handleEditorChange={handleEditorChange}
              fetched={fetched}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <FileUpload
            formName="selection"
            label="upload_national_goal"
            fileData={projectSelection.nationalGoalFile}
            name="nationalGoalFile"
            form={projectSelection}
            fetched={fetched}
            projectId={urlParams.projectId}
          />
        </Form.Group>

        <Form.Group>
          <Form.Field width={16}>
            <label>{lang.project_goal}</label>
            <Editor
              formData={projectSelection.projectGoal}
              formInfo={projectSelection}
              field={false}
              name="projectGoal"
              handleEditorChange={handleEditorChange}
              fetched={fetched}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <FileUpload
            formName="selection"
            label="upload_project_goal"
            fileData={projectSelection.projectGoalFile}
            name="projectGoalFile"
            form={projectSelection}
            fetched={fetched}
            projectId={urlParams.projectId}
          />
        </Form.Group>
      </div>

      <div className="form-group-container">
        {/* <Form.Field label={lang.target_beneficiaries} /> */}
        <p className="form-info">{lang.target_beneficiaries}</p>
        <Form.Group>
          <Form.Field
            label={lang.direct_number_of_household}
            control={Input}
            width={8}
            id="directBeneficiaries"
            name="directBeneficiaries"
            placeholder="Number of household"
            onChange={(event) => {
              dispatch(
                handleInputChange(
                  {
                    event,
                    field: 'targetBeneficiaries',
                    info: projectSelection,
                  },
                  fetched
                )
              );
              setProjectError({ ...projectError, directBeneficiaries_error: false });
            }}
            value={
              projectSelection.targetBeneficiaries &&
              projectSelection.targetBeneficiaries.directBeneficiaries
            }
            className={projectError.directBeneficiaries_error ? 'error' : ''}
          />
          <Form.Field
            label={lang.indirect_number_of_household}
            control={Input}
            id="indirectBeneficiaries"
            name="indirectBeneficiaries"
            placeholder="Number of household"
            width={8}
            onChange={(event) => {
              dispatch(
                handleInputChange(
                  {
                    event,
                    field: 'targetBeneficiaries',
                    info: projectSelection,
                  },
                  fetched
                )
              );
              setProjectError({ ...projectError, indirectBeneficiaries_error: false });
            }}
            value={
              projectSelection.targetBeneficiaries &&
              projectSelection.targetBeneficiaries.indirectBeneficiaries
            }
            className={projectError.indirectBeneficiaries_error ? 'error' : ''}
          />
        </Form.Group>
        <Form.Group>
          <Form.Field width={16}>
            <label>{lang.beneficiaries}</label>
            <Editor
              field="targetBeneficiaries"
              name="beneficiaries"
              formInfo={projectSelection}
              formData={
                projectSelection.targetBeneficiaries &&
                projectSelection.targetBeneficiaries.beneficiaries
              }
              handleEditorChange={handleEditorChange}
              fetched={fetched}
            />
          </Form.Field>
        </Form.Group>
      </div>

      <div className="form-group-container">
        {/* <Form.Field label={lang.outcomes_deliverables} /> */}
        <p className="form-info">{lang.outcomes_deliverables}</p>
        <Form.Group>
          <Form.Field width={16}>
            <label>{lang.indicate_final_outcomes_for_the_project_assessment}</label>
            <Editor
              // field="outcomesAndDeliverables"
              name="outcomesAndDeliverable"
              formInfo={projectSelection}
              formData={projectSelection.outcomesAndDeliverable}
              handleEditorChange={handleEditorChange}
              fetched={fetched}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <FileUpload
            formName="selection"
            label="upload_outcome"
            fileData={projectSelection.outcomesAndDeliverableFile}
            name="outcomesAndDeliverableFile"
            form={projectSelection}
            fetched={fetched}
            projectId={urlParams.projectId}
          />
        </Form.Group>
      </div>

      <div className="form-group-container">
        <Form.Group grouped>
          <Form.Field label={lang.sustainable_development_goals} />
          <Form.Group className="field-flex-wrap">
            {sustainableGoals.map((goals, index) => {
              return (
                <Form.Field
                  key={index}
                  width={4}
                  label={goals.label}
                  control={Checkbox}
                  type="checkbox"
                  id={goals.value}
                  name={goals.value}
                  onChange={(event) =>
                    dispatch(
                      handleCheckboxChange(
                        { event, info: projectSelection, field: 'sustainableGoals' },
                        fetched
                      )
                    )
                  }
                  checked={
                    projectSelection.sustainableGoals.length > 0 &&
                    projectSelection.sustainableGoals.filter((goal, index) => goal === goals.value)
                      .length > 0
                  }
                />
              );
            })}
          </Form.Group>
        </Form.Group>
      </div>

      <div className="form-group-container">
        <Form.Group grouped>
          <Form.Field label={lang.poverty_sign} />
          <Form.Group inline>
            <Form.Field
              label={lang.direct_contribution_to_poverty}
              control={Radio}
              name="povertySign"
              value="true"
              checked={projectSelection.povertySign === 'true'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectSelection,
                      value,
                      name,
                    },
                    fetched
                  )
                );
              }}
            />
            <Form.Field
              label={lang.indirect_contribution_to_poverty}
              control={Radio}
              name="povertySign"
              value="false"
              checked={projectSelection.povertySign === 'false'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectSelection,
                      value,
                      name,
                    },
                    fetched
                  )
                );
              }}
            />
          </Form.Group>
        </Form.Group>
      </div>

      <div className="form-group-container">
        <Form.Group grouped>
          <Form.Field label={lang.gender_sign} />
          <Form.Group inline>
            <Form.Field
              label={lang.direct_contribution_to_gender}
              control={Radio}
              name="genderSign"
              value="true"
              checked={projectSelection.genderSign === 'true'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectSelection,
                      value,
                      name,
                    },
                    fetched
                  )
                );
              }}
            />
            <Form.Field
              label={lang.indirect_contribution_to_gender}
              control={Radio}
              name="genderSign"
              value="false"
              checked={projectSelection.genderSign === 'false'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectSelection,
                      value,
                      name,
                    },
                    fetched
                  )
                );
              }}
            />
          </Form.Group>
        </Form.Group>
      </div>

      <div className="form-group-container">
        <Form.Group grouped>
          <Form.Field label={lang.climate_sign} />
          <Form.Group inline>
            <Form.Field
              label={lang.extremely_relevant}
              control={Radio}
              name="climateSign"
              value="true"
              checked={projectSelection.climateSign === 'true'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectSelection,
                      value,
                      name,
                    },
                    fetched
                  )
                );
              }}
            />
            <Form.Field
              label={lang.relevant}
              control={Radio}
              name="climateSign"
              value="false"
              checked={projectSelection.climateSign === 'false'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectSelection,
                      value,
                      name,
                    },
                    fetched
                  )
                );
              }}
            />
            <Form.Field
              label={lang.neutral}
              control={Radio}
              name="climateSign"
              value="neutral"
              checked={projectSelection.climateSign === 'neutral'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectSelection,
                      value,
                      name,
                    },
                    fetched
                  )
                );
              }}
            />
          </Form.Group>
        </Form.Group>
      </div>

      <div className="form-actions flex">
        <button
          className="btn-rect btn-rect--small btn-blue"
          onClick={() => handleSaveAndContinue('continue')}
        >
          Save and continue
        </button>
        <button
          className="btn-rect btn-rect--small btn-dark"
          onClick={() => handleSaveAndContinue('exit')}
        >
          Save and exit
        </button>
        <button
          type="button"
          className="btn-link"
          onClick={() => dispatch(ProjectAction.projectStep({ step: 4 }))}
        >
          Back to previous form
        </button>
      </div>
    </Form>
  );
};

export default ProjectSelection;
