import React, {useState} from 'react';
import { Form, Input } from 'semantic-ui-react';
import Editor from '../../../components/CkEditor/Editor';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// Necessary for svg icon
import {ReactComponent as CalendarIcon} from "./../../../assets/images/ic_calendar.svg"

const ExampleInput = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <>
      {/* single form field start */}
      <Form.Group>
        <Form.Field
          label="Single field"
          control={Input}
          type="text"
          name="isPartOfProgram"
          placeholder="This is a placeholder"
          id="part_of_program"
          width={8}
          className="field-align-bottom"
        />
      </Form.Group>
      {/* single form field start end */}

      {/* Form group start */}
      <Form.Group>
        <Form.Field
          control={Input}
          label="First name"
          placeholder="First name"
          width={8}
          className="field-align-bottom"
        />
        <Form.Field
          control={Input}
          label="Last name"
          placeholder="Last name"
          width={8}
          className="field-align-bottom"
        />
      </Form.Group>
      {/* Form group end */}

      {/* Form group start */}
      <Form.Group>
        <Form.Field
          control={Input}
          className="field-align-bottom"
          label="First name"
          placeholder="First name"
          width={4}
        />
        <Form.Field
          control={Input}
          className="field-align-bottom"
          label="Middle name This is a very long label that you have ever seen"
          placeholder="Middle name"
          width={4}
        />
        <Form.Field
          control={Input}
          className="field-align-bottom"
          label="Last name"
          placeholder="Last name"
          width={4}
        />
        <Form.Field width={4} className="position-relative field-align-bottom">
        <label>Select date</label>
          <i className="ic-calendar">
            <CalendarIcon />
          </i>
          <DatePicker
            showPopperArrow={false}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
      </Form.Field>
      </Form.Group>
      {/* Form group end */}

      {/* Form group start */}
      <Form.Group>
        <Form.Field
          control={Input}
          label="First name This is a very long label that you have ever seen"
          placeholder="First name"
          width={4}
          className="field-align-bottom"
        />
        <Form.Field
          control={Input}
          label="Middle name"
          placeholder="Middle name"
          width={4}
          className="field-align-bottom"
        />
        <Form.Field
          control={Input}
          label="Last name"
          placeholder="Last name"
          width={4}
          className="field-align-bottom"
        />
        <Form.Field
          control={Input}
          label="Nick name test"
          placeholder="Nick name"
          width={4}
          className="field-align-bottom"
        />
      </Form.Group>
      {/* Form group end */}

      {/* Form group start */}
      <Form.Group>
        <Form.Field width={8}>
          <label>Textarea</label>
          <Editor />
        </Form.Field>
      </Form.Group>
      {/* Form group end */}
    </>
  );
};

export default ExampleInput;
