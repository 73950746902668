import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Radio, Select, Input } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';

import { localization } from '../../../../localization';
import { auth } from '../../../../helpers';
import Editor from '../CkEditor/index';
import fiscalYear from '../data/fiscalYear';
import CurrencyField from '../CurrencyField';
import FileUpload from '../FileUpload/index';
import {
  addRepeaterField,
  handleRadioChange,
  handleSelectChange,
  handleEditorChange,
  handleRepeaterChange,
  removeRepeaterField,
  handleSaveAndExit,
  handleInputChange,
  repeaterFieldOptimise,
} from '../../../../actions';
import { fetched, fetchedNullData } from '../../../../reducers/form/ProjectSpecific/action';
import * as ProjectAction from '../../../../reducers/form/action';

import DatePicker from '../DatePicker';
import NepaliDatePicker from '../DatePicker/NepaliDatePicker';

const ProjectSpecific = ({ urlParams, projectStatus = 'ongoing' }) => {
  const dispatch = useDispatch();
  const language = useSelector(auth.getSelectedLanguage);

  const lang = localization[language].specific;
  const { projectSpecific } = useSelector((state) => state);
  const { projectInfo } = useSelector((state) => state.projectBasicInfo);

  useEffect(() => {
    if (projectInfo.project_specific) {
      dispatch(
        fetched({
          ...projectInfo.project_specific,
        })
      );
    }
    if (!projectInfo.project_specific) {
      dispatch(fetchedNullData());
    }
  }, [projectInfo]);

  const [projectError, setProjectError] = useState({
    isYearlyFunding_error: false,
    projectStartDateAD_error: false,
    projectStartDateBS_error: false,
    estimatedProjectEndDateAD_error: false,
    estimatedProjectEndDateBS_error: false,
    originalCost_error: false,
    originalCostWord_error: false,
    originalCostExchangeRate_error: false,
    originalCostCurrency_error: false,
    revisedCost_error: false,
    revisedCostCurrency_error: false,
    revisedCostWord_error: false,
    revisedCostExchangeRate_error: false,
  });

  const handleSaveAndContinue = (type) => {
    let data = cloneDeep(projectSpecific);
    delete data['step'];
    const requiredFields = ['isYearlyFunding'];
    const regexCheckNumberFields = [
      'costHeadingNumber',
      'originalCost',
      'originalCostExchangeRate',
      'revisedCost',
      'revisedCostExchangeRate',
      'fCostComponent',
      'fCostComponentExchangeRate',
      'totalCost',
      'totalCostExchangeRate',
    ];
    const regexCheckWordFields = [
      'originalCostWord',
      'revisedCostWord',
      'totalCostWord',
      'fCostComponentWord',
    ];
    const dependentFields = [
      'projectStartDateAD',
      'projectStartDateBS',
      'estimatedProjectEndDateAD',
      'estimatedProjectEndDateBS',
    ];
    const errors = {};
    let canSubmit = true;
    errors[`revisedCost_error`] = {};
    for (let d in data) {
      if (data['projectStartDateAD'] && data['estimatedProjectEndDateAD']) {
        let start = new Date(data['projectStartDateAD']);
        let end = new Date(data['estimatedProjectEndDateAD']);

        if (start > end) {
          errors['projectStartDateAD_error'] = true;
          errors['estimatedProjectEndDateAD_error'] = true;
          errors['projectStartDateBS_error'] = true;
          errors['estimatedProjectEndDateBS_error'] = true;
        }
      }
      if (
        requiredFields.includes(d) &&
        (data[d] === '' || data[d].length === 0) &&
        d !== 'projectCostImplementation'
      ) {
        errors[`${d}_error`] = true;
        canSubmit = false;
      }
      if (d === 'isYearlyFunding' && data[d] === 'No' && urlParams.stage === 'appraisal') {
        dependentFields.map((field) => {
          if (!data[field] || data[field] === '') {
            errors[`${field}_error`] = true;
            canSubmit = false;
          }
        });
      }
      if (d === 'projectCostImplementation') {
        errors[`${d}_error`] = {};
        for (let pCost in data[d]) {
          let regexNumber = /^[+]?\d+(\.\d+)?$/;
          let regexWord = /^[^[0-9!@*,_\.~`$#%\^\(\)-+='""{}><\?\\\/||\+]*$/;
          if (
            data[d][pCost] !== '' &&
            regexCheckNumberFields.includes(pCost) &&
            !regexNumber.test(data[d][pCost])
          ) {
            errors[`${d}_error`][`${pCost}`] = true;
            canSubmit = false;
          }
          if (
            data[d][pCost] !== '' &&
            regexCheckWordFields.includes(pCost) &&
            !regexWord.test(data[d][pCost])
          ) {
            errors[`${d}_error`][`${pCost}`] = true;
            canSubmit = false;
          }
        }
      }

      if (d === 'revisedCost' || d === 'revisedCostExchangeRate' || d === 'revisedCostWord') {
        let regexNumber = /^[+]?\d+(\.\d+)?$/;
        let regexWord = /^[^[0-9!@*,_\.~`$#%\^\(\)-+='""{}><\?\\\/||\+]*$/;
        if (regexCheckNumberFields.includes(d) && !regexNumber.test(data[d]) && data[d] !== '') {
          errors[`revisedCost_error`][`${d}`] = true;
          canSubmit = false;
        }
        if (data[d] !== '' && regexCheckWordFields.includes(d) && !regexWord.test(data[d])) {
          errors[`revisedCost_error`][`${d}`] = true;
          canSubmit = false;
        }
      }
      if (d === 'summaryOfCost') {
        errors[`${d}_error`] = {};
        for (let i = 0; i < data[d].length; i++) {
          for (let sCost in data[d][i]) {
            let regexNumber = /^[+]?\d+(\.\d+)?$/;
            let regexWord = /^[^[0-9!@*,_\.~`$#%\^\(\)-+='""{}><\?\\\/||\+]*$/;
            if (
              data[d][i][sCost] !== '' &&
              regexCheckNumberFields.includes(sCost) &&
              !regexNumber.test(data[d][i][sCost])
            ) {
              sCost !== 'costHeadingNumber'
                ? (errors[`${d}_error`][`${sCost}_${i}_error`] = true)
                : (errors[`${sCost}_${i}_error`] = true);
              canSubmit = false;
            }
            if (
              data[d][i][sCost] !== '' &&
              regexCheckWordFields.includes(sCost) &&
              !regexWord.test(data[d][i][sCost])
            ) {
              errors[`${d}_error`][`${sCost}_${i}_error`] = true;
              canSubmit = false;
            }
          }
        }
      }
    }
    setProjectError(errors);
    if (canSubmit) {
      data.stage = urlParams.stage;
      let newProjectSpecific = repeaterFieldOptimise(data, 'summaryOfCost', [
        {
          costHeadingNumber: '',
          costHeading: '',
          fCostComponent: '',
          fCostComponentExchangeRate: '',
          fCostComponentCurrency: 'npr',
          fCostComponentWord: '',
        },
      ]);
      data.summaryOfCost = [...newProjectSpecific];
      handleSaveAndExit({ project_specific: data }, type, dispatch, 3, urlParams);
    }
  };

  return (
    <Form className="form-new">
      <div className="form-group-container">
        <Form.Group grouped>
          <Form.Field
            label={lang.is_yearly_funding}
            required
            className={projectError.isYearlyFunding_error ? 'error' : 'field-align-bottom'}
          />
          <Form.Group inline>
            <Form.Field
              label={lang.ho}
              control={Radio}
              name="isYearlyFunding"
              value="Yes"
              checked={projectSpecific.isYearlyFunding === 'Yes'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectSpecific,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, isYearlyFunding_error: false });
              }}
            />
            <Form.Field
              label={lang.hoina}
              control={Radio}
              name="isYearlyFunding"
              value="No"
              checked={projectSpecific.isYearlyFunding === 'No'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectSpecific,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, isYearlyFunding_error: false });
              }}
            />
          </Form.Group>
        </Form.Group>
        {urlParams.stage !== 'identification' &&
          projectSpecific.isYearlyFunding &&
          projectSpecific.isYearlyFunding === 'No' && (
            <>
              <Form.Group>
                <DatePicker
                  lang={lang}
                  label={
                    projectInfo.project_status === 'new'
                      ? 'estimated_commencement_date'
                      : 'project_commencement_date'
                  }
                  name="projectStartDateAD"
                  formName={projectSpecific}
                  fetched={fetched}
                  changeField="projectStartDateBS"
                  required={true}
                  error={projectError.projectStartDateAD_error}
                />
                <NepaliDatePicker
                  lang={lang}
                  label={
                    projectInfo.project_status === 'new'
                      ? 'estimated_commencement_date_bs'
                      : 'project_start_date'
                  }
                  name="projectStartDateBS"
                  formName={projectSpecific}
                  fetched={fetched}
                  changeField="projectStartDateAD"
                  required={true}
                  error={projectError.projectStartDateBS_error}
                  formSection={projectSpecific}
                />
              </Form.Group>
              <Form.Group>
                <DatePicker
                  lang={lang}
                  label={
                    projectInfo.project_status === 'new'
                      ? 'estimated_project_completion_date_ad'
                      : 'project_completion_date_ad'
                  }
                  name="estimatedProjectEndDateAD"
                  formName={projectSpecific}
                  fetched={fetched}
                  changeField="estimatedProjectEndDateBS"
                  required={true}
                  error={projectError.estimatedProjectEndDateAD_error}
                />
                <NepaliDatePicker
                  lang={lang}
                  label={
                    projectInfo.project_status === 'new'
                      ? 'estimated_commencement_date_bs'
                      : 'project_completion_date_bs'
                  }
                  name="estimatedProjectEndDateBS"
                  formName={projectSpecific}
                  fetched={fetched}
                  changeField="estimatedProjectEndDateAD"
                  required={true}
                  error={projectError.estimatedProjectEndDateBS_error}
                  formSection={projectSpecific}
                />
              </Form.Group>
            </>
          )}
      </div>
      {urlParams.stage === 'identification' && projectInfo.project_status === 'new' && (
        <div className="form-group-container">
          <Form.Group>
            <Form.Field width={16}>
              <label>{lang.brief_description_salient_feature}</label>
              <Editor
                formData={projectSpecific.projectDescription}
                formInfo={projectSpecific}
                field={false}
                name="projectDescription"
                handleEditorChange={handleEditorChange}
                dispatch={dispatch}
                fetched={fetched}
              />
            </Form.Field>
          </Form.Group>
          <FileUpload
            formName="specific"
            label="upload_documents"
            fileData={projectSpecific.projectDescriptionFile}
            name="projectDescriptionFile"
            form={projectSpecific}
            fetched={fetched}
            projectId={urlParams.projectId}
          />
        </div>
      )}
      {urlParams.stage === 'identification' && projectInfo.project_status === 'new' && (
        <div className="form-group-container">
          <Form.Group>
            <Form.Field
              control={Input}
              name="estimatedTime"
              width={8}
              className="field-align-bottom"
              label={lang.estimated_time}
              value={projectSpecific.estimatedTime}
              placeholder="Eg: 5 Years"
              onChange={(event) => {
                dispatch(
                  handleInputChange(
                    {
                      event,
                      info: projectSpecific,
                    },
                    fetched
                  )
                );
              }}
            />
          </Form.Group>
        </div>
      )}

      <div className="form-group-container">
        <p className="form-info">
          {projectInfo.project_status === 'new'
            ? lang.project_cost_implementation
            : lang.project_cost_implementation}
        </p>

        <Form.Group>
          <CurrencyField
            costLabel={
              projectInfo.project_status === 'new'
                ? lang.estimated_cost
                : lang.original_cost_estimate
            }
            costName="originalCost"
            exchangeRateLabel={lang.usd_exchange_rate}
            exchangeRateName="originalCostExchangeRate"
            costInWordsLabel={
              projectInfo.project_status === 'new'
                ? lang.estimated_cost_word
                : lang.original_cost_estimate_word
            }
            costInWordsName="originalCostWord"
            formInfo={projectSpecific}
            dispatch={dispatch}
            fetched={fetched}
            field="projectCostImplementation"
            error={projectError.projectCostImplementation_error}
          />
        </Form.Group>

        <Form.Group>
          <Form.Field
            control={Select}
            label={lang.fiscal_year}
            options={fiscalYear}
            placeholder={lang.fiscal_year}
            width={4}
            name="fiscalYearForOriginalCost"
            search
            onChange={(event, { value, name }) => {
              dispatch(
                handleSelectChange(
                  {
                    value,
                    name,
                    info: projectSpecific,
                    field: 'projectCostImplementation',
                  },
                  fetched
                )
              );
            }}
            value={
              projectSpecific.projectCostImplementation &&
              projectSpecific.projectCostImplementation.fiscalYearForOriginalCost
            }
          />
          {urlParams.stage !== 'identification' && (
            <>
              <DatePicker
                lang={lang}
                label="date_of_estimate_AD"
                name="estimateDateAD"
                formName={projectSpecific}
                fetched={fetched}
                changeField="estimateDateBS"
                field="projectCostImplementation"
              />
              <NepaliDatePicker
                lang={lang}
                label="date_of_estimate_BS"
                name="estimateDateBS"
                formName={projectSpecific}
                fetched={fetched}
                changeField="estimateDateAD"
                field="projectCostImplementation"
                formSection={projectSpecific}
              />
            </>
          )}
        </Form.Group>
      </div>

      {/* {projectInfo.project_status === 'new' ? (
        <></>
      ) : ( */}
      <>
        {urlParams.stage !== 'identification' && (
          <div className="form-group-container">
            <Form.Group grouped>
              <Form.Field label={lang.is_the_project_cost_revise} />
              <Form.Group inline>
                <Form.Field
                  label={lang.yes}
                  control={Radio}
                  name="isProjectCostRevise"
                  value="Yes"
                  checked={projectSpecific.isProjectCostRevise === 'Yes'}
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleRadioChange(
                        {
                          info: projectSpecific,
                          value,
                          name,
                        },
                        fetched
                      )
                    );
                  }}
                />
                <Form.Field
                  label={lang.no}
                  control={Radio}
                  name="isProjectCostRevise"
                  value="No"
                  checked={projectSpecific.isProjectCostRevise === 'No'}
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleRadioChange(
                        {
                          info: projectSpecific,
                          value,
                          name,
                        },
                        fetched
                      )
                    );
                  }}
                />
              </Form.Group>
            </Form.Group>
            {projectSpecific.isProjectCostRevise === 'Yes' && (
              <>
                <Form.Group>
                  <CurrencyField
                    costLabel={lang.revised_cost_estimated}
                    costName="revisedCost"
                    exchangeRateLabel={lang.usd_exchange_rate}
                    exchangeRateName="revisedCostExchangeRate"
                    costInWordsLabel={lang.revised_cost_estimated_word}
                    costInWordsName="revisedCostWord"
                    formInfo={projectSpecific}
                    dispatch={dispatch}
                    fetched={fetched}
                    field={false}
                    error={projectError.revisedCost_error}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Field
                    control={Select}
                    label={lang.fiscal_year}
                    options={fiscalYear}
                    placeholder={lang.fiscal_year}
                    width={4}
                    name="fiscalYearForRevised"
                    onChange={(event, { value, name }) => {
                      dispatch(
                        handleSelectChange(
                          {
                            value,
                            name,
                            info: projectSpecific,
                          },
                          fetched
                        )
                      );
                    }}
                    value={projectSpecific.fiscalYearForRevised}
                  />
                  <DatePicker
                    lang={lang}
                    label="revised_date_estimate_ad"
                    name="revisedDateEstimateAD"
                    formName={projectSpecific}
                    fetched={fetched}
                    changeField="revisedDateEstimateBS"
                  />
                  <NepaliDatePicker
                    lang={lang}
                    label="revised_date_estimate_ad"
                    name="revisedDateEstimateBS"
                    formName={projectSpecific}
                    fetched={fetched}
                    changeField="revisedDateEstimateAD"
                  />
                </Form.Group>
              </>
            )}
          </div>
        )}

        <div className="form-group-container">
          <Form.Group grouped>
            <Form.Field label={lang.breakdown} />
            <Form.Group inline>
              <Form.Field
                label={lang.yes}
                control={Radio}
                name="isBreakOfCost"
                value="Yes"
                checked={projectSpecific.isBreakOfCost === 'Yes'}
                onChange={(event, { value, name }) => {
                  dispatch(
                    handleRadioChange(
                      {
                        info: projectSpecific,
                        value,
                        name,
                      },
                      fetched
                    )
                  );
                }}
              />
              <Form.Field
                label={lang.no}
                control={Radio}
                name="isBreakOfCost"
                value="No"
                checked={projectSpecific.isBreakOfCost === 'No'}
                onChange={(event, { value, name }) => {
                  dispatch(
                    handleRadioChange(
                      {
                        info: projectSpecific,
                        value,
                        name,
                      },
                      fetched
                    )
                  );
                }}
              />
            </Form.Group>
          </Form.Group>
          {projectSpecific.isBreakOfCost === 'Yes' && (
            <>
              <Form.Group grouped>
                <Form.Field label={lang.summary_of_cost_under_major_headings} />
                {projectSpecific.summaryOfCost.map((value, index) => {
                  return (
                    <div
                      className={index === 0 ? 'field-repeat--first' : 'field-repeat'}
                      key={index}
                    >
                      <Form.Group>
                        <Form.Field
                          control={Input}
                          label={lang.cost_heading_number}
                          width={4}
                          className={
                            projectError[`costHeadingNumber_${index}_error`]
                              ? 'error'
                              : 'field-align-bottom'
                          }
                          name="costHeadingNumber"
                          onChange={(event) => {
                            dispatch(
                              handleRepeaterChange(
                                {
                                  event,
                                  info: projectSpecific,
                                  field: 'summaryOfCost',
                                  index: index,
                                },
                                fetched
                              )
                            );
                            setProjectError({
                              ...projectError,
                              [`costHeadingNumber_${index}_error`]: false,
                            });
                          }}
                          value={value.costHeadingNumber || ''}
                        />
                        <Form.Field
                          control={Input}
                          label={lang.cost_headings}
                          placeholder={lang.cost_headings}
                          width={4}
                          className="field-align-bottom"
                          name="costHeading"
                          onChange={(event) => {
                            dispatch(
                              handleRepeaterChange(
                                {
                                  event,
                                  info: projectSpecific,
                                  field: 'summaryOfCost',
                                  index: index,
                                },
                                fetched
                              )
                            );
                          }}
                          value={value.costHeading || ''}
                        />
                      </Form.Group>
                      <Form.Group>
                        <CurrencyField
                          costLabel={lang.cost_usd}
                          costName="fCostComponent"
                          exchangeRateLabel="Exchange rate"
                          exchangeRateName="fCostComponentExchangeRate"
                          costInWordsLabel={lang.cost_usd_word}
                          costInWordsName="fCostComponentWord"
                          formInfo={projectSpecific}
                          dispatch={dispatch}
                          fetched={fetched}
                          field="summaryOfCost"
                          index={index}
                          error={projectError.summaryOfCost_error}
                        />
                      </Form.Group>
                      {index > 0 && (
                        <div
                          className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                          onClick={(event) => {
                            dispatch(
                              removeRepeaterField(
                                {
                                  event,
                                  info: projectSpecific,
                                  field: 'summaryOfCost',
                                  index: index,
                                },
                                fetched
                              )
                            );
                          }}
                        >
                          <i className="material-icons md-12 mr-2 material-delete">
                            delete_outline
                          </i>
                          Delete
                        </div>
                      )}
                    </div>
                  );
                })}
              </Form.Group>

              <Form.Group className="field-repeat-btn">
                <Form.Field>
                  <button
                    className="btn-text"
                    onClick={(event) => {
                      dispatch(
                        addRepeaterField(
                          {
                            event,
                            info: projectSpecific,
                            field: 'summaryOfCost',
                          },
                          fetched
                        )
                      );
                    }}
                  >
                    + {lang.add_more}
                  </button>
                </Form.Field>
              </Form.Group>
            </>
          )}
        </div>
        <div className="form-group-container">
          <FileUpload
            formName="specific"
            label="upload_documents_summary_of_cost"
            fileData={projectSpecific.summaryCostFile}
            name="summaryCostFile"
            form={projectSpecific}
            fetched={fetched}
            projectId={urlParams.projectId}
          />
        </div>
      </>

      <div className="form-actions flex">
        <button
          className="btn-rect btn-rect--small btn-blue"
          onClick={() => handleSaveAndContinue('continue')}
        >
          Save and continue
        </button>
        <button
          className="btn-rect btn-rect--small btn-dark"
          onClick={() => handleSaveAndContinue('exit')}
        >
          Save and exit
        </button>
        <button
          type="button"
          className="btn-link"
          onClick={() => dispatch(ProjectAction.projectStep({ step: 1 }))}
        >
          Back to previous form
        </button>
      </div>
    </Form>
  );
};

export default ProjectSpecific;
