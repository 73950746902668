const initialState = {
  userManualFetching: true,
  userManualFetched: false,
  userManualError: null,
  user_manual_files: '',
  guidelineFetching: true,
  guidelineFetched: false,
  guidelineError: null,
  guideline_files: '',
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'USER_MANUAL_FETCHING':
      state = {
        ...state,
        ...{
          userManualError: null,
          userManualFetching: true,
          userManualFetched: false,
        },
      };
      break;

    case 'USER_MANUAL_FETCHED':
      state = {
        ...state,
        ...{
          userManualError: null,
          user_manual_files: payload.data,
          userManualFetching: false,
          userManualFetched: true,
        },
      };
      break;

    case 'USER_MANUAL_ERROR':
      state = {
        ...state,
        ...{
          userManualError: payload,
          userManualFetching: false,
          userManualFetched: true,
        },
      };
      break;

    case 'GUIDELINE_FETCHED':
      state = {
        ...state,
        ...{
          guidelineError: null,
          guideline_files: payload.data,
          guidelineFetching: false,
          guidelineFetched: true,
        },
      };
      break;
    default:
      break;
  }
  return state;
};

export default reducer;
