import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Grid, Divider, Table } from 'semantic-ui-react';
import { get } from 'lodash';
import moment from 'moment';

import { API_URL, auth } from '../../../helpers';
import { localization } from '../../../localization';

const ProjectSpecificSection = ({ gate }) => {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['project_specific_view'];
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const [projectData, setProjectData] = useState();

  useEffect(() => {
    setProjectData(projectInfo[gate]);
  }, [gate]);

  const getDate = (engDate, nepDate) => {
    if (engDate && nepDate) {
      return `${moment(engDate).format('YYYY-MM-DD')} (${nepDate})`;
    }
  };

  return (
    <div name="project-section">
      <div className="project-section" id="project-section">
        <div className="header medium">{lang.section_name}</div>
        <Tab.Pane>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.is_project_a_year_on_year} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_specific.isYearlyFunding')}
                </p>
              </Grid.Column>
            </Grid.Row>
            {get(projectData, 'project_specific.isYearlyFunding') === 'No' &&
            gate === 'project_appraisal' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.project_commencement_date} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {/* {get(projectData, 'project_specific.projectStartDateAD') &&
                        moment(get(projectData, 'project_specific.projectStartDateAD')).format(
                          'YYYY-MM-DD'
                        )}{' '}
                      ({get(projectData, 'project_specific.projectStartDateBS')}){' '} */}
                      {getDate(
                        get(projectData, 'project_specific.projectStartDateAD'),
                        get(projectData, 'project_specific.projectStartDateBS')
                      )}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.estimated_project_completion_date} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {getDate(
                        get(projectData, 'project_specific.estimatedProjectEndDateAD'),
                        get(projectData, 'project_specific.estimatedProjectEndDateBS')
                      )}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              ''
            )}
            {gate === 'project_identification' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.brief_description_of_project} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p
                      className="project-info"
                      dangerouslySetInnerHTML={{
                        __html: get(projectData, 'project_specific.projectDescription'),
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.supporting_document} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {/* {get(
                                            projectData,
                                            "project_specific.projectDescriptionFile"
                                        )} */}
                      {projectData &&
                        projectData.project_specific &&
                        projectData.project_specific.projectDescriptionFile.map((item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>
                                {item.originalName ? item.originalName: item.fileName}
                                {item.fileName.length > 0 && (
                                  <>
                                    {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                    <a
                                      href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                      target="_blank"
                                      download
                                      rel="noopener noreferrer"
                                    >
                                      (Download)
                                    </a>
                                  </>
                                )}
                              </p>
                            )
                          );
                        })}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.file_description} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p
                      className="project-info"
                      style={{ display: 'inline-block', marginRight: '5px' }}
                    >
                      {projectData &&
                        projectData.project_specific &&
                        projectData.project_specific.projectDescriptionFile.map((item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <span key={index}>{item.fileDescription}</span>
                            )
                          );
                        })}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              ''
            )}
            {gate === 'project_identification' && (
              <Grid.Row>
                <Grid.Column width={5}>
                  <p>{lang.estimated_time} :</p>
                </Grid.Column>
                <Grid.Column>
                  <p className="project-info">
                    {get(projectData, 'project_specific.estimatedTime')}
                  </p>
                </Grid.Column>
              </Grid.Row>
            )}

            <Divider />

            <Grid.Row>
              <Grid.Column>
                <div className="header small">{lang.project_implementation_cost}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>
                  {/* {lang.original_cost} ({lang.usd}) : */}
                  {lang.original_cost} :
                </p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_specific.projectCostImplementation.originalCost')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>
                  {lang.original_cost} ({'Currency'}) :
                </p>
              </Grid.Column>
              {/* <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_specific.projectCostImplementation.originalCostNPR')}
                </p>
              </Grid.Column> */}
              <Grid.Column>
                <p className="project-info">
                  {get(
                    projectData,
                    'project_specific.projectCostImplementation.originalCostCurrency'
                  ) &&
                    get(
                      projectData,
                      'project_specific.projectCostImplementation.originalCostCurrency'
                    ).toUpperCase()}
                </p>
              </Grid.Column>
            </Grid.Row>
            {get(projectData, 'project_specific.projectCostImplementation.originalCostCurrency') ===
              'usd' && (
              <Grid.Row>
                <Grid.Column width={5}>
                  <p>{lang.usd_exchange_rate} :</p>
                </Grid.Column>
                <Grid.Column>
                  <p className="project-info">
                    {get(
                      projectData,
                      'project_specific.projectCostImplementation.originalCostExchangeRate'
                    )}
                  </p>
                </Grid.Column>
              </Grid.Row>
            )}
            {(projectInfo.project_status === 'ongoing' || gate === 'project_appraisal') && (
              <Grid.Row>
                <Grid.Column width={5}>
                  <p>{lang.date_of_estimate} :</p>
                </Grid.Column>
                <Grid.Column>
                  <p className="project-info">
                    {getDate(
                      get(projectData, 'project_specific.projectCostImplementation.estimateDateAD'),
                      get(projectData, 'project_specific.projectCostImplementation.estimateDateBS')
                    )}
                  </p>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.original_cost_fiscal_year}</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(
                    projectData,
                    'project_specific.projectCostImplementation.fiscalYearForOriginalCost'
                  )}
                </p>
              </Grid.Column>
            </Grid.Row>

            {gate === 'project_appraisal' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.is_project_cost_revised}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'project_specific.isProjectCostRevise')}
                    </p>
                  </Grid.Column>
                </Grid.Row>

                {get(projectData, 'project_specific.isProjectCostRevise') === 'Yes' ? (
                  <>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.revised_cost_estimated} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <p className="project-info">
                          {get(projectData, 'project_specific.revisedCost')}
                        </p>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>
                          {lang.revised_cost_estimated} ({'Currency'}):
                        </p>
                      </Grid.Column>
                      <Grid.Column>
                        <p className="project-info">
                          {get(projectData, 'project_specific.revisedCostCurrency') &&
                            get(projectData, 'project_specific.revisedCostCurrency').toUpperCase()}
                        </p>
                      </Grid.Column>
                    </Grid.Row>

                    {get(projectData, 'project_specific.revisedCostCurrency') === 'usd' && (
                      <Grid.Row>
                        <Grid.Column width={5}>
                          <p>{lang.revised_usd_exchange_rate} :</p>
                        </Grid.Column>
                        <Grid.Column>
                          <p className="project-info">
                            {get(projectData, 'project_specific.revisedCostExchangeRate')}
                          </p>
                        </Grid.Column>
                      </Grid.Row>
                    )}

                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.revised_date_estimate} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <p className="project-info">
                          {getDate(
                            get(projectData, 'project_specific.revisedDateEstimateAD'),
                            get(projectData, 'project_specific.revisedDateEstimateBS')
                          )}
                        </p>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.revised_cost_fiscal_year} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <p className="project-info">
                          {get(projectData, 'project_specific.fiscalYearForRevised')}
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.is_break_down_of_cost_under_major_heading_available} </p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectData, 'project_specific.isBreakOfCost')}</p>
              </Grid.Column>
            </Grid.Row>

            {get(projectData, 'project_specific.isBreakOfCost') === 'Yes' ? (
              <Grid.Row>
                <Grid.Column width={5}>
                  <p>{lang.summary_of_cost_under_major_heading} :</p>
                </Grid.Column>
                <Grid.Column width={11}>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{lang.cost_heading_number}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.cost_heading}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.cost}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.cost_word}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.currency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.usd_exchange_rate}</Table.HeaderCell>
                        {/* <Table.HeaderCell>{lang.total_cost_in_npr}</Table.HeaderCell> */}
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {projectData.project_specific &&
                        projectData.project_specific.summaryOfCost.map((summary, index) => {
                          return (
                            Object.keys(summary).length > 0 && (
                              <Table.Row key={index}>
                                {summary.costHeadingNumber ? (
                                  <Table.Cell>{summary.costHeadingNumber}</Table.Cell>
                                ) : (
                                  <Table.Cell></Table.Cell>
                                )}
                                {summary.costHeading ? (
                                  <Table.Cell>{summary.costHeading}</Table.Cell>
                                ) : (
                                  <Table.Cell></Table.Cell>
                                )}
                                {summary.fCostComponent ? (
                                  <Table.Cell>{summary.fCostComponent}</Table.Cell>
                                ) : (
                                  <Table.Cell></Table.Cell>
                                )}
                                {summary.fCostComponentWord ? (
                                  <Table.Cell>{summary.fCostComponentWord}</Table.Cell>
                                ) : (
                                  <Table.Cell></Table.Cell>
                                )}
                                {summary.fCostComponent ? (
                                  <Table.Cell>
                                    {summary.fCostComponent !== ''
                                      ? summary.fCostComponentCurrency &&
                                        summary.fCostComponentCurrency === 'usd'
                                        ? `${summary.fCostComponentCurrency.toUpperCase()}`
                                        : 'npr'.toUpperCase()
                                      : ''}
                                  </Table.Cell>
                                ) : (
                                  <Table.Cell></Table.Cell>
                                )}
                                {/* {summary.totalCostInNpr ? (
                                      <Table.Cell>{summary.totalCostInNpr}</Table.Cell>
                                    ) : (
                                      <Table.Cell></Table.Cell>
                                    )} */}
                                {summary.fCostComponentExchangeRate ? (
                                  <Table.Cell>{summary.fCostComponentExchangeRate}</Table.Cell>
                                ) : (
                                  <Table.Cell></Table.Cell>
                                )}
                              </Table.Row>
                            )
                          );
                        })}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            ) : (
              ''
            )}

            <Grid.Row>
              <Grid.Column width={5}>
                <p>
                  {lang.supporting_document} {lang.upload_documents_summary_of_cost} :
                </p>
              </Grid.Column>
              <Grid.Column>
                <div className="project-info file-download">
                  {projectData &&
                    projectData.project_specific &&
                    projectData.project_specific.summaryCostFile &&
                    projectData.project_specific.summaryCostFile.map((item, index) => {
                      return (
                        Object.keys(item).length !== 0 && (
                          <p key={index}>
                            {item.originalName ? item.originalName: item.fileName}
                            {item.fileName.length > 0 && (
                              <>
                                {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                <a
                                  href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                  target="_blank"
                                  download
                                  rel="noopener noreferrer"
                                >
                                  (Download)
                                </a>
                              </>
                            )}
                          </p>
                        )
                      );
                    })}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.file_description} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info" style={{ display: 'inline-block', marginRight: '5px' }}>
                  {projectData &&
                    projectData.project_specific &&
                    projectData.project_specific.summaryCostFile &&
                    projectData.project_specific.summaryCostFile.map((item, index) => {
                      return (
                        Object.keys(item).length !== 0 && <p key={index}>{item.fileDescription}</p>
                      );
                    })}
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      </div>
    </div>
  );
};

export default ProjectSpecificSection;
