export function fetching() {
  return { type: 'FETCHING' };
}

export function fetched(payload) {
  return { type: 'ASSESSMENT_FETCHED', payload };
}

export function lists(payload) {
  return {type: 'LIST_ALL_ASSESSMENT', payload}
}

export function ASSESSMENT_ERROR(payload) {
  return { type: 'ASSESSMENT_ERROR', payload };
}