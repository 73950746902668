import React, { useState, useEffect } from 'react';
import { Table, Modal, Button, Form, Pagination, Input } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import moment from 'moment';
import query from 'query-string';

import GateApi from '../../../services/Gate/Project';
import { auth } from '../../../helpers';
import Can from '../../../components/Can/can';
import { localization } from '../../../localization';
import LoaderNP from '../../../components/Loader/Loader';

import empty from '../../../assets/images/no-data.svg';
import MODERATION_STATUS from '../../../helpers/constant/ModerationStatus';

function ProjectList({ pageSize, history, pathName, page }) {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language].assessment_gate_project_list;
  const [isLoading, setIsLoading] = useState(true);
  const [projectList, setProjectList] = useState([]);
  const [projectId, setProjectId] = useState('');
  const [ministryId, setMinistryId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [action, setAction] = useState('');
  const user = useSelector(auth.getCurrentUser);
  const [activePage, setActivePage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [totalProjects, setTotalProject] = useState();

  const [queryString, setQueryString] = useState({
    page: 0,
    projectStatus: 'new',
    moderationStatus: 'completed',
    projectStage: 'appraisal',
  });

  const [pagination, setPagination] = useState({
    activePage: 1,
    column: null,
    direction: null,
  });
  const [projectData, setProjectData] = useState({
    projects: null,
    count: 0,
  });

  useEffect(() => {
    getProjectList();
  }, [queryString, pathName]);

  const getProjectList = () => {
    setIsLoading(true);
    let qString = queryString;
    qString = `?${query.stringify(queryString)}`;
    GateApi.listProjects(`${qString}`)
      .then((response) => {
        if (response.success) {
          if (pathName === 'approved') {
            const approvedProjects = response.data.projects.filter(
              (project) => project.hasPublished
            );
            setProjectData({ projects: approvedProjects, count: approvedProjects.length });
          } else {
            setProjectData({ projects: response.data.projects, count: response.data.count });
          }
          setIsLoading(false);
        } else {
          setProjectData({ projects: [], count: 0 });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toastr.error('', `${err.message}`);
      });
  };

  const deleteProject = () => {
    setModalOpen(!modalOpen);
    setAction('');
    GateApi.deleteProject({ id: projectId })
      .then((response) => {
        if (response.success) {
          toastr.success('', `${response.message}`);
          setQueryString({ ...queryString, page: activePage - 1 });
          //   setProjectData({ projects: response.data.projects, count: response.data.count });
        } else {
          toastr.error('', `${response.error.message}`);
        }
      })
      .catch((err) => toastr.error('', `${err.message}`));
  };

  const handlePaginationChange = (e, { activePage }) => {
    setActivePage(activePage);
    setPagination({ activePage });
    setQueryString({ ...queryString, page: activePage - 1 });
  };

  return (
    <>
      <div className="dashboard-header justify-between">
        <div className="flex">
          <h2 className="header-main">{lang[`${pathName}_projects`]}</h2>
          {/* <div className="project-stats flex">
            <div className="card">
              <p className="card-info">
                {isLoading ? (
                  <LoaderNP />
                ) : (
                  projectData.projects && projectData.count.toLocaleString()
                )}
              </p>
              <p className="card-label">{lang.projects}</p>
            </div>
           </div> */}
        </div>
      </div>
      <div className="project-list-container">
        {isLoading ? (
          <LoaderNP />
        ) : projectData.projects && projectData.projects.length > 0 ? (
          <>
            <Table padded className="project-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1} className="id-header  width-8">
                    {lang.project_id}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    // sorted={pagination.column === 'projectName' ? pagination.direction : null}
                    // onClick={handleSort('projectName')}
                    width={4}
                  >
                    {lang.project_name}
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>{lang.line_ministry}</Table.HeaderCell>
                  <Table.HeaderCell width={2}>{lang.department}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {projectData.projects.map((project, index) => {
                  return (
                    <Link key={project.id} to={`/project/${project.id}`} className="link-row">
                      <Table.Cell className="text-bold project-id">
                        {project.uniqueProjectId}
                      </Table.Cell>
                      <Table.Cell>
                        <p className="project-title">
                          {project.hasPublished ? (
                            <span className="icon-done">
                              <i className="material-icons">done</i>
                            </span>
                          ) : (
                            ''
                          )}{' '}
                          {project.project_name_in_english}
                        </p>
                        <div className="project-metadata">
                          {project.project_status === 'new' ? (
                            <span className="gate-label project-status dark">
                              {project.project_stage}
                            </span>
                          ) : (
                            <span className="gate-label project-status dark">Ongoing</span>
                          )}
                          {project.moderation_status && (
                            <span
                              className={`project-status ${
                                project &&
                                (MODERATION_STATUS[project.moderation_status]
                                  ? MODERATION_STATUS[project.moderation_status]
                                  : MODERATION_STATUS.draft)
                              }`}
                            >
                              {MODERATION_STATUS[project.moderation_status]
                                ? MODERATION_STATUS[project.moderation_status]
                                : MODERATION_STATUS.draft}
                            </span>
                          )}
                          {project.parent && (
                            <>
                              <span>
                                Parent:
                                {project.parent.project_name_in_english}
                              </span>
                              <span className="divider">|</span>
                            </>
                          )}
                          {project.project_budget_code ? (
                            <>
                              <span>
                                Budget code:
                                {project.project_budget_code}
                              </span>
                              <span className="divider">|</span>
                            </>
                          ) : (
                            ''
                          )}
                          {project.program_name && (
                            <>
                              <span>
                                Program:
                                {project.program_name}
                              </span>
                              <span className="divider">|</span>
                            </>
                          )}
                          {project.ranking ? (
                            <>
                              <span>
                                Ranking:
                                {project.ranking}
                              </span>
                              <span className="divider">|</span>
                            </>
                          ) : (
                            ''
                          )}

                          <span>
                            Last updated: {moment(project.updated_at).format('MMM DD, YYYY')}
                          </span>
                        </div>
                      </Table.Cell>
                      <Table.Cell>{project.ministry_name}</Table.Cell>
                      <Table.Cell>
                        <div className="column-action">
                          {project.department_name ? (
                            project.department_name
                          ) : (
                            <span className="not-available">Dept. not available</span>
                          )}
                        </div>
                        <div className="actions">
                          <Can
                            role={user.role}
                            perform="action:edit-delete"
                            data={project.isActionable}
                            yes={() => (
                              <>
                                <Link
                                  to={`/add/project?projectId=${project.id}&stage=${project.project_stage}`}
                                  onClick={(e) => e.stopPropagation()}
                                  className="btn-outline btn-outline--small btn-outline--blue"
                                >
                                  <i className="material-icons md-12 mr-2">edit</i> Edit
                                </Link>
{' '}
                                <span
                                  className="btn-outline btn-outline--small btn-outline--red"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setAction('delete');
                                    setModalOpen(!modalOpen);
                                    setProjectId(project.id);
                                  }}
                                >
                                  <i className="material-icons md-12 mr-2 material-delete">
                                    delete_outline
                                  </i>
                                  Delete
                                </span>
                              </>
                            )}
                            no={() => <></>}
                          />
                        </div>
                      </Table.Cell>
                    </Link>
                    // </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            <div className="justify-center">
              <Pagination
                defaultActivePage={pagination.activePage}
                onPageChange={handlePaginationChange}
                totalPages={Math.ceil(projectData.count / pageSize)}
                firstItem={{ 'aria-label': 'First item', content: 'First' }}
                lastItem={{ 'aria-label': 'Last item', content: 'Last' }}
                prevItem={{
                  'aria-label': 'Previous item',
                  content: 'Previous',
                }}
                nextItem={{ 'aria-label': 'First item', content: 'Next' }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="empty">
              <img src={empty} alt="no data found" />
              <p as="p" size="">
                No projects found.
                <Can
                  role={user.role}
                  perform="project:create"
                  yes={() => <Link to="/project">Please Add Project</Link>}
                  no={() => ''}
                />
              </p>
            </div>
          </>
        )}
      </div>
      )
      <Modal
        dimmer="blurring"
        open={modalOpen}
        onClose={() => setModalOpen(!modalOpen)}
        size="mini"
      >
        {action === 'delete' && (
          <>
            <Modal.Header>{lang.delete_project}</Modal.Header>
            <Modal.Content>
              <p>{lang.are_you_sure_delete_project}</p>
            </Modal.Content>
            <Modal.Actions>
              <Button color="black" onClick={() => setModalOpen(!modalOpen)}>
                Cancel
              </Button>
              <Button
                positive
                content="Yes"
                onClick={(e) => {
                  deleteProject(e);
                }}
              />
            </Modal.Actions>
          </>
        )}
      </Modal>
    </>
  );
}

export default ProjectList;
