export function fetching() {
    return { type: 'FETCHING' };
  }
  
  export function fetched(payload) {
    return { type: 'FINANCING_ARRANGEMENT_FETCHED', payload };
  }

  export function fetchedNullData() {
    return { type: 'NULL_FINANCING_ARRANGEMENT_FETCHED'};
  }