import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';

import errorReducer from './errorReducer';
import authReducer from './authReducer';
import localization from '../localization/localizationReducer';
import ministry from './ministry';
import department from './department';
import user from './users';
import dashboard from './dashboard';

import projectReducer from './form/formReducer';
import generalInformation from './form/GeneralInformation';
import contact from './form/Contact';
import projectSelection from './form/ProjectSelection';
import projectSpecific from './form/ProjectSpecific';
import landAcquisition from './form/LandAcquisition';
import projectViability from './form/ProjectViability';
import projectReadiness from './form/ProjectReadiness';
import projectPrioritization from './form/ProjectPrioritization';
import modality from './form/Modality';
import remarks from './form/Remarks';
import progress from './form/Progress';
import moderationStatus from './form/ModerationStatus';
import financingArrangement from './form/FinancingArrangement';
import projectBasicInfo from './form/BasicInfo';
import justification from './form/Justification';
import assessment from './form/Assessment';
import quarterlyAssessment from './form/QuarterlyAssessment';
import documents from './documents'

const reducers = {
  auth: authReducer,
  errors: errorReducer,
  toastr: toastrReducer,
  project: projectReducer,
  ministry,
  department,
  localization,
  user,
  dashboard,
  generalInformation,
  contact,
  projectSelection,
  projectSpecific,
  landAcquisition,
  projectViability,
  projectReadiness,
  projectPrioritization,
  modality,
  remarks,
  progress,
  moderationStatus,
  financingArrangement,
  projectBasicInfo,
  justification,
  assessment,
  quarterlyAssessment,
  documents
};

export default combineReducers(reducers);
