import React, { useEffect, useState, useRef } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import ProjectApi from '../../../services/Gate/Project';

import LoaderNP from '../../../components/Loader/Loader';
import { auth } from '../../../helpers';

import { localization } from '../../../localization';

const PBMIS = ({ history }) => {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language];
  const [dashboard, setDashboard] = useState({
    infrastructureProjects: 0,
    socialSectorProjects: 0,
    stats: {
      totalProject: 0,
      onGoingProject: 0,
      futureProject: 0,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const province = history.location.pathname;
  const provinceNo = province.split('/')[3];

  useEffect(() => {
    getProvinceData();
  }, [provinceNo]);

  const getProvinceData = () => {
    setIsLoading(true);
    ProjectApi.fetchProvinceData({ provinceNo })
      .then((response) => {
        if (response.success) {
          let stats = response.data;
          setDashboard((prevState) => {
            return {
              ...prevState,
              stats: stats.stats,
              infrastructureProjects: stats.infrastructureProjects,
              socialSectorProjects: stats.socialSectorProjects,
            };
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setDashboard((prevState) => {
            return {
              ...prevState,
              stats: {
                totalProject: 0,
                onGoingProject: 0,
                futureProject: 0,
              },
              infrastructureProjects: 0,
              socialSectorProjects: 0,
            };
          });
          toastr.error('', `${response.error.message}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };

  const getTitle = () => {
    const provinceInfo = {
      province_1: () => lang.pbmis.province1,
      province_2: () => lang.pbmis.province2,
      province_3: () => lang.pbmis.province3,
      province_4: () => lang.pbmis.province4,
      province_5: () => lang.pbmis.province5,
      province_6: () => lang.pbmis.province6,
      province_7: () => lang.pbmis.province7,
    };
    let provinceName = lang.pbmis.summary.replace(
      '%province',
      provinceInfo[`province_${provinceNo}`]
    );
    return provinceName;
  };
  return (
    <>
      <Grid columns="equal">
        <Grid.Column className="form-container print">
          <div className="operations-btn no-print">
            <div>
              <Link to="/dashboard" className="btn-normal btn-normal--medium btn-dark">
                <i className="material-icons md-bold  mr-6">keyboard_backspace</i>
                Back to Dashboard
              </Link>
            </div>
          </div>
          <div>
            <div className="dashboard-header justify-between">
              <div className="flex">
                <h2 className="header-main">{getTitle()}</h2>
              </div>
            </div>
            <div className="chart-container">
              <div
                className={`chart-wrapper bar-chart ${
                  dashboard.stats && dashboard.stats.totalProject > 0 ? '' : 'disable-action'
                }`}
              >
                {isLoading ? (
                  <LoaderNP />
                ) : (
                  <>
                    <Header
                      size="medium"
                      className="pbmis-medium-huge"
                    >
                      {lang.pbmis.total + ' ' + lang.pbmis.projects}
                    </Header>
                    {dashboard.stats && dashboard.stats.totalProject > 0 ? (
                      <>
                        {/* <div className="text-center chart-info">{dashboard.totalProjects}</div> */}
                        <Header
                          size="huge"
                          className="pbmis-medium-huge"
                        >
                          {dashboard.stats.totalProject}
                        </Header>
                      </>
                    ) : (
                      <Header size="huge" className="pbmis-medium-huge">
                        0
                      </Header>
                    )}
                  </>
                )}
              </div>

              <div
                className={`chart-wrapper bar-chart ${
                  dashboard.stats && dashboard.stats.onGoingProject > 0 ? '' : 'disable-action'
                }`}
              >
                {isLoading ? (
                  <LoaderNP />
                ) : (
                  <>
                    <Header size="medium" className="pbmis-medium-huge">
                      {lang.pbmis.ongoing + ' ' + lang.pbmis.projects}
                    </Header>
                    {dashboard.stats && dashboard.stats.onGoingProject > 0 ? (
                      <>
                        {/* <div className="text-center chart-info">{dashboard.proposedProjects}</div> */}
                        <Header size="huge" className="pbmis-medium-huge">
                          {dashboard.stats.onGoingProject}
                        </Header>
                      </>
                    ) : (
                      <Header size="huge" className="pbmis-medium-huge">
                        0
                      </Header>
                    )}
                  </>
                )}
              </div>

              <div
                className={`chart-wrapper bar-chart ${
                  dashboard.stats && dashboard.stats.futureProject > 0 ? '' : 'disable-action'
                }`}
              >
                {isLoading ? (
                  <LoaderNP />
                ) : (
                  <>
                    <Header size="medium" className="pbmis-medium-huge">
                      {lang.pbmis.future + ' ' + lang.pbmis.projects}
                    </Header>
                    {dashboard.stats && dashboard.stats.futureProject > 0 ? (
                      <>
                        {/* <div className="text-center chart-info">{dashboard.existingProjects}</div> */}
                        <Header size="huge" className="pbmis-medium-huge">
                          {dashboard.stats.futureProject}
                        </Header>
                      </>
                    ) : (
                      <Header size="huge" className="pbmis-medium-huge">
                        0
                      </Header>
                    )}
                  </>
                )}
              </div>

              <div
                className={`chart-wrapper bar-chart ${
                  dashboard.infrastructureProjects && dashboard.infrastructureProjects > 0
                    ? ''
                    : 'disable-action'
                }`}
              >
                {isLoading ? (
                  <LoaderNP />
                ) : (
                  <>
                    <Header size="medium" className="pbmis-medium-huge">
                      {lang.pbmis.infrastructure + ' ' + lang.pbmis.projects}
                    </Header>
                    {dashboard.infrastructureProjects && dashboard.infrastructureProjects > 0 ? (
                      <>
                        {/* <div className="text-center chart-info">{dashboard.infrastructureProjects}</div> */}
                        <Header size="huge" className="pbmis-medium-huge">
                          {dashboard.infrastructureProjects}
                        </Header>
                      </>
                    ) : (
                      <Header size="huge" className="pbmis-medium-huge">
                        0
                      </Header>
                    )}
                  </>
                )}
              </div>

              <div
                className={`chart-wrapper bar-chart ${
                  dashboard.socialSectorProjects && dashboard.socialSectorProjects > 0
                    ? ''
                    : 'disable-action'
                }`}
              >
                {isLoading ? (
                  <LoaderNP />
                ) : (
                  <>
                    <Header size="medium" className="pbmis-medium-huge">
                      {lang.pbmis.social_sector + ' ' + lang.pbmis.projects}
                    </Header>
                    {dashboard.socialSectorProjects && dashboard.socialSectorProjects > 0 ? (
                      <>
                        {/* <div className="text-center chart-info">{dashboard.socialSectorProjects}</div> */}
                        <Header size="huge" className="pbmis-medium-huge">
                          {dashboard.socialSectorProjects}
                        </Header>
                      </>
                    ) : (
                      <Header size="huge" className="pbmis-medium-huge">
                        0
                      </Header>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default withRouter(PBMIS);
