import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProjectList from './ProjectList';
import DashboardService from '../../../services/Gate/Dashboard';
import { localization } from '../../../localization';
import { auth } from '../../../helpers';
import * as DashboardAction from '../../../reducers/dashboard/action';

const AssessmentList = (history) => {
  const user = useSelector(auth.getCurrentUser);
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language];

  const getRoutePath = () => {
    let exactPathName = history.location.pathname.split('/')[1];
    return exactPathName;
  };

  return (
    <>
      <div className="body-container no-print">
        <ProjectList pageSize={25} history={history} pathName={getRoutePath()} page={0} />
      </div>
    </>
  );
};

export default AssessmentList;
