import React, { useEffect, useState } from 'react';
// import ReactDOM from 'react-dom';
import { Button, Checkbox, Input, Modal, Pagination, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { toastr } from 'react-redux-toastr';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import Can from '../../components/Can/can';

import LoaderNP from '../../components/Loader/Loader';

import UserService from '../../services/User';
import MinistryService from '../../services/Ministry';
import DepartmentService from '../../services/Department';

import { auth } from '../../helpers';

import { localization } from '../../localization';
// import Filter from '../../components/filter-new/filter-new';
import UserFilter from '../../components/UserFilter/UserFilter';

function UserList({ history }) {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language].users;

  const [userList, setUserList] = useState([]);
  const [errors, setErrorMessage] = useState({
    success: false,
    message: '',
  });
  const [userId, setUserId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [counts, setCountRole] = useState({});
  const [search, setSearch] = useState();
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  // const [language,setLang] = useState({})
  const [ministries, setMinistries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  const user = useSelector(auth.getCurrentUser);
  const selectedLanguage = useSelector((state) => state.localization.selectedLanguage);
  // const [column,setColumn]=useState(null);
  // const [sortIn,setSortIn] = useState(null);
  // const [allCheck,setallCheck] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [filter, setFilter] = useState({
    ministry_id: { value: 0 },
    department_id: { value: 0 },
    role: { value: 0 },
    status: { value: 0 },
  });

  const [queryString, setQueryString] = useState({
    column: null,
    direction: null,
    page: 0,
  });
  const [errObj, setValidationError] = useState({
    actions: false,
  });
  // -------hide filter--------------

  // const wrapperRef = useRef(null);

  // useEffect(() => {
  // 	document.addEventListener("mousedown", handleClickOutside, false);
  // 	return () => {
  // 	document.removeEventListener("mousedown", handleClickOutside, false);
  // 	};
  // }, []);

  // const handleClickOutside = event => {
  // 	if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
  // 	// setShowAdvanceFilter(false);
  // 	this.useState({ showAdvanceFilter: false });
  // 	}
  // };

  // ----------- hide filter ends------------------

  const actionList = [
    // {label:"Add the Approver role to the selected user(s)",value:"add_role_dataApprover"},
    // {label:"Add the Data Entry role to the selected user(s)",value:"add_role_dataEntry"},
    // {label:"Add the NPC Admin role to the selected user(s)",value:"add_role_npcAdmin"},
    { label: 'Block the selected user(s)', value: 'block' },
    // {label:"Cancel the selected user account(s)",value:"cancel_user_account"},
    // {label:"Remove the Approver role from the selected user(s)",value:"remove_role_dataApprover"},
    // {label:"Remove the Data Entry role from the selected user(s)",value:"remove_role_data_entry"},
    // {label:"Remove the NPC Admin role from the selected user(s)",value:"remove_role_npcAdmin"},
    { label: 'Unblock the selected user(s)', value: 'unblock' },
  ];
  const [applied, setApplied] = useState(false);

  useEffect(() => {
    allUsers();
    if (user.role === 'npcAdmin') {
      listMinistries();
      listDepartments({ ministryId: user.ministry.id });
    } else {
      listDepartments({ ministryId: user.ministry.id });
      filter.ministry_id = {
        label: user.ministry.name,
        value: user.ministry.id,
      };
      setFilter(filter);
    }
  }, [queryString]);

  const allUsers = (query) => {
    setLoading(true);
    UserService.listUsers(queryString)
      .then((response) => {
        if (response.success) {
          const u = response.data.users;
          setUserList(u.lists);
          setCountRole(u.counts);
          setTotalPage(u.totalPage);
          setTotalSize(u.totalSize);
          setLoading(false);
        } else {
          throw new Error(response.error.message);
        }
      })
      .catch((err) => {
        setErrorMessage({
          success: true,
          message: err.message,
        });
        history.push('/dashboard');
        toastr.error('', `${err.message}`);
      });
  };

  const listMinistries = () => {
    MinistryService.listAllMinistries(`?isDashboard=true&`)
      .then((response) => {
        const ministries = [];
        response.data.ministries.lists.forEach((m) => {
          ministries.push({ label: m.name, value: m.id });
        });
        setMinistries(ministries);
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const listDepartments = (params) => {
    DepartmentService.listDepartmentByMinistry(params)
      .then((response) => {
        setDepartmentList(response.data.department);
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const deleteUser = (e) => {
    setModalOpen(!modalOpen);
    UserService.deleteUser({ userId })
      .then((response) => {
        if (response.success) {
          allUsers(`?page=0`);
          toastr.success('', `${response.message}`);
        } else {
          throw new Error(response.error.message);
        }
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const handlePaginationChange = (e, { activePage }) => {
    // allUsers(`?page=${activePage -1}`);
    setActivePage(activePage);
    // setQueryString(`?page=${activePage -1}`)
    setQueryString({ ...queryString, page: activePage - 1 });
  };

  const handleSelect = (selectedOption) => {
    setValidationError({ ...errObj, actions: false });
  };

  const handleActions = (actions) => {
    let updateUser = true;

    if (selectedRow.length === 0) {
      updateUser = false;
    }

    if (updateUser) {
      const data = {};
      if (actions === 'block') {
        data.status = 'inactive';
      }
      if (actions === 'unblock') {
        data.status = 'active';
      }
      const updatedUserData = [...selectedRow, data];

      UserService.updateBulkUsers(updatedUserData)
        .then((response) => {
          if (response.success) {
            toastr.success('', `${response.message}`);
            history.push('/users');
            setQueryString({
              ...queryString,
              page: activePage - 1,
              column: null,
            });
            setSelectedRow([]);
            // setallCheck(false);
          } else {
            throw new Error(response.error.message);
          }
        })
        .catch((err) => {
          toastr.error('', `${err.message}`);
        });
    }
  };

  const handleSort = (type) => {
    const column = queryString.column !== type ? type : queryString.column;
    const direction =
      queryString.column !== type
        ? 'ascending'
        : queryString.direction === 'ascending'
        ? 'descending'
        : 'ascending';
    setQueryString({ ...queryString, column, direction, page: activePage - 1 });
  };

  const handleCheckBoxChange = (id) => {
    let newSelectedRow = [];
    if (selectedRow.includes(id)) {
      newSelectedRow = selectedRow.filter((userId) => userId !== id);
    }
    !selectedRow.includes(id)
      ? setSelectedRow([...selectedRow, id])
      : setSelectedRow(newSelectedRow);
  };

  const handleAllCheck = () => {
    const selectedUser = [];
    setSelectedRow([]);
    //  setallCheck(!allCheck);
    if (!isAllChecked()) {
      for (const user of userList) {
        selectedUser.push(user.id);
      }
      setSelectedRow(selectedUser);
    }
  };

  const isChecked = (userId) => selectedRow.includes(userId);

  const isAllChecked = () => {
    let checkFlag = true;
    for (const user of userList) {
      if (!selectedRow.includes(user.id)) {
        checkFlag = false;
        break;
      }
    }

    return checkFlag;
  };

  const handleSearchInputChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearch = (e = null) => {
    if (e) e.preventDefault();

    const { ministry_id, department_id, role, status } = filter;
    const query = {};

    delete queryString.ministry_id;
    delete queryString.department_id;
    delete queryString.role;
    delete queryString.status;

    if (ministry_id.value !== 0) {
      query.ministry_id = ministry_id.value;
    }
    if (department_id.value !== 0) {
      query.department_id = department_id.value;
    }
    if (role.value !== 0) {
      query.role = role.value;
    }
    if (status.value !== 0) {
      query.status = status.value;
    }
    setQueryString({
      ...queryString,
      page: activePage - 1,
      search: search ? search.trim() : '',
      ...query,
    });
  };

  const handleReset = () => {
    let ministry_id = { value: 0 };
    if (user.role !== 'npcAdmin') {
      ministry_id = { label: user.ministry.name, value: user.ministry.id };
    }
    setFilter({
      ministry_id,
      department_id: { value: 0 },
      role: { value: 0 },
      status: { value: 0 },
    });
    setQueryString({
      column: null,
      direction: null,
      page: activePage - 1,
    });
    setSearch('');
  };

  const handleFilterOptions = (name, selectedOption) => {
    setFilter((filter) => {
      return { ...filter, [name]: selectedOption };
    });
    if (name === 'ministry_id') {
      filter.department_id &&
        setFilter((filter) => {
          return { ...filter, department_id: { value: 0 } };
        });
      const dept = cloneDeep(departmentList);

      const deptfil = dept.filter((d) => selectedOption.value == d.ministry_id);
      const departments = [];
      for (const d of deptfil) {
        const check = departments.find((a) => d.title === a.label);
        if (!check)
          departments.push({
            label: d.name,
            value: d.id,
          });
      }

      setDepartments(departments);
    }
  };

  const handleFilter = () => {
    setQueryString((queryString) => {
      return { ...queryString, page: 0, filter };
    });
    setShowAdvanceFilter(!showAdvanceFilter);
  };

  const checkDepartmentDisplay = () => {
    if (user.role === 'npcAdmin' && filter.ministry_id) {
      return true;
    }
    if (user.role !== 'npcAdmin') {
      return true;
    }
    return false;
  };

  const status = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
  ];

  const roles = [
    { label: 'Npc Admin', value: 'npcAdmin' },
    { label: 'Ministry Admin/Data Approver', value: 'ministryAdmin' },
    { label: 'Data Verifier', value: 'dataApprover' },
    { label: 'Data Entry', value: 'dataEntry' },
    { label: 'Data Viewer', value: 'dataViewer' },
  ];

  const showFiltertag = (filter, label) => {
    return (
      <div className="tag">
        <span onClick={() => handleRemoveFilter(filter)}>
          <i className="material-icons close">close</i>
        </span>
        {label}
      </div>
    );
  };

  const handleRemoveFilter = (filterLabel) => {
    filter[filterLabel] = { value: 0 };
    setFilter(filter);
    handleSearch();
  };

  const handleApplyFilterStatus = () => {
    setApplied(true);
  };

  const calcDays = (loginDate) => {
    const startDate = moment(loginDate);
    const endDate = moment(new Date());

    const diffYear = endDate.diff(startDate, 'year');
    startDate.add(diffYear, 'years');
    const diffMonth = endDate.diff(startDate, 'months');
    startDate.add(diffMonth, 'months');
    const diffWeek = endDate.diff(startDate, 'weeks');
    startDate.add(diffWeek, 'weeks');
    const diff = endDate.diff(startDate, 'days');
    startDate.add(diff, 'days');
    const diffHour = endDate.diff(startDate, 'hours');
    startDate.add(diffHour, 'hours');
    const diffMin = endDate.diff(startDate, 'minutes');
    startDate.add(diffMin, 'minutes');
    const diffSec = endDate.diff(startDate, 'seconds');
    startDate.add(diffSec, 'seconds');

    let returnedData = '';
    let count = 0;
    if (diffYear) {
      returnedData = `${diffYear}${diffYear > 1 ? ' years ' : ' year '}`;
      count = diffMonth ? count + 1 : 2;
    }
    if (diffMonth && count < 2) {
      returnedData = `${returnedData + diffMonth}${diffMonth > 1 ? ' months ' : ' month '}`;
      count = diffWeek ? count + 1 : 2;
    }
    if (diffWeek && count < 2) {
      returnedData = `${returnedData + diffWeek}${diffWeek > 1 ? ' weeks ' : ' week '}`;
      count = diff ? count + 1 : 2;
    }
    if (diff && count < 2) {
      returnedData = `${returnedData + diff}${diff > 1 ? ' days ' : ' day '}`;
      count = diffHour ? count + 1 : 2;
    }
    if (diffHour && count < 2) {
      returnedData = `${returnedData + diffHour}${diffHour > 1 ? ' hrs ' : ' hr '}`;
      count = diffMin ? count + 1 : 2;
    }
    if (diffMin && count < 2) {
      returnedData = `${returnedData + diffMin}${diffMin > 1 ? ' mins ' : ' min '}`;
      count = diffSec ? count + 1 : 2;
    }
    if (diffSec && count < 2) {
      returnedData = `${returnedData + diffSec}${diffSec > 1 ? ' secs ' : ' sec '}`;
      count += 1;
    }

    returnedData = `${returnedData}ago`;
    // return `${diff} ${diff > 1 ? 'days' : 'day'} ago`;
    return returnedData;
  };

  return (
    <Can
      role={user.role}
      perform="users:list"
      yes={() => (
        <>
          {errors.success ? (
            <div>{errors.message}</div>
          ) : (
            <div className="body-container">
              <div className="dashboard-header justify-between">
                <div className="flex">
                  <h2 className="header-main">{lang.users}</h2>
                  <div className="project-stats flex">
                    <div className="card">
                      <p className="card-info">{counts.users || 0}</p>
                      <p className="card-label">{lang.users}</p>
                    </div>
                    <div className="card">
                      <p className="card-info">{counts.ministryAdmin || 0}</p>
                      <p className="card-label">{lang.ministry_admins}</p>
                    </div>
                    <div className="card">
                      <p className="card-info">{counts.dataEntry || 0}</p>
                      <p className="card-label">{lang.data_entry}</p>
                    </div>
                    <div className="card">
                      <p className="card-info">{counts.dataViewer || 0}</p>
                      <p className="card-label">{lang.data_viewer}</p>
                    </div>
                    <div className="card">
                      <p className="card-info">{counts.dataApprover || 0}</p>
                      <p className="card-label">{lang.data_approver}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="export-add-button">
                <button
                  onClick={() => history.push('/add/users')}
                  className="btn-normal btn-blue btn-shadow--blue btn-transition"
                >
                  <i className="material-icons mr-6 md-18 md-bold add">add</i>
                  {lang.add_user}
                </button>
              </div>
              {/* <Filter /> */}
              <UserFilter
                ministries={ministries}
                departments={departments}
                roles={roles}
                status={status}
                language={lang}
                handleSelect={handleFilterOptions}
                filterValues={filter || {}}
                handleSearch={handleSearch}
                handleReset={handleReset}
                handleApplyFilterStatus={handleApplyFilterStatus}
                role={user.role}
              />

              <div className="flex align-center chart-search-wrapper">
                <div>
                  <form onSubmit={(e) => handleSearch(e)}>
                    <div className="form-search">
                      <Input
                        iconPosition="left"
                        type="text"
                        placeholder="Search by typing username or email ..."
                        onChange={(e) => handleSearchInputChange(e)}
                        action={lang.search}
                        className="input-search"
                        value={search}
                      />
                      <i className="material-icons md-24">search</i>
                    </div>
                  </form>
                </div>

                <div
                  className={`bulk-operation ${
                    selectedRow.length > 0 ? 'visibility-visible' : 'visibility-hidden'
                  }`}
                >
                  <p>Select bulk operation</p>
                  <button
                    className="btn-outline btn-outline--blue btn-block"
                    onClick={() => handleActions('block')}
                  >
                    Block users
                  </button>
                  <button
                    className="btn-outline btn-outline--blue btn-unblock"
                    onClick={() => handleActions('unblock')}
                  >
                    Unblock users
                  </button>
                </div>
              </div>

              {applied && (
                <div className="filter-tags">
                  {filter.department_id.value !== 0 ||
                  filter.ministry_id.value !== 0 ||
                  filter.role.value !== 0 ||
                  filter.status.value !== 0 ? (
                    <p>{lang.filter_applied}: </p>
                  ) : (
                    ''
                  )}

                  <div className="tags-list">
                    {filter.department_id.value !== 0
                      ? showFiltertag('department_id', filter.department_id.label)
                      : ''}
                    {filter.ministry_id.value !== 0
                      ? showFiltertag('ministry_id', filter.ministry_id.label)
                      : ''}
                    {filter.status.value !== 0 ? showFiltertag('status', filter.status.label) : ''}
                    {filter.role.value !== 0 ? showFiltertag('role', filter.role.label) : ''}
                  </div>

                  {filter.department_id.value !== 0 ||
                  filter.ministry_id.value !== 0 ||
                  filter.role.value !== 0 ||
                  filter.status.value !== 0 ? (
                    <button className="btn-outline btn-outline--red" onClick={handleReset}>
                      {lang.clear_filter}
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              )}
              <div className="table-container">
                {loading ? (
                  <LoaderNP />
                ) : userList && userList.length > 0 ? (
                  <>
                    <Table sortable className="project-table no-action">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={1} className="id-header">
                            <Checkbox onChange={() => handleAllCheck()} checked={isAllChecked()} />
                          </Table.HeaderCell>
                          <Table.HeaderCell width={6}>{lang.first_name}</Table.HeaderCell>
                          <Table.HeaderCell
                            sorted={
                              queryString.column === 'username' ? queryString.direction : null
                            }
                            onClick={() => handleSort('username')}
                            width={3}
                          >
                            {lang.username}
                          </Table.HeaderCell>
                          <Table.HeaderCell width={3}>{lang.role}</Table.HeaderCell>
                          {/* <Table.HeaderCell  sorted={queryString.column === 'status' ? queryString.direction : null}
                        onClick={()=>handleSort('status')}
                        width={3}>{lang.status}</Table.HeaderCell> */}
                          <Table.HeaderCell width={4}>{lang.ministry}</Table.HeaderCell>
                          <Table.HeaderCell width={4}>{lang.department}</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        {userList.map((u, index) => {
                          return (
                            <Table.Row
                              key={u.id}
                              className={selectedRow.includes(u.id) ? 'selected-row' : ''}
                            >
                              <Table.Cell width={1}>
                                <Checkbox
                                  checked={isChecked(u.id)}
                                  onChange={() => handleCheckBoxChange(u.id)}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <p className="project-title">
                                  {`${u.first_name} ${u.last_name}`}{' '}
                                  {u.status === 'inactive' && (
                                    <span className="blocked-user">blocked user</span>
                                  )}
                                </p>
                                <div className="project-metadata">
                                  <span>
                                    Email:
                                    {u.email}
                                  </span>

                                  {u.last_login_at && (
                                    <>
                                      <span className="divider">|</span>
                                      <span>
                                        Last login:
                                        {calcDays(u.last_login_at)}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </Table.Cell>
                              <Table.Cell>{u.username}</Table.Cell>
                              {/* <Table.Cell>{u.email}</Table.Cell> */}
                              <Table.Cell>{u.roles}</Table.Cell>
                              {/* <Table.Cell>{u.status}</Table.Cell> */}
                              <Table.Cell>{u.ministry && u.ministry.name}</Table.Cell>
                              <Table.Cell>
                                {u.department ? (
                                  u.department.name
                                ) : (
                                  <span className="not-available">Dept. not available</span>
                                )}

                                <div className="actions">
                                  <Link
                                    to={`/add/users?userId=${u.id}`}
                                    onClick={(e) => e.stopPropagation()}
                                    className="btn-outline btn-outline--small btn-outline--blue"
                                  >
                                    <i className="material-icons md-12 mr-2 edit">edit</i>
                                    Edit
                                  </Link>
                                  {u.canDeleteAble ? (
                                    <span
                                      className="btn-outline btn-outline--small btn-outline--red"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setModalOpen(!modalOpen);
                                        setUserId(u.id);
                                      }}
                                    >
                                      <i className="material-icons md-12 mr-2 material-delete delete">
                                        delete_outline
                                      </i>
                                      Delete
                                    </span>
                                  ) : (
                                    <span
                                      className="btn-outline btn-outline--small btn-disabled-delete"
                                      data-tooltip={lang.user_associated_message}
                                    >
                                      <i className="material-icons md-12 mr-2 material-delete delete">
                                        delete_outline
                                      </i>
                                      Delete
                                    </span>
                                  )}
                                </div>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                    <div className="justify-center">
                      <Pagination
                        defaultActivePage={activePage}
                        onPageChange={handlePaginationChange}
                        totalPages={totalPage}
                        firstItem={{
                          'aria-label': 'First item',
                          content: 'First',
                        }}
                        lastItem={{
                          'aria-label': 'Last item',
                          content: 'Last',
                        }}
                        prevItem={{
                          'aria-label': 'Previous item',
                          content: 'Previous',
                        }}
                        nextItem={{
                          'aria-label': 'First item',
                          content: 'Next',
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="empty">
                      <p as="p" size="">
                        {lang.no_user_added}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          <Modal
            dimmer="blurring"
            open={modalOpen}
            onClose={() => setModalOpen(!modalOpen)}
            size="mini"
          >
            <Modal.Header>{lang.delete_user}</Modal.Header>
            <Modal.Content>
              <p>{lang.are_you_sure_delete_user}</p>
            </Modal.Content>
            <Modal.Actions>
              <Button color="black" onClick={() => setModalOpen(!modalOpen)}>
                Cancel
              </Button>
              <Button
                positive
                content="Yes"
                onClick={(e) => {
                  deleteUser(e);
                }}
              />
            </Modal.Actions>
          </Modal>
        </>
      )}
      no={() => (
        <div>
          <h1> You haven't permission to access this interface.</h1>
        </div>
      )}
    />
  );
}

export default UserList;
