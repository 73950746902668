import React, { useEffect, useState } from 'react';
import { Table, Pagination } from 'semantic-ui-react';
import { englishToNepaliNumber } from 'nepali-number';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import LoaderNP from '../../../components/Loader/Loader';
import { toastr } from 'react-redux-toastr';

import ClusteredBarChart from '../../../components/Chart/ClusteredBarChart';
import StackedBarChart from '../../../components/Chart/StackedBarChart';
import DonutChart from '../../../components/Chart/DonutChart';
import BarLineMixChart from '../../../components/Chart/BarLineMix';
import ReportFilter from '../../../components/ReportFilter/ReportFilter';
import BisesFilter from './filter';
import BisesService from '../../../services/Bises';
import { localization } from '../../../localization';
import { auth } from '../../../helpers';
import ProjectMap from '../../SamapurakProjectMap';
import empty from '../../../assets/images/no-data.svg';
import formatNumber from '../../../helpers/formatNumber';

const Bises = ({ isHomePage = false }) => {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['samapurak'];

  const [costDetails, setCostDetails] = useState(null);
  const [projectDetails, setProjectDetails] = useState(null);
  const [basicAreaDetails, setBasicAreaDetails] = useState(null);
  const [basicArea, setBasicArea] = useState(null);

  // const [basicAreaLegend, setBasicAreaLegend] = useState(["infrastructure_sector","social_sector","economic_sector","agriculture_land_forest_sector","good_governance_interrelated_sector"]);
  const [projectDuration, setProjectDuration] = useState(null);
  const [annualRecommendation, setAnnualRecommendation] = useState(null);
  const [allocatedRecommended, setAllocatedRecommended] = useState(null);
  const [annualAllocation, setAnnualAllocation] = useState(null);
  const [specificAnnualAllocation, setSpecificAnnualAllocation] = useState(null);
  const [detailData, setDetailData] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [basicAreaList, setBasicAreaList] = useState([]);
  const [fiscalYearList, setFiscalYearList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [mapData, setMapData] = useState([]);

  const [xaxisTitle, setXaxisTitle] = useState('province');
  const [projectDetailsLoading, setProjectDetailsLoading] = useState(true);
  const [projectDetailsTitle, setProjectDetailsTitle] = useState('state_project_details_title');

  const [costDetailsLoading, setCostDetailsLoading] = useState(true);
  const [costDetailsTitle, setCostDetailsTitle] = useState('state_cost_details');

  const [basicAreaDetailsLoading, setBasicAreaDetailsLoading] = useState(true);
  const [basicAreaDetailsTitle, setBasicAreaDetailsTitle] = useState('state_basic_area');

  const [basicAreaLoading, setBasicAreaLoading] = useState(true);
  const [projectDurationLoading, setProjectDurationLoading] = useState(true);
  const [annualRecommendationLoading, setAnnualRecommendationLoading] = useState(true);
  const [allocatedRecommendedLoading, setAllocatedRecommendedLoading] = useState(true);
  const [annualAllocationLoading, setAnnualAllocationLoading] = useState(true);
  const [specificAnnualAllocationLoading, setSpecificAnnualAllocationLoading] = useState(true);
  const [detailDataLoading, setDetailDataLoading] = useState(true);
  const [projectListLoading, setProjectListLoading] = useState(true);
  const [projectSearch, setProjectSearch] = useState('');

  const [viewPage, setViewPage] = useState('Local Level');
  const [search, setSearch] = useState('');
  const [type, setType] = useState('Old');
  const [level, setLevel] = useState('LocalLevel');
  const [currentYear, setCurrentYear] = useState('2079/80');

  const [filter, setFilter] = useState({
    provinces_id: { value: 0 },
    districts_id: { value: 0 },
    // provinces_id: [{ label: 'Select a Province', value: 0 }],
    // districts_id: [{ label: 'Select a District', value: 0 }],
    role: { value: 0 },
    status: { value: 0 },
    projectCostFrom: null,
    projectCostTo: null,
    basic_area: { value: 0 },
    year: { value: 0 },
    projectCostTo: null,
    level: viewPage,
    search: '',
  });

  const [queryString, setQueryString] = useState({ type: 'New', level: level });
  // const [queryString, setQueryString] = useState({ level: viewPage });
  const [applied, setApplied] = useState(false);
  const [mapDataLoading, setMapDataLoading] = useState(true);

  const [view, setView] = useState('table');
  const [projectList, setProjectList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalProjectCount, setTotalProjectCount] = useState(0);

  useEffect(() => {
    getProvinceList();
    getBasicAreaList();
    getFiscalYearList();
  }, []);

  useEffect(() => {
    getDetailData();
    getMapData();
    getProjectDetails();
    getCostDetails();
    getBasicAreaDetails();
    getBasicArea();
    getBasicAreaList();
    getFiscalYearList();
    getProjectDuration();
    getAnnualRecommendation();
    getAllocatedRecommended();
    getAnnualAllocation();
    getSpecificAnnualAllocation();
    changeGraphInfos();
  }, [queryString]);

  useEffect(() => {
    getProjectList();
  }, [activePage, queryString]);

  // const getProvinces = () => {
  //   // setProjectDetailsLoading(true);
  //   BisesService.getProjectDetails(queryString)
  //     .then(response => {
  //       if (response) {
  //         setProjectDetails(response.data);
  //         setProjectDetailsLoading(false);
  //       }
  //     })
  //     .catch(err => {
  //       toastr.error('', `${err.message}`);
  //     })
  // };

  const getProvinceList = () => {
    BisesService.getProvinceList(queryString)
      .then((response) => {
        if (response.data) {
          setProvinces(response?.data?.province ?? []);
          setDistrictList(response?.data?.district ?? []);
        }
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const getBasicAreaList = () => {
    BisesService.getBasicAreaList(queryString)
      .then((response) => {
        if (response) {
          setBasicAreaList(response.data);
        }
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const getFiscalYearList = () => {
    BisesService.getFiscalYearList(queryString)
      .then((response) => {
        if (response) {
          setFiscalYearList(response.data);
          if (response.data) {
            let currentYearArr = response.data.filter((year) => year.isCurrent);
            currentYearArr.length > 0 && setCurrentYear(currentYearArr[0].value);
          }
        }
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const getProjectDetails = () => {
    setProjectDetailsLoading(true);
    BisesService.getProjectDetails(queryString)
      .then((response) => {
        if (response) {
          setProjectDetails(response.data);
          setProjectDetailsLoading(false);
        }
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const getCostDetails = () => {
    setCostDetailsLoading(true);
    BisesService.getCostDetails(queryString).then((response) => {
      if (response) {
        setCostDetails(response.data);
        setCostDetailsLoading(false);
      }
    });
  };

  const getBasicAreaDetails = () => {
    setBasicAreaDetailsLoading(true);
    BisesService.getBasicAreaDetails(queryString).then((response) => {
      if (response) {
        setBasicAreaDetails(response.data);
        setBasicAreaDetailsLoading(false);
      }
    });
  };

  const getBasicArea = () => {
    setBasicAreaLoading(true);
    BisesService.getBasicArea(queryString).then((response) => {
      if (response) {
        setBasicArea(response.data);
        setBasicAreaLoading(false);
      }
    });
  };

  const getProjectDuration = () => {
    setProjectDurationLoading(true);
    BisesService.getProjectDuration(queryString).then((response) => {
      if (response) {
        setProjectDuration(response.data);
        setProjectDurationLoading(false);
      }
    });
  };

  const getAnnualRecommendation = () => {
    setAnnualRecommendationLoading(true);
    BisesService.getAnnualRecommendation(queryString).then((response) => {
      if (response) {
        setAnnualRecommendation(response.data);
        setAnnualRecommendationLoading(false);
      }
    });
  };

  const getAllocatedRecommended = () => {
    setAllocatedRecommendedLoading(true);
    BisesService.getAllocatedRecommended(queryString).then((response) => {
      if (response) {
        setAllocatedRecommended(response.data);
        setAllocatedRecommendedLoading(false);
      }
    });
  };

  const getSpecificAnnualAllocation = () => {
    setSpecificAnnualAllocationLoading(true);
    BisesService.getSpecificAnnualAllocation(queryString).then((response) => {
      if (response) {
        setSpecificAnnualAllocation(response.data);
        setSpecificAnnualAllocationLoading(false);
      }
    });
  };

  const getAnnualAllocation = () => {
    setAnnualAllocationLoading(true);
    BisesService.getAnnualAllocation(queryString).then((response) => {
      if (response) {
        setAnnualAllocation(response.data);
        setAnnualAllocationLoading(false);
      }
    });
  };

  const getMapData = () => {
    setMapDataLoading(true);
    BisesService.getMapData(queryString, isHomePage)
      .then((response) => {
        if (response) {
          setMapData(response.data);
        }
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      })
      .finally(() => setMapDataLoading(false));
  };

  const getDetailData = () => {
    setDetailDataLoading(true);
    BisesService.getDetailData(queryString)
      .then((response) => {
        setDetailData(response.data ? response.data[0] : null);
        setDetailDataLoading(false);
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const getProjectList = () => {
    let params = {};
    if (!!Object.keys(queryString).length) {
      params = { ...queryString, page: activePage, search: projectSearch };
    } else {
      params = { page: activePage, search: projectSearch };
    }

    BisesService.getProjectList(params)
      .then((response) => {
        setProjectList(response.data.paginatedData);
        setTotalProjectCount(response.data.pageCount);
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const statDetail = () => {
    return (
      detailData &&
      Object.keys(detailData).map((key) => {
        return (
          <li className="stat-list__item" key={key}>
            <div className="stat-name">{lang[key]}</div>
            <div className="stat-number">{formatNumber(detailData[key])}</div>
          </li>
        );
      })
    );
  };

  // const addCommasToCurrency = (currency) => {
  //   let x = currency;
  //   x=x.toString();
  //   let afterPoint = '';
  //   if(x.indexOf('.') > 0)
  //      afterPoint = x.substring(x.indexOf('.'),x.length);
  //   x = Math.floor(x);
  //   x=x.toString();
  //   let lastThree = x.substring(x.length-3);
  //   let otherNumbers = x.substring(0,x.length-3);
  //   if(otherNumbers != '')
  //       lastThree = ',' + lastThree;
  //   let res = otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree + afterPoint;

  //   return res;
  // }

  function costDetailsSeriesInfo() {
    return {
      legend: [{ total_cost_rs: lang.total_cost_rs }],
    };
  }

  function projectDurationSeriesInfo() {
    return {
      legend: [
        { First_Year: lang.one_year },
        { Second_Year: lang.two_year },
        { Third_Year: lang.three_year },
      ],
    };
  }

  function annualAllocationSeriesInfo() {
    return {
      legend: [
        { '2076-77_allocated_budget': lang.chart_2076_77_allocated_budget },
        { '2077-78_allocated_budget': lang.chart_2077_78_allocated_budget },
        { '2078-79_allocated_budget': lang.chart_2078_79_allocated_budget },
        { '2078-79_proposed_allocation_budget': lang.chart_2078_79_proposed_allocation_budget },
        { '2079-80_proposed_budget': lang.chart_2079_80_proposed_budget },
        { '2080-81_proposed_budget': lang.chart_2080_81_proposed_budget },
      ],
    };
  }

  function annualRecommendationSeriesInfo() {
    return {
      legend: [
        { recommended_rs_first_year: lang.recommended_rs_first_year },
        { recommended_rs_second_year: lang.recommended_rs_second_year },
        { recommended_rs_third_year: lang.recommended_rs_third_year },
      ],
    };
  }

  function allocatedRecommendedSeriesInfo() {
    return {
      legend: [
        { allocated_budget: lang.total_allocated_budget },
        { demanded_amount: lang.total_demanded_budget },
      ],
    };
  }

  function basicAreaDetailsSeriesInfo() {
    return basicAreaList.map((area) => {
      return { [area.value]: area.label };
    });
    // return [
    //   { खानेपानी: lang.water },
    //   { 'समावेशी तथा मूलप्रवाहीकरण': lang.inclusive_mainstreaming },
    //   { 'फोहोर मैला व्यवस्थापन': lang.sanitation },
    //   { 'फोहरमैला व्यवस्थापन': lang.sanitation },
    //   { 'खाद्य सुरक्षा': lang.food_security },
    //   { 'तहगत सन्तुलन': lang.level_wise_balance },
    //   { शिक्षा: lang.education },
    //   { स्वास्थ्य: lang.health },
    // ];
  }

  const handleFilterOptions = (name, selectedOption) => {
    setFilter((filter) => {
      return { ...filter, [name]: selectedOption };
    });
    if (name === 'provinces_id') {
      filter.districts_id &&
        setFilter((filter) => {
          return { ...filter, districts_id: { value: 0 } };
        });

      let dis = cloneDeep(districtList);

      let disFil = dis.filter((d) => selectedOption.value == d.province_id);

      let districtArr = [];
      for (let d of disFil) {
        districtArr.push({
          label: d.label,
          value: d.value,
        });
      }
      setDistricts(districtArr);
    }
  };

  const handleChange = (key, value) => {
    setFilter((filter) => {
      return { ...filter, [key]: value };
    });
  };

  // const handleMultiFilterOptions = (name, selectedOption) => {
  //   let newArr = [...filter[name]];
  //   let filteredArr = [];
  //   let unCheckedItem = newArr.find((data)=>data.value === selectedOption.value)
  //   if (unCheckedItem) filteredArr = newArr.filter((data)=>{ return data.value !== selectedOption.value })

  //   setFilter((filter) => {
  //     return { ...filter, [name]: filter[name][filter[name].length-1].value !== 0 ? (filteredArr.length > 0 ? [...filteredArr] : [...newArr,selectedOption] ): [selectedOption]};
  //   });
  //   if (name === 'provinces_id') {
  //     filter.districts_id &&
  //       setFilter(filter => {
  //         return { ...filter, districts_id: [{ label: 'Select a District', value: 0 }] };
  //       });

  //     let dis = cloneDeep(districtList);
  //     let selectedProvince = []
  //     let loopProvince =  filter[name][filter[name].length-1].value !== 0 ? (filteredArr.length > 0 ? [...filteredArr] : [...newArr,selectedOption] ): [selectedOption]
  //     loopProvince.forEach((province)=> {
  //       if(province.value !== 0){
  //         selectedProvince.push(province.value)
  //       }
  //     });
  //     let disFil = dis.filter(d => [...selectedProvince].includes(d.province_id));

  //     let districtArr = [];
  //     for (let d of disFil) {
  //       districtArr.push({
  //         label: d.label,
  //         value: d.value
  //       });
  //     }
  //     setDistricts(districtArr);
  //   }
  // };

  const handleSearch = (e = null) => {
    if (e) e.preventDefault();

    const {
      provinces_id,
      districts_id,
      role,
      status,
      basic_area,
      year,
      projectCostFrom,
      projectCostTo,
      search,
    } = filter;
    let query = {};

    // if(provinces_id[0].value !==0){
    //   provinces_id.map((province)=>{
    //     query.province = `${query.province ? query.province + ':' : ''}${province.value}`
    //   })
    // }

    // if(districts_id[0].value !==0){
    //   districts_id.map((district)=>{
    //     query.district = `${query.district ? query.district + ':' : ''}${district.value}`
    //   })
    // }

    if (search && search !== '') {
      query.search = search.trim();
      setSearch(search.trim());
    }

    if (provinces_id.value !== 0) {
      query.province = provinces_id.value;
    }

    if (districts_id.value !== 0) {
      query.district = districts_id.value;
    }

    if (basic_area.value != 0) {
      query.basicArea = basic_area.label;
    }

    if (year.value != 0) {
      query.year = year.value;
    }

    if (projectCostFrom && projectCostTo) {
      query.costFrom = projectCostFrom;
      query.costTo = projectCostTo;
    }

    setQueryString({
      ...queryString,
      ...query,
    });

    setActivePage(1);
  };

  const changeGraphInfos = () => {
    const { provinces_id, districts_id } = filter;

    if (provinces_id.value === 0 && districts_id.value === 0) {
      setXaxisTitle('province');
      setProjectDetailsTitle('state_project_details_title');
      setCostDetailsTitle('state_cost_details');
      setBasicAreaDetailsTitle('state_basic_area');
    }

    if (provinces_id.value !== 0 && districts_id.value === 0) {
      setXaxisTitle('district');
      setProjectDetailsTitle('district_project_details_title');
      setCostDetailsTitle('district_cost_details');
      setBasicAreaDetailsTitle('district_basic_area');
    }

    if (provinces_id.value !== 0 && districts_id.value !== 0) {
      setXaxisTitle('municipality');
      setProjectDetailsTitle('district_project_details_title');
      setCostDetailsTitle('local_cost_details');
      setBasicAreaDetailsTitle('local_basic_area');
    }
  };

  const handleReset = () => {
    setFilter({
      // provinces_id: [{ label: 'Select a Province', value: 0 }],
      // districts_id: [{ label: 'Select a District', value: 0 }],
      provinces_id: { value: 0 },
      districts_id: { value: 0 },
      role: { value: 0 },
      status: { value: 0 },
      basic_area: { value: 0 },
      year: { value: 0 },
      projectCostFrom: null,
      projectCostTo: null,
      level: viewPage,
    });
    setQueryString({
      level: viewPage,
    });
    setActivePage(1);
    setApplied(false);
  };

  const handleApplyFilterStatus = () => {
    setApplied(true);
  };

  const handlePaginationChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  const exportExcel = async () => {
    const data = await BisesService.exportBisesExcel({ ...queryString, search: search });
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('type', 'hidden');
    link.setAttribute('download', 'Bises.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const searchInputChanged = (value) => {
    setSearch(value);
    setProjectSearch(value);
  };

  const searchProject = (e) => {
    e.preventDefault();
    getProjectList();
  };

  const selectedFilterContentDisplay = () => {
    const keysNotObject = ['search', 'projectCostFrom', 'projectCostTo'];
    const denyFilter = ['role', 'status'];
    const keyMapping = {
      provinces_id: 'Province',
      districts_id: 'District',
      projectCostFrom: 'Project Cost From',
      projectCostTo: 'Project Cost To',
      basic_area: 'Basic Area',
      year: 'Year',
    };
    return Object.keys(filter).map((key) => {
      if (filter[key] && filter[key].value && !keysNotObject.includes(key)) {
        return (
          <>
            <div className="tags-list">
              <div class="tag">
                {' '}
                {keyMapping[key]} : {filter[key].label}{' '}
                <span>
                  <i class="material-icons close" onClick={() => handleSampaurakFilterClear(key)}>
                    close
                  </i>
                </span>
              </div>
            </div>
          </>
        );
      }
      if (!denyFilter.includes(key) && keysNotObject.includes(key) && filter[key]) {
        return (
          <>
            <div className="tags-list">
              <div class="tag">
                {' '}
                {keyMapping[key]} : {filter[key]}{' '}
                <span>
                  <i class="material-icons close" onClick={() => handleSampaurakFilterClear(key)}>
                    close
                  </i>
                </span>
              </div>
            </div>
          </>
        );
      }
    });
  };

  const handleSampaurakFilterClear = (removedKey) => {
    const keysNotObject = ['search', 'projectCostFrom', 'projectCostTo'];
    const parentKey = ['provinces_id', 'projectCostFrom', 'projectCostTo'];
    const dependentKey = {
      provinces_id: 'districts_id',
      projectCostTo: 'projectCostFrom',
      projectCostFrom: 'projectCostTo',
    };
    const keyMapping = {
      provinces_id: 'province',
      districts_id: 'district',
      projectCostFrom: 'projectCostFrom',
      projectCostTo: 'projectCostTo',
      basic_area: 'basic_area',
      year: 'year',
    };
    if (parentKey.includes(removedKey)) {
      setFilter((prevFilter) => {
        return {
          ...prevFilter,
          [removedKey]: keysNotObject.includes(removedKey) ? '' : { value: 0 },
          [dependentKey[removedKey]]: keysNotObject.includes(removedKey) ? '' : { value: 0 },
        };
      });
    } else {
      setFilter((prevFilter) => {
        return {
          ...prevFilter,
          [removedKey]: keysNotObject.includes(removedKey) ? '' : { value: 0 },
        };
      });
    }

    let newQuery = { ...queryString };

    if (filter.search && filter.search !== '') {
      newQuery.search = filter.search.trim();
    }

    if (!keysNotObject.includes(removedKey) && newQuery[keyMapping[removedKey]]) {
      if (parentKey.includes(removedKey)) {
        delete newQuery[keyMapping[dependentKey[removedKey]]];
      }
      delete newQuery[keyMapping[removedKey]];
    }
    if (!Object.keys(newQuery).length) {
      setApplied(false);
    }
    setQueryString({ ...newQuery });
  };

  const handleViewPage = (e) => {
    e.preventDefault();
    setViewPage(`${e.target.getAttribute('level')}`);
    setLevel(e.target.getAttribute('level'));
    // setType(e.target.getAttribute('type'));
    setFilter({
      // provinces_id: [{ label: 'Select a Province', value: 0 }],
      // districts_id: [{ label: 'Select a District', value: 0 }],
      provinces_id: { value: 0 },
      districts_id: { value: 0 },
      role: { value: 0 },
      status: { value: 0 },
      projectCostFrom: null,
      projectCostTo: null,
      basic_area: { value: 0 },
      year: { value: 0 },
      level: viewPage,
    });
    setApplied(false);
    setActivePage(1);
    setQueryString({ level: e.target.getAttribute('level'), type: 'New' });
  };

  const getYearInfo = () => {
    if (!queryString.year) {
      return language !== 'en'
        ? englishToNepaliNumber(currentYear.replace('_', '/'))
        : currentYear.replace('_', '/');
    } else {
      return language === 'en' ? filter.year.value : englishToNepaliNumber(filter.year.value);
    }
  };

  return (
    <>
      {/* <div className='flex justify-center'  */}

      <button
        className="btn-normal btn-dark btn-shadow--dark btn-transition btn-export"
        onClick={exportExcel}
      >
        <i className="material-icons md-normal mr-6">system_update_alt</i>
        {lang['export_data']}
      </button>
      <div className="body-container">
        <div className="flex justify-center">
          <div className="btn-toggle-wrapper flex label-2">
            <button
              className={`btn-toggle btn-transition ${viewPage === 'LocalLevel' ? 'active' : ''}`}
              level="LocalLevel"
              type="New"
              onClick={(e) => handleViewPage(e)}
              disabled={detailDataLoading ? 'disabled' : false}
            >
              {/* <i className="material-icons md-18 mr-6">grid_on</i> */}
              {/* {lang['table']}  */}
              {lang['local']}
            </button>
            <button
              className={`btn-toggle btn-transition ${viewPage === 'Province' ? 'active' : ''}`}
              level="Province"
              type="New"
              onClick={(e) => handleViewPage(e)}
              disabled={detailDataLoading ? 'disabled' : false}
            >
              {/* {lang['table']} */}
              {lang['province']}
            </button>
          </div>
        </div>

        <h2 className="text-center">
          आ.व. {getYearInfo()} मा विशेष अनुदान मार्फत् {viewPage} तहबाट कार्यान्वयन गर्न छानिएका
          आयोजना/कार्यक्रमहरू र विनियोजित बजेटको विवरण
        </h2>

        {applied ? (
          <div className="filter-tags">
            <p>{lang['filter_applied']}</p>
            {selectedFilterContentDisplay()}
          </div>
        ) : (
          <></>
        )}
        {!isHomePage && (
          <BisesFilter
            provinces={provinces}
            districts={districts}
            basicArea={basicAreaList}
            fiscalYear={fiscalYearList}
            level={viewPage}
            roles={['roles']}
            language={lang}
            handleSelect={handleFilterOptions}
            handleChange={handleChange}
            // handleSelect={handleMultiFilterOptions}
            filterValues={filter || {}}
            handleSearch={handleSearch}
            handleReset={handleReset}
            handleApplyFilterStatus={handleApplyFilterStatus}
            role={'npcAdmin'}
          />
        )}

        <div className="stat-map-wrapper">
          <div className="stat-map-wrapper__left">
            {detailDataLoading ? <LoaderNP /> : <ul className="stat-list">{statDetail()}</ul>}
          </div>
          <div className="stat-map-wrapper__right">
            {mapDataLoading ? (
              <LoaderNP />
            ) : (
              <ProjectMap
                isHomePage={false}
                isReport={true}
                reportData={mapData}
                level={viewPage}
              />
            )}
          </div>
        </div>

        <div className="flex justify-center">
          <div className="btn-toggle-wrapper flex label-2">
            <button
              className={`btn-toggle btn-transition ${view === 'table' ? 'active' : ''}`}
              onClick={() => setView('table')}
            >
              <i className="material-icons md-18 mr-6">grid_on</i>
              {lang['table']}
            </button>
            <button
              className={`btn-toggle btn-transition ${view === 'map' ? 'active' : ''}`}
              onClick={() => setView('map')}
            >
              <i className="material-icons md-18 mr-6">assessment</i>
              {lang['analysis']}
            </button>
          </div>
        </div>

        {view === 'table' ? (
          <>
            <div className="samapurak-project-search">
              <form onSubmit={searchProject}>
                <div className="form-search">
                  <div className="field-search">
                    <input
                      className="input-text input-icon"
                      onChange={(e) => searchInputChanged(e.target.value)}
                      type="text"
                      placeholder="Search by project name"
                    />
                    <i className="material-icons md-24">search</i>
                    <button className="button" type="submit">
                      {lang['search']}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {!!projectList?.length ? (
              <>
                <Table padded className="project-table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={2} className="id-header">
                        {lang['project_name']}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>{lang['basic_area']}</Table.HeaderCell>
                      <Table.HeaderCell width={1}>{lang['fiscal_year']}</Table.HeaderCell>
                      <Table.HeaderCell width={1}>{lang['project_duration']}</Table.HeaderCell>
                      <Table.HeaderCell width={1}>{lang['project_start_year']}</Table.HeaderCell>
                      <Table.HeaderCell width={1}>{lang['total_cost_rs']}</Table.HeaderCell>
                      <Table.HeaderCell width={1}>{lang['demanded_amount']}</Table.HeaderCell>
                      <Table.HeaderCell width={1}>
                        {lang['proposed_allocation_budget']}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {projectList.map((project) => (
                      <Table.Row key={project.project_code}>
                        <Table.Cell className="text-bold project-id">
                          {project.project_name}
                          <div className="samapurak-project-location">
                            {project.local_level_name}, {project.province}, {project.district}
                          </div>
                        </Table.Cell>
                        <Table.Cell>{project.basic_area}</Table.Cell>
                        <Table.Cell>{project.fiscal_year}</Table.Cell>
                        <Table.Cell>{project.project_duration}</Table.Cell>
                        <Table.Cell>{project.start_year}</Table.Cell>
                        <Table.Cell>{formatNumber(project.total_cost_rs)}</Table.Cell>
                        <Table.Cell>{formatNumber(project.demanded_amount * 100000)}</Table.Cell>
                        <Table.Cell>
                          {formatNumber(project['2078-79_proposed_allocation_budget'] * 100000)}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <div className="justify-center">
                  <Pagination
                    activePage={activePage}
                    onPageChange={handlePaginationChange}
                    totalPages={totalProjectCount}
                    firstItem={{ 'aria-label': 'First item', content: 'First' }}
                    lastItem={{ 'aria-label': 'Last item', content: 'Last' }}
                    prevItem={{
                      'aria-label': 'Previous item',
                      content: 'Previous',
                    }}
                    nextItem={{ 'aria-label': 'First item', content: 'Next' }}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="empty">
                  <img src={empty} alt="no data found" />
                  <p as="p" size="">
                    No projects found.
                  </p>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="samapurak-chart-wrapper">
              <div className="samapurak-chart-card">
                {projectDetailsLoading ? (
                  <LoaderNP />
                ) : (
                  <BarLineMixChart
                    titleHeader={lang[projectDetailsTitle]}
                    id="projectDetailsChart"
                    data={projectDetails}
                    categoryX="xLabel"
                    barChartValueY="project_count"
                    barChartYAxisTitle={lang.no_of_projects}
                    lineChartYAxisTitle={lang.project_budget}
                    lineChartValueY="total_cost_rs"
                    yAxisTitle=""
                    xAxisTitle={lang[xaxisTitle]}
                  />
                )}
              </div>
              <div className="samapurak-chart-card">
                {costDetailsLoading ? (
                  <LoaderNP />
                ) : (
                  <ClusteredBarChart
                    data={costDetails}
                    titleHeader={lang[costDetailsTitle]}
                    seriesInfo={costDetailsSeriesInfo()}
                    category="xLabel"
                    xAxisTitle={lang[xaxisTitle]}
                    yAxisTitle={lang.project_budget}
                    id="budgetChart"
                  />
                )}
              </div>
            </div>
            <div className="samapurak-chart-wrapper">
              <div className="samapurak-chart-card">
                {basicAreaDetailsLoading ? (
                  <LoaderNP />
                ) : (
                  <StackedBarChart
                    data={basicAreaDetails}
                    titleHeader={lang[basicAreaDetailsTitle]}
                    xAxisTitle={lang[xaxisTitle]}
                    yAxisTitle={lang.no_of_projects}
                    id="basicAreaDetailsChart"
                    seriesInfo={basicAreaDetailsSeriesInfo()}
                  />
                )}
              </div>

              <div className="samapurak-chart-card">
                {basicAreaLoading ? (
                  <LoaderNP />
                ) : (
                  <DonutChart
                    data={basicArea}
                    id="basicFieldDescriptionChart"
                    titleHeader={lang.basic_field_discription}
                    dataFieldCategory="basicArea"
                    dataFieldValue="percentage"
                  />
                )}
              </div>
            </div>

            <div className="samapurak-chart-wrapper">
              <div className="samapurak-chart-card-full">
                {projectDurationLoading ? (
                  <LoaderNP />
                ) : (
                  <ClusteredBarChart
                    data={projectDuration}
                    titleHeader={lang.project_duration}
                    seriesInfo={projectDurationSeriesInfo()}
                    category="xLabel"
                    xAxisTitle={lang[xaxisTitle]}
                    yAxisTitle={lang.no_of_projects}
                    id="durationChart"
                  />
                )}
              </div>
            </div>
            <div className="samapurak-chart-wrapper">
              <div
                className={
                  queryString.district || queryString.province
                    ? 'samapurak-chart-card'
                    : 'samapurak-chart-card-full'
                }
              >
                {allocatedRecommendedLoading ? (
                  <LoaderNP />
                ) : (
                  <ClusteredBarChart
                    data={annualAllocation}
                    titleHeader={lang.annual_allocation}
                    seriesInfo={annualAllocationSeriesInfo()}
                    category="xLabel"
                    xAxisTitle={lang[xaxisTitle]}
                    yAxisTitle={lang.allocated_budget}
                    id="annualRecommendationChart"
                  />
                )}
              </div>
              {(queryString.district || queryString.province) && (
                <div className="samapurak-chart-card">
                  {specificAnnualAllocationLoading ? (
                    <LoaderNP />
                  ) : (
                    <ClusteredBarChart
                      data={specificAnnualAllocation}
                      titleHeader={`${lang.annual_allocation}(${
                        lang[queryString.district ? 'district' : 'province']
                      })`}
                      seriesInfo={annualAllocationSeriesInfo()}
                      category="xLabel"
                      xAxisTitle={lang[queryString.district ? 'district' : 'province']}
                      yAxisTitle={lang.allocated_budget}
                      id="specificAnnualRecommendationChart"
                    />
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Bises;
