import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, withRouter } from 'react-router-dom';
import { Grid, Header, Modal } from 'semantic-ui-react';

import ProjectApi from '../../../services/Gate/Project';
import ProjectList from './ProvinceProjectList';

import BarChart from '../../../components/Chart/BarChart';
import LoaderNP from '../../../components/Loader/Loader';

import { auth } from '../../../helpers';

import { localization } from '../../../localization';

const AggregateProvince = ({ history }) => {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language];
  const [dashboard, setDashboard] = useState({
    infrastructureProjects: 0,
    socialSectorProjects: 0,
    projectMinistry: [],
    projectPriority: [],
    projectSector: [],
    totalProject: 0,
    onGoingProject: 0,
    futureProject: 0,
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(true);

  const [modalData, setModalData] = useState({});
  const [selectedProvince, setSelectedProvince] = useState('');

  const [isLoading, setIsLoading] = useState({
    statsLoading: false,
    priorityLoading: false,
    sectorLoading: false,
    ministryLoading: false,
  });

  useEffect(() => {
    getProvinceData();
  }, [selectedProvince]);

  const handleParams = (query) => {
    if (query !== '?') {
      setSelectedProvince(query);
    } else {
      setSelectedProvince('');
    }
  };

  const getProvinceData = () => {
    // setIsLoading(true);
    setShowFilter(false);
    setIsLoading((prevState) => {
      return {
        ...prevState,
        statsLoading: true,
        priorityLoading: true,
        sectorLoading: true,
        ministryLoading: true,
      };
    });
    ProjectApi.fetchProvinceData(selectedProvince ? selectedProvince : '')
      .then((response) => {
        if (response.success) {
          let provinceObj = {};
          let provinceDashboard = {
            infrastructureProjects: 0,
            socialSectorProjects: 0,
            projectMinistry: [],
            projectPriority: [],
            projectSector: [],
            totalProject: 0,
            onGoingProject: 0,
            futureProject: 0,
          };
          [response.data].map((province) => {
            let provData = { ...province };
            // console.log('provdata', provData, province);
            provinceObj[`province_${province['provinceNo']}`] = {
              stats: provData.stats,
              infrastructureProjects: provData.infrastructureProjects,
              socialSectorProjects: provData.socialSectorProjects,
            };
            // if (!selectedProvince.includes(':') && selectedProvince) {
            provinceDashboard.projectSector = provData?.sector ?? [];
            provinceDashboard.projectMinistry = provData?.ministry ?? [];
            provinceDashboard.projectPriority = provData?.priority ?? [];
            // }
            provinceDashboard[`projectSector_${province['provinceNo']}`] = provData.sector;

            if (provData.stats) {
              provinceDashboard.totalProject =
                provinceDashboard?.totalProject + provData?.stats?.totalProject ?? 0;
              provinceDashboard.onGoingProject =
                provinceDashboard?.onGoingProject + provData?.stats?.onGoingProject ?? 0;
              provinceDashboard.futureProject =
                provinceDashboard?.futureProject + provData?.stats?.futureProject ?? 0;
              provinceDashboard.infrastructureProjects =
                provinceDashboard?.infrastructureProjects + provData?.infrastructureProjects ?? 0;
              provinceDashboard.socialSectorProjects =
                provinceDashboard?.socialSectorProjects + provData?.socialSectorProjects ?? 0;
            }
          });
          // console.log(provinceObj, provinceDashboard);

          // setProvinceData((prevState) => {
          //   return {
          //     ...prevState,
          //     [`province_${}`]: {
          //       stats: stats.stats,
          //       infrastructureProjects: stats.infrastructureProjects,
          //       socialSectorProjects: stats.socialSectorProjects,
          //     },
          //   };
          // });
          setDashboard((prevState) => {
            return {
              ...prevState,
              ...provinceDashboard,
            };
          });
          setIsLoading((prevState) => {
            return {
              ...prevState,
              statsLoading: false,
              priorityLoading: false,
              sectorLoading: false,
              ministryLoading: false,
            };
          });
          setShowFilter(true);
          // if (i === 1) {
          //   setDashboard((prevState) => {
          //     return {
          //       ...prevState,
          //       projectSector: stats.sector,
          //     };
          //   });
          // }

          // setIsLoading(false);
        } else {
          setIsLoading((prevState) => {
            return {
              ...prevState,
              statsLoading: false,
              priorityLoading: false,
              sectorLoading: false,
              ministryLoading: false,
            };
          });
          setShowFilter(true);
          toastr.error('', `${response.error.message}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
    // }
  };

  const compare = (a, b) => b.count - a.count;

  const sortedProjectMinistry = useMemo(
    () =>
      [...dashboard.projectMinistry]
        .map((data) => {
          return { name: data.name, count: data.project_count };
        })
        .sort(compare),
    [dashboard.projectMinistry]
  );
  const sortedProjectPriority = useMemo(
    () =>
      [...dashboard.projectPriority]
        .map((data) => {
          return { name: data.colname, count: data.count };
        })
        .sort(compare),
    [dashboard.projectPriority]
  );
  const sortedProjectSector = useMemo(() => [...dashboard.projectSector].sort(compare), [
    dashboard.projectSector,
  ]);

  const topMinistry = () => {
    const list = cloneDeep([...sortedProjectMinistry.slice(0, 5)]);

    for (const m of list) {
      m.name = m.name.replace('Ministry of ', '');
      if (m.name.length > 23) {
        m.name = `${m.name.substring(0, 20).trim()}...`;
      } else if (m.name === 'Election Commission') {
        m.name = `${m.name.replace(/\s+/g, '')}`;
      }
    }
    return list;
  };

  const topProjectPriority = () => {
    const list = cloneDeep([...sortedProjectPriority.slice(0, 5)]);
    for (const m of list) {
      if (m.name.length > 23) {
        m.name = `${m.name.substring(0, 20).trim()}...`;
      }
    }
    return list;
  };
  const topProjectSector = () => {
    const list = cloneDeep([...sortedProjectSector.slice(0, 5)]);
    /** deployed for each province then issue will be fixed sector has sectorial_area prvios but now all will have name key */
    for (const m of list) {
      if (m.name.length > 23) {
        m.name = `${m.name.substring(0, 20).trim()}...`;
      }
    }
    return list;
  };

  const topProjectSectorColumn = () => {
    const list = cloneDeep([...sortedProjectSector.slice(0, 5)]);
    return [
      {
        province: 1,
        income: 23.5,
        expenses: 18.1,
      },
      {
        province: 2,
        income: 26.2,
      },
      {
        province: 3,
        income: 30.1,
        expenses: 23.9,
      },
      {
        province: 4,
        income: 29.5,
        expenses: 25.1,
      },
      {
        province: 5,
        income: 24.6,
        expenses: 25,
      },
    ];
    return list;
  };

  const getTitle = () => {
    let provinceNo = selectedProvince.split('province').length > 1 && selectedProvince.split('province')[1][1];
    const provinceInfo = {
      province_1: () => lang.pbmis.province1,
      province_2: () => lang.pbmis.province2,
      province_3: () => lang.pbmis.province3,
      province_4: () => lang.pbmis.province4,
      province_5: () => lang.pbmis.province5,
      province_6: () => lang.pbmis.province6,
      province_7: () => lang.pbmis.province7,
    };
    return lang.pbmis.summary.replace(
      '%province',
      !selectedProvince ? lang.pbmis.all_province : provinceInfo[`province_${provinceNo}`]
    );
  };

  const getSubTitle = () => {
    let provinceNo = selectedProvince.split('province').length > 1 && selectedProvince.split('province')[1][1];
    return provinceNo
      ? lang.pbmis.summary_sub_title.replace(
          '%link',
          // `<a href="https://p${provinceNo[0]}.pbmis.staging.yipl.com.np">${lang.pbmis.text_for_site}</a>`
          getLinkContent(provinceNo)
        )
      : '';
  };

  const getLinkContent = (provinceNo) =>{
    const provinceArrMapping = {
      '1': 'p1',
      '2': 'p2',
      '3': 'bagamati',
      '4': 'gandaki',
      '5': 'lumbini',
      '6': 'karnali',
      '7': 'supa'
  }
   return process.env.NODE_ENV === 'development' ? `<a href="https://p${provinceNo[0]}.pbmis.staging.yipl.com.np">${lang.pbmis.text_for_site}</a>` :  `<a href="https://${provinceArrMapping[`${provinceNo[0]}`]}.ppbmis.gov.np">${lang.pbmis.text_for_site}</a>`
  }
  const childRef = useRef();
  return (
    <>
      <Grid columns="equal">
        <Grid.Column className="form-container print">
          <div className="operations-btn no-print">
            <div>
              <Link to="/dashboard" className="btn-normal btn-normal--medium btn-dark">
                <i className="material-icons md-bold  mr-6">keyboard_backspace</i>
                Back to Dashboard
              </Link>
            </div>
          </div>
          <div>
            <div className="align-center pbmis-header justify-between">
              {/* <div className="flex"> */}
              <div className="header-main">{getTitle()}</div>
              {getSubTitle() && (
                <div className="pbmis-operations-btn no-print">
                  <div className="btn-normal btn-normal--medium btn-outline">
                    <i className="material-icons mr-6">error_outline</i>
                    <span dangerouslySetInnerHTML={{ __html: getSubTitle() }}></span>
                  </div>
                </div>
                // </div>
              )}
              {/* </div> */}
              {/* </div> */}
            </div>
            <div className="project-stats flex">
              <div className="card">
                {isLoading.statsLoading ? (
                  <LoaderNP />
                ) : (
                  <>
                    <p className="card-info">{dashboard.totalProject}</p>
                    <p className="card-label">{lang.pbmis.total + ' ' + lang.pbmis.projects}</p>
                  </>
                )}
              </div>
              <div className="card">
                {isLoading.statsLoading ? (
                  <LoaderNP />
                ) : (
                  <>
                    <p className="card-info">{dashboard.onGoingProject}</p>
                    <p className="card-label">{lang.pbmis.ongoing + ' ' + lang.pbmis.projects}</p>
                  </>
                )}
              </div>
              <div className="card">
                {isLoading.statsLoading ? (
                  <LoaderNP />
                ) : (
                  <>
                    <p className="card-info">{dashboard.futureProject}</p>
                    <p className="card-label">{lang.pbmis.future + ' ' + lang.pbmis.projects}</p>
                  </>
                )}
              </div>
              <div className="card">
                {isLoading.statsLoading ? (
                  <LoaderNP />
                ) : (
                  <>
                    <p className="card-info">{dashboard.infrastructureProjects}</p>
                    <p className="card-label">
                      {lang.pbmis.infrastructure + ' ' + lang.pbmis.projects}
                    </p>
                  </>
                )}
              </div>
              <div className="card">
                {isLoading.statsLoading ? (
                  <LoaderNP />
                ) : (
                  <>
                    <p className="card-info">{dashboard.socialSectorProjects}</p>
                    <p className="card-label">
                      {lang.pbmis.social_sector + ' ' + lang.pbmis.projects}
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className="chart-container">
              <div
                className={`chart-wrapper bar-chart ${
                  dashboard.projectPriority && dashboard.projectPriority.length > 0
                    ? ''
                    : 'disable-action'
                }`}
                onClick={() => {
                  setModalOpen(!modalOpen);
                  setModalData({
                    data: sortedProjectPriority,
                    title: `${lang.dashboard.project_based_on_priority}`,
                    useCan: false,
                    maxWidth: 170,
                  });
                }}
              >
                {isLoading.priorityLoading ? (
                  <LoaderNP />
                ) : (
                  <>
                    <Header size="tiny">{lang.dashboard.project_based_on_priority}</Header>
                    {dashboard.projectPriority && dashboard.projectPriority.length > 0 ? (
                      <>
                        <BarChart data={topProjectPriority()} truncateText={false} />
                        <p className="text-center chart-info">
                          Click to see chart in detail
                          <i className="material-icons ml-2">unfold_more</i>
                        </p>
                      </>
                    ) : (
                      <div className="no-result">No Result Found</div>
                    )}
                  </>
                )}
              </div>

              <div
                className={`chart-wrapper bar-chart ${
                  dashboard.projectSector && dashboard.projectSector.length > 0
                    ? ''
                    : 'disable-action'
                }`}
                onClick={() => {
                  setModalOpen(!modalOpen);
                  setModalData({
                    data: sortedProjectSector,
                    title: lang.dashboard.sectors_based_on_projects,
                    useCan: false,
                    maxWidth: 170,
                  });
                }}
              >
                {isLoading.sectorLoading ? (
                  <LoaderNP />
                ) : (
                  <>
                    <Header size="tiny">
                      {lang.dashboard.top_5_sectors}{' '}
                      <span>{lang.dashboard.based_on_no_of_projects}</span>
                    </Header>
                    {dashboard.projectSector && dashboard.projectSector.length > 0 ? (
                      <>
                        <BarChart data={topProjectSector()} truncateText={false} />
                        <p className="text-center chart-info">
                          Click to see chart in detail
                          <i className="material-icons ml-2">unfold_more</i>
                        </p>
                      </>
                    ) : (
                      <div className="no-result">No Result Found</div>
                    )}
                  </>
                )}
              </div>
              <div
                className={`chart-wrapper bar-chart ${true ? '' : 'disable-action'}`}
                onClick={() => {
                  setModalOpen(!modalOpen);
                  setModalData({
                    data: sortedProjectMinistry,
                    title: lang.dashboard.ministries_based_on_project,
                    useCan: true,
                    maxWidth: 150,
                  });
                }}
              >
                {isLoading.ministryLoading ? (
                  <LoaderNP />
                ) : (
                  <>
                    <Header size="tiny">
                      {lang.dashboard.top_5_ministries}{' '}
                      <span>{lang.dashboard.based_on_no_of_projects}</span>
                    </Header>
                    {dashboard.projectMinistry && dashboard.projectMinistry.length > 0 ? (
                      <>
                        <BarChart data={topMinistry()} truncateText={false} />
                        <p className="text-center chart-info">
                          Click to see chart in detail
                          <i className="material-icons ml-2">unfold_more</i>
                        </p>
                      </>
                    ) : (
                      <div className="no-result">No Result Found</div>
                    )}
                  </>
                )}
              </div>
            </div>
            <Modal
              open={modalOpen}
              onClose={() => setModalOpen(!modalOpen)}
              closeIcon
              className="chart-modal"
            >
              {modalData.useCan ? (
                <div className="chart-wrapper bar-chart">
                  <div className="bar-chart-header flex justify-between align-center">
                    <Header size="tiny">{modalData.title}</Header>
                    <div className="no-print">
                      <button
                        type="button"
                        className="btn-outline btn-outline--blue"
                        onClick={() => window.print()}
                      >
                        <i className="material-icons mr-6">print</i>
                        Print
                      </button>
                      <button
                        type="button"
                        className="btn-outline btn-outline--blue"
                        onClick={() => childRef.current.exportPdf()}
                      >
                        <i className="material-icons mr-6">cloud_download</i>
                        Download graph
                      </button>
                    </div>
                  </div>
                  {modalData.data && modalData.data.length > 0 ? (
                    <BarChart
                      data={modalData.data}
                      truncateText={false}
                      maxWidth={modalData.maxWidth}
                      horizontal
                      ref={childRef}
                      title={modalData.title}
                    />
                  ) : (
                    <div className="no-result">No Result Found</div>
                  )}
                </div>
              ) : (
                <div className="chart-wrapper bar-chart">
                  <div className="bar-chart-header flex justify-between align-center">
                    <Header size="tiny">{modalData.title}</Header>
                    <div className="no-print">
                      <button
                        type="button"
                        className="btn-outline btn-outline--blue"
                        onClick={() => window.print()}
                      >
                        <i className="material-icons mr-6">print</i>
                        Print
                      </button>
                      <button
                        type="button"
                        className="btn-outline btn-outline--blue"
                        onClick={() => childRef.current.exportPdf()}
                      >
                        <i className="material-icons mr-6">cloud_download</i>
                        Download graph
                      </button>
                    </div>
                  </div>
                  {modalData.data && modalData.data.length > 0 ? (
                    <BarChart
                      data={modalData.data}
                      truncateText={false}
                      maxWidth={modalData.maxWidth}
                      horizontal
                      ref={childRef}
                      title={modalData.title}
                    />
                  ) : (
                    <div className="no-result">No Result Found</div>
                  )}
                </div>
              )}
            </Modal>
          </div>

          <ProjectList
            handleParams={handleParams}
            history={history}
            projectList={dashboard?.projects ?? []}
            showFilter={showFilter}
          />
        </Grid.Column>
      </Grid>
    </>
  );
};

export default withRouter(AggregateProvince);
