import moment from 'moment';
import { cloneDeep } from 'lodash';
import * as ProjectAction from '../reducers/form/action';
import ProjectApi from '../services/Gate/Project';
import DateConvert from '../components/DateConverter/DateConverter';
import { toastr } from 'react-redux-toastr';

export const handleInputChange = (data, fetched) => (dispatch) => {
  data.event.persist();
  data.field
    ? dispatch(
        fetched({
          ...data.info,
          [data.field]: {
            ...data.info[data.field],
            [data.event.target.name]: data.event.target.value,
          },
        })
      )
    : dispatch(
        fetched({
          ...data.info,
          [data.event.target.name]: data.event.target.value,
        })
      );
};

export const handleSelectChange = (data, fetched) => (dispatch) => {
  if (data.index >= 0) {
    let repeatSelect = data.subField
      ? cloneDeep(data.info[data.field][data.subField])
      : cloneDeep(data.info[data.field]);

    let inputId = data.name;
    repeatSelect[data.index][inputId] = data.value.trim().length > 0 ? data.value : '';
    if (data.currencyField) {
      let { costName, exchangeRateName, costInWordsName } = data.currencyField;
      repeatSelect[data.index][costName] = '';
      repeatSelect[data.index][exchangeRateName] = '';
      repeatSelect[data.index][costInWordsName] = '';
    }
    data.subField
      ? dispatch(
          fetched({
            ...data.info,
            [data.field]: { ...data.info[data.field], [data.subField]: repeatSelect },
          })
        )
      : dispatch(
          fetched({
            ...data.info,
            [data.field]: repeatSelect,
          })
        );
  } else {
    let selectData = data.field ? cloneDeep(data.info[data.field]) : cloneDeep(data.info);
    selectData[data.name] = data.value;
    if (data.currencyField) {
      let { costName, exchangeRateName, costInWordsName } = data.currencyField;
      selectData[costName] = '';
      selectData[exchangeRateName] = '';
      selectData[costInWordsName] = '';
    }
    data.field
      ? dispatch(
          fetched({
            ...data.info,
            [data.field]: selectData,
          })
        )
      : dispatch(
          fetched({
            ...selectData,
          })
        );
  }
};

export const handleCheckboxChange = (data, fetched) => (dispatch) => {
  let checkItem = data.info[data.field].filter((item, index) => item === data.event.target.name);
  if (checkItem.length > 0) {
    let unCheckedIndex = data.info[data.field].indexOf(checkItem[0]);
    let newCheckBox = [];
    data.info[data.field].map((item, index) => {
      if (index !== unCheckedIndex && item) {
        newCheckBox.push(item);
      }
    });
    dispatch(
      fetched({
        ...data.info,
        [data.field]: [...newCheckBox],
      })
    );
  } else {
    data.event.target.name &&
      dispatch(
        fetched({
          ...data.info,
          [data.field]: [...data.info[data.field], data.event.target.name],
        })
      );
  }
};

export const handleRadioChange = (data, fetched) => (dispatch) => {
  const { info, value, name } = data;
  if (name === 'is_sub_project' && value === 'No' && info.projectOnBoarding.parent_id) {
    info.projectOnBoarding.parent_id = null;
  }
  if (name === 'isMasterPlan' && value === 'No' && info.isMasterPlanApproved === 'Yes') {
    info.isMasterPlanApproved = 'No';
  }

  let adDate = name.split('BS');
  if (adDate.length > 1 && name !== 'projectCostImplementation') {
    let dateInAD = adDate[0] + 'AD';
    info[dateInAD] = null;
  }

  data.field
    ? dispatch(
        fetched({
          ...data.info,
          [data.field]: {
            ...data.info[data.field],
            [data.name]: data.value,
          },
        })
      )
    : dispatch(
        fetched({
          ...data.info,
          [data.name]: data.value,
        })
      );
};

export const handleEditorChange = (data, fetched) => (dispatch) => {
  data.field
    ? dispatch(
        fetched({
          ...data.formInfo,
          [data.field]: {
            ...data.formInfo[data.field],
            [data.name]: data.fieldData.trim().length > 0 ? data.fieldData : '',
          },
        })
      )
    : dispatch(
        fetched({
          ...data.formInfo,
          [data.name]: data.fieldData.trim().length > 0 ? data.fieldData : '',
        })
      );
};

export const handleSaveAndExit = (data, type, dispatch, step, params) => {
  ProjectApi.updateProject(data, params)
    .then((response) => {
      if (response.success) {
        dispatch(ProjectAction.success(response));
        toastr.success('', `${response.message}`);
        setTimeout(() => {
          if (step === 14 && data.moderation_status) dispatch(ProjectAction.stageUpdate(data));
          if (type === 'continue') dispatch(ProjectAction.projectStep({ step }));
          else window.location = '/dashboard';
        }, 2000);
      } else {
        dispatch(ProjectAction.error(response));
        toastr.error('', `${response.error.message}`);
      }
    })
    .catch((err) => dispatch(ProjectAction.error(err)));
};

const formatDate = (convertedDate) => {
  let storingDate;
  storingDate = `${convertedDate.month}-${convertedDate.day}-${convertedDate.year}`;
  if (convertedDate.day < 10 && convertedDate.month < 10) {
    storingDate = `${convertedDate.year}-0${convertedDate.month}-0${convertedDate.day}`;
  } else if (convertedDate.month < 10) {
    storingDate = `${convertedDate.year}-0${convertedDate.month}-${convertedDate.day}`;
  } else if (convertedDate.day < 10) {
    storingDate = `${convertedDate.year}-${convertedDate.month}-0${convertedDate.day}`;
  }
  return storingDate;
};

export const handleNepaliDateChange = (data, fetched) => (dispatch) => {
  let convertedDate = DateConvert({ nepaliDate: data.selectedNepaliDate });
  let storingDate = new Date(formatDate(convertedDate));

  data.field
    ? dispatch(
        fetched({
          ...data.info,
          [data.field]: {
            ...data.info[data.field],
            [data.name]: data.value,
            [data.changeField]: storingDate,
          },
        })
      )
    : dispatch(
        fetched({
          ...data.info,
          [data.name]: data.value,
          [data.changeField]: storingDate,
        })
      );
};

export const handleEnglishDateChange = (data, fetched) => (dispatch) => {
  let convertedDate =
    moment(data.value, 'YYYY-MM-DD', true).isValid() &&
    moment(data.value).isBetween('1922-12-30', '2035-12-30') &&
    DateConvert({ englishDate: data.value }).ne;
  let storingDate = convertedDate
    ? `${convertedDate.strShortDayOfWeek}, ${convertedDate.strMonth} ${convertedDate.day}, ${convertedDate.year}`
    : '';

  if (data.index >= 0) {
    let repeatDate = data.field ? cloneDeep(data.info[data.field]) : cloneDeep(data.info);
    let inputId = data.name;
    repeatDate[data.index][inputId] = data.value;
    data.field
      ? dispatch(
          fetched({
            ...data.info,
            [data.field]: repeatDate,
          })
        )
      : dispatch(
          fetched({
            ...data.info,
            [data.name]: repeatDate,
          })
        );
  } else {
    data.field
      ? dispatch(
          fetched({
            ...data.info,
            [data.field]: {
              ...data.info[data.field],
              [data.name]: data.value,
              [data.changeField]: storingDate,
            },
          })
        )
      : data.changeField
      ? dispatch(
          fetched({
            ...data.info,
            [data.name]: data.value,
            [data.changeField]: storingDate,
          })
        )
      : dispatch(
          fetched({
            ...data.info,
            [data.name]: data.value,
          })
        );
  }
};

export const addRepeaterField = (data, fetched) => (dispatch) => {
  data.event.preventDefault();
  let repeatFieldClone = data.subField
    ? cloneDeep(data.info[data.field][data.subField])
    : cloneDeep(data.info[data.field]);
  const fieldType = repeatFieldClone.concat([{}]);

  data.subField
    ? dispatch(
        fetched({
          ...data.info,
          [data.field]: { ...data.info[data.field], [data.subField]: fieldType },
        })
      )
    : dispatch(
        fetched({
          ...data.info,
          [data.field]: fieldType,
        })
      );
};

export const handleRepeaterChange = (data, fetched) => (dispatch) => {
  data.event.preventDefault();
  let repeatFieldClone = data.subField
    ? cloneDeep(data.info[data.field][data.subField])
    : data.mainField
    ? cloneDeep(data.info[data.mainField][data.field])
    : cloneDeep(data.info[data.field]);

  let inputId = data.event.target.name;
  repeatFieldClone[data.index][inputId] =
    data.event.target.value.trim().length > 0 ? data.event.target.value : '';
  data.subField
    ? dispatch(
        fetched({
          ...data.info,
          [data.field]: { ...data.info[data.field], [data.subField]: repeatFieldClone },
        })
      )
    : data.mainField
    ? dispatch(
        fetched({
          ...data.info,
          [data.mainField]: { ...data.info[data.mainField], [data.field]: repeatFieldClone },
        })
      )
    : dispatch(
        fetched({
          ...data.info,
          [data.field]: repeatFieldClone,
        })
      );
};

export const removeRepeaterField = (data, fetched) => (dispatch) => {
  data.event.preventDefault();
  let removeFieldClone = data.subField
    ? cloneDeep(data.info[data.field][data.subField])
    : cloneDeep(data.info[data.field]);
  removeFieldClone.splice(data.index, 1);
  data.subField
    ? dispatch(
        fetched({
          ...data.info,
          [data.field]: { ...data.info[data.field], [data.subField]: removeFieldClone },
        })
      )
    : dispatch(
        fetched({
          ...data.info,
          [data.field]: removeFieldClone,
        })
      );
};

const handleImageLoading = (value, label, index, dispatch) => {
  dispatch(ProjectAction.imageLoading({ value, label, index }));
  dispatch(ProjectAction.disableImageButton({ disabled: value }));
};

export const handleFileUpload = (data, fetched) => (dispatch) => {
  try {
    let { projectId, field, name, e, info, index } = data;
    if (projectId) {
      const formData = new FormData();
      if (e.target.files[0]) {
        formData.append('image', e.target.files[0]);
        handleImageLoading(true, name, index, dispatch);
        ProjectApi.uploadFile(formData, { projectId })
          .then((response) => {
            handleImageLoading(false, name, index, dispatch);
            if (response.success) {
              let fieldData = field ? cloneDeep(info[field][name]) : cloneDeep(info[name]);

              if (fieldData[0].fileName === '' && index === 0){
                fieldData[index].fileName = response.data.filename;
                fieldData[index].originalName = response.data.originalname;
                fieldData[index].fileSize = formatBytes(response.data.size );
              }

              else fieldData.push({ fileName: response.data.filename,
                fileDescription: '', originalName: response.data.originalname,
                fileSize: formatBytes(response.data.size )});

              field
                ? dispatch(
                    fetched({
                      ...info,
                      [field]: {
                        ...info[field],
                        [name]: [...fieldData],
                      },
                    })
                  )
                : dispatch(
                    fetched({
                      ...info,
                      [name]: [...fieldData],
                    })
                  );
            } else {
              dispatch(ProjectAction.error(response));
              toastr.error('', `${response.error.message}`);
            }
          })
          .catch((err) => {
            handleImageLoading(false, name, index, dispatch);
            dispatch(ProjectAction.error(err));
            toastr.error('', `${err}`);
          });
      }
    }
  } catch (err) {
    dispatch(ProjectAction.error(err));
  }
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const handleRemoveFileUpload = (data, fetched) => (dispatch) => {
  try {
    let { projectId, field, name, e, info, index } = data;
    if (projectId) {
      let fieldData = field ? cloneDeep(info[field][name]) : cloneDeep(info[name]);
      let fileName = fieldData[index].fileName;
      ProjectApi.deleteUploadedFile({ projectId }, { fileName })
        .then((response) => {
          fieldData.splice(index, 1);
          if (fieldData.length === 0) {
            fieldData.push({ fileName: '', fileDescription: '' });
          }
          field
            ? // selectedFieldState[field][index].fileName = response.data.filename;
              dispatch(
                fetched({
                  ...info,
                  [field]: {
                    ...info[field],
                    [name]: [...fieldData],
                  },
                })
              )
            : dispatch(
                fetched({
                  ...info,
                  [name]: [...fieldData],
                })
              );
        })
        .catch((err) => {
          toastr.error('', `${err.message}`);
        });
    }
  } catch (err) {
    dispatch(ProjectAction.error(err));
  }
};

export const repeaterFieldOptimise = (data, mainField, emptyObject, subField) => {
  const filteringData = subField ? data[mainField][subField] : data[mainField];
  if (filteringData.length > 1) {
    let newSubField = filteringData.filter((item) => {
      let testForFilter = Object.keys(item).filter(
        (key) => item[key] || (item[key] && item[key].length > 0)
      );
      if (testForFilter.length > 0 && !testForFilter[0].includes('Currency')) {
        return testForFilter.length > 0 && Object.keys(item).length > 0;
      }
    });
    if (newSubField.length === 0) {
      newSubField = [...emptyObject];
    }
    return newSubField;
  }
  return filteringData;
};
