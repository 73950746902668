import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Radio } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';

import { localization } from '../../../../localization';
import { handleRadioChange, handleSaveAndExit } from '../../../../actions';
import { fetched,fetchedNullData } from '../../../../reducers/form/ProjectPrioritization/action';
import FileUpload from '../FileUpload/index';
import * as ProjectAction from '../../../../reducers/form/action';

const ProjectPrioritization = ({ urlParams }) => {
  const selectedLanguage = useSelector((state) => state.localization.selectedLanguage);
  const lang = localization[selectedLanguage].prioritization_score;

  const { projectPrioritization } = useSelector((state) => state);
  const { generalInformation } = useSelector((state) => state);

  const dispatch = useDispatch();
  const [projectError, setProjectError] = useState({
    economicGrowth_error: false,
    achievementOfSDG_error: false,
    participationLevel_error: false,
    sectoralInclusiveness_error: false,
    socialInclusiveness_error: false,
    sectoralGoal_error: false,
    achievementOfSectoralSDG_error: false,
  });
  const {projectInfo} = useSelector((state) => state.projectBasicInfo);

  useEffect(() => {
    if (projectInfo.project_prioritization_score) {
      dispatch(
        fetched({
          ...projectInfo.project_prioritization_score
        })
      );
    }
    if (!projectInfo.project_prioritization_score) {
      dispatch(fetchedNullData());
    }
  }, [projectInfo]);

  const handleSaveAndContinue = (type) => {
    let data = cloneDeep(projectPrioritization);
    delete data['step'];
    delete data['prioritizationScore'];
    data.isPriority = checkRequired();
    const requiredFields = [
      'economicGrowth',
      'achievementOfSDG',
      'participationLevel',
      'sectoralInclusiveness',
      'socialInclusiveness',
      'sectoralGoal',
      'achievementOfSectoralSDG',
    ];
    const errors = {};
    let canSubmit = true;

    for (let d in data) {
      if (
        requiredFields.includes(d) &&
        checkRequired() &&
        (data[d] === '' || data[d].length === 0)
      ) {
        errors[`${d}_error`] = true;
        canSubmit = false;
      }
    }
    setProjectError(errors);
    if (canSubmit) {
      handleSaveAndExit({ project_prioritization_score: data }, type, dispatch, 7, urlParams);
    }
  };

  const checkRequired = () => {
    return (
      generalInformation.projectPriority === 'priority1' ||
      generalInformation.projectPriority === 'priority2'
    );
  };

  return (
    <Form className="form-new">
      <div className="form-group-container">
        <p className="form-info">{lang.general_basis}</p>
        <Form.Group grouped>
          <Form.Field
            label={lang.general_running_plan}
            required={checkRequired()}
            className={projectError.economicGrowth_error ? 'error' : ''}
          />
          <Form.Group inline>
            <Form.Field
              label="0"
              control={Radio}
              name="economicGrowth"
              value="0"
              checked={projectPrioritization.economicGrowth === '0'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, economicGrowth_error: false });
              }}
            />
            <Form.Field
              label="1"
              control={Radio}
              name="economicGrowth"
              value="1"
              checked={projectPrioritization.economicGrowth === '1'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, economicGrowth_error: false });
              }}
            />
            <Form.Field
              label="2"
              control={Radio}
              name="economicGrowth"
              value="2"
              checked={projectPrioritization.economicGrowth === '2'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, economicGrowth_error: false });
              }}
            />
            <Form.Field
              label="3"
              control={Radio}
              name="economicGrowth"
              value="3"
              checked={projectPrioritization.economicGrowth === '3'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, economicGrowth_error: false });
              }}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group grouped>
          <Form.Field
            label={lang.general_development_goal}
            required={checkRequired()}
            className={projectError.achievementOfSDG_error ? 'error' : ''}
          />
          <Form.Group inline>
            <Form.Field
              label="0"
              control={Radio}
              name="achievementOfSDG"
              value="0"
              checked={projectPrioritization.achievementOfSDG === '0'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, achievementOfSDG_error: false });
              }}
            />
            <Form.Field
              label="1"
              control={Radio}
              name="achievementOfSDG"
              value="1"
              checked={projectPrioritization.achievementOfSDG === '1'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, achievementOfSDG_error: false });
              }}
            />
            <Form.Field
              label="2"
              control={Radio}
              name="achievementOfSDG"
              value="2"
              checked={projectPrioritization.achievementOfSDG === '2'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, achievementOfSDG_error: false });
              }}
            />
            <Form.Field
              label="3"
              control={Radio}
              name="achievementOfSDG"
              value="3"
              checked={projectPrioritization.achievementOfSDG === '3'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, achievementOfSDG_error: false });
              }}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group grouped>
          <Form.Field
            label={lang.participation}
            required={checkRequired()}
            className={projectError.participationLevel_error ? 'error' : ''}
          />
          <Form.Group inline>
            <Form.Field
              label="0"
              control={Radio}
              name="participationLevel"
              value="0"
              checked={projectPrioritization.participationLevel === '0'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, participationLevel_error: false });
              }}
            />
            <Form.Field
              label="1"
              control={Radio}
              name="participationLevel"
              value="1"
              checked={projectPrioritization.participationLevel === '1'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, participationLevel_error: false });
              }}
            />
            <Form.Field
              label="2"
              control={Radio}
              name="participationLevel"
              value="2"
              checked={projectPrioritization.participationLevel === '2'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, participationLevel_error: false });
              }}
            />
            <Form.Field
              label="3"
              control={Radio}
              name="participationLevel"
              value="3"
              checked={projectPrioritization.participationLevel === '3'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, participationLevel_error: false });
              }}
            />
          </Form.Group>
        </Form.Group>
      </div>

      <div className="form-group-container">
        <p className="form-info">{lang.consolidation_contribution}</p>
        <Form.Group grouped>
          <Form.Field
            label={lang.local_contribution}
            required={checkRequired()}
            className={projectError.sectoralInclusiveness_error ? 'error' : ''}
          />
          <Form.Group inline>
            <Form.Field
              label="0"
              control={Radio}
              name="sectoralInclusiveness"
              value="0"
              checked={projectPrioritization.sectoralInclusiveness === '0'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, sectoralInclusiveness_error: false });
              }}
            />
            <Form.Field
              label="1"
              control={Radio}
              name="sectoralInclusiveness"
              value="1"
              checked={projectPrioritization.sectoralInclusiveness === '1'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, sectoralInclusiveness_error: false });
              }}
            />
            <Form.Field
              label="2"
              control={Radio}
              name="sectoralInclusiveness"
              value="2"
              checked={projectPrioritization.sectoralInclusiveness === '2'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, sectoralInclusiveness_error: false });
              }}
            />
            <Form.Field
              label="3"
              control={Radio}
              name="sectoralInclusiveness"
              value="3"
              checked={projectPrioritization.sectoralInclusiveness === '3'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, sectoralInclusiveness_error: false });
              }}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group grouped>
          <Form.Field
            label={lang.social_contribution}
            required={checkRequired()}
            className={projectError.socialInclusiveness_error ? 'error' : ''}
          />
          <Form.Group inline>
            <Form.Field
              label="0"
              control={Radio}
              name="socialInclusiveness"
              value="0"
              checked={projectPrioritization.socialInclusiveness === '0'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, socialInclusiveness_error: false });
              }}
            />
            <Form.Field
              label="1"
              control={Radio}
              name="socialInclusiveness"
              value="1"
              checked={projectPrioritization.socialInclusiveness === '1'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, socialInclusiveness_error: false });
              }}
            />
            <Form.Field
              label="2"
              control={Radio}
              name="socialInclusiveness"
              value="2"
              checked={projectPrioritization.socialInclusiveness === '2'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, socialInclusiveness_error: false });
              }}
            />
            <Form.Field
              label="3"
              control={Radio}
              name="socialInclusiveness"
              value="3"
              checked={projectPrioritization.socialInclusiveness === '3'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, socialInclusiveness_error: false });
              }}
            />
          </Form.Group>
        </Form.Group>
      </div>

      <div className="form-group-container">
        <p className="form-info">{lang.area_basis}</p>
        <Form.Group grouped>
          <Form.Field
            label={lang.running_plan}
            required={checkRequired()}
            className={projectError.sectoralGoal_error ? 'error' : ''}
          />
          <Form.Group inline>
            <Form.Field
              label="0"
              control={Radio}
              name="sectoralGoal"
              value="0"
              checked={
                projectPrioritization.sectoralGoal && projectPrioritization.sectoralGoal === '0'
              }
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, sectoralGoal_error: false });
              }}
            />
            <Form.Field
              label="1"
              control={Radio}
              name="sectoralGoal"
              value="1"
              checked={
                projectPrioritization.sectoralGoal && projectPrioritization.sectoralGoal === '1'
              }
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, sectoralGoal_error: false });
              }}
            />
            <Form.Field
              label="2"
              control={Radio}
              name="sectoralGoal"
              value="2"
              checked={
                projectPrioritization.sectoralGoal && projectPrioritization.sectoralGoal === '2'
              }
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, sectoralGoal_error: false });
              }}
            />
            <Form.Field
              label="3"
              control={Radio}
              name="sectoralGoal"
              value="3"
              checked={
                projectPrioritization.sectoralGoal && projectPrioritization.sectoralGoal === '3'
              }
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, sectoralGoal_error: false });
              }}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group grouped>
          <Form.Field
            label={lang.ultimate_development}
            required={checkRequired()}
            className={projectError.achievementOfSectoralSDG_error ? 'error' : ''}
          />
          <Form.Group inline>
            <Form.Field
              label="0"
              control={Radio}
              name="achievementOfSectoralSDG"
              value="0"
              checked={projectPrioritization.achievementOfSectoralSDG === '0'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, achievementOfSectoralSDG_error: false });
              }}
            />
            <Form.Field
              label="1"
              control={Radio}
              name="achievementOfSectoralSDG"
              value="1"
              checked={projectPrioritization.achievementOfSectoralSDG === '1'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, achievementOfSectoralSDG_error: false });
              }}
            />
            <Form.Field
              label="2"
              control={Radio}
              name="achievementOfSectoralSDG"
              value="2"
              checked={projectPrioritization.achievementOfSectoralSDG === '2'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, achievementOfSectoralSDG_error: false });
              }}
            />
            <Form.Field
              label="3"
              control={Radio}
              name="achievementOfSectoralSDG"
              value="3"
              checked={projectPrioritization.achievementOfSectoralSDG === '3'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: projectPrioritization,
                      value,
                      name,
                    },
                    fetched
                  )
                );
                setProjectError({ ...projectError, achievementOfSectoralSDG_error: false });
              }}
            />
          </Form.Group>
        </Form.Group>
      </div>
      <div className="form-group-container">
        <FileUpload
          formName="prioritization_score"
          label="upload_project_prioritization"
          fileData={projectPrioritization.projectPrioritizationFile}
          name="projectPrioritizationFile"
          form={projectPrioritization}
          fetched={fetched}
          projectId={urlParams.projectId}
        />
      </div>
      <div className="form-group-container">
        <Form.Field>
          <label>{lang.score} : <b>{projectPrioritization.prioritizationScore} %</b></label>
        </Form.Field>
      </div>

      <div className="form-actions flex">
        <button
          className="btn-rect btn-rect--small btn-blue"
          onClick={() => handleSaveAndContinue('continue')}
        >
          Save and continue
        </button>
        <button
          className="btn-rect btn-rect--small btn-dark"
          onClick={() => handleSaveAndContinue('exit')}
        >
          Save and exit
        </button>
        <button
          type="button"
          className="btn-link"
          onClick={() => dispatch(ProjectAction.projectStep({ step: 5 }))}
        >
          Back to previous form
        </button>
      </div>
    </Form>
  );
};

export default ProjectPrioritization;
