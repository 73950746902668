import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Grid } from 'semantic-ui-react';
import { get } from 'lodash';

import { auth } from '../../../helpers';

import { localization } from '../../../localization';

const ProjectImplementationSection = ({ gate }) => {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['modality_view'];
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const [projectData, setProjectData] = useState();

  useEffect(() => {
    setProjectData(projectInfo[gate]);
  }, [gate]);

  const modalityList = [
    { text: 'Public (GON)', value: 'publicGON', key: 'publicGON' },
    { text: 'PPP', value: 'ppp', key: 'ppp' },
    { text: 'Private', value: 'private', key: 'private' },
    { text: 'Other', value: 'other', key: 'other' },
  ];

  const privateType = [
    { text: 'Domestic', value: 'domestic', key: 'domestic' },
    { text: 'Foreign', value: 'foreign', key: 'foreign' },
    { text: 'Both', value: 'both', key: 'both' },
  ];

  const typePPP = [
    { text: 'BT', value: 'bt', key: 'bt' },
    { text: 'BOOT', value: 'boot', key: 'boot' },
    { text: 'BOT', value: 'bot', key: 'bot' },
    { text: 'EPC & F', value: 'epc', key: 'epc' },
    { text: 'Other', value: 'other', key: 'other' },
  ];

  const getFormattedValue = (filteringArray, value) => {
    let filteredVal = filteringArray.filter((type) => value === type.value);
    if (filteredVal.length > 0) {
      return filteredVal[0].text;
    }
  };

  return (
    <div name="project-implementation">
      <div className="project-section" id="project-implementation">
        <div className="header medium">
          {projectInfo.project_status === 'new' && gate === 'project_identification'
            ? lang.form_name_alternate
            : lang.section_name}
        </div>
        <Tab.Pane>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.project_implementation} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {getFormattedValue(
                    modalityList,
                    get(
                      projectData,
                      'project_implementation_modality.projectImplementationModalType'
                    )
                  )}
                </p>
              </Grid.Column>
            </Grid.Row>
            {get(projectData, 'project_implementation_modality.projectImplementationModalType') ===
            'ppp' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.ppp_type} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {getFormattedValue(
                        typePPP,
                        get(projectData, 'project_implementation_modality.PPPType')
                      )}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                {get(projectData, 'project_implementation_modality.PPPType') === 'other' && (
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <p>{lang.ppp_type_description} :</p>
                    </Grid.Column>
                    <Grid.Column>
                      <p className="project-info">
                        {get(projectData, 'project_implementation_modality.otherPPPType')}
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                )}
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.ppp_company} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {projectData &&
                        projectData.project_implementation_modality.PPPCompanyName &&
                        projectData.project_implementation_modality.PPPCompanyName.map(
                          (company, index) => {
                            return (
                              <span key={index} className="general-array">
                                {company.companyName}
                              </span>
                            );
                          }
                        )}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              ''
            )}

            {get(projectData, 'project_implementation_modality.projectImplementationModalType') ===
            'private' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.private_type} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {getFormattedValue(
                        typePPP,
                        get(projectData, 'project_implementation_modality.privateType')
                      )}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.private_company} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {projectData &&
                        projectData.project_implementation_modality.privateCompanyName &&
                        projectData.project_implementation_modality.privateCompanyName.map(
                          (company, index) => {
                            return (
                              <span key={index} className="general-array">
                                {company.companyName}
                              </span>
                            );
                          }
                        )}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              ''
            )}

            {get(projectData, 'project_implementation_modality.projectImplementationModalType') ===
            'other' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.other_detail} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'project_implementation_modality.otherImplementationModal')}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              ''
            )}

            {/* Only remove after making sure that this condition does not exist */}
            {/* {get(
                        projectData,
                        "project_implementation_modality.projectImplementationModalType"
                    ) === "Public (GON)" ? (
                        <>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <p>Funding source :</p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p className="project-info">
                                        {get(
                                            projectData,
                                            "project_implementation_modality.fundingSourcePublicProjectModal"
                                        )}
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </>
                    ) : (
                        ""
                    )} */}
          </Grid>
        </Tab.Pane>
      </div>
    </div>
  );
};

export default ProjectImplementationSection;
