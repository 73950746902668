import React from 'react';
import { Form, Select } from 'semantic-ui-react';

const ExampleSelect = () => {
  return (
    <>
      {/* Form field select start */}
      <Form.Field
        control={Select}
        label="Gender"
        options={[
          { key: 'm', text: 'Male', value: 'male' },
          { key: 'f', text: 'Female', value: 'female' },
          { key: 'o', text: 'Other', value: 'other' },
        ]}
        placeholder="Gender"
        width={8}
        search
      />
      {/* Form field select end */}
    </>
  );
};

export default ExampleSelect;
