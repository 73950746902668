import React, { useEffect, useState } from 'react';
import { Table, Modal, Button, Pagination, Header } from 'semantic-ui-react';

import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import moment from 'moment';

import empty from './../../../assets/images/no-data.svg';
// import published from '../../../assets/images/published.png';

import { auth } from '../../../helpers';

import { localization } from '../../../localization';

import LoaderNP from '../../../components/Loader/Loader';
import Can from '../../../components/Can/can';

import ProjectService from '../../../services/Gate/Project';

const ProjectList = ({ history }) => {
  const user = useSelector(auth.getCurrentUser);
  const selectedLanguage = useSelector(auth.getSelectedLanguage);
  const lang = localization[selectedLanguage];
  const language = lang.closed_projects;

  const [pagination, setPagination] = useState({
    activePage: 1,
    column: null,
    direction: null,
  });
  const [projectData, setProjectData] = useState({
    projects: null,
    count: 0,
  });

  useEffect(() => {
    listProjects(`?page=0&pageSize=25`);
  }, []);

  // useEffect(() => {
  //   searchProject()(null);
  // }, [pagination.activePage]);

  // const deleteProject = (e) => {
  //   e.preventDefault();
  //   ProjectService.deleteProject({ id: modalData.projectId })
  //     .then((response) => {
  //       if (response.success) {
  //         toastr.success('', `${response.message}`);
  //         setProjectData({ projects: response.data.projects, count: response.data.count });
  //       } else {
  //         toastr.error('', `${response.error.message}`);
  //       }
  //     })
  //     .catch((err) => toastr.error('', `${err.message}`));
  //   setModatData({ ...modalData, modalOpen: !modalData.modalOpen });
  // };

  // const getQueryString = (appFilters=null) => {
  //   const joinValues = (arr, type = false) => {
  //     return type ? map(arr, 'label').join(':') : map(arr, 'value').join(':');
  //   };
  //   let f=filters;

  //   const appliedFilters = [];
  //   let queryString = '?';

  //   if (appFilters) {
  //     f=appFilters
  //   }

  //   for (const filter in f) {
  //     if (typeof f[filter] === 'string' && f[filter] !== '') {
  //       queryString = `${queryString}${filter}=${f[filter].trim()}&`;
  //     } else if (f[filter] instanceof Array && f[filter][0].value !== 0) {
  //       queryString = `${queryString}${filter}=${joinValues(f[filter])}&`;
  //       appliedFilters.push({ [filter]: f[filter] });
  //     } else if (
  //       typeof f[filter] === 'object' &&
  //       !Array.isArray(f[filter]) &&
  //       f[filter].value !== 0
  //     ) {
  //       queryString = `${queryString}${filter}=${f[filter].value}&`;
  //     }
  //   }

  //   setAppliedFilters(appliedFilters);

  //   return queryString;
  // };

  const searchProject = () => (e) => {
    if (e) e.preventDefault();
    let { activePage, column, direction } = pagination;
    activePage = activePage - 1;
    let queryString;
    if (column) {
      queryString = `${queryString}column=${column}&sort=${direction}&`;
    }

    queryString = `${queryString}page=${activePage}&pageSize=25`;

    listProjects(queryString);
  };

  const handlePaginationChange = (e, { activePage }) => {
    setPagination({ ...pagination, activePage });
  };

  const handleSort = (clickedColumn) => () => {
    const { column, direction } = pagination;
    if (column !== clickedColumn) {
      setPagination({
        column: clickedColumn,
        direction: 'ascending',
        activePage: 1,
      });

      return;
    }
    setPagination({
      ...pagination,
      direction: direction === 'ascending' ? 'descending' : 'ascending',
      activePage: 1,
    });
  };

  const handleRoute = (projectID) => {
    history.push(`/project/${projectID}`);
  };

  const listProjects = (queryString) => {
    ProjectService.listClosedProjects(`${queryString}`)
      .then((response) => {
        if (response.success) {
          setProjectData({ projects: response.data.projects, count: response.data.count });
        }
      })
      .catch((err) => toastr.error('', `${err.message}`));
  };

  return (
    <>
      <div className="body-container no-print">
        <div className="flex">
          <h2 className="header-main">{language.projects}</h2>
          <div className="project-stats flex">
            <div className="card">
              <p className="card-info">{projectData && projectData.count.toLocaleString()}</p>
              <p className="card-label">{language.projects}</p>
            </div>
            <div className="card">
              <p className="card-info"></p>
              <p className="card-label"></p>
            </div>
            <div className="card">
              <p className="card-info"></p>
              <p className="card-label"></p>
            </div>
            <div className="card">
              <p className="card-info"></p>
              <p className="card-label"></p>
            </div>
            <div className="card">
              <p className="card-info"></p>
              <p className="card-label"></p>
            </div>
          </div>
        </div>

        {projectData && projectData.projects === null ? (
          <LoaderNP />
        ) : projectData.projects && projectData.projects.length > 0 ? (
          <>
            <Table padded className="project-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1} className="id-header">
                    {language.project_id}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={pagination.column === 'projectName' ? pagination.direction : null}
                    onClick={handleSort('projectName')}
                    width={4}
                  >
                    {language.project_name}
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>{language.line_ministry}</Table.HeaderCell>
                  {/*<Table.HeaderCell width={2}>*/}
                  {/*{language.agency}*/}
                  {/*</Table.HeaderCell>*/}
                  <Table.HeaderCell width={2}>{language.department}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {projectData.projects.map((project, index) => {
                  return (
                    <Table.Row
                      key={project.id}
                      onClick={() => {
                        handleRoute(project.id);
                      }}
                    >
                      <Table.Cell className="text-bold project-id">{project.id}</Table.Cell>
                      <Table.Cell>
                        <p className="project-title">
                          {project.hasPublished ? (
                            <div className="icon-done">
                              <i className="material-icons">done</i>
                            </div>
                          ) : (
                            ''
                          )}{' '}
                          {project.project_name_in_english}
                        </p>
                        <div className="project-metadata">
                          {project.project_status === 'new' ? (
                            <span className="gate-label project-status dark">
                              {project.project_stage}
                            </span>
                          ) : project.project_status === 'closed' ? (
                            <span className="gate-label project-status dark">Closed</span>
                          ) : (
                            <span className="gate-label project-status dark">Ongoing</span>
                          )}
                          {/* {project.moderation_status && (
                            <span
                              className={`project-status
																		${
                                      project &&
                                      (project.moderation_status === 'published'
                                        ? 'verification'
                                        : project.moderation_status === 'completed'
                                        ? 'submitted'
                                        : 'draft')
                                    }`}
                            >
                              {project.moderation_status === 'published'
                                ? 'verification'
                                : project.moderation_status === 'completed'
                                ? 'submitted'
                                : 'draft'}
                            </span>
                          )} */}
                          {project.parent && (
                            <>
                              <span>Parent: {project.parent.project_name_in_english}</span>
                              <span className="divider">|</span>
                            </>
                          )}
                          {project.project_budget_code ? (
                            <>
                              <span>Budget code: {project.project_budget_code}</span>
                              <span className="divider">|</span>
                            </>
                          ) : (
                            ''
                          )}
                          {project.program_name && (
                            <>
                              <span
                                onClick={(e) => {
                                  searchProject()(null);
                                }}
                              >
                                Program: {project.program_name}
                              </span>
                              <span className="divider">|</span>
                            </>
                          )}
                          {project.ranking ? (
                            <>
                              <span>Ranking: {project.ranking}</span>
                              <span className="divider">|</span>
                            </>
                          ) : (
                            ''
                          )}

                          <span>
                            Last updated: {moment(project.updated_at).format('MMM DD, YYYY')}
                          </span>
                        </div>
                      </Table.Cell>
                      <Table.Cell>{project.ministry_name}</Table.Cell>
                      <Table.Cell>
                        <div className="column-action">
                          {project.department_name ? (
                            project.department_name
                          ) : (
                            <span className="not-available">Dept. not available</span>
                          )}
                        </div>
                        <div className="actions">
                          <Can
                            role={user.role}
                            perform="action:edit-delete"
                            data={project.isActionable}
                            yes={() => (
                              <>
                                <Link
                                  to={`/add/project?projectId=${project.id}&stage=${project.project_stage}`}
                                  onClick={(e) => e.stopPropagation()}
                                  className="btn-outline btn-outline--small btn-outline--blue"
                                >
                                  <i className="material-icons md-12 mr-2">edit</i> Edit
                                </Link>{' '}
                                <span
                                  className="btn-outline btn-outline--small btn-outline--red"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <i className="material-icons md-12 mr-2 material-delete">
                                    delete_outline
                                  </i>
                                  Delete
                                </span>
                              </>
                            )}
                            no={() => <></>}
                          />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            <div className="justify-center">
              <Pagination
                defaultActivePage={pagination.activePage}
                onPageChange={handlePaginationChange}
                totalPages={Math.ceil(projectData.count / 25)}
                firstItem={{ 'aria-label': 'First item', content: 'First' }}
                lastItem={{ 'aria-label': 'Last item', content: 'Last' }}
                prevItem={{
                  'aria-label': 'Previous item',
                  content: 'Previous',
                }}
                nextItem={{ 'aria-label': 'First item', content: 'Next' }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="empty">
              <img src={empty} alt="no data found" />
              <p as="p" size="">
                No projects found.
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default ProjectList;
