import React from 'react';
import SamaPurak from './Gate/SamaPurak';

const SamapurakProjects = () => {
  return (
    <>
      <SamaPurak isHomePage={true} />
    </>
  );
};

export default SamapurakProjects;
