import currentYear from '../../../helpers/constant/CurrentYearGate';
const initialState = {
  isYearlyFunding: '',
  projectStartDateAD: '',
  projectStartDateBS: '',
  estimatedProjectEndDateAD: '',
  estimatedProjectEndDateBS: '',
  estimatedTime:'',
  projectDescription: '',
  projectDescriptionFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  projectCostImplementation: {
    originalCost: '',
    originalCostWord: '',
    estimateDateAD: '',
    estimateDateBS: '',
    originalCostExchangeRate:'',
    originalCostCurrency:'npr',
    fiscalYearForOriginalCost: currentYear(),
  },
  isProjectCostRevise: '',
  revisedCost:'',
  revisedCostExchangeRate: '',
  fiscalYearForRevised: '2076/77',
  revisedCostCurrency:'npr',
  revisedCostWord:'',
  // revisedCostUSD: '',
  // revisedCostNPR: '',
  revisedDateEstimateAD: '',
  revisedDateEstimateBS: '',
  isBreakOfCost: '',
  summaryOfCost: [
    {
      costHeadingNumber:'',
      costHeading: '',
      fCostComponent: '',
      fCostComponentExchangeRate:'',
      fCostComponentCurrency:'npr',
      fCostComponentWord: '',
      // totalCost:'',
      // totalCostWord:'',
      // totalCostExchangeRate:'',
      // totalCostCurrency:'npr',
      // nprCostComponent: '',
      // totalCostInNpr: '',
      // nprCostComponentWord: '',
      // totalCostInNprWord: '',
    },
  ],
  summaryCostFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }]
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'ERROR':
      return {};
    case 'PROJECT_STEP':
      return {
        ...state,
        step: payload.step,
      };

    case 'SPECIFIC_FETCHED':
      state = { ...payload };
      return state;

      case 'NULL_SPECIFIC_FETCHED':
        return initialState;

    default:
      return state;
  }
}
