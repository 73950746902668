import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import io from 'socket.io-client';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import ReactGA from 'react-ga';

import logo from '../assets/images/nepal-govt-logo.png';
import UserManual from '../assets/images/NPBMIS-User-Manual-V1.pdf';
import { logout, setCurrentUser } from '../services/Auth';
import GuidelineApi from '../services/UploadGuideline';

import { auth } from '../helpers';
import { localization } from '../localization';
import * as localizationActions from '../localization/localizationAction';
import { fetchGuideline, fetchUserManual } from '../reducers/documents/action';

import Can from '../components/Can/can';
// eslint-disable-next-line no-unused-vars
const Header = ({ history, ...props }) => {
  const dispatch = useDispatch();
  // const [value, setValue] = useState(false);
  const [manualName, setManualName] = useState('');

  const handleLogout = (event) => {
    event.preventDefault();
    dispatch(
      logout(() => {
        toastr.success('', `Successfully logged out`);
      })
    );
  };

  const language = useSelector(auth.getSelectedLanguage);
  console.log(language);
  const lang = localization[language];

  const user = useSelector(auth.getCurrentUser);

  const trackingId = process.env.REACT_APP_GOOGLE_TRACKING_ID;

  ReactGA.initialize(
    trackingId
    // ,
    // {
    //   debug: true,
    //   titleCase: false,
    //   gaOptions: {
    //     userId: user.id
    //   }
    // }
  );

  ReactGA.set({ page: history.location.pathname, user: user.id });
  ReactGA.pageview(history.location.pathname);

  const selectLanguage = (e, selectedLanguage) => {
    dispatch(localizationActions.setLanguage(selectedLanguage));
    // setValue(true);
  };

  // const socket = io.connect(process.env.REACT_APP_SOCKET_URL, {
  //   path: process.env.REACT_APP_SOCKET_PATH,
  // });

  const isAuthenticated = useSelector(auth.checkIfAuthenticated);
  //
  // socket.emit('join', { id: user.id });
  // socket.on('token', (data) => {
  //   const newToken = data.token;
  //   if (newToken) {
  //     const decoded = jwtDecode(newToken);
  //     const object = {
  //       token: newToken,
  //       timestamp: new Date().getTime(),
  //     };
  //
  //     Cookies.set('npbmisToken', newToken);
  //     localStorage.setItem('npbmisToken', JSON.stringify(object));
  //
  //     dispatch(setCurrentUser(decoded));
  //   }
  // });

  const { role } = useSelector(auth.getCurrentUser);
  const manual = useSelector((state) => state.documents.user_manual_files);
  const guideline = useSelector((state) => state.documents.guideline_files);

  useEffect(() => {
    GuidelineApi.getDocument({ type: 'user-manual' })
      .then((res) => {
        if (res.success) {
          dispatch(fetchUserManual(res));
        }
      })
      .catch((err) => {
        console.error('User manual upload error', err);
      });
  }, []);

  useEffect(() => {
    GuidelineApi.getDocument({ type: 'guideline' })
      .then((res) => {
        if (res.success) {
          dispatch(fetchGuideline(res));
        }
      })
      .catch((err) => {
        console.error('Guideline upload error', err);
      });
  }, []);

  return (
    <Menu
      size="massive"
      className={`main-header ${
        history.location.pathname === '/' ||
        history.location.pathname === '/login' ||
        history.location.pathname === '/samapurak-projects'
          ? 'no-background'
          : ''
      }`}
    >
      <Menu.Item name="Logo">
        <Link to="/dashboard" className="logo-wrapper">
          <img
            src={logo}
            className="logo"
            alt="National project bank management information system logo"
          />
          <div className="logo-text">
            <p>
              {lang.header.logo_title}
              <span>{lang.header.logo_sub_title}</span>
            </p>
          </div>
        </Link>
      </Menu.Item>

      <Menu.Item
        className="nav-items no-print"
        // style={language === 'ne' ? { fontSize: 14 } : { fontSize: 12 }}
      >
        <div className="flex full-height align-center">
          <ul className="nav-menu-items flex">
            {isAuthenticated && (
              <>
                <li
                  className={`nav-menu-item ${
                    history.location.pathname === '/dashboard' ? 'active' : ''
                  }`}
                >
                  <Link to="/dashboard" className="sidebar-icon dashboard">
                    {lang.header.dashboard}
                  </Link>
                </li>

                <li
                  className={`nav-menu-item ${
                    history.location.pathname === '/programs' ? 'active' : ''
                  }`}
                >
                  <Link to="/programs" className="sidebar-icon programs">
                    {lang.header.programs}
                  </Link>
                </li>
              </>
            )}

            {isAuthenticated && (
              <Can
                role={role}
                perform="project:ranking"
                yes={() => (
                  <li
                    className={`nav-menu-item parent-menu ${
                      history.location.pathname === '/new-identification-ranking' ||
                      history.location.pathname === '/new-appraisal-ranking' ||
                      history.location.pathname === '/ongoing-project-ranking'
                        ? 'active'
                        : ''
                    }`}
                  >
                    <span className="sidebar-icon programs">{lang.header.ranking}</span>

                    <ul className="child-menu">
                      <li
                        className={`nav-menu-sub-item  ${
                          history.location.pathname === '/new-identification-ranking'
                            ? 'active'
                            : ''
                        }`}
                      >
                        <Link to="/new-identification-ranking" className="sidebar-icon programs">
                          {lang.header.identification}
                        </Link>
                      </li>
                      <li
                        className={`nav-menu-sub-item  ${
                          history.location.pathname === '/new-appraisal-ranking' ? 'active' : ''
                        }`}
                      >
                        <Link to="/new-appraisal-ranking" className="sidebar-icon programs">
                          {lang.header.appraisal}
                        </Link>
                      </li>

                      <li
                        className={`nav-menu-sub-item  ${
                          history.location.pathname === '/ongoing-project-ranking' ? 'active' : ''
                        }`}
                      >
                        <Link to="/ongoing-project-ranking" className="sidebar-icon programs">
                          {lang.header.ongoing}
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}
                no={() => <></>}
              />
            )}

            {isAuthenticated && (
              <li
                className={`nav-menu-item parent-menu ${
                  history.location.pathname === '/users' ||
                  history.location.pathname === '/departments' ||
                  history.location.pathname === '/ministries' ||
                  history.location.pathname === '/sectors' ||
                  history.location.pathname === '/trash'
                    ? 'active'
                    : ''
                }`}
              >
                <span className="sidebar-icon programs">{lang.header.manage}</span>
                <ul className="child-menu">
                  <li
                    className={`nav-menu-sub-item ${
                      history.location.pathname === '/gate/parent' ? 'active' : ''
                    }`}
                  >
                    <Link to="/gate/parent" className="sidebar-icon programs">
                      {lang.header.parent_project}
                    </Link>
                  </li>
                  <Can
                    role={role}
                    perform="user:view"
                    yes={() => (
                      <li
                        className={`nav-menu-sub-item ${
                          history.location.pathname === '/users' ? 'active' : ''
                        }`}
                      >
                        <Link to="/users" className="sidebar-icon programs">
                          {lang.header.users}
                        </Link>
                      </li>
                    )}
                    no={() => <></>}
                  />

                  <Can
                    role={role}
                    perform="ministries:list"
                    yes={() => (
                      <li
                        className={`nav-menu-sub-item ${
                          history.location.pathname === '/ministries' ? 'active' : ''
                        }`}
                      >
                        <Link to="/ministries" className="sidebar-icon programs">
                          {lang.header.ministries}
                        </Link>
                      </li>
                    )}
                    no={() => <></>}
                  />
                  <Can
                    role={role}
                    perform="department:view"
                    yes={() => (
                      <li
                        className={`nav-menu-sub-item ${
                          history.location.pathname === '/departments' ? 'active' : ''
                        }`}
                      >
                        <Link to="/departments" className="sidebar-icon programs">
                          {lang.header.departments}
                        </Link>
                      </li>
                    )}
                    no={() => <></>}
                  />

                  <li
                    className={`nav-menu-sub-item ${
                      history.location.pathname === '/sectors' ? 'active' : ''
                    }`}
                  >
                    <Link to="/sectors" className="sidebar-icon programs">
                      {lang.header.sector}
                    </Link>
                  </li>
                  <Can
                    role={role}
                    perform="email:list:edit:delete"
                    yes={() => (
                      <li
                        className={`nav-menu-sub-item ${
                          history.location.pathname === '/config' ? 'active' : ''
                        }`}
                      >
                        <Link to="/config" className="sidebar-icon programs">
                          {lang.header.config}
                        </Link>
                      </li>
                    )}
                    no={() => <></>}
                  />

                  <li
                    className={`nav-menu-sub-item ${
                      history.location.pathname === '/trash' ? 'active' : ''
                    }`}
                  >
                    <Link to="/trash" className="sidebar-icon programs">
                      {lang.header.trash}
                    </Link>
                  </li>
                  <li
                    className={`nav-menu-sub-item ${
                      history.location.pathname === '/closed-projects' ? 'active' : ''
                    }`}
                  >
                    <Link to="/closed-projects" className="sidebar-icon programs">
                      {lang.header.closed_projects}
                    </Link>
                  </li>
                  <Can
                    role={role}
                    perform="upload:guideline"
                    yes={() => (
                      <li
                        className={`nav-menu-sub-item ${
                          history.location.pathname === '/upload-guidelines' ? 'active' : ''
                        }`}
                      >
                        <Link to="/upload-guidelines" className="sidebar-icon programs">
                          {lang.header.upload_guidelines}
                        </Link>
                      </li>
                    )}
                    no={() => <></>}
                  />
                </ul>
              </li>
            )}

            {isAuthenticated && (
              <li
                className={`nav-menu-item ${
                  history.location.pathname === '/stats/province' ? 'active' : ''
                }`}
              >
                <Link to="/stats/province" className="sidebar-icon programs">
                  {lang.header.pbmis}
                </Link>
              </li>
            )}

            {isAuthenticated && (
              <li
                className={`nav-menu-item parent-menu ${
                  history.location.pathname === '/evaluated' ||
                  history.location.pathname === '/approved'
                    ? 'active'
                    : ''
                }`}
              >
                <span className="sidebar-icon programs">{lang.header.assessment}</span>
                <ul className="child-menu">
                  <li
                    className={`nav-menu-sub-item ${
                      history.location.pathname === '/evaluated' ? 'active' : ''
                    }`}
                  >
                    <Link to="/evaluated" className="sidebar-icon programs">
                      {lang.header.evaluated}
                    </Link>
                  </li>
                  <li
                    className={`nav-menu-sub-item ${
                      history.location.pathname === '/approved' ? 'active' : ''
                    }`}
                  >
                    <Link to="/approved" className="sidebar-icon programs">
                      {lang.header.approved}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>

        <div className="flex align-center full-height">
          {!isAuthenticated && (
            <>
              <Menu.Item>
                <div style={{ fontSize: 16 }}>
                  <Link to="/samapurak-projects">
                    समपूरक आयोजना/कार्यक्रमहरू
                    {/* <a
                    href="https://drive.google.com/uc?export=download&id=1JFPUwhF0jPs-byH6MwEqTJhmShnIsJ10"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    समपूरक आयोजना/कार्यक्रमहरू
                  </a> */}
                  </Link>
                </div>
              </Menu.Item>
              <Menu.Item>
                <div style={{ fontSize: 16, marginRight: 16 }}>
                  <Link to="/bises-projects">
                    विशेष अनुदान आयोजना/कार्यक्रमहरू
                    {/* <a
                    href="https://drive.google.com/uc?export=download&id=1Dn15102QoxGKI1UZZjKYZvWgRN1lSdIh"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    विशेष अनुदान आयोजना/कार्यक्रमहरू
                  </a> */}
                  </Link>
                </div>
              </Menu.Item>
              <Menu.Item>
                <div style={{ fontSize: 16, marginRight: 16 }}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      manual !== ''
                        ? `${process.env.REACT_APP_API_URL}assets/uploads/documents/user-manual/${manual}`
                        : UserManual
                    }
                    className="sidebar-icon faq"
                  >
                    {lang.header.user_manual}
                  </a>
                </div>
              </Menu.Item>
            </>
          )}
          {history.location.pathname === '/' ||
          history.location.pathname === '/samapurak-projects' ||
          history.location.pathname === '/bises-projects' ? (
            <>
              <div className="flex align-center">
                <div className="language-select">
                  <div
                    text={language === 'en' ? 'ENG' : 'NEP'}
                    className={`language-select-text text flag ${language}`}
                  >
                    {language === 'en' ? 'ENG' : 'NEP'}
                  </div>
                  <div className="option">
                    {language === 'en' ? (
                      <div
                        className="list ne nepali"
                        // defaultselectedlabel={true}
                        onClick={(e) => {
                          selectLanguage(e, 'ne');
                        }}
                      >
                        NEP
                      </div>
                    ) : (
                      <div
                        className="list en english"
                        onClick={(e) => {
                          selectLanguage(e, 'en');
                        }}
                      >
                        ENG
                      </div>
                    )}
                  </div>
                </div>

                <Link
                  to="/login"
                  className="btn-normal btn-blue btn-shadow--dark btn-transition btn-login"
                >
                  Login
                </Link>
              </div>
            </>
          ) : history.location.pathname === '/login' ? (
            <>
              <div className="flex align-center">
                <div className="language-select">
                  <div
                    text={language === 'en' ? 'ENG' : 'NEP'}
                    className={`language-select-text text flag ${language}`}
                  >
                    {language === 'en' ? 'ENG' : 'NEP'}
                  </div>
                  <div className="option">
                    {language === 'en' ? (
                      <div
                        className="list ne nepali"
                        // defaultselectedlabel={true}
                        onClick={(e) => {
                          selectLanguage(e, 'ne');
                        }}
                      >
                        NEP
                      </div>
                    ) : (
                      <div
                        className="list en english"
                        onClick={(e) => {
                          selectLanguage(e, 'en');
                        }}
                      >
                        ENG
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <ul className="nav-menu-items flex nav-menu-items-secondary">
                <li
                  className={`nav-menu-item ${
                    history.location.pathname === '/user-manual' ? 'active' : ''
                  }`}
                  style={{ paddingLeft: 10 }}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      manual !== ''
                        ? `${process.env.REACT_APP_API_URL}assets/uploads/documents/user-manual/${manual}`
                        : UserManual
                    }
                    className="sidebar-icon faq"
                  >
                    {lang.header.user_manual}
                  </a>
                </li>
                <li
                  className={`nav-menu-item ${
                    history.location.pathname === '/guideline' ? 'active' : ''
                  }`}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      guideline !== ''
                        ? `${process.env.REACT_APP_API_URL}assets/uploads/documents/guideline/${guideline}`
                        : 'https://www.npc.gov.np/images/category/National_Project_Bank_Guidelines.pdf'
                    }
                    className="sidebar-icon faq"
                  >
                    {lang.header.guideline}
                  </a>
                </li>
                <li
                  className={`nav-menu-item ${
                    history.location.pathname === '/feedback' ? 'active' : ''
                  }`}
                >
                  <Link to="/feedback" className="sidebar-icon feedback">
                    {lang.header.feedback}
                  </Link>
                </li>
                <li
                  className={`nav-menu-item ${
                    history.location.pathname === '/faq' ? 'active' : ''
                  }`}
                >
                  <Link to="/faq" className="sidebar-icon faq">
                    {lang.header.faq}
                  </Link>
                </li>
                <li
                  className={`nav-menu-item ${
                    history.location.pathname === '/bises-visualization' ? 'active' : ''
                  }`}
                >
                  <Link to="/bises-visualization" className="sidebar-icon feedback">
                    {lang.header.bises_viz}
                  </Link>
                </li>
                <li
                  className={`nav-menu-item ${
                    history.location.pathname === '/samapurak-visualization' ? 'active' : ''
                  }`}
                >
                  <Link to="/samapurak-visualization" className="sidebar-icon feedback">
                    {lang.header.samapurak_viz}
                  </Link>
                </li>
              </ul>

              <div className="language-select">
                <div
                  text={language === 'en' ? 'ENG' : 'NEP'}
                  className={`language-select-text text flag ${language}`}
                >
                  {language === 'en' ? 'ENG' : 'NEP'}
                </div>
                <div className="option">
                  {language === 'en' ? (
                    <div
                      className="list ne nepali"
                      // defaultselectedlabel={true}
                      onClick={(e) => {
                        selectLanguage(e, 'ne');
                      }}
                    >
                      NEP
                    </div>
                  ) : (
                    <div
                      className="list en english"
                      onClick={(e) => {
                        selectLanguage(e, 'en');
                      }}
                    >
                      ENG
                    </div>
                  )}
                </div>
              </div>

              <Can
                role={user.role}
                perform="project:create"
                yes={() => (
                  <Menu.Item
                    name={lang.header.add_new_project}
                    link
                    onClick={() => history.push('/project')}
                    className="add-project no-print"
                  >
                    <i className="material-icons md-bold mr-6 add">add</i>
                    {lang.header.add_new_project}
                  </Menu.Item>
                )}
                no={() => ''}
              />

              <Menu.Menu className="user no-print">
                <div className="user-details">
                  <ul>
                    <li
                      className={`nav-menu-sub-item ${
                        history.location.pathname === '/profile' ? 'active' : ''
                      }`}
                    >
                      <Link to="/profile" className="sidebar-icon programs">
                        My Account
                      </Link>
                    </li>
                    <li
                      className={`nav-menu-sub-item ${
                        history.location.pathname === '/setting' ? 'active' : ''
                      }`}
                    >
                      <Link to="/setting" className="sidebar-icon programs">
                        Settings
                      </Link>
                    </li>
                    <li onClick={handleLogout}>Logout</li>
                  </ul>
                </div>
              </Menu.Menu>
            </>
          )}
        </div>
      </Menu.Item>
    </Menu>
  );
};
export default withRouter(Header);
