class ApiUrlConstant {
    async GET_PROJECT_DETAILS() {
        return `2/stats/project`;
    }

    async GET_PROJECT_LIST() {
        return `2/GetTableData`;
    }

    async GET_COST_DETAILS() {
        return `2/stats/cost`;
    }

    async GET_BASIC_AREA_DETAILS() {
        return `2/stats/basic/details`;
    }

    async GET_BASIC_AREA() {
        return `2/stats/basic`;
    }

    async GET_PROJECT_DURATION() {
        return `2/stats/duration`;
    }

    // async GET_ANNUAL_RECOMMENDATION() {
    //     return `2/stats/allocation`;
    // }

    async GET_ALLOCATED_RECOMMENDED() {
        return `2/stats/allocation`;
    }

    async GET_MAP_DATA() {
        return `2/map`;
    }

    async GET_DETAIL_DATA() {
        return `2/details`;
    }

    async GET_PROVINCE_LIST() {
        return `provinceList?fiscal_year=2078-79`;
    }

    async GET_DISTRICT_LIST() {
        return `districtList?fiscal_year=2078-79`;
    }

    async GET_BASIC_AREA_LIST(query) {
        return `${query.grantType}/areas`
        // return `basicAreaList?fiscal_year=2078-79`;
    }

    async GET_FISCAL_YEAR_LIST() {
        return `Common/year`;
        // return `fiscalYearList?fiscal_year=2078-79`;
    }

    async EXPORT_SAMAPURAK_EXCEL() {
        return `samapurak-visualization/export`;
    }
   
}

export default new ApiUrlConstant();