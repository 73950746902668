export function fetching() {
  return { type: 'FETCHING' };
}

export function fetched(payload) {
  return { type: 'PRIORITIZATION_FETCHED', payload };
}

export function fetchedNullData() {
  return { type: 'NULL_PRIORITIZATION_FETCHED' };
}