import Api from '../api';
import ApiUrlConstant from '../../helpers/constant/GateApiURL';

class ProjectApi {
  //Create new project, submit onboarding form
  async createProject(data) {
    try {
      const res = await Api.post(await ApiUrlConstant.PROJECT(), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  //get Project information by stage
  async getProjectData(params) {
    try {
      const res = await Api.get(await ApiUrlConstant.PROJECT_GET(params));
      return res.body;
    } catch (error) {
      return error;
    }
  }

  //Update Project data by submitted form
  async updateProject(data, params) {
    try {
      const res = await Api.patch(await ApiUrlConstant.PROJECT_UPDATE(params), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  //Fetch all Sector / Sub-sector
  async getAllsectors() {
    try {
      const res = await Api.get(await ApiUrlConstant.SECTOR_GET());
      return res.body;
    } catch (error) {
      return error;
    }
  }

  //Fetch all Locations
  async getAllLocation() {
    try {
      const res = await Api.get(await ApiUrlConstant.LOCATION_GET());
      return res.body;
    } catch (error) {
      return error;
    }
  }

  //function to Upload file
  async uploadFile(data, params) {
    try {
      const res = await Api.patch(await ApiUrlConstant.UPLOAD_PROJECT_FILES(params), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  //for validating form fields
  async validateProjectData(params, data) {
    try {
      const res = await Api.post(await ApiUrlConstant.VALIDATE_PROJECT_DATA(params), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async listParentProjects(params) {
    try {
      const res = await Api.get(await ApiUrlConstant.FETCH_PROJECT_BY_MINISTRY(params));
      const projects = [];
      res.body.data.projects.forEach((p) => {
        projects.push({
          value: p.id,
          label: p.project_name_in_english,
          project_budget_code: p.project_budget_code ? p.project_budget_code : '',
        });
      });
      return projects;
    } catch (error) {
      return error;
    }
  }

  async assessment(data) {
    try {
      const res = await Api.post(await ApiUrlConstant.ASSESSMENT(), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async listProjects(val) {
    try {
      const res = await Api.get(await ApiUrlConstant.PROJECT(val));
      return res.body;
    } catch (error) {
      return error;
    }
  }
  async deleteProject(params) {
    try {
      const res = await Api.destroy(await ApiUrlConstant.PROJECT_ACTION(params));
      return res.body;
    } catch (error) {
      return error;
    }
  }

  // view project data
  async getProject(params) {
    try {
      const res = await Api.get(await ApiUrlConstant.VIEW_GATE_PROJECT(params));
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async downloadZip(val) {
    try {
      const res = await Api.get(await ApiUrlConstant.DOWNLOAD_GATE_FILES(val), 'arraybuffer');
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async projectGateAssessment(params, stage) {
    try {
      const res = await Api.get(await ApiUrlConstant.LIST_GATE_ASSESSMENTS(params, stage));
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async exportProjects(val) {
    try {
      const res = await Api.get(await ApiUrlConstant.EXPORT_PROJECT(val), 'arraybuffer');
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async listProjectMap(query) {
    try {
      const response = await Api.get(await ApiUrlConstant.PROJECT_MAP(query));
      return response.body;
    } catch (error) {
      return error;
    }
  }

  async listAllParentProjects(query) {
    try {
      const res = await Api.get(await ApiUrlConstant.PARENT_PROJECT(), null, query);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async updateParentProject(params, data) {
    try {
      const res = await Api.patch(await ApiUrlConstant.PARENT_PROJECT_UPDATE(params), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async exportParentProject(val) {
    try {
      const res = await Api.get(await ApiUrlConstant.EXPORT_PARENT_PROJECT(val), 'arraybuffer');
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async listProjectRanking(val) {
    try {
      const res = await Api.get(await ApiUrlConstant.PROJECT_RANKING(val));
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async quarterlyAssessment(data) {
    try {
      const res = await Api.post(await ApiUrlConstant.QUARTERLY_ASSESSMENT(), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async listQuarterlyAssessment(params, stage, fiscalYear) {
    try {
      const res = await Api.get(
        await ApiUrlConstant.LIST_QUARTERLY_ASSESSMENT(params, stage, fiscalYear)
      );
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async updateCreatorOfProject(data, params) {
    try {
      const res = await Api.patch(await ApiUrlConstant.PROJECT_CREATOR_UPDATE(params), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async fetchQuarterlyAssessment(params) {
    try {
      const res = await Api.get(await ApiUrlConstant.QUARTERLY_ASSESSMENT_ACTION(params));
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async updateQuarterlyAssessment(params, data) {
    try {
      const res = await Api.patch(await ApiUrlConstant.QUARTERLY_ASSESSMENT_ACTION(params), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async updateStatusOfProject(data, params) {
    try {
      const res = await Api.patch(await ApiUrlConstant.PROJECT_STATUS_UPDATE(params), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async deleteUploadedFile(params, data) {
      try {
        const res = await Api.put(await ApiUrlConstant.UPLOAD_PROJECT_FILES(params), data);
        return res.body;
      } catch (error) {
        return error;
      }
  }

  async listClosedProjects(val) {
    try {
      const res = await Api.get(await ApiUrlConstant.CLOSED_PROJECT(val));
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async fetchProvinceData(params) {
    try {
      const res = await Api.get(await ApiUrlConstant.FETCH_PROVINCE_DATA(params));
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async listProjectsProvince(val) {
    try {
      const res = await Api.get(await ApiUrlConstant.PROVINCE_PROJECT(val));
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async exportProvinceProjects(val) {
    try {
      const res = await Api.get(await ApiUrlConstant.EXPORT_PROVINCE_PROJECT(val), 'arraybuffer');
      return res.body;
    } catch (error) {
      return error;
    }
  }

  //   async updateAndGetProject(data, params) {
  //     try {
  //       const response = await Api.patch(await ApiUrlConstant.PROJECT_ACTION(params), data);
  //       if (response.body.success) {
  //         const res = await Api.get(await ApiUrlConstant.PROJECT_ACTION(params));
  //         return res.body;
  //       }
  //       return response.body;
  //     } catch (error) {
  //       return error;
  //     }
  //   }

  //   async listProvinces() {
  //     try {
  //       const response = await Api.get(await ApiUrlConstant.PROVINCE_LIST());

  //       return response.body;
  //     } catch (error) {
  //       return error;
  //     }
  //   }

  //   async verificationMail(params, data) {
  //     try {
  //       const res = await Api.post(await ApiUrlConstant.VERIFICATION_MAIL(params), data);
  //       return res.body;
  //     } catch (error) {
  //       return error;
  //     }
  //   }
}

export default new ProjectApi();
