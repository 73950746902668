import Http from 'axios';
import { toastr } from 'react-redux-toastr';
import Cookies from 'js-cookie';

import { EXTERNAL_API_URL } from '../helpers';

class Api {
  constructor() {
    Http.defaults.baseURL = EXTERNAL_API_URL;
    Http.defaults.timeout = 300000;
  }

  async get(resource, responseType = null, params = {}) {
    const config = {
      responseType,
      params,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await Http.get(`${EXTERNAL_API_URL}${resource}`, config);

      return this.successResponse(res);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  errorResponse(error) {
    switch (error.response.status) {
      case 401:
        toastr.error('', 'Unauthorized');
        localStorage.removeItem('npbmisToken');
        Cookies.remove('npbmisToken');
        window.location.replace('/');
        break;
      default:
        if (!error.response.data.success) {
          return this.response(error.response);
        }
        return this.response({
          data: 'Network Error',
          success: false,
        });
    }
  }

  successResponse(response) {
    return this.response(response);
  }

  response({ data, status, headers }) {
    return {
      body: data,
      status,
      headers,
    };
  }
}

export default new Api();
