const initialState = {
  projectImplementationModalType: '',
  PPPType: '',
  otherPPPType: '',
  privateType: '',
  privateCompanyName: [{ companyName: '' }],
  PPPCompanyName: [{ companyName: '' }],
  otherImplementationModal: '',
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'ERROR':
      return {};
    case 'PROJECT_STEP':
      return {
        ...state,
        step: payload.step,
      };
    case 'MODALITY_FETCHED':
      state = { ...payload };
      return state;

    case 'NULL_MODALITY_FETCHED':
      return initialState;

    default:
      return state;
  }
}
