// import ExternalApi from './api';
import Api from './api';
import ExternalApi from './externalApi';
import Http from 'axios';
import { API_URL, EXTERNAL_API_URL } from '../helpers';

import BisesApiUrl from '../helpers/constant/BisesApiUrl';

class BisesApi {
    async getProjectDetails(query) {
        try {
            const res = await ExternalApi.get(await BisesApiUrl.GET_PROJECT_DETAILS(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getProjectList(query) {
        try {
            const res = await ExternalApi.get(await BisesApiUrl.GET_PROJECT_LIST(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getCostDetails(query) {
        try {
            const res = await ExternalApi.get(await BisesApiUrl.GET_COST_DETAILS(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getBasicAreaDetails(query) {
        try {
            const res = await ExternalApi.get(await BisesApiUrl.GET_BASIC_AREA_DETAILS(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getBasicArea(query) {
        try {
            const res = await ExternalApi.get(await BisesApiUrl.GET_BASIC_AREA(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getProjectDuration(query) {
        try {
            const res = await ExternalApi.get(await BisesApiUrl.GET_PROJECT_DURATION(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getAnnualRecommendation(query) {
        try {
            const res = await ExternalApi.get(await BisesApiUrl.GET_ANNUAL_RECOMMENDATION(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getAllocatedRecommended(query) {
        try {
            const res = await ExternalApi.get(await BisesApiUrl.GET_ALLOCATED_RECOMMENDED(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getAnnualAllocation(query) {
        try {
            const res = await ExternalApi.get(await BisesApiUrl.GET_ANNUAL_ALLOCATION(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getSpecificAnnualAllocation(query) {
        try {
            const res = await ExternalApi.get(await BisesApiUrl.GET_SPECIFIC_ANNUAL_ALLOCATION(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getMapData(query, isHomePage = false) {
        try {
            if (!isHomePage) {

                const res = await Api.get(await BisesApiUrl.GET_MAP_DATA(), null, query);

                // const res = await ExternalApi.get(await BisesApiUrl.GET_MAP_DATA(), null, query);
                return res.body;
            }
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                params: query
            };
            let res = await Http.get(`${API_URL}1/map`, config);
            return res.data
        } catch (error) {
            return error;
        }
    }

    async getDetailData(query) {
        try {
            const res = await ExternalApi.get(await BisesApiUrl.GET_DETAIL_DATA(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getProvinceList(query) {
        try {
            const res = await ExternalApi.get('Common/province');
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getBasicAreaList(query) {
        try {
            const res = await ExternalApi.get(await BisesApiUrl.GET_BASIC_AREA_LIST());
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getFiscalYearList(query) {
        try {
            const res = await ExternalApi.get(await BisesApiUrl.GET_FISCAL_YEAR_LIST());
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async exportBisesExcel(query) {
        try {
            const res = await ExternalApi.get(await BisesApiUrl.EXPORT_BISES_EXCEL(), 'arraybuffer', query);
            return res.body;
        } catch (error) {
            return error;
        }
    }
}

export default new BisesApi();