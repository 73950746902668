import Api from './api';
import ApiUrlConstant from '../helpers/constant/api-url';

class DepartmentApi {
  async listDepartments(params) {
    try {
      const res = await Api.get(await ApiUrlConstant.DEPARTMENTS(params));

      return res.body;
    } catch (error) {
      return error;
    }
  }

  async createDepartment(data) {
    try {
      const res = await Api.post(await ApiUrlConstant.DEPARTMENT(), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async updateDepartment(params, data) {
    try {
      const res = await Api.patch(await ApiUrlConstant.DEPARTMENT_ACTION(params), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async listAllDepartments(query) {
    try {
      const res = await Api.get(await ApiUrlConstant.DEPARTMENT(), null, query);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async getDepartment(params) {
    try {
      const res = await Api.get(await ApiUrlConstant.DEPARTMENT_ACTION(params));
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async deleteDepartment(params) {
    try {
      const res = await Api.destroy(await ApiUrlConstant.DEPARTMENT_ACTION(params));
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async listDepartmentByMinistry(params) {
    try {
      const res = await Api.get(await ApiUrlConstant.DEPARTMENT_OF_MINISTRY(params));
      return res.body;
    } catch (error) {
      return error;
    }
  }
}

export default new DepartmentApi();
