import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { localization } from '../../../localization';

import SectorService from '../../../services/Gate/Sector';

import LoaderNP from '../../../components/Loader/Loader';

function SectorList({history}) {
  const language = useSelector((state) => state.localization.selectedLanguage);

  const lang = localization[language]['sector'];
  const [isExporting, setIsExporting] = useState(false);

  const [obj, setValues] = useState({
    sectorCount: 0,
    sectorList : [],
    totalSectors: 0,
    totalProjects: 0,
    isLoading: true,
    queryString:{}
  });

  const [errors, setErrorMessage] = useState({
    success: false,
    message: '',
  });

  const allSectors = () => {
    SectorService.listAllGateSectors(obj.queryString)
      .then((response) => {
        if (response.success) {
          const sectors = response.data.sectorList;
          const parents = sectors.filter((s) => s.subSector === '');

          setValues({...obj,
            sectorCount: parents.length,
            sectorList: sectors,
            totalSectors: response.data.totalCount,
            totalOngoing: response.data.totalOngoingCount,
            totalNew: response.data.totalFutureCount,
            totalIdentification: response.data.totalIdentificationCount,
            totalAppraisal: response.data.totalAppraisalCount,
            totalProjects: response.data.totalProjects,
            isLoading: false
          });

        } else {
          throw new Error(response.error.message);
        }
      })
      .catch((err) => {
        setErrorMessage({
          success: true,
          message: err.message,
        });
        toastr.error('', `${err.message}`);
      });
  };

  useEffect(() => {
    allSectors();
  }, [obj.queryString]);

  const exportSectorData = async () => {
    setIsExporting(true);
    let string = {...obj.queryString, download: 1};

    const data = await SectorService.listAllGateSectors(string);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('type', 'hidden');
    link.setAttribute('download', 'sectors.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
    setIsExporting(false);
  };
  const getPercentage = (value, total) => {
    let percentage = (value / total) * 100;
    return percentage % 1 != 0 ? percentage.toFixed(2) : percentage;
  };

  return (
    <>
    {errors.success ? (
      <div>{errors.message}</div>
    ) : (
      <>
      <div className='dashboard-header justify-between'>
        <div className='flex'>
          <h2 className='header-main'>{lang.sectors}</h2>
          <div className='project-stats flex'>
            <div className='card'>
              <p className='card-info'>{obj.sectorCount}</p>
              <p className='card-label'>{lang.sectors}</p>
            </div>
            <div className='card'>
              <p className='card-info'>{obj.totalProjects}</p>
              <p className='card-label'>{lang.projects}</p>
            </div>
          </div>
        </div>
        <div>
          <button
            className='btn-transition btn-outline btn-outline--blue'
            onClick={() => window.print()}
          >
            <i className='material-icons mr-6'>print</i>
            Print
          </button>
        </div>
      </div>
      <div className='export-add-button no-print'>
      {!isExporting ? (
              <button
              className='btn-normal btn-dark btn-shadow--dark btn-transition export'
              onClick={exportSectorData}
              >
              <i className='material-icons md-normal download mr-6'>
                system_update_alt
              </i>
              {lang.export_data}
              </button>
              ) : (
              <button className="btn-normal btn-dark btn-shadow--dark btn-transition export">
              <i className="ui secondary loading button" />
              {lang.export_data}
              </button>
            )}        
      </div>

      <div className='table-container'>
        {obj.isLoading ? (
          <LoaderNP/>
        ) : obj.sectorList.length > 0 ? (
          <>
          <Table padded className='project-table no-action'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}>{lang.s_n}</Table.HeaderCell>
                <Table.HeaderCell>{lang.sector}</Table.HeaderCell>
                <Table.HeaderCell>{lang.sub_sector}</Table.HeaderCell>
                <Table.HeaderCell>{lang.total_projects}</Table.HeaderCell>
                <Table.HeaderCell>{lang.ongoing}</Table.HeaderCell>
                <Table.HeaderCell>{lang.future}</Table.HeaderCell>
                <Table.HeaderCell>{lang.identification}</Table.HeaderCell>
                <Table.HeaderCell>{lang.appraisal}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {obj.sectorList.map((m, index) => {
                return (
                  <Table.Row key={index+1}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>
                      <p className='project-title'>
                        {language === 'en' ? m.sector.trim() : m.nepaliSector.trim()}
                      </p>
                    </Table.Cell>
                    <Table.Cell>
                      {language === 'en' ? m.subSector.trim() : m.nepaliSubSector.trim()}
                    </Table.Cell>
                    {m.subSector === '' ? (
                      <>
                      <Table.Cell>
                        {' '}
                        <p className='project-title'>{`${m.count} ${
                          parseInt(m.count) !== 0
                            ? '(' +
                            getPercentage(
                              parseInt(m.count),
                              parseInt(obj.totalProjects)
                            ) +
                            '%)'
                            : ''
                          }`}</p>
                      </Table.Cell>
                      <Table.Cell>
                        {' '}
                        <p className='project-title'>{`${m.ongoingCount} ${
                          parseInt(m.ongoingCount) !== 0
                            ? '(' +
                            getPercentage(
                              parseInt(m.ongoingCount),
                              parseInt(obj.totalProjects)
                            ) +
                            '%)'
                            : ''
                          }`}</p>
                      </Table.Cell>
                      <Table.Cell>
                        {' '}
                        <p className='project-title'>{`${m.futureCount} ${
                          parseInt(m.futureCount) !== 0
                            ? '(' +
                            getPercentage(
                              parseInt(m.futureCount),
                              parseInt(obj.totalProjects)
                            ) +
                            '%)'
                            : ''
                          }`}</p>
                      </Table.Cell>
                      <Table.Cell>
                        {' '}
                        <p className='project-title'>{`${m.identificationCount} ${
                          parseInt(m.identificationCount) !== 0
                            ? '(' +
                            getPercentage(
                              parseInt(m.identificationCount),
                              parseInt(obj.totalProjects)
                            ) +
                            '%)'
                            : ''
                          }`}</p>
                      </Table.Cell>
                      <Table.Cell>
                        {' '}
                        <p className='project-title'>{`${m.appraisalCount} ${
                          parseInt(m.appraisalCount) !== 0
                            ? '(' +
                            getPercentage(
                              parseInt(m.appraisalCount),
                              parseInt(obj.totalProjects)
                            ) +
                            '%)'
                            : ''
                          }`}</p>
                      </Table.Cell>
                      </>
                    ) : (
                      <>
                      <Table.Cell>{`${m.count} ${
                        parseInt(m.count) !== 0
                          ? '(' +
                          getPercentage(
                            parseInt(m.count),
                            parseInt(m.parentCount)
                          ) +
                          '%)'
                          : ''
                        }`}
                        </Table.Cell>
                      <Table.Cell>{`${m.ongoingCount} ${
                        parseInt(m.ongoingCount) !== 0
                          ? '(' +
                          getPercentage(
                            parseInt(m.ongoingCount),
                            parseInt(m.parentCount)
                          ) +
                          '%)'
                          : ''
                        }`}
                      </Table.Cell>
                      <Table.Cell>{`${m.futureCount} ${
                        parseInt(m.futureCount) !== 0
                          ? '(' +
                          getPercentage(
                            parseInt(m.futureCount),
                            parseInt(m.parentCount)
                          ) +
                          '%)'
                          : ''
                        }`}
                      </Table.Cell>
                      <Table.Cell>{`${m.identificationCount} ${
                        parseInt(m.parentCount) !== 0
                          ? '(' +
                          getPercentage(
                            parseInt(m.identificationCount),
                            parseInt(m.parentCount)
                          ) +
                          '%)'
                          : ''
                        }`}
                      </Table.Cell>
                      <Table.Cell>{`${m.appraisalCount} ${
                        parseInt(m.parentCount) !== 0
                          ? '(' +
                          getPercentage(
                            parseInt(m.appraisalCount),
                            parseInt(m.parentCount)
                          ) +
                          '%)'
                          : ''
                        }`}
                      </Table.Cell>
                      </>
                    )}
                  </Table.Row>
                );
              })}
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell>
                  {' '}
                  <p className='project-title'>Total
                    <small>(Sectors included in Project)</small>
                  </p>
                </Table.Cell>
                <Table.Cell>
                  {' '}
                  <p className='project-title'>{`${obj.totalSectors}`}</p>
                </Table.Cell>
                <Table.Cell>
                  {' '}
                  <p className='project-title'>{`${obj.totalOngoing}`}</p>
                </Table.Cell>
                <Table.Cell>
                  {' '}
                  <p className='project-title'>{`${obj.totalNew}`}</p>
                </Table.Cell>
                <Table.Cell>
                  {' '}
                  <p className='project-title'>{`${obj.totalIdentification}`}</p>
                </Table.Cell>
                <Table.Cell>
                  {' '}
                  <p className='project-title'>{`${obj.totalAppraisal}`}</p>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          </>
        ) : (
          <>
          <div className='empty'>
            <p as='p' size=''>
              No any sector found.
            </p>
          </div>
          </>
        )}
      </div>
      </>
    )}
    </>
  );
}

export default SectorList;
