import React from 'react';
import { useSelector } from 'react-redux';
import { useUrlSearchParams } from 'use-url-search-params';

import Contact from './Contact';
import FinancingArrangement from './FinancingArrangement';
import GeneralForm from './GeneralInformation';
import Modality from './ImplementationModality';
import Justification from './Justification';
import LandAcquisition from './LandAcquisition';
import ModerationStatus from './ModerationStatus';
import Progress from './Progress';
import ProjectPrioritization from './ProjectPrioritization';
import ProjectReadiness from './ProjectReadiness';
import ProjectSelection from './ProjectSelection';
import ProjectSpecific from './ProjectSpecific';
import ProjectViability from './ProjectViability';
import Remark from './Remarks';

const Form = ({ history }) => {
  const step = useSelector((state) => state.project.step);
  const {
    generalInformation,
    projectSpecific,
    projectSelection,
    contact,
    projectViability,
    landAcquisition,
    projectReadiness,
    projectPrioritization,
    modality,
    remarks,
    progress,
    financingArrangement,
    moderationStatus,
    justification,
  } = useSelector((state) => state);

  const [params] = useUrlSearchParams();

  // useEffect(()=>{
  //   console.log('===formType===')
  // },[step]);

  switch (step) {
    case 1:
      return generalInformation ? <GeneralForm urlParams={params} /> : <></>;
    case 2:
      return projectSpecific ? <ProjectSpecific urlParams={params} /> : <></>;
    case 3:
      return progress ? <Progress urlParams={params} /> : <></>;
    case 4:
      return projectReadiness ? <ProjectReadiness urlParams={params} /> : <></>;
    case 5:
      return projectSelection ? <ProjectSelection urlParams={params} /> : <></>;
    case 6:
      return projectPrioritization ? <ProjectPrioritization urlParams={params} /> : <></>;
    case 7:
      return modality ? <Modality urlParams={params} /> : <></>;
    case 8:
      return financingArrangement ? <FinancingArrangement urlParams={params} /> : <></>;
    case 9:
      return projectViability ? <ProjectViability urlParams={params} /> : <></>;
    case 10:
      return landAcquisition ? <LandAcquisition urlParams={params} /> : <></>;
    case 11:
      return justification ? <Justification urlParams={params} /> : <></>;
    case 12:
      return contact ? <Contact urlParams={params} /> : <></>;
    case 13:
      return remarks ? <Remark urlParams={params} /> : <></>;
    case 14:
      return moderationStatus ? <ModerationStatus urlParams={params} /> : <></>;
    default:
      return (
        <div>
          <p>Page Not Found</p>
        </div>
      );
  }
};

export default Form;
