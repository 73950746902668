import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { useUrlSearchParams } from 'use-url-search-params';
import { toastr } from 'react-redux-toastr';

import { auth } from '../../helpers';
import { localization } from '../../localization';

import UserService from '../../services/User';
import USER_ROLES from '../../helpers/constant/UserRoles';

const Profile = ({ history }) => {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language].user_profile;
  const [params] = useUrlSearchParams();

  const [user, setUserDetail] = useState({});

  const userDetail = useSelector(auth.getCurrentUser);

  useEffect(() => {
    if (params && params.userId) {
      getUserDetail();
    } else {
      setUserDetail(userDetail);
    }
  }, [params]);

  const getUserDetail = () => {
    UserService.getUser(params)
      .then((response) => {
        if (response.success) {
          setUserDetail(response.data.user);
        } else {
          throw new Error(response.error.message);
        }
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  return (
    <>
      <div className="body-container">
        <Grid columns="equal">
          <Grid.Column className="flex">
            <div className="inner-contents">
              <Grid columns="equal">
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.name} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">{user.name}</p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.role} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">{USER_ROLES[user.role]}</p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.ministry} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">{user.ministry && user.ministry.name}</p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.email} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">{user.email}</p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.department} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">{user.department && user.department.name}</p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </>
  );
};

export default Profile;
