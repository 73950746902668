export function fetching() {
    return { type: 'FETCHING' };
  }
  
  export function fetched(payload) {
    return { type: 'CONTACT_FETCHED', payload };
  }
  
  export function fetchedNullData() {
    return { type: 'NULL_CONTACT_FETCHED'};
  }