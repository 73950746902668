import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const LoaderNP = () => {
  return (
    <Dimmer inverted active>
      <Loader inverted>Loading</Loader>
    </Dimmer>
  );
};

export default LoaderNP;
