class ApiUrlConstant {
	async LOGIN() {
		return `login`;
	}

	async PROJECT(val) {
		if (val) {
			return `projects${val}`;
		} else {
			return `projects`;
		}
	}

	async PROJECT_ACTION(params) {
		return `projects/${params.id}`;
	}

	async UPLOAD_PROJECT_FILES_ACTION(params) {
		return `projects/${params.projectId}/uploads`;
	}

	async PROGRAM(params) {
		return `ministries/${params.ministryId}/programs`;
	}

	async DEPARTMENTS(params) {
		return `ministries/${params.ministryId}/departments`;
	}

	async FEEDBACK() {
		return `feedback`;
	}

	async PROJECT_FEEDBACK_ACTION(params) {
		return `projects/${params.projectId}/feedback`;
	}
	async VALIDATE_PROJECT_DATA(params) {
		return `projects/${params.projectId}/validate`;
	}

	async PROGRAM_LIST() {
		return `programs`;
	}

	async PROGRAM_ACTION(params) {
		return `ministries/${params.ministryId}/programs/${params.programId}`;
	}

	async MINISTRY_LIST(val) {
		if (val) {
			return `ministries${val}`;
		} else {
			return `ministries`;
		}
	}

	async DASHBOARD_STATS(params) {
		return `dashboard/stats${params}`;
	}

	async DASHBOARD_SECTOR(params) {
		return `dashboard/project/sector${params}`;
	}

	async DASHBOARD_PRIORITY(params) {
		return `dashboard/project/priority${params}`;
	}

	async DASHBOARD_MINISTRY(params) {
		return `dashboard/project/ministry${params}`;
	}

	async DASHBOARD_YEAR_ON_YEAR(params) {
		return `dashboard/project/yearonyear${params}`;
	}

	async DASHBOARD_STATUS(params) {
		return `dashboard/project/status${params}`;
	}

	async PROVINCE_LIST() {
		return `projects/fetch/locations`;
	}

	async EXPORT_PROJECT(params) {
		if (params) {
			return `export/projects${params}`;
		} else {
			return `export/projects`;
		}
	}

	async DOWNLOAD_PROJECT_FILES(params) {
		return `download/projects/files/${params}`;
	}

	async USER_ACTION(params) {
		return `users/${params.userId}`;
	}

	async DEPARTMENT(val) {
		if (val) {
			return `departments${val}`;
		} else {
			return `departments`;
		}
	}

	async DEPARTMENT_ACTION(params) {
		return `departments/${params.departmentId}`;
	}

	async USER(val) {
		if (val) {
			return `users${val}`;
		} else {
			return `users`;
		}
	}

	async MINISTRY_ACTION(params) {
		return `ministries/${params.ministryId}`;
	}

	async CREATE_MINISTRY() {
		return `ministries`;
	}

	async DEPARTMENT_OF_MINISTRY(params) {
		return `departments/ministries/${params.ministryId}`;
	}

	async BULK_USER_ACTION() {
		return `bulk/users`;
	}

	async DOWNLOAD_PROGRAM_FILES(programId) {
		return `programs/${programId}/download`;
	}

	async CHANGE_PASSWORD(params) {
		return `users/${params.userId}/changepassword`;
	}

	async VERIFICATION_MAIL(params) {
		return `/verified/projects/${params.projectId}`;
	}

	async PROJECT_RANKING(val) {
		return `dashboard/project/matrix?status=${val}`;
	}

	async EXPORT_PROGRAM(params) {
		if (params) {
			return `exports/programs${params}`;
		} else {
			return `exports/programs`;
		}
	}
	async PROJECT_MAP(params) {
		if (params) {
			return `projects/locations/map${params}`;
		} else {
			return `projects/locations/map`;
		}
	}

	async SECTOR_LIST() {
		return `sectors`;
	}

	async TRASH(val) {
		if (val) {
			return `trash/${val.id}`;
		} else {
			return `trash`;
		}
	}

	async BULK_TRASH() {
		return `bulk/trash`;
	}

	async EMAIL() {		
		return `emails`;		
	}

	async EMAIL_ACTION(params) {
		return `emails/${params.id}`;
	}
	async LIST_USER_BY_MINISTRY(params) {
		return `ministries/${params.ministryId}/users`;
	  }

	async UPLOAD_GUIDELINE(params) {
		return `upload/documents/${params.type}`;
	}

	async GET_GUIDELINE(params) {
		return `documents/${params.type}`;
	}
	
}

export default new ApiUrlConstant();
