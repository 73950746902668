
export function fetched(payload) {
  return { type: 'QUARTERLY_ASSESSMENT_FETCHED', payload };
}

export function lists(payload) {
  return {type: 'LIST_ALL_QUARTERLY_ASSESSMENT', payload}
}


export function reset(payload){
  return { type: 'QUARTERLY_ASSESSMENT_REST', payload };
}