import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function Editor({ name, formInfo, field, formData, handleEditorChange, fetched }) {
  const dispatch = useDispatch();
  const [ displayData, setDisplayData ] = useState(formData);
 

  useEffect(() => {
    if(!formData){
      setDisplayData('')
    }
    else{
      setDisplayData(formData)
    }
  }, [formData])

  return (
    <CKEditor
      editor={ClassicEditor}
      data={displayData}
      onInit={ editor => {
        const fieldData = editor.getData();
        dispatch(handleEditorChange({ name, formInfo, field, fieldData }, fetched));
    } }
      onChange={(event, editor) => {
        const fieldData = editor.getData();
        dispatch(handleEditorChange({ name, formInfo, field, fieldData }, fetched));
      }}
      config={{
        toolbar: [
          'Heading',
          'bold',
          'italic',
          'bulletedList',
          'numberedList',
          'blockQuote',
          'link',
          'insertTable',
        ],
      }}
    />
  );
}
