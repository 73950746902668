import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Input, Modal } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';
import ProjectApi from '../../services/Gate/Project';

import { localization } from '../../localization';

const ProjectAddModal = ({
  isProjectModelOpen,
  onCloseModel,
  setProjectModalOpen,
  listProjects,
  setData,
  ministryId,
}) => {

  const selectedLanguage = useSelector((state) => state.localization.selectedLanguage);
  const lang = localization[selectedLanguage]['project_boarding_page'];

  const [errors, setProjectError] = useState({
    project_name_in_english_error: false,
    project_budget_code_error: false
  });

  const [project, setProject] = useState({
    project_status: 'parent',
    sectorial_area: 'parent',
    project_stage: 'parent',
    part_of_program: 'No',
    is_sub_project: 'No',
    ministry_id: null,
    project_name_in_english: '',
    project_name_in_nepali: '',
    project_budget_code: '',
    is_parent: true
  });

  const createProject = (e) => {
    e.preventDefault();
    let canSubmit = true;
    let errors = {};

    const requiredField = [
      'project_name_in_english',
      'project_budget_code'
    ];

    for (let key in project) {
      if (requiredField.includes(key) && project[key].trim() === '') {
        canSubmit = false;
        errors[`${key}_error`] = true;
      }
    }

    setProjectError(errors);

    if (canSubmit) {
      const data = {
        ...project,
        project_name_in_english: project.project_name_in_english.trim().replace(/\s{2,}/g, ' '),
        project_name_in_nepali: project.project_name_in_nepali.trim().replace(/\s{2,}/g, ' '),
        project_budget_code: project.project_budget_code.trim().replace(/\s{2,}/g, ' '),
        ministry_id: ministryId
      };

      ProjectApi.createProject(data)
        .then((response) => {
          if (response.success) {
            setData({
              selected: {
                label: response.data.project.project_name_in_english,
                value: response.data.project.id,
              },
              projectBudgetCode: response.data.project.project_budget_code,
              projectId: response.data.project.id,
            });
            listProjects({ministryId: ministryId});
            setProjectModalOpen(!isProjectModelOpen);
          } else {
            toastr.error('', response.error.message);
            setProjectError({
              project_name_in_english_error: false,
              project_budget_code_error: false,
              ministry_id_error: false
            });
            setProject({...project});
            setProjectModalOpen(!isProjectModelOpen);
          }
        })
        .catch((err) => {
          toastr.error('', `${err.error.message}`);
        });
    }


  };

  return (
    <Modal dimmer="blurring" open={isProjectModelOpen} onClose={onCloseModel} size="mini">
      <Modal.Header>{lang.create_new_project}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group grouped>
            <Form.Field
              label={lang.project_name_in_english}
              control={Input}
              onChange={(e) => {
                setProject({...project, project_name_in_english: e.target.value});
                setProjectError({...errors, project_name_in_english_error: false});
              }}
              required
              className={errors.project_name_in_english_error ? 'error' : ''}
            />
            <Form.Field
              label={lang.project_name_in_nepali}
              control={Input}
              onChange={(e) => setProject({...project, project_name_in_nepali: e.target.value})}
            />
            <Form.Field
              label={lang.budget_code}
              control={Input}
              required
              onChange={(e) => {
                setProject({...project, project_budget_code: e.target.value});
                setProjectError({...errors, project_budget_code_error: false});
              }}
              className={errors.project_budget_code_error ? 'error' : ''}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={onCloseModel}>
          {lang.cancel}
        </Button>
        <Button positive content={lang.create} onClick={createProject}/>
      </Modal.Actions>
    </Modal>
  );
};

export default ProjectAddModal;
