const initialState = {
  moderation_status: 'draft',
  errorForms: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'ERROR':
      return {};
    case 'PROJECT_STEP':
      return {
        ...state,
        step: payload.step,
      };
    case 'MODERATION_STATUS_FETCHED':
      state = { ...payload };
      return state;
    case 'VALIDATION_FETCHED':
      state = { ...payload };
      return state;
    default:
      return state;
  }
}
