import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import L from 'leaflet';
import L1 from 'leaflet.markercluster';
import markerIcon from '../assets/images/marker.svg';

L.Mask = L.Polygon.extend({
  options: {
    stroke: true,
    color: '#7ca7f8',
    width: 1,
    opacity: 0.2,
    fillColor: '#2a2f38',
    fillOpacity: 0.3,
    clickable: true,
    outerBounds: new L.LatLngBounds([-90, -360], [90, 360]),
  },

  initialize: function (latLngs, options) {
    const outerBoundsLatLngs = [
      this.options.outerBounds.getSouthWest(),
      this.options.outerBounds.getNorthWest(),
      this.options.outerBounds.getNorthEast(),
      this.options.outerBounds.getSouthEast(),
    ];

    L.Polygon.prototype.initialize.call(this, [outerBoundsLatLngs, latLngs], options);
  },
});

L.mask = function (latLngs, options) {
  return new L.Mask(latLngs, options);
};

const ProjectMap = ({ isHomePage, provinceNo = '', isReport = false, reportData = [] }) => {
  const [nepal, setNepal] = useState({});
  const [provinces, setProvinces] = useState({});
  const [districts, setDistricts] = useState({});
  const [municipalities, setMunicipalities] = useState({});
  const mapRef = useRef(null);
  let layerRef = useRef(null);
  const markerRef = useRef(null);
  const districtGeoJson = useRef(null);
  const municipalitiesGeoJson = useRef(null);
  const list = useSelector((state) => state.project.mapProjectList);

  useEffect(() => {
    mapRef.current = L.map('map', {
      center: [28.3949, 84.124],
      zoom: 7,
      // minZoom:7,
      attributionControl: true,
      trackResize: false,
      scrollWheelZoom: false,
      tileSize: 512,
      layers: [
        L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
          attribution: '&copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        }),
      ],
    });
  }, []);

  useEffect(() => {
    fetch('/data/Nepal.json')
      .then((response) => response.json())
      .then((data) => setNepal(data));
  }, []);

  useEffect(() => {
    fetch('/data/NewNepalProvince.json')
      .then((response) => response.json())
      .then((data) => setProvinces(data));
  }, []);

  useEffect(() => {
    fetch('/data/NewNepalDistrict.json')
      .then((response) => response.json())
      .then((data) => setDistricts(data));
  }, []);

  useEffect(() => {
    fetch('/data/Municipalities.json')
      .then((response) => response.json())
      .then((data) => setMunicipalities(data));
  }, []);

  useEffect(() => {
   
    // if (layerRef.current) {
    //   // mapRef.current.clearLayers()
    //   layerRef.current.clearLayers();
    //   layerRef.current = null;
    // }
    if (
      Object.keys(nepal).length &&
      Object.keys(provinces).length &&
      Object.keys(districts).length &&
      Object.keys(municipalities).length
    ) {
      isReport ? mapPlot(reportData?.mapData) : mapPlot(list);
    }
  }, [list.projects, nepal, provinces, districts, reportData.mapData, isReport, municipalities]);

  const provinceFeatureStyle = (feature) => {
    const provinceStyle = {
      1: { fillColor: '#D69069', fillOpacity: 1, weight: 2, color: '#2564de' },
      2: { fillColor: '#CCC292', fillOpacity: 1, weight: 2, color: '#2564de' },
      3: { fillColor: '#81C186', fillOpacity: 1, weight: 2, color: '#2564de' },
      4: { fillColor: '#FF9A2C', fillOpacity: 1, weight: 2, color: '#2564de' },
      5: { fillColor: '#877B36', fillOpacity: 1, weight: 2, color: '#2564de' },
      6: { fillColor: '#B9CEEA', fillOpacity: 1, weight: 2, color: '#2564de' },
      7: { fillColor: '#9CE588', fillOpacity: 1, weight: 2, color: '#2564de' },
    };
    return (
      provinceStyle[feature.properties.STATE_CODE] ?? {
        fillColor: '#D69069',
        fillOpacity: 1,
        weight: 2,
        color: '#2564de',
      }
    );
  };

  function mouseOver() {
    this.bringToFront();
    this.setStyle({
      color: '#000',
    });
  }

  function mouseOut() {
    this.setStyle({
      color: '#2564de',
    });
  }

  const tooltipOption = {
    permanent: true,
    direction: 'center',
    className: 'feature-label',
  };

  const mapPlot = (data) => {
    const myIcon = L.icon({
      iconUrl: markerIcon,
      iconAnchor: [11, 41],
      popupAnchor: [0, -41],
    });
    const latLngs = [];
    const coordinates = nepal.features[0].geometry.coordinates[0][0];

    for (let i = 0; i < coordinates.length; i++) {
      latLngs.push(new L.LatLng(coordinates[i][1], coordinates[i][0]));
    }

    if (layerRef.current === null && latLngs.length && data.projects && data.projects.length) {
      // L.mask(latLngs).addTo(mapRef.current);
      layerRef.current = L.mask(latLngs).addTo(mapRef.current);

      const options = {
        style: {
          color: '#2564de',
          weight: 2,
          opacity: 1,
          // fillColor: 'transparent',
        },
      };

      layerRef.current = L.geoJSON(nepal.features, options).addTo(mapRef.current);
      // layerRef.current.addData(districts);
      layerRef.current = L.geoJSON(provinces.features, {
        style: provinceFeatureStyle,
        onEachFeature: onEachFeature,
      }).addTo(mapRef.current);
    }
    if (markerRef.current) {
      markerRef.current.clearLayers();
    }

    const popup = (state) => {
      return `<div class="map-popup">
      <div class="title">
        ${
          isHomePage
            ? `${state.project_name_in_english}`
            : isReport
            ? `${state.project_name}`
            : `<a href="/project/${state.id}"> ${state.project_name_in_english}</a>`
        }
        ${
          !isReport
            ? `</div>
          <div class="location-info">
            <label class="info-label">Ministry: </label>
            <label class="info-text">${state.ministry.name}</label>
          </div>`
            : `<div></div>`
        }             
      ${
        !isReport
          ? Object.keys(state.locationInfo).length > 0 &&
            Object.keys(state.locationInfo).includes('municipality')
            ? `<div class="location-info"><label class="info-label">Municipality: </label><label class="info-text">${state.locationInfo.municipality}</label></div>`
            : ''
          : `<div></div>`
      }
       <div class="location-info">
        <label class="info-label">District: </label>
        <label class="info-text">${isReport ? state.district : state.locationInfo.district}</label>
      </div>
      <div class="location-info">
        <label class="info-label">State: </label>
        <label class="info-text">${isReport ? state.province : state.locationInfo.province}</label>
      </div>
      ${
        isReport
          ? `<div class="location-info">
      <label class="info-label">Project Code: </label>
      <label class="info-text">${state.project_code}</label>
    </div>`
          : `<div></div>`
      }
     
    </div>`;
    };

    function addMarkers(coordinateLevel) {
      markerRef.current = new L1.MarkerClusterGroup();
      const provinceMap = [];
      data.projects &&
        data.projects.length > 0 &&
        data.projects.map((state) =>
          provinceMap.push(
            markerRef.current.addLayer(
              L.marker(state[coordinateLevel], { icon: myIcon }).bindPopup(popup(state))
            )
          )
        );

      const layerGroup = L.layerGroup(provinceMap);
      layerGroup.addTo(mapRef.current);

      mapRef.current.addLayer(markerRef.current);
    }

    function openAllProviceLayerTooltip() {
      let provinceLayers = Object.keys(layerRef.current._layers);
      provinceLayers.forEach(function (layer) {
        layerRef.current._layers[layer].openTooltip();
      });
    }

    function openAllDistrictLayerTooltip() {
      let districtLayers = Object.keys(districtGeoJson.current._layers);
      districtLayers.forEach(function (layer) {
        districtGeoJson.current._layers[layer].openTooltip();
      });
    }

    if (!isReport) {
      // addMarkers('coord');
    }

    function onEachFeature(feature, layer) {
      layer.on('mouseover', mouseOver);
      layer.on('mouseout', mouseOut);

      layer.bindTooltip(
        `${feature.properties.Province} (<b>${
          data.provinceCount[feature.properties.Province] ?? 0
        }</b>)`,
        tooltipOption
      );

      // if (provinceNo) {
      layer.on('click', function (e) {
        mapRef.current.fitBounds(this.getBounds());

        openAllProviceLayerTooltip();
        this.closeTooltip();

        if (districtGeoJson.current) {
          districtGeoJson.current.clearLayers();
        }

        if (municipalitiesGeoJson.current) {
          municipalitiesGeoJson.current.clearLayers();
        }

        if (markerRef.current) {
          markerRef.current.clearLayers();
        }

        districtGeoJson.current = L.geoJSON(districts.features, {
          onEachFeature: onEachFeatureDistrict,
          style: {
            color: '#2564de',
            weight: 2,
          },
          filter: function (geoJsonFeature) {
            return e.target.feature.properties.STATE_CODE === geoJsonFeature.properties.STATE_CODE;
          },
        }).addTo(mapRef.current);
      });
      // }
    }

    function onEachFeatureDistrict(feature, layer) {
      let capitalize = (str) => {
        return str && str.length > 1 ? str[0].toUpperCase() + str.slice(1).toLowerCase() : str;
      };
      layer.on('mouseover', mouseOver);
      layer.on('mouseout', mouseOut);

      layer.bindTooltip(
        `${feature.properties.DISTRICT_NAME} <b>(${
          data.districtCount[capitalize(feature.properties.DISTRICT)] ?? 0
        })</b>`,
        tooltipOption
      );

      layer.on('click', function (e) {
        openAllDistrictLayerTooltip();
        this.closeTooltip();
        mapRef.current.fitBounds(this.getBounds());

        if (municipalitiesGeoJson.current) {
          municipalitiesGeoJson.current.clearLayers();
        }

        if (markerRef.current) {
          markerRef.current.clearLayers();
        }

        municipalitiesGeoJson.current = L.geoJSON(municipalities.features, {
          onEachFeature: (feature, layer) => {
            layer.on('mouseover', mouseOver);
            layer.on('mouseout', mouseOut);

            // layer.bindTooltip(feature.properties.NAME, tooltipOption);
            layer.bindTooltip(feature.properties.LOCAL_LEVEL_NAME, tooltipOption);

            layer.on('click', function (e) {
              mapRef.current.fitBounds(this.getBounds());
            });
          },
          style: {
            color: '#2564de',
            weight: 2,
          },
          filter: function (geoJsonFeature) {
            return (
              e.target.feature.properties.DISTRICT.toLowerCase() ===
              geoJsonFeature.properties.DISTRICT.toLowerCase()
            );
          },
        }).addTo(mapRef.current);

        markerRef.current = new L1.MarkerClusterGroup();
        const provinceMap = [];

        data.projects.map((state) => {
          if (
            !!state.coord &&
            state.locationInfo?.district?.toLowerCase() ===
              e.target.feature.properties.DISTRICT.toLowerCase()
          ) {
            provinceMap.push(
              markerRef.current.addLayer(
                L.marker(state.coord, { icon: myIcon }).bindPopup(popup(state))
              )
            );
          }
        });

        const layerGroup = L.layerGroup(provinceMap);
        layerGroup.addTo(mapRef.current);

        mapRef.current.addLayer(markerRef.current);
      });
    }

    let prevZoom = mapRef.current.getZoom();
    mapRef.current.on('zoomend', function (e) {
      let map = mapRef.current;
      let currentZoom = map.getZoom();
      let diff = prevZoom - currentZoom;
      prevZoom = currentZoom;

      if (diff > 0 && currentZoom <= 10) {
        if (municipalitiesGeoJson.current) {
          municipalitiesGeoJson.current.clearLayers();
          openAllDistrictLayerTooltip();
        }
        if (markerRef.current) {
          markerRef.current.clearLayers();
        }
      }

      if (diff > 0 && currentZoom < 8) {
        if (districtGeoJson.current) {
          districtGeoJson.current.clearLayers();
          openAllProviceLayerTooltip();
        }
      }
    });
  };

  const zoomToProvince = (layer) => {
    if (+layer.properties.STATE_CODE === +provinceNo) {
      let polygon = L.polygon(layer.geometry.coordinates);
      let bounds = polygon.getBounds();
      let cSouthWest = L.latLng(bounds.getSouthWest().lng, bounds.getSouthWest().lat);
      let cNortEast = L.latLng(bounds.getNorthEast().lng, bounds.getNorthEast().lat);
      let flippedBounds = L.latLngBounds(cSouthWest, cNortEast);
      mapRef.current.fitBounds(flippedBounds);
    }
  };

  return (
    <>
      <div id="map" />
    </>
  );
};

export default ProjectMap;
