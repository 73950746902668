import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';

import Editor from '../CkEditor';
import FileUpload from '../FileUpload';
import { localization } from '../../../../localization';
import { handleSaveAndExit, handleEditorChange } from '../../../../actions';
import { fetched, fetchedNullData } from '../../../../reducers/form/Remarks/action';
import * as ProjectAction from '../../../../reducers/form/action';

const Remarks = ({ urlParams }) => {
  const selectedLanguage = useSelector((state) => state.localization.selectedLanguage);
  const lang = localization[selectedLanguage]['remarks'];
  const dispatch = useDispatch();
  const { remarks } = useSelector((state) => state);
  const { projectInfo } = useSelector((state) => state.projectBasicInfo);

  useEffect(() => {
    if (projectInfo.remarks) {
      dispatch(fetched({ ...projectInfo.remarks }));
    }
    if (!projectInfo.remarks) {
      dispatch(fetchedNullData());
    }
  }, [projectInfo]);

  const handleSaveAndContinue = (type) => {
    let data = cloneDeep(remarks);
    delete data['step'];
    handleSaveAndExit({ remarks: data }, type, dispatch, 14, urlParams);
  };

  return (
    <Form className="form-new">
      <div className="form-group-container">
        <Form.Group>
          <Form.Field width={16}>
            <label>{lang.remarks}</label>
            <Editor
              formData={remarks.title}
              formInfo={remarks}
              field={false}
              name="title"
              handleEditorChange={handleEditorChange}
              fetched={fetched}
            />
          </Form.Field>
        </Form.Group>
        <FileUpload
          formName={'remarks'}
          label="upload"
          fileData={remarks.documentFile}
          name="documentFile"
          form={remarks}
          fetched={fetched}
          projectId={urlParams.projectId}
        />
      </div>

      <div className="form-actions flex">
        <button
          className="btn-rect btn-rect--small btn-blue"
          onClick={() => handleSaveAndContinue('continue')}
        >
          Save and continue
        </button>
        <button
          className="btn-rect btn-rect--small btn-dark"
          onClick={() => handleSaveAndContinue('exit')}
        >
          Save and exit
        </button>
        <button
          type="button"
          className="btn-link"
          onClick={() => dispatch(ProjectAction.projectStep({ step: 12 }))}
        >
          Back to previous form
        </button>
      </div>
    </Form>
  );
};

export default Remarks;
