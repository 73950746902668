import React from 'react';
import UserList from './UserList';

function User({ history }) {
  return (
    <>
      <UserList history={history} />
    </>
  );
}

export default User;
