import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ReactComponent as CalendarIcon } from '../../../../assets/images/ic_calendar.svg';
import { handleEnglishDateChange } from '../../../../actions';

const DatePickerComponent = ({
  lang,
  label,
  name,
  formName,
  fetched,
  field = null,
  changeField = null,
  index,
  required = false,
  error = false,
}) => {
  const dispatch = useDispatch();
  const selectedDate = () => {
    if (index >= 0) {
      return formName[field][index][name] ? new Date(formName[field][index][name]) : null;
    }
    return field
      ? formName[field][name]
        ? new Date(formName[field][name])
        : null
      : formName[name]
      ? new Date(formName[name])
      : null;
  };

  const selected = selectedDate()
  return (
    <>
      {/* Form field date picker start */}
      <Form.Field
        width={4}
        required={required}
        className={
          error
            ? 'error field-align-bottom position-relative'
            : 'field-align-bottom position-relative'
        }
      >
        <label>{lang[label]}</label>
        <i className="ic-calendar">
          <CalendarIcon />
        </i>
        <DatePicker
          showPopperArrow={false}
          selected={selectedDate()}
          strictParsing
          onChange={(date) => {
            return index >= 0
              ? dispatch(
                  handleEnglishDateChange(
                    {
                      info: formName,
                      field: field,
                      value: date,
                      name: name,
                      changeField: changeField,
                      index: index,
                    },
                    fetched
                  )
                )
              : dispatch(
                  handleEnglishDateChange(
                    {
                      info: formName,
                      field: field,
                      value: date,
                      name: name,
                      changeField: changeField,
                    },
                    fetched
                  )
                )
            
          }
          }
          minDate={new Date('1922-12-30')}
          maxDate={new Date('2035-12-30')}
        />
      </Form.Field>
      {/* Form field date picker end */}
    </>
  );
};

export default DatePickerComponent;
