class BisesApiUrl {
    async GET_PROJECT_DETAILS() {
        return `1/stats/project`;
    }

    async GET_PROJECT_LIST() {
        return `1/GetTableData`;
    }

    async GET_COST_DETAILS() {
        return `1/stats/cost`;
    }

    async GET_BASIC_AREA_DETAILS() {
        return `1/stats/basic/details`;
    }

    async GET_BASIC_AREA() {
        return `1/stats/basic`;
    }

    async GET_PROJECT_DURATION() {
        return `1/stats/duration`;
    }

    // async GET_ANNUAL_RECOMMENDATION() {
    //     return `bises/annualRecommendation`;
    // }

    async GET_ALLOCATED_RECOMMENDED() {
        return `1/stats/allocation`;
    }

    // async GET_ANNUAL_ALLOCATION() {
    //     return `bises/annualAllocation`;
    // }

    // async GET_SPECIFIC_ANNUAL_ALLOCATION() {
    //     return `bises/specificAnnualAllocation`;
    // }

    async GET_MAP_DATA() {
        return `1/map`;
    }

    async GET_DETAIL_DATA() {
        return `1/details`;
    }

    async GET_PROVINCE_LIST() {
        return `provinceList?fiscal_year=2078-79`;
    }

    async GET_DISTRICT_LIST() {
        return `districtList?fiscal_year=2078-79`;
    }

    async GET_BASIC_AREA_LIST() {
        return `1/areas`;
    }

    async GET_FISCAL_YEAR_LIST() {
        return `Common/year`;
        // return `bises/fiscalYearList?fiscal_year=2078-79`;
    }

    async EXPORT_BISES_EXCEL() {
        return `bises/bises-visualization/export`;
    }
   
}

export default new BisesApiUrl();