import React, { useState, Fragment } from 'react';
import { Form, Select } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Necessary for svg icon
import { ReactComponent as CalendarIcon } from './../../../assets/images/ic_calendar.svg';

const ExampleDatePicker = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <>
      <Form.Group>
        {/* Form field date picker start */}
        <Form.Field width={4} className="position-relative">
          <label>Select date</label>
          <i className="ic-calendar">
            <CalendarIcon />
          </i>
          <DatePicker
            showPopperArrow={false}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </Form.Field>
        {/* Form field date picker end */}

        {/* Form field date picker start */}
        <Form.Field width={4} className="position-relative">
          <label>Select date</label>
          <i className="ic-calendar">
            <CalendarIcon />
          </i>
          <DatePicker
            showPopperArrow={false}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </Form.Field>
        {/* Form field date picker end */}

        {/* Form field date picker start */}
        <Form.Field width={4} className="position-relative">
          <label>Select date</label>
          <i className="ic-calendar">
            <CalendarIcon />
          </i>
          <DatePicker
            showPopperArrow={false}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </Form.Field>
        {/* Form field date picker end */}
      </Form.Group>
    </>
  );
};

export default ExampleDatePicker;
