const initialState = {
  remainingAcquisitionIssues: '',
  remainingLandAcquisitionFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  noOfAffectedAreas: '',
  noOfRelocated: '',
  noOfRemainingRelocated: '',
  resettlementPlanFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  landAcquisitionStatus: [
    {
      landType: '',
      requiredLand: '',
      totalAcquiredLand: '',
      remainingAcquiredLand: '',
      estimatedDateToAcquireLand: '',
      other: '',
    },
  ],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'ERROR':
      return {};
    case 'PROJECT_STEP':
      return {
        ...state,
        step: payload.step,
      };
    case 'LAND_ACQUISITION_FETCHED':
      state = { ...payload };
      return state;

    case 'NULL_LAND_ACQUISITION_FETCHED':
      return initialState;

    default:
      return state;
  }
}
