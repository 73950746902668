import React from 'react';
import { useSelector } from 'react-redux';

import { auth } from '../helpers';

import Ranking from './Ranking';

import PageNotFound from './PageNotFound';
import Can from '../components/Can/can';

const NewProjectRanking = () => {
  const user = useSelector(auth.getCurrentUser);

  return (
    <Can
      role={user.role}
      perform="project:ranking"
      yes={() => <Ranking status="new" />}
      no={() => <PageNotFound message={"You have'nt permission to edit this project"} />}
    />
  );
};

export default NewProjectRanking;
