import { easeQuadInOut } from 'd3-ease';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Select from 'react-select';
import { Form, Input, Popup, Radio } from 'semantic-ui-react';

import { auth } from '../../helpers';
import { localization } from '../../localization';
import * as ProjectAction from '../../reducers/form/BasicInfo/action';

import AddModal from '../../components/AddModal';
import Can from '../../components/Can/can';
import AnimatedProgressProvider from '../../components/ProgressBar/AnimatedProgressProvider';
import ProjectAddModal from '../../components/ProjectAddModal';
import PageNotFound from '../PageNotFound';

import DepartmentService from '../../services/Department';
import ProjectApi from '../../services/Gate/Project';
import MinistryApi from '../../services/Ministry';
import ProgramApi from '../../services/Program';

const BasicInfo = ({ history }) => {
  const dispatch = useDispatch();
  const user = useSelector(auth.getCurrentUser);

  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['project_boarding_page'];

  const [modalOpen, setModalOpen] = useState(false);
  const [projectModalOpen, setProjectModalOpen] = useState(false);

  const [projectList, setProjectList] = useState([]);
  const [ministriesList, setMinistriesList] = useState([]);
  const [departmentList, setDepartmentList] = useState({
    all: [],
    filtered: [],
  });

  const [programsList, setProgramList] = useState([]);

  const [ministry, setMinistry] = useState({
    selected: { label: 'Select a Ministry', value: '' },
    ministryID: '',
  });

  const [department, setDepartment] = useState({ label: 'Select a Department', value: '' });

  const [program, setProgram] = useState({
    selected: { label: 'Select a Program', value: '' },
    programBudgetCode: '',
    programId: null
  });

  const [projectSelected, setSelectedProject] = useState({
    selected: { label: 'Select a Project', value: '' },
    projectBudgetCode: '',
    projectId: null
  });

  const [others, setOthers] = useState({
    isConfirmed: false,
    isDisabled: false,
    projectId: null,
    redirectPage: false
  });

  const [project, setProject] = useState({
    project_status: '',
    sectorial_area: '',
    project_stage: '',
    part_of_program: '',
    is_sub_project: '',
    parent_id: null,
    program_id: null,
    project_name_in_english: '',
    project_name_in_nepali: '',
    project_budget_code: '',
  });

  const [projectError, setProjectError] = useState({
    project_status_error: false,
    sectorial_area_error: false,
    project_stage_error: false,
    part_of_program_error: false,
    project_name_in_english_error: false,
    project_budget_code_error: false,
    ministry_id_error: false,
    program_id_error: false,
    parent_id_error: false
  });

  const fetchProgram = (params) => {
    ProgramApi.listPrograms(params)
      .then((response) => {
        setProgramList(response)
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const listDepartments = (params) => {
    DepartmentService.listDepartmentByMinistry(params)
      .then((response) => {
        let departments = [{ label: 'Select a Department', value: 0 }];
        for (const d of response.data.department) {
          departments.push({ label: d.name, value: d.id, ministryId: d.ministry_id });
        }

        if (['dataEntry', 'dataApprover'].includes(user.role) && user.department) {
          const findDep = departments.find((d) => d.value === user.department.id);
          setDepartment(findDep);
        }
        setDepartmentList({
          all: cloneDeep(departments),
          filtered: cloneDeep(departments).filter((d) => d.ministryId === ministry.ministryID),
        });
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const listMinistries = () => {
    MinistryApi.listAllMinistries(`?isDashboard=true&`)
      .then((response) => {
        if (response.success) {
          const ministries = [];
          for (const d of response.data?.ministries?.lists) {
            ministries.push({ label: d.name, value: d.id });
          }
          setMinistriesList(ministries);
        } else {
          toastr.err('', `${response.error.message}`);
        }
      })
      .catch((err) => toastr.err('', `${err.message}`));
  };

  const listProjects = (params) => {
    ProjectApi.listParentProjects(params)
      .then((response) => {
        setProjectList(response);
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  useEffect(() => {
    const ministryID = user.ministry.id;
    if (user.role === 'npcAdmin') {
      listMinistries();
    } else if (user && user.ministry && user.ministry.id) {
      setMinistry({ selected: { label: user.ministry.name, value: ministryID }, ministryID });
    }

    fetchProgram({ ministryId: ministryID });
    listDepartments({ ministryId: ministryID });
    listProjects({ ministryId: ministryID });
  }, [user]);

  const closeModal = () => {
    setModalOpen(!modalOpen);
  };

  const projectCloseModal = () => {
    setProjectModalOpen(!projectModalOpen);
  };

  const validateInput = (value, name) => {
    if (value.trim() === '') setProjectError({ ...projectError, [`${name}_error`]: true });
  };

  const handleCreate = (e) => {
    e.preventDefault();

    const data = {
      ...project,
      ministry_id: ministry.ministryID,
      program_id: program.programId,
      parent_id: projectSelected.projectId,
      department_id: department.value || null
    };

    let canSubmit = true;
    let errors = { ...projectError };

    for (let key in data) {
      if (data[key] === '' && key !== 'project_name_in_nepali') {
        canSubmit = false;
        errors[`${key}_error`] = true;
      }

      if(data.part_of_program === 'Yes' ){
        if(!data.program_id){
          canSubmit = false;
          errors[`program_id_error`] = true;
        }
      }

      if(data.is_sub_project === 'Yes' ){
        if(!data.parent_id){
          canSubmit = false;
          errors[`parent_id_error`] = true;
        }
      }

    }

    setProjectError(errors);

    if (canSubmit) {
      setOthers({ ...others, isDisabled: true });
      ProjectApi.createProject(data)
        .then((response) => {
          if (response.success) {
            setOthers({
              isConfirmed: true,
              isDisabled: false,
              projectId: response.data.project.id,
            });
            toastr.success('', `${response.message}`);

            dispatch(ProjectAction.getProjectInfo(response));
            setTimeout(() => {
              setOthers({
                isConfirmed: true,
                isDisabled: false,
                redirectPage: true,
                projectId: response.data.project.id,
              });
            }, 5000);
          } else if (response.error) {
            setOthers({
              isDisabled: false,
            });
            toastr.error('', `${response.error.message}`);
          } else {
            setOthers({ ...others, isDisabled: true });
            dispatch(ProjectAction.projectInfoError(response.error));
            throw new Error(response.error.message);
          }
        })
        .catch((err) => {
          toastr.error('', `${err.message}`);
          dispatch(ProjectAction.projectInfoError(err));
        });
    }
  };

  return (
    <Can
    role={user.role}
    perform="project:create"
    yes={() => (<div className="form-basic__container">
    {!others.isConfirmed ? (
      <>
        <h2 className="heading-1 normal text-center ">{lang.enter_basic_information}</h2>
        <Form className="form-new">
          <div className="form-group-container">
            <Form.Group>
              <Form.Field width={8} required>
                <Form.Field
                  label={lang.line_ministry}
                  required
                  className={projectError.ministry_id_error ? 'error' : ''}
                />
                <Select
                  isDisabled={['dataEntry', 'dataApprover', 'ministryAdmin'].includes(user.role)}
                  name="ministry_id"
                  value={ministry.selected}
                  options={ministriesList}
                  onChange={(selectedOption) => {
                    setMinistry({ selected: selectedOption, ministryID: selectedOption.value });
                    const departmentFilter = cloneDeep(departmentList.all).filter(
                      (d) => d.ministryId === selectedOption.value
                    );
                    fetchProgram({ ministryId: selectedOption.value });
                    listProjects({ ministryId: selectedOption.value });
                    setDepartmentList({ ...departmentList, filtered: departmentFilter });
                    setProjectError({ ...projectError, ministry_id_error: false });
                    setDepartment({ label: 'Select a Department', value: '' })
                  }}
                  isSearchable
                  className={`react-select ${projectError.ministry_id_error ? 'error' : ''}
                  ${
                    ['dataEntry', 'dataApprover', 'ministryAdmin'].includes(user.role)
                      ? 'disabled'
                      : ''
                  }
                            `}
                />
              </Form.Field>
              <Form.Field width={8}>
                <Form.Field label={lang.authority} />
                <Form.Field
                  disabled={
                    ['dataEntry', 'dataApprover'].includes(user.role) ||
                    (user.role === 'npcAdmin' && ministry.ministryID === '')
                  }
                >
                  <Select
                    name="department"
                    value={department}
                    options={user.role==='ministryAdmin' ? departmentList.all : departmentList.filtered}
                    onChange={(selectedOption) => {
                      setDepartment(selectedOption);
                      setProjectError({ ...projectError, department_id_error: false });
                    }}
                    isSearchable
                    className={`react-select ${projectError.department_id_error ? 'error' : ''}`}
                  />
                </Form.Field>
              </Form.Field>
            </Form.Group>
          </div>
          <div className="form-group-container">
            <Form.Group>
              <Form.Field width={8} required>
                <Form.Field
                  required
                  className={projectError.part_of_program_error ? 'error' : ''}
                  label={lang.is_part_of_program}
                />
                <Form.Group inline>
                  <Form.Field
                    label={lang.yes}
                    control={Radio}
                    name="part_of_program"
                    value="Yes"
                    checked={project.part_of_program === 'Yes'}
                    onChange={(e, { value }) => {
                      setProject({ ...project, part_of_program: value });
                      setProjectError({ ...projectError, part_of_program_error: false });
                    }}
                  />
                  <Form.Field
                    label={lang.no}
                    control={Radio}
                    name="part_of_program"
                    value="No"
                    checked={project.part_of_program === 'No'}
                    onChange={(e, { value }) => {
                      setProject({ ...project, part_of_program: value, program_id: null });
                      setProgram({
                        selected: { label: 'Select a Program', value: '' },
                        programBudgetCode: '',
                        programId: null,
                      });
                      setProjectError({ ...projectError,
                        part_of_program_error: false,
                        program_id_error: false });
                    }}
                  />
                </Form.Group>
              </Form.Field>
            </Form.Group>

            {project.part_of_program === 'Yes' ? (
              <>
                <Form.Group>
                  <Form.Field width={8}>
                    <Form.Field
                      label={lang.program_name_selection}
                      required
                      className={projectError.program_id_error ? 'error' : ''}
                    />
                    <Form.Field>
                      <Select
                        name="programs"
                        value={program.selected}
                        options={programsList}
                        onChange={(selectedOption) => {
                          setProgram({
                            selected: selectedOption,
                            programId: selectedOption.value,
                            programBudgetCode: selectedOption.program_budget_code,
                          });
                          setProject({ ...project, program_id: selectedOption.value });
                          setProjectError({ ...projectError, program_id_error: false });
                        }}
                        isSearchable
                        className={`react-select ${projectError.program_id_error ? 'error' : ''}`}
                      />
                    </Form.Field>
                    <label className="helper-text">
                      {lang.select_program_name}
                      <button className="btn-text" onClick={() => setModalOpen(!modalOpen)}>
                        {lang.create_new_program}
                      </button>
                    </label>
                  </Form.Field>
                  {program.selected && program.selected.value ? (
                    <Form.Field
                      control={Input}
                      label={lang.program_budget_code}
                      width={8}
                      value={program.programBudgetCode}
                      disabled
                    />
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </>
            ) : (
              ''
            )}
          </div>
          <div className="form-group-container">
            <Form.Group>
              <Form.Field width={8} required>
                <Form.Field
                  required
                  className={projectError.is_sub_project_error ? 'error' : ''}
                  label={lang.is_sub_project}
                />
                <Form.Group inline>
                  <Form.Field
                    label={lang.yes}
                    control={Radio}
                    name="is_sub_project"
                    value="Yes"
                    checked={project.is_sub_project === 'Yes'}
                    onChange={(e, { value }) => {
                      setProject({ ...project, is_sub_project: value });
                      setProjectError({ ...projectError, is_sub_project_error: false });
                    }}
                  />
                  <Form.Field
                    label={lang.no}
                    control={Radio}
                    name="is_sub_project"
                    value="No"
                    checked={project.is_sub_project === 'No'}
                    onChange={(e, { value }) => {
                      setProject({ ...project, is_sub_project: value, parent_id: null });
                      setSelectedProject({
                        selected: { label: 'Select a Project', value: '' },
                        projectBudgetCode: '',
                        projectId: null
                      });
                      setProjectError({ ...projectError, is_sub_project_error: false, parent_id_error: false });
                    }}
                  />
                </Form.Group>
              </Form.Field>
            </Form.Group>

            {project.is_sub_project === 'Yes' && (
              <>
                <Form.Group>
                  <Form.Field width={8}>
                    <Form.Field
                      label={lang.select_project_name}
                      required
                      className={projectError.parent_id_error ? 'error' : ''}
                    />
                    <Form.Field>
                      <Select
                        name="projects"
                        value={projectSelected.selected}
                        options={projectList}
                        onChange={(selectedOption) => {
                          setProject({ ...project, parent_id: selectedOption.value });
                          setSelectedProject({
                            selected: selectedOption,
                            projectId: selectedOption.value,
                            projectBudgetCode: selectedOption.project_budget_code
                          });
                          setProjectError({ ...projectError, parent_id_error: false });
                        }}
                        isSearchable
                        className={`react-select ${projectError.parent_id_error ? 'error' : ''}`}
                      />
                    </Form.Field>
                    <label className="helper-text">
                      {lang.if_not_select_project_name}
                      <button
                        className="btn-text"
                        onClick={() => setProjectModalOpen(!projectModalOpen)}
                      >
                        {lang.create_new_project}
                      </button>
                    </label>
                  </Form.Field>
                  {projectSelected.selected && projectSelected.selected.value ? (
                    <Form.Field
                      control={Input}
                      label={lang.budget_code}
                      width={8}
                      disabled
                      value={projectSelected.projectBudgetCode}
                    />
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </>
            )}
          </div>
          <div className="form-group-container">
            <Form.Group>
              <Form.Field
                required
                control={Input}
                label={
                  project.is_sub_project === 'Yes'
                    ? lang.sub_project_name_in_english
                    : lang.project_name_in_english
                }
                placeholder={lang.project_name_in_english}
                onChange={(e, { value }) => {
                  setProject({ ...project, project_name_in_english: value });
                  setProjectError({ ...projectError, project_name_in_english_error: false });
                }}
                width={8}
                className={projectError.project_name_in_english_error ? 'error' : ''}
                onBlur={(e) => validateInput(e.target.value, 'project_name_in_english')}
              />
              <Form.Field
                control={Input}
                label={
                  project.is_sub_project === 'Yes'
                    ? lang.sub_project_name_in_nepali
                    : lang.project_name_in_nepali
                }
                placeholder={lang.project_name_in_nepali}
                width={8}
                onChange={(e, { value }) => {
                  setProject({ ...project, project_name_in_nepali: value });
                }}
                onBlur={(e) => validateInput(e.target.value, 'project_name_in_nepali')}
              />
            </Form.Group>

            <Form.Group>
              <Form.Field
                required
                control={Input}
                label={
                  project.is_sub_project === 'Yes'
                    ? lang.sub_project_budget_code
                    : lang.budget_code
                }
                placeholder={lang.budget_code}
                width={8}
                onChange={(e, { value }) => {
                  setProject({ ...project, project_budget_code: value });
                  setProjectError({ ...projectError, project_budget_code_error: false });
                }}
                className={projectError.project_budget_code_error ? 'error' : ''}
                onBlur={(e) => validateInput(e.target.value, 'project_budget_code')}
              />
            </Form.Group>
          </div>
          <div className="form-group-container">
            <Form.Group>
              <Form.Field width={8} required>
                <Form.Field
                  required
                  className={projectError.project_status_error ? 'error' : ''}
                  label={lang.what_is_project_status}
                />
                <Form.Group inline>
                  <Form.Field
                    label={lang.new_future_project}
                    control={Radio}
                    name="project_status"
                    value="new"
                    checked={project.project_status === 'new'}
                    onChange={(e, { value }) => {
                      setProject({ ...project, project_status: value, project_stage: '' });
                      setProjectError({ ...projectError, project_status_error: false });
                    }}
                  />
                  <Form.Field
                    label={lang.under_implementation}
                    control={Radio}
                    name="project_status"
                    value="ongoing"
                    checked={project.project_status === 'ongoing'}
                    onChange={(e, { value }) => {
                      setProject({
                        ...project,
                        project_status: value,
                        project_stage: 'appraisal',
                      });
                      setProjectError({ ...projectError, project_status_error: false });
                    }}
                  />
                </Form.Group>
              </Form.Field>
              {project.project_status === 'new' ? (
                <Form.Field width={8}>
                  <Form.Field
                    required
                    className={projectError.project_stage_error ? 'error' : ''}
                    label={lang.what_is_the_project_stage}
                  />
                  <Form.Group inline>
                    <Form.Field
                      label={lang.identification}
                      control={Radio}
                      name="stage"
                      value="identification"
                      checked={project.project_stage === 'identification'}
                      onChange={(e, { value }) => {
                        setProject({ ...project, project_stage: value });
                        setProjectError({ ...projectError, project_stage_error: false });
                      }}
                    />
                    <Popup
                      basic
                      content={lang.identification_tooltip}
                      trigger={<div className="tooltip">?</div>}
                    />
                    <Form.Field
                      label={lang.appraisal}
                      control={Radio}
                      name="stage"
                      value="appraisal"
                      checked={project.project_stage === 'appraisal'}
                      onChange={(e, { value }) => {
                        setProject({ ...project, project_stage: value });
                        setProjectError({ ...projectError, project_stage_error: false });
                      }}
                    />
                    <Popup
                      basic
                      content={lang.appraisal_tooltip}
                      trigger={<div className="tooltip">?</div>}
                    />
                  </Form.Group>
                </Form.Field>
              ) : (
                <></>
              )}
            </Form.Group>
          </div>
          <div className="form-group-container">
            <Form.Group>
              <Form.Field width={8}>
                <Form.Field
                  required
                  className={projectError.sectorial_area_error ? 'error' : ''}
                  label={lang.sectorial_area}
                />
                <Form.Group inline>
                  <Form.Field
                    label={lang.infrastructure}
                    control={Radio}
                    name="area"
                    value="infrastructure"
                    checked={project.sectorial_area === 'infrastructure'}
                    onChange={(e, { value }) => {
                      setProject({ ...project, sectorial_area: value });
                      setProjectError({ ...projectError, sectorial_area_error: false });
                    }}
                  />
                  <Form.Field
                    label={lang.social}
                    control={Radio}
                    name="area"
                    value="service"
                    checked={project.sectorial_area === 'service'}
                    onChange={(e, { value }) => {
                      setProject({ ...project, sectorial_area: value });
                      setProjectError({ ...projectError, sectorial_area_error: false });
                    }}
                  />
                </Form.Group>
              </Form.Field>
            </Form.Group>
          </div>
          <div className="form-actions flex">
            <button
              type="submit"
              disabled={others.isDisabled}
              onClick={handleCreate}
              className="btn-rect btn-rect--small btn-blue"
            >
              {lang.create_project_draft}
            </button>
            <button type="button" onClick={() => history.goBack()} className="btn-text">
              {lang.cancel}
            </button>
          </div>
        </Form>
      </>
    ) : (
      <>
        <div className="success-msg-container">
          <div className="animated-progress-bar">
            <AnimatedProgressProvider
              valueStart={0}
              valueEnd={100}
              duration={5.2}
              easingFunction={easeQuadInOut}
            >
              {(val) => (
                <CircularProgressbar
                  value={val}
                  styles={buildStyles({
                    pathTransition: 'none',
                  })}
                />
              )}
            </AnimatedProgressProvider>
          </div>
          <div>
            Congratulations, A project titled{' '}
            <span className="heading-2 normal text-center">
              {project.project_name_in_english}
            </span>{' '}
            has been successfully created. Go ahead and fill in rest of the information.
          </div>
        </div>
        {others.redirectPage &&
          // <Redirect to={`/add/project?projectId=${others.projectId}&stage=${project.project_stage}`} />
          window.location.replace(
            `/add/project?projectId=${others.projectId}&stage=${project.project_stage}`
          )}
      </>
    )}
    <AddModal
      ministryId={ministry.ministryID}
      setModalOpen={setModalOpen}
      isModelOpen={modalOpen}
      onCloseModel={closeModal}
      setData={setProgram}
      fetchProgram={fetchProgram}
    />
    <ProjectAddModal
      ministryId={ministry.ministryID}
      setProjectModalOpen={setProjectModalOpen}
      isProjectModelOpen={projectModalOpen}
      onCloseModel={projectCloseModal}
      setData={setSelectedProject}
      listProjects={listProjects}
    />
  </div>)}
    no={()=>(<PageNotFound message={`You can't access this interface`}/>)}
    />

    
  );
};

export default BasicInfo;
