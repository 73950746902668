import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import ScrollArea from 'react-scrollbar';

import FilterSection from '../filter-new/FilterSection';

import { toastr } from 'react-redux-toastr';
// const UserFilter = () => {
// 	return <Sidebar />;
// };

class UserFilter extends React.Component {
	constructor(props) {
		super(props);
		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.state = {
			showSidebar: false,
			showMinistryOption: false,
			showDepartmentOption: false,
			showRoleOption: false,
			showStatusOption: false,
			collapseAll: false,
		};
	}

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	handleOutsideClick = (event) => {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ showSidebar: false });
		}
	};

	escFunction = (event) => {
		if (event.keyCode === 27) {
			this.setState({ showSidebar: false });
		}
	};

	componentDidMount() {
		document.addEventListener('mousedown', this.handleOutsideClick, false);
		document.addEventListener('keydown', this.escFunction, false);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleOutsideClick, false);
		document.removeEventListener('keydown', this.escFunction, false);
	}

	toggleSidebar = () => {
		this.setState({ showSidebar: !this.state.showSidebar});
	};

	toggleFilterOption = (filter) => {
		this.setState({ [filter]: !this.state[filter], collapseAll: true });
	};

	collapseAll = () => {
		this.setState({
			showMinistryOption: !this.state.collapseAll,
			showDepartmentOption: !this.state.collapseAll,
			showRoleOption: !this.state.collapseAll,
			showStatusOption: !this.state.collapseAll,
			collapseAll: !this.state.collapseAll,
		});
	};

	render() {
		const {
			ministries,
			departments,
			status,
			roles,
			language,
			filterValues,
			handleSearch,
			handleReset,
			role,
		} = this.props;
		// console.log(this.props);
		return (
			<div ref={this.setWrapperRef}>
				<div
					className={`sidebar-filter ${this.state.showSidebar ? '' : 'hidden'}`}
				>
						<div className='close-button' onClick={this.toggleSidebar}>
							<i className='material-icons close'>close</i>
						</div>
					<div className='filter-container'>
						<div
							className={`filter-action
										${
											filterValues.department_id.value !== 0 ||
											filterValues.ministry_id.value !== 0 ||
											filterValues.role.value !== 0 ||
											filterValues.status.value !== 0
												? 'visibility-visible'
												: 'visibility-hidden'
										}
									`}
						>
							<button
								className='btn-rect btn-blue'
								onClick={() => {
									handleSearch();
									this.toggleSidebar();
									this.props.handleApplyFilterStatus();
								}}
							>
								{language.apply_filter}
							</button>
							<button className='btn-text' onClick={handleReset}>
								{language.clear_filter}
							</button>
						</div>
						<div className='header'>
							<p className='title'>{language.filter}</p>
							<p className='collapse' onClick={this.collapseAll}>
							{!this.state.showMinistryOption &&
							!this.state.showDepartmentOption &&
							!this.state.showRoleOption &&
							!this.state.showStatusOption
							? language.expand_all : language.collapse_all_filter}
								<i className='material-icons md-bold md-blue remove'>{!this.state.showMinistryOption &&
										!this.state.showDepartmentOption &&
										!this.state.showRoleOption &&
										!this.state.showStatusOption
										? 'add' : 'remove'} </i>
							</p>
						</div>

						{/* Filter Section for Ministries */}
						{['dataViewer', 'npcAdmin'].indexOf(role) !== -1 && (
							<FilterSection
								title={language.ministry}
								toggleFilterOption={() =>
									this.toggleFilterOption('showMinistryOption')
								}
								showOption={this.state.showMinistryOption}
								placeholder='Type a ministry name ...'
								data={ministries}
								handleSelect={this.props.handleSelect}
								selectedVal={filterValues.ministry_id}
								filterLabel={'ministry_id'}
								showSearch={true}
							/>
						)}

						{/* Filter Section for Departments */}
						{filterValues.ministry_id &&
							filterValues.ministry_id.value !== 0 && (
								<FilterSection
									title={language.department}
									toggleFilterOption={() =>
										this.toggleFilterOption('showDepartmentOption')
									}
									showOption={this.state.showDepartmentOption}
									placeholder='Type a department name ...'
									data={departments}
									handleSelect={this.props.handleSelect}
									selectedVal={filterValues.department_id}
									filterLabel={'department_id'}
								/>
							)}

						{/* Filter Section for Roles */}
						<FilterSection
							title={language.role}
							toggleFilterOption={() =>
								this.toggleFilterOption('showRoleOption')
							}
							showOption={this.state.showRoleOption}
							placeholder='Type a role ...'
							data={roles}
							handleSelect={this.props.handleSelect}
							selectedVal={filterValues.role}
							filterLabel={'role'}
						/>
						{/* Filter Section for status */}
						<FilterSection
							title={language.status}
							toggleFilterOption={() =>
								this.toggleFilterOption('showStatusOption')
							}
							showOption={this.state.showStatusOption}
							placeholder='Type a status ...'
							data={status}
							handleSelect={this.props.handleSelect}
							selectedVal={filterValues.status}
							filterLabel={'status'}
						/>
					</div>
				</div>
				<button
					className='btn-normal btn-blue btn-shadow--blue btn-transition btn-filter'
					onClick={this.toggleSidebar}
				>
					<i className='material-icons md-bold md-18 mr-6'>filter_list</i>
					{language.filter}
				</button>
			</div>
		);
	}
}

export default UserFilter;
