import React from 'react';
import { Grid } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DepartmentList from './DepartmentList';

import { auth } from '../../helpers';

import PageNotFound from '../PageNotFound';
import Can from '../../components/Can/can';

function Department({ history }) {
  const { role } = useSelector(auth.getCurrentUser);

  return (
    <Can
      role={role}
      perform="departments:list"
      yes={() => (
        <>
          <div className="body-container">
            <DepartmentList history={history} />
          </div>
        </>
      )}
      no={() => <PageNotFound message={`You can't access this interface`} />}
    />
  );
}

export default withRouter(Department);
