import React, { useRef, useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

function BarLineMixChart({
    data, 
    id, 
    xAxisTitle, 
    titleHeader, 
    categoryX, 
    barChartValueY, 
    barChartYAxisTitle,
    lineChartYAxisTitle,
    lineChartValueY 
}) {
    const chart1 = useRef(null);

    useLayoutEffect(() => {
        // Create chart instance
        let chart = am4core.create(id, am4charts.XYChart);
        chart.logo.disabled = true;

        chart.colors.list = [
            am4core.color("#B49BEC"),
            am4core.color("#B8CFFC"),
            am4core.color("#7CA7F8"),
            am4core.color("#C5F3F5"),
            am4core.color("#9BE8EC"),
            am4core.color("#5ACBD2"),
        ];

        let title = chart.titles.create();
        title.text = titleHeader;
        title.fontSize = 25;
        title.marginBottom = 18;

        /* Create axes */
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = categoryX;
        categoryAxis.title.text = xAxisTitle;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.grid.template.disabled = true;
        let label = categoryAxis.renderer.labels.template;
        label.truncate = false;
        categoryAxis.events.on("sizechanged", function(ev) {
            var axis = ev.target;
            var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
            axis.renderer.labels.template.maxWidth = cellWidth;
            axis.renderer.labels.template.horizontalCenter = cellWidth< 120? "right" : "middle";
            axis.renderer.labels.template.verticalCenter = cellWidth< 120 ? "middle" : "top";
            label.rotation = cellWidth < 120 ? -45 : 0;
          });
        label.tooltipText = "{category}";

        /* Create value axis */
        let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.title.text = lineChartYAxisTitle;
        valueAxis2.renderer.opposite = true;
        valueAxis2.renderer.grid.template.disabled = true;
        valueAxis2.numberFormatter = new am4core.NumberFormatter();
        valueAxis2.numberFormatter.numberFormat = "#a";
        valueAxis2.numberFormatter.bigNumberPrefixes = [
            // { "number": 1e+3, "suffix": "K" },
            // { "number": 1e+5, "suffix": "L" },
            { "number": 1e+7, "suffix": "C" },
            { "number": 1e+7, "suffix": "C" },
            { "number": 1e+7, "suffix": "C" },
            { "number": 1e+7, "suffix": "C" },
            { "number": 1e+7, "suffix": "C" },
            { "number": 1e+7, "suffix": "C" },
            // { "number": 1e+9, "suffix": "A" },
            // { "number": 1e+11, "suffix": "Kh" }
          ];

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = barChartYAxisTitle;

        /* Create series */
        let columnSeries = chart.series.push(new am4charts.ColumnSeries());
        columnSeries.name = barChartYAxisTitle;
        columnSeries.dataFields.valueY = barChartValueY;
        columnSeries.dataFields.categoryX = categoryX;
        columnSeries.yAxis = valueAxis;

        columnSeries.columns.template.tooltipText = "[bold]{name}\n[/]{categoryX}: {valueY}"
        columnSeries.tooltip.getFillFromObject = false;
        columnSeries.tooltip.background.fillOpacity = 1;
		columnSeries.tooltip.background.fill = am4core.color("#fff");
		columnSeries.tooltip.autoTextColor = false;
		columnSeries.tooltip.label.fill = am4core.color("#697588");

        let bulletColumn = columnSeries.bullets.push(new am4charts.LabelBullet());
        bulletColumn.label.text = "{valueY}";
        bulletColumn.dy = 20;

//          
        // columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
        // columnSeries.columns.template.propertyFields.stroke = "stroke";
        // columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
        // columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
        // columnSeries.tooltip.label.textAlign = "middle";

        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.name = lineChartYAxisTitle;
        lineSeries.dataFields.valueY = lineChartValueY;
        lineSeries.dataFields.categoryX = categoryX;
        lineSeries.yAxis = valueAxis2;

        lineSeries.stroke = am4core.color("#fdd400");
        lineSeries.strokeWidth = 3;
        // lineSeries.propertyFields.strokeDasharray = "lineDash";

        let bullet = lineSeries.bullets.push(new am4charts.LabelBullet());
        bullet.fill = am4core.color("#fdd400"); // tooltips grab fill from parent by default
        bullet.tooltipText = "[bold]{name}\n[/]{categoryX}: {valueY}"
        lineSeries.tooltip.getFillFromObject = false;
        lineSeries.tooltip.background.fillOpacity = 1;
		lineSeries.tooltip.background.fill = am4core.color("#fff");
		lineSeries.tooltip.autoTextColor = false;
		lineSeries.tooltip.label.fill = am4core.color("#697588");
        

        let circle = bullet.createChild(am4core.Circle);
        circle.radius = 4;
        circle.fill = am4core.color("#fff");
        circle.strokeWidth = 3;

        chart.data = data;
        
        // Add legend
        chart.legend = new am4charts.Legend();
        chart.legend.paddingTop = 20
        
        chart1.current = chart;

        return () => {
            chart.dispose();
        };
    }, [data,  
        xAxisTitle, 
        titleHeader, 
        categoryX, 
        barChartValueY, 
        barChartYAxisTitle,
        lineChartYAxisTitle,
        lineChartValueY ]);

    return <div id={id} style={{ width: '100%', height: '500px' }}></div>;
}

export default BarLineMixChart;
