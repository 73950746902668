import React, { useEffect, useState, useRef } from 'react';
import { Button, Grid, Modal, Accordion, Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import Timer from 'react-compound-timer';

import ProjectApi from '../../../services/Gate/Project';

import PageNotFound from '../../PageNotFound';

import * as ProjectAction from '../../../reducers/form/action';

import {
  GeneralInformationSection,
  LandAcquisitionSection,
  ProjectFinancingSection,
  ProjectImplementationSection,
  ProjectJustificationSection,
  ProjectPrioritizationSection,
  ProjectProgressSection,
  ProjectReadinessSection,
  ProjectSelectionSection,
  ProjectSpecificSection,
  ProjectViabilitySection,
  RemarkSection,
  ContactSection,
  AuthorSection,
  StatusChangeSection,
} from './index';

import Assessment from '../Assessment';

import Can from '../../../components/Can/can';
import { auth } from '../../../helpers';

import { localization } from '../../../localization';
import AssessmentSection from './Assessment';
import QuarterlyAssessmentSection from './QuarterlyAssessment';

let timer = null;

const ViewGate = ({ history }) => {
  const [projectData, setProjectData] = useState({});
  // const selected = history.location.hash;
  const [projectHasFile, setprojectHasFile] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [currentDiv, setCurrentDiv] = useState('general-information');
  const [gate, setGate] = useState('');
  const [activeIndex, setActiveIndex] = useState(false);
  const [activeIndexStatus, setActiveIndexStatus] = useState(false);
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language];

  const dispatch = useDispatch();

  const project = history.location.pathname;
  // const projectId = project.split('/')[3];
  const projectId = project.split('/')[2];

  const user = useSelector((state) => state.auth.user);
  const qRef = useRef(null);

  // const [Print, setPrint] = useState(false);
  const elems = [
    'general-information',
    'project-section',
    'project-progress',
    'project-readiness',
    'project-selection',
    'project-prioritization',
    'project-implementation',
    'project-financing',
    'project-viability',
    'land-acquisition',
    'project-justification',
    'contact',
    'remark',
    'quarterly-assessments',
  ];

  useEffect(() => {
    getProject();
    // const selected = history.location.hash;
    // if (selected && selected.length > 0) {
    //   setCurrentDiv(selected);
    //   const elem = document.querySelector(selected);
    //   elem && elem.scrollIntoView();
    // }
  }, [projectId]);

  const getProject = () => {
    dispatch(ProjectAction.fetching());
    ProjectApi.getProject({ projectId })
      .then((response) => {
        if (response.success) {
          dispatch(ProjectAction.getProjectInfo(response));
          setProjectData(response.data.project);
          let gate =
            response.data.project.project_appraisal && response.data.project.project_identification
              ? 'project_appraisal'
              : response.data.project.project_identification &&
                !response.data.project.project_appraisal
              ? 'project_identification'
              : !response.data.project.project_identification &&
                response.data.project.project_appraisal
              ? 'project_appraisal'
              : '';

          setGate(gate);
          const projects = response.data.project;
          for (let i of Object.keys(projects[gate])) {
            if (projects[gate][i] && typeof projects[gate][i] === 'object') {
              for (let field in projects[gate][i]) {
                if (
                  projects[gate][i][field] &&
                  field.includes('File') &&
                  Array.isArray(projects[gate][i][field]) &&
                  projects[gate][i][field][0].fileName !== ''
                ) {
                  setprojectHasFile(true);
                  return;
                }
              }
            }
          }
        } else {
          toastr.error('', `${response.error.message}`);
          dispatch(ProjectAction.error(response));
          return true;
        }
      })
      .catch((err) => {
        dispatch(ProjectAction.error(err));
      });
  };

  const handleScrollEvent = () => {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      let scrollPos = window.pageYOffset + 300;
      let prevScroll = 0;
      scrollPos = currentDiv === 'remark' ? scrollPos + 300 : scrollPos;

      for (let i = 0; i < elems.length; i++) {
        try {
          const elemScrollPos = document.getElementById(elems[i]).offsetTop;
          if (i - 1 >= 0) prevScroll = document.getElementById(elems[i - 1]).offsetTop;

          if (scrollPos >= prevScroll && scrollPos <= elemScrollPos) {
            setCurrentDiv(elems[i - 1]);
          } else if (scrollPos < 203) setCurrentDiv(elems[0]);
        } catch (err) {
          // handles error
        }
      }
    }, 150);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent, { passive: true });
  }, [currentDiv]);

  const selected = history.location.hash;
  useEffect(() => {
    if (
      selected === '#quarterly-assessments' &&
      (document.readyState === 'complete' || document.readyState === 'interactive')
    ) {
      setCurrentDiv('quarterly-assessments');

      setTimeout(() => {
        const offset = document.getElementById('quarterly-assessments').offsetTop;
        window.scrollTo(0, offset);
      }, 2000);
    }
  }, [selected, document.readyState]);

  const scrollTo = (elem) => {
    const offsetTop = document.getElementById(elem).offsetTop;
    // if( ['project-justification','remark'].includes(elem))
    window.removeEventListener('scroll', handleScrollEvent, { passive: true });
    window.scrollTo({
      top: offsetTop - 100,
      behavior: 'smooth',
      duration: 500,
    });
    setCurrentDiv(elem);
  };

  const projectInfo = useSelector((state) => state.project.projectInfo);
  const projectError = useSelector((state) => state.project.errorInfo);

  const deleteProject = (projectID) => {
    ProjectApi.deleteProject({ id: projectID })
      .then((response) => {
        if (response.success) {
          toastr.success('', `${response.message}`);
          window.location.replace('/dashboard');
        } else {
          toastr.error('', `${response.error.message}`);
        }
      })
      .catch((err) => toastr.error('', `${err.message}`));
    setModalOpen(!modalOpen);
  };

  const handleDelete = () => {
    setModalOpen(!modalOpen);
  };

  const handleZipDownload = async () => {
    setIsDownloading(true);
    ProjectApi.downloadZip(projectId)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('type', 'hidden');
        link.setAttribute('download', `project${projectId}.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setIsDownloading(false);
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
        setIsDownloading(false);
      });
  };

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const handleClickStatus = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndexStatus === index ? -1 : index;
    setActiveIndexStatus(newIndex);
  };

  // useEffect(() => {
  //   if (history.location.hash === '#quarterly-assessments' && projectInfo && projectInfo.project_name_in_english) {
  //     scrollTo('quarterly-assessments');
  //   }
  // }, [projectInfo]);

  const print = () => {
    var element = document.getElementById('project-detail-container');
    element.classList.remove('printable');
    window.print();
  };

  const showStatusChange = () => {
    if (projectInfo.project_status === 'closed' && user.role === 'npcAdmin') {
      return true;
    } else if (gate.length > 0 && projectInfo[gate].hasPublished && user.role !== 'dataViewer') {
      return true;
    } else return false;
  };

  const calcAssessment = (date) => {
    let submittedDate = moment(date);
    let endDateForSubmission = submittedDate.clone().add(21, 'days');
    let today = moment(new Date());

    if (endDateForSubmission.isSameOrBefore(today)) {
      return false;
    }
    let diffSecs = endDateForSubmission.diff(today, 'seconds');

    let diff = endDateForSubmission.diff(today, 'days');
    today.add(diff, 'days');
    let diffHour = endDateForSubmission.diff(today, 'hours');
    today.add(diffHour, 'hours');
    let diffMin = endDateForSubmission.diff(today, 'minutes');
    today.add(diffMin, 'minutes');
    let diffSec = endDateForSubmission.diff(today, 'seconds');
    today.add(diffSec, 'seconds');

    let returnedData = '';

    if (diff) {
      returnedData = returnedData + diff + `${diff > 1 ? ' days ' : ' day '}`;
    }
    if (diffHour) {
      returnedData = returnedData + diffHour + `${diffHour > 1 ? ' hrs ' : ' hr '}`;
    }
    if (diffMin) {
      returnedData = returnedData + diffMin + `${diffMin > 1 ? ' mins ' : ' min '}`;
    }
    if (diffSec) {
      returnedData = returnedData + diffSec + `${diffSec > 1 ? ' secs ' : ' sec '}`;
    }

    return { diffSecs, returnedData };
  };


  return projectInfo && projectInfo.project_name_in_english ? (
    <>
      <Grid columns="equal">
        <Grid.Column className="form-container print">
          <div className="operations-btn no-print">
            <div>
              <Link to="/dashboard" className="btn-normal btn-normal--medium btn-dark">
                <i className="material-icons md-bold  mr-6">keyboard_backspace</i>
                Back to Dashboard
              </Link>
            </div>
            {projectInfo && projectInfo.project_status !== 'closed' && (
              <div>
                <button
                  type="button"
                  className="btn-transition btn-outline btn-outline--blue"
                  onClick={print}
                >
                  <i className="material-icons mr-6">print</i>
                  Print
                </button>

                {projectHasFile && (
                  <>
                    {isDownloading ? (
                      <button
                        type="button"
                        className="btn-transition btn-outline btn-outline--blue"
                      >
                        <i className="material-icons  mr-6">cloud_download</i>
                        Downloading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={handleZipDownload}
                        className="btn-transition btn-outline btn-outline--blue"
                      >
                        <i className="material-icons  mr-6">cloud_download</i>
                        Download related files
                      </button>
                    )}
                  </>
                )}
                <Can
                  role={user.role}
                  perform="action:edit-delete"
                  data={projectData.isActionable}
                  yes={() => {
                    return (
                      gate.length > 0 &&
                      projectInfo[gate].canActionable && (
                        <Link
                          to={`/add/project?projectId=${projectId}&stage=${
                            gate === 'project_identification' ? 'identification' : 'appraisal'
                          }`}
                        >
                          <button
                            type="button"
                            className="btn-transition btn-outline btn-outline--blue"
                          >
                            <i className="material-icons  mr-6">edit</i>
                            Edit
                          </button>
                        </Link>
                      )
                    );
                  }}
                  no={() => ''}
                />
                <Can
                  role={user.role}
                  perform="action:edit-delete"
                  data={projectData.isActionable}
                  yes={() => {
                    return (
                      gate.length > 0 &&
                      projectInfo[gate].canActionable && (
                        <button
                          type="button"
                          className="btn-transition btn-outline btn-outline--red"
                          onClick={() => handleDelete()}
                          color="red"
                        >
                          <i className="material-icons  mr-6 material-delete">delete_outline</i>
                          Delete
                        </button>
                      )
                    );
                  }}
                  no={() => ''}
                />
              </div>
            )}
          </div>
          <div className="header large">{projectInfo.project_name_in_english}</div>
          <div className="project-detail">
            <div id="project-detail-container" className="project-detail-container">
              <div className="flex justify-between align-center project-detail-info">
                <div className="info-detail">
                  <p className="label-2 inline-block">
                    Submitted by&nbsp;
                    <Link to={`/profile?userId=${projectInfo.user.id}`}>
                      <span className="bold">{projectInfo.user.username}</span>
                    </Link>
                    &nbsp;on&nbsp;
                    <span>{moment(projectInfo.updatedAt).format('MMM DD,YYYY')}</span>
                  </p>
                  { gate.length > 0 &&
                    gate === 'project_identification' &&
                    projectInfo.project_appraisal && (
                      <button
                        className="inline-block label-2 no-print text-bold btn-text"
                        onClick={(e) => {
                          e.preventDefault();
                          setGate('project_appraisal');
                        }}
                      >
                        See project data from {projectInfo.project_status === 'new' ? 'appraisal' : 'ongoing'} stage
                      </button>
                    )}
                  {gate.length > 0 &&
                    gate === 'project_appraisal' &&
                    projectInfo.project_identification && (
                      <button
                        className="inline-block label-2 no-print text-bold btn-text"
                        onClick={(e) => {
                          e.preventDefault();
                          setGate('project_identification');
                        }}
                      >
                        See project data from identification stage
                      </button>
                    )}
                </div>
                <div className="countdown">
                  {user.role === 'npcAdmin' &&
                    projectInfo.project_status === 'new' &&
                    gate.length > 0 &&
                    projectInfo[gate] &&
                    !projectInfo[gate].hasPublished &&
                    projectInfo[gate].canActionable && 
                    projectInfo[gate].moderation_status === 'completed' &&
                    (calcAssessment(projectInfo[gate] && projectInfo[gate].completed_date)
                      .returnedData ? (
                      <Timer
                        initialTime={
                          calcAssessment(projectInfo[gate] && projectInfo[gate].completed_date)
                            .diffSecs
                        }
                        direction="backward"
                      >
                        {() => (
                          <React.Fragment>
                            <p className="counter label-2">
                              <span>Time left to Assessment:</span>
                              <span className="bold time">
                                {
                                  calcAssessment(
                                    projectInfo[gate] && projectInfo[gate].completed_date
                                  ).returnedData
                                }
                              </span>
                            </p>
                          </React.Fragment>
                        )}
                      </Timer>
                    ) : (
                      <p className="counter label-2">
                        Assessment deadline has already passed, please assess the project as soon as
                        possible
                      </p>
                    ))}
                </div>
                <div className="flex align-center">
                  {gate.length > 0 && projectInfo[gate].ranking ? (
                    <div className="info-rank bold">
                      {gate.length > 0 && projectInfo[gate].ranking}
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="info-gate project-metadata gate-label-wrapper bold">
                    <span className="gate-label project-status">
                      {projectInfo && projectInfo.project_status === 'closed'
                        ? 'closed'
                        : projectInfo && projectInfo.project_status === 'ongoing'
                        ? 'ongoing'
                        : gate === 'project_identification'
                        ? 'identification'
                        : 'appraisal'}
                    </span>
                  </div>
                  {projectInfo.project_status !== 'closed' ? (
                    <>
                      <div className="info-status project-metadata bold">
                        <span
                          className={`project-status ${
                            gate.length > 0 && projectInfo[gate].moderationStatus.toLowerCase()
                          }`}
                        >
                          {gate.length > 0 && projectInfo[gate].moderationStatus}
                        </span>
                      </div>
                      {gate.length > 0 && projectInfo[gate].hasPublished ? (
                        <div className="icon-done">
                          <i className="material-icons">done</i>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              {/* Assessment section goes here */}
              <div>
                <AssessmentSection projectId={projectId} gate={gate} />
              </div>

              <GeneralInformationSection gate={gate} />
              <ProjectSpecificSection gate={gate} />
              <ProjectProgressSection gate={gate} />
              <ProjectReadinessSection gate={gate} />
              <ProjectSelectionSection gate={gate} />
              <ProjectPrioritizationSection gate={gate} />
              <ProjectImplementationSection gate={gate} />
              <ProjectFinancingSection gate={gate} />
              <ProjectViabilitySection gate={gate} />
              <LandAcquisitionSection gate={gate} />
              {projectInfo.project_appraisal && gate === 'project_appraisal' && (
                <ProjectJustificationSection gate={gate} />
              )}
              <ContactSection gate={gate} />
              <RemarkSection gate={gate} />
              {user.role === 'npcAdmin' &&
                projectInfo.project_status === 'new' &&
                gate.length > 0 &&
                projectInfo[gate].moderation_status === 'completed' &&
                (gate === 'project_identification' &&
                !projectInfo.project_appraisal &&
                projectInfo.project_identification ? (
                  <Assessment projectId={projectId} gate={gate} getProject={getProject} />
                ) : gate === 'project_appraisal' && projectInfo.project_appraisal ? (
                  <Assessment projectId={projectId} gate={gate} getProject={getProject} />
                ) : (
                  <></>
                ))}

              <div id="quarterly-assessments" name="quarterly-assessments">
                <QuarterlyAssessmentSection projectId={projectId} gate={gate} />
              </div>
            </div>
            <div className="project-detail-dashboard no-print">
              <ul>
                <li>
                  <a
                    href="#general-information"
                    className={currentDiv === 'general-information' ? 'active' : ''}
                    onClick={() => scrollTo('general-information')}
                  >
                    {lang.general_information_view.section_name}
                  </a>
                </li>
                <li>
                  <a
                    href="#project-section"
                    className={currentDiv === 'project-section' ? 'active' : ''}
                    onClick={() => scrollTo('project-section')}
                  >
                    {lang.project_specific_view.section_name}
                  </a>
                </li>
                <li>
                  <a
                    href="#project-progress"
                    className={currentDiv === 'project-progress' ? 'active' : ''}
                    onClick={() => scrollTo('project-progress')}
                  >
                    {projectInfo.project_status === 'new' && gate === 'project_identification'
                      ? lang.progress_view.form_name_alternate
                      : lang.progress_view.section_name}
                  </a>
                </li>
                <li>
                  <a
                    href="#project-readiness"
                    className={currentDiv === 'project-readiness' ? 'active' : ''}
                    onClick={() => scrollTo('project-readiness')}
                  >
                    {lang.project_readiness_view.section_name}
                  </a>
                </li>
                <li>
                  <a
                    href="#project-selection"
                    className={currentDiv === 'project-selection' ? 'active' : ''}
                    onClick={() => scrollTo('project-selection')}
                  >
                    {lang.selection_view.section_name}
                  </a>
                </li>
                <li>
                  <a
                    href="#project-prioritization"
                    className={currentDiv === 'project-prioritization' ? 'active' : ''}
                    onClick={() => scrollTo('project-prioritization')}
                  >
                    {lang.prioritization_score_view.section_name}
                  </a>
                </li>
                <li>
                  <a
                    href="#project-implementation"
                    className={currentDiv === 'project-implementation' ? 'active' : ''}
                    onClick={() => scrollTo('project-implementation')}
                  >
                    {projectInfo.project_status === 'new' && gate === 'project_identification'
                      ? lang.modality_view.form_name_alternate
                      : lang.modality_view.section_name}
                  </a>
                </li>
                <li>
                  <a
                    href="#project-financing"
                    className={currentDiv === 'project-financing' ? 'active' : ''}
                    onClick={() => scrollTo('project-financing')}
                  >
                    {projectInfo.project_status === 'new' && gate === 'project_identification'
                      ? lang.financing_view.form_name_alternate
                      : lang.financing_view.section_name}
                  </a>
                </li>
                <li>
                  <a
                    href="#project-viability"
                    className={currentDiv === 'project-viability' ? 'active' : ''}
                    onClick={() => scrollTo('project-viability')}
                  >
                    {projectInfo.project_status === 'new' && gate === 'project_identification'
                      ? lang.viability_view.form_name_alternate
                      : lang.viability_view.section_name}
                  </a>
                </li>
                <li>
                  <a
                    href="#land-acquisition"
                    className={currentDiv === 'land-acquisition' ? 'active' : ''}
                    onClick={() => scrollTo('land-acquisition')}
                  >
                    {lang.land_acquisition_view.section_name}
                  </a>
                </li>
                {projectInfo.project_appraisal && gate === 'project_appraisal' && (
                  <li>
                    <a
                      href="#project-justification"
                      className={currentDiv === 'project-justification' ? 'active' : ''}
                      onClick={() => scrollTo('project-justification')}
                    >
                      {lang.justification_view.section_name}
                    </a>
                  </li>
                )}
                <li>
                  <a
                    href="#contact"
                    className={currentDiv === 'contact' ? 'active' : ''}
                    onClick={() => scrollTo('contact')}
                  >
                    {lang.contact_view.section_name}
                  </a>
                </li>
                <li>
                  <a
                    href="#remark"
                    className={currentDiv === 'remark' ? 'active' : ''}
                    onClick={() => scrollTo('remark')}
                  >
                    {lang.remarks_view.section_name}
                  </a>
                </li>
                <div className="accordion-wrapper">
                  {gate.length > 0 &&
                    projectInfo[gate].hasPublished &&
                    user.role !== 'dataViewer' &&
                    (projectInfo.project_status !== 'closed' || user.role === 'npcAdmin') && (
                      <Accordion className="sidebar-accordion" styled>
                        <Accordion.Title
                          active={activeIndexStatus === 0}
                          index={0}
                          onClick={handleClickStatus}
                        >
                          <Icon name="dropdown" />
                          {lang.status_change_view.section_name}
                        </Accordion.Title>
                        <Accordion.Content active={activeIndexStatus === 0}>
                          <StatusChangeSection projectId={projectId} getProject={getProject} />
                        </Accordion.Content>
                      </Accordion>
                    )}
                  {projectInfo.project_status !== 'closed' &&
                    (user.role === 'npcAdmin' || user.role === 'ministryAdmin') && (
                      <Accordion className="sidebar-accordion" styled>
                        <Accordion.Title active={activeIndex === 0} index={0} onClick={handleClick}>
                          <Icon name="dropdown" />
                          {lang.author_view.section_name}
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 0}>
                          <AuthorSection projectId={projectId} getProject={getProject} />
                        </Accordion.Content>
                      </Accordion>
                    )}
                </div>
              </ul>
            </div>
          </div>
          {gate.length > 0 && projectInfo[gate].hasPublished && user.role !== 'dataViewer' ? (
            <div className="export-add-button no-print">
              <button
                onClick={() => history.push(`/assessment/${projectId}?gate=${gate}`)}
                className="btn-normal btn-blue btn-shadow--blue btn-transition"
              >
                <Icon name="plus" />
                Add Assessment
              </button>
            </div>
          ) : (
            ''
          )}
        </Grid.Column>
      </Grid>

      <Modal
        dimmer="blurring"
        open={modalOpen}
        onClose={() => setModalOpen(!modalOpen)}
        size="mini"
      >
        <Modal.Header>Delete project?</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete the project?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setModalOpen(!modalOpen)}>
            Cancel
          </Button>
          <Button
            positive
            content="Yes"
            onClick={() => {
              deleteProject(projectData.id);
            }}
          />
        </Modal.Actions>
      </Modal>
    </>
  ) : projectError.error && projectError.error.code === 404 ? (
    <PageNotFound message={projectError.error && projectError.error.message} />
  ) : (
    <></>
  );
};

export default withRouter(ViewGate);
