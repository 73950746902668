import Api from '../api';
import ApiUrlConstant from '../../helpers/constant/GateApiURL';

class TrashApi {
  async listProjects(query) {
    try {
      const res = await Api.get(await ApiUrlConstant.TRASH(), null, query);
      return res.body;
    } catch (err) {
      return err;
    }
  }

  async restoreProject(params) {
    try {
      const res = await Api.patch(await ApiUrlConstant.TRASH(params));
      return res.body;
    } catch (err) {
      return err;
    }
  }

  async deleteProject(params) {
    try {
      const res = await Api.destroy(await ApiUrlConstant.TRASH(params));
      return res.body;
    } catch (err) {
      return err;
    }
  }

  async bulkRestore(data) {
    try {
      const res = await Api.patch(await ApiUrlConstant.BULK_TRASH(), data);
      return res.body;
    } catch (err) {
      return err;
    }
  }

  async bulkDelete(data) {
    try {
      const res = await Api.destroy(await ApiUrlConstant.BULK_TRASH(), data);
      return res.body;
    } catch (err) {
      return err;
    }
  }
}

export default new TrashApi();
