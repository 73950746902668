import { GET_ERRORS } from '../helpers/constant/types';

const initialState = {
  isError: false,
  error: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        isError: action.payload.isError,
        error: action.payload,
      };
    default:
      return state;
  }
}
