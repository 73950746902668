import React from "react";
import {useSelector} from "react-redux"
import NotFoundImage from "../../assets/images/404.svg"
import { Link } from "react-router-dom";
import {auth} from "../../helpers"


const PageNotFound = ({message}) => {
  const selectedLangage = useSelector(auth.getSelectedLanguage)
  return (
    <div className="page-not-found">
      <img src={NotFoundImage} alt="Page not found" />
      {message ? (<p className="heading-1">{message}</p>) : (
        <p className={`heading-1 ${selectedLangage}`}>{selectedLangage === "ne" ? "तपाईले खोज्नु भएको पृष्ठ फेला पार्न सकिएन :("  : "Oh no! We couldn’t find the page you’re looking for :("}</p>
      )}
      <p className="body-2">
        {selectedLangage === "ne" ?
         (<>माफ गर्नुहोस्, हामी तपाईंको लागि पृष्ठ प्राप्त गर्न सकेनौं। यदि तपाईं परियोजना खोज्दै हुनुहुन्छ भने, कृपया <Link to="/dashboard">ड्यासबोर्डमा</Link> फिर्ता जानुहोस् </>) : 
         (<>Sorry, we couldn’t retrieve a page for you. If you’re looking to search for a project, 
          please go back to <Link to="/dashboard">Dashboard</Link></>)}
      </p>
    </div>
  )
};
export default PageNotFound;
