import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Select from 'react-select';
import {
	Button,
	Form, Input, Modal, Pagination, Table
} from 'semantic-ui-react';

import MinistryService from '../../services/Ministry';
import ProgramService from '../../services/Program';

import { auth } from '../../helpers';

import Can from '../../components/Can/can';

import LoaderNP from '../../components/Loader/Loader';
import Filter from '../../components/ProgramFilter/index';
import { localization } from '../../localization';

function ProgramList({ history }) {
	const language = useSelector(auth.getSelectedLanguage);
	const lang = localization[language]['program'];
	const [isLoading, setIsLoading] = useState(true)

	const [programList, setProgramList] = useState([]);
	const [programId, setProgramId] = useState('');
	const [ministryId, setMinistryId] = useState('');
	const [programName, setProgramName] = useState('');
	const [programBudgetCode, setProgramBudgetCode] = useState('');

	const [modalOpen, setModalOpen] = useState(false);
	const [action, setAction] = useState('');
	const user = useSelector(auth.getCurrentUser);
	const [isDownloading, setIsDownloading] = useState(false);
	const [selectedProgram, setSelectedProgram] = useState(false);
	const [programError, setProgramError] = useState(false);
	const [ministries, setMinistries] = useState([]);
	const [program, setProgram] = useState({
		name: '',
		program_budget_code: '',
		ministry_id: '',
	});
	const [activePage, setActivePage] = useState(1);
	const [totalPage, setTotalPage] = useState(0);
	const [totalSize, setTotalSize] = useState(0);
	const [totalPrograms, setTotalPrograms] = useState();

	const [queryString, setQueryString] = useState({ page: 0 });
	const [errObj, setValidationError] = useState({
		name: false,
		ministry: false,
	});
	const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
	const [filterBy, setFilterBy] = useState({ ministry_id: { value: 0 } });
	const [search, setSearch] = useState('');
	const [isExporting,setIsExporting] = useState(false);
	const [downloading, setDownloading] = useState({});

	useEffect(() => {
		allProgram();
		if (user.role === 'npcAdmin' || user.role === 'dataViewer') {
			listMinistries();
		} else {
			setProgram({ ...program, ministry_id: { value: user.ministry.id } });
		}
	}, [queryString]);

	const listMinistries = () => {
		MinistryService.listAllMinistries(`?isDashboard=true&`)
			.then((response) => {
				let ministries = [];
				response.data.ministries.lists.forEach((m) => {
					ministries.push({ label: m.name, value: m.id });
				});
				setMinistries(ministries);
			})
			.catch((err) => {
				toastr.error('', `${err.message}`);
			});
	};

	const allProgram = () => {
		ProgramService.listAllProgram(queryString)
			.then((response) => {
				let program = response.data.programs;
				setProgramList(program.lists);
				setTotalPage(program.totalPage);
				setTotalSize(program.totalSize);
				setTotalPrograms(program.count);
				setIsLoading(false);
			})
			.catch((err) => {
				toastr.error('', `${err.message}`);
			});
	};

	const deleteProgram = (e) => {
		setModalOpen(!modalOpen);
		ProgramService.deleteProgram({
			programId: programId,
			ministryId: ministryId,
		})
			.then((response) => {
				toastr.success('', `${response.message}`);
				setQueryString({ ...queryString, page: activePage - 1 });
			})
			.catch((err) => {
				toastr.error('', `${err.message}`);
			});
	};

	const editProgram = (e) => {
		let data = {};
		data.name = programName;
		data.program_budget_code = programBudgetCode;
		setModalOpen(!modalOpen);

        if (data.name.length <= 0) {
            toastr.error('', `Program Name cannot be empty.`);
            return
        }

		ProgramService.updateProgram(
			{
				programId: programId,
				ministryId: ministryId,
			},
			data
		)
			.then((response) => {
				toastr.success('', `${response.message}`);
				setQueryString({ ...queryString, page: activePage - 1 });
			})
			.catch((err) => {
				toastr.error('', `${err.message}`);
			});
	};

	const handleZipDownload = async (programId) => {
		setDownloading(item =>{return {...item,[`download_${programId}`]:true}})
		setSelectedProgram(programId);
		setIsDownloading(true);
		ProgramService.downloadProgramZip(programId)
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('type', 'hidden');
				link.setAttribute('download', `program${programId}.zip`);
				document.body.appendChild(link);
				link.click();
				link.remove();
				setIsDownloading(false);
				setSelectedProgram(false);
				setDownloading(item =>{return {...item,[`download_${programId}`]:false}})
			})
			.catch((err) => {
				toastr.error('', `${err.message}`);
				setIsDownloading(false);
				setDownloading(item =>{return {...item,[`download_${programId}`]:false}})
			});
	};

	const createProgram = (e) => {
		e.preventDefault();
		if (!program.name && program.name.trim() === '') {
			setValidationError({ ...errObj, name: true });
			setModalOpen(false)
			toastr.error('', `Program Name cannot be empty.`);
			return;
		}
		if (user.role === 'npcAdmin' && !program.ministry_id) {
			setValidationError({ ...errObj, ministry: true });
			setModalOpen(false)
			toastr.error('', 'Ministry is required.');
			return;
		}
		if (program.name && program.ministry_id) {
			ProgramService.createProgram(
				{
					name: program.name.trim().replace(/\s{2,}/g, ' '),
					program_budget_code: program.program_budget_code
						.trim()
						.replace(/\s{2,}/g, ' '),
				},
				{ ministryId: program.ministry_id.value }
			)
				.then((response) => {
					if (response.success) {
						setProgram({ name: '', program_budget_code: '', ministry_id: '' });
						setQueryString({ ...queryString, page: activePage - 1 });
						setModalOpen(!modalOpen);
						toastr.success('', `${response.message}`);
					} else {
						toastr.error('', response.message);
						setProgramError(false);
						setProgram({ name: '', program_budget_code: '', ministry_id: '' });
						setModalOpen(!modalOpen);
					}
				})
				.catch((err) => {
					toastr.error('', `${err.message}`);
				});
		}
	};

	const handlePaginationChange = (e, { activePage }) => {
		setActivePage(activePage);
		setQueryString({ ...queryString, page: activePage - 1 });
	};

	// const handleSearch = () => {
	// 	setQueryString(queryString => {
	// 		return { ...queryString, page: 0, ...filterBy, search: search };
	// 	});
	// 	filterBy.ministry_id && setShowAdvanceFilter(true);
	// 	// setQueryString({ ...queryString, page: activePage - 1, search: search,filterBy });
	// };

	const handleSearch = (label = null, e = null) => {
		if (e) e.preventDefault();
		let filter = {};
		let clonedQueryString = { ...queryString };

		if (label) {
			clonedQueryString = Object.keys(clonedQueryString).reduce(
				(object, key) => {
					if (key !== label) {
						object[key] = clonedQueryString[key];
					}
					return object;
				},
				{}
			);
		}

		// delete queryString.ministry_id;
		// delete queryString.department_id;

		if (filterBy.ministry_id.value !== 0) {
			filter.ministry_id = filterBy.ministry_id.value;
		}
		setQueryString({
			...clonedQueryString,
			page: 0,
			search: search.trim(),
			...filter,
		});
		setActivePage(1);
	};

	const handleReset = () => {
		// setQueryString({
		// 	...queryString,
		// 	page: activePage - 1,
		// 	search: '',
		// 	filterBy: {}
		// });
		setQueryString({ page: activePage - 1 });
		setSearch('');
		setFilterBy({ ministry_id: { value: 0 } });
	};

	const handleRemoveFilter = (filterLabel) => {
		filterBy[filterLabel] = { value: 0 };
		setFilterBy(filterBy);
		handleSearch(filterLabel);
	};

	const showFiltertag = (filter, label) => {
		return (
			<div className='tag'>
				<span onClick={() => handleRemoveFilter(filter)}>
					<i className='material-icons close'>close</i>
				</span>
				{label}
			</div>
		);
	};

	const handleSearchInputChange = (e) => {
		setSearch(e.target.value);
	};

	const handleFilterOptions = (name, selectedOption) => {
		setFilterBy((filterBy) => {
			return { ...filterBy, [name]: selectedOption };
		});
	};

	const handleApplyFilterStatus = () => {
		setShowAdvanceFilter(true);
	};

	const exportProgramData = async () => {
		setIsExporting(true);
		let string = '?';

		if (queryString.filterBy && queryString.filterBy.ministry_id !== '') {
			string = `${string}ministry_id=${queryString.filterBy.ministry_id}&`;
		}

		if (queryString.search && queryString.search !== '') {
			string = `${string}search=${search}&`;
		}

		const data = await ProgramService.exportProgram(string);
		const url = window.URL.createObjectURL(new Blob([data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('type', 'hidden');
		link.setAttribute('download', 'programs.xlsx');
		document.body.appendChild(link);
		link.click();
		link.remove();
		setIsExporting(false);
	};

	return (
		<>
			<div className='dashboard-header justify-between'>
				<div className='flex'>
					<h2 className='header-main'>{lang.programs}</h2>
					<div className='project-stats flex'>
						<div className='card'>
							<p className='card-info'>
								{totalPrograms && totalPrograms.toLocaleString()}
							</p>
							<p className='card-label'>{lang.programs}</p>
						</div>
						<div className='card'>
							<p className='card-info'></p>
							<p className='card-label'></p>
						</div>
						<div className='card'>
							<p className='card-info'></p>
							<p className='card-label'></p>
						</div>
						<div className='card'>
							<p className='card-info'></p>
							<p className='card-label'></p>
						</div>
						<div className='card'>
							<p className='card-info'></p>
							<p className='card-label'></p>
						</div>
					</div>
				</div>
			</div>
			<div className='export-add-button'>
				{programList.length > 0 && (!isExporting  ? <button
					className='btn-normal btn-dark btn-shadow--dark btn-transition export'
					onClick={exportProgramData}
				>
					<i className='material-icons md-normal download mr-6'>
						system_update_alt
					</i>
					{lang.export_data}
				</button> : <button
				  className='btn-normal btn-dark btn-shadow--dark btn-transition export'
				 >
				 <i className="ui secondary loading button" />
				 		{lang.export_data}
				 </button>)
				  }
				{user.role !== 'dataViewer' ? (
					<button
						onClick={() => {
							setModalOpen(!modalOpen);
							setAction('add');
						}}
						className='btn-normal btn-blue btn-shadow--blue btn-transition'
					>
						<i className='material-icons download md-18 md-bold mr-6'>add</i>
						{lang.add_program}
					</button>
				) : (
					<></>
				)}
			</div>
			<Can
				role={user.role}
				perform='project-ministry:filter'
				yes={() => (
					<Filter
						ministries={ministries}
						language={lang}
						handleSelect={handleFilterOptions}
						filterValues={filterBy || {}}
						handleSearch={handleSearch}
						handleReset={handleReset}
						handleApplyFilterStatus={handleApplyFilterStatus}
						role={user.role}
					/>
				)}
				no={() => <></>}
			/>
			<div className='flex align-center chart-search-wrapper'>
				<div>
					<form onSubmit={(e) => handleSearch(null, e)}>
						<div className='form-search'>
							<Input
								iconPosition='left'
								type='text'
								placeholder='Search by typing name & budget code...'
								onChange={(e) => handleSearchInputChange(e)}
								value={search}
								action={lang.search}
								className='input-search'
							/>
							<i className='material-icons md-24'>search</i>
						</div>
					</form>
				</div>
			</div>
			<div className='filter-container'>
				<Form>
					{showAdvanceFilter && (
						<div className='filter-tags'>
							{filterBy.ministry_id.value !== 0 ? (
								<p>{lang.filter_applied}: </p>
							) : (
								''
							)}

							<div className='tags-list'>
								{filterBy.ministry_id.value !== 0
									? showFiltertag('ministry_id', filterBy.ministry_id.label)
									: ''}
							</div>

							{filterBy.ministry_id.value !== 0 ? (
								<button
									className='btn-outline btn-outline--red'
									onClick={handleReset}
								>
									{lang.clear_filter}
								</button>
							) : (
								''
							)}
						</div>
					)}
				</Form>
			</div>
			<div className="table-container">
			{isLoading ? <LoaderNP /> : (
				programList.length > 0 ? (
					<>
						<Table padded className='project-table no-action'>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell width={1}>{lang.program_id}</Table.HeaderCell>
									<Table.HeaderCell width={6}>{lang.name}</Table.HeaderCell>
									<Table.HeaderCell width={4}>{lang.ministry}</Table.HeaderCell>
									<Table.HeaderCell width={2}>
										{lang.program_budget_code}
									</Table.HeaderCell>
									<Table.HeaderCell>{lang.download}</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
	
							<Table.Body>
								{programList.map((program, index) => {
									return (
										<Table.Row key={program.id}>
											<Table.Cell className='text-bold project-id'>
												{program.id}
											</Table.Cell>
											<Table.Cell>
												<p className='project-title'>{program.name}</p>
											</Table.Cell>
											<Table.Cell>
												{program.ministry && program.ministry.name}
											</Table.Cell>
											<Table.Cell>{program.program_budget_code}</Table.Cell>
											<Table.Cell className='table-bottom-spacing'>
												{/* {selectedProgram === program.id && isDownloading */}
												{selectedProgram === program.id && downloading && downloading[`download_${program.id}`]
													? program.hasZipDownload && (
															// <button className='btn-normal btn-normal--small btn-dark'>
															// 	<i className='material-icons mr-2 download'>
															// 		get_app
															// 	</i>
															// 	Download
															// </button>
															<button
															className='btn-normal btn-normal--small btn-dark'
														 >
														 <i className="ui secondary loading button" />
																 Download
														 </button>
													  )
													: program.hasZipDownload && (
															<button
																onClick={() => handleZipDownload(program.id)}
																className='btn-normal btn-normal--small btn-dark'
															>
																<i className='material-icons mr-2 download'>
																	get_app
																</i>
																Download
															</button>
													  )}
	
												<Can
													role={user.role}
													perform='action:edit-delete'
													data={program.isActionable}
													yes={() => (
														<div className='actions'>
															{/* <Link to={`/add/programs?id=${program.id}`}>Edit |</Link> */}
															<span
																onClick={() => {
																	setModalOpen(!modalOpen);
																	setProgramId(program.id);
																	setMinistryId(
																		program.ministry && program.ministry.id
																	);
																	setAction('edit');
																	setProgramName(program.name);
																	setProgramBudgetCode(
																		program.program_budget_code
																	);
																}}
																className='btn-outline btn-outline--small btn-outline--blue'
															>
																<i className='material-icons md-12 mr-2'>edit</i>
																Edit{' '}
															</span>
	
															{program.isDeleteAble ? (
																<span
																	className='btn-outline btn-outline--small btn-outline--red'
																	onClick={() => {
																		setModalOpen(!modalOpen);
																		setProgramId(program.id);
																		setMinistryId(
																			program.ministry && program.ministry.id
																		);
																		setAction('delete');
																	}}
																>
																	<i className='material-icons md-12 mr-2 material-delete'>
																		delete_outline
																	</i>
																	Delete{' '}
																</span>
															) : (
																<span
																	className='btn-outline btn-outline--small btn-disabled-delete'
																	data-tooltip={lang.program_associated_message}
																>
																	<i className='material-icons md-12 mr-2 material-delete'>
																		delete_outline
																	</i>
																	Delete
																</span>
															)}
														</div>
													)}
													no={() => ''}
												/>
											</Table.Cell>
										</Table.Row>
									);
								})}
							</Table.Body>
						</Table>
	
						<div className='justify-center'>
							<Pagination
								defaultActivePage={activePage}
								onPageChange={handlePaginationChange}
								totalPages={totalPage}
								firstItem={{ 'aria-label': 'First item', content: 'First' }}
								lastItem={{ 'aria-label': 'Last item', content: 'Last' }}
								prevItem={{
									'aria-label': 'Previous item',
									content: 'Previous',
								}}
								nextItem={{ 'aria-label': 'First item', content: 'Next' }}
							/>
						</div>
					</>
				) : (
					<div className='empty'>{lang.no_program_found}</div>
				)
			)}
			</div>

			<Modal
				dimmer='blurring'
				open={modalOpen}
				onClose={() => setModalOpen(!modalOpen)}
				size='mini'
			>
				{action === 'delete' && (
					<>
						<Modal.Header>{lang.delete_program}</Modal.Header>
						<Modal.Content>
							<p>{lang.are_you_sure_delete_program}</p>
						</Modal.Content>
						<Modal.Actions>
							<Button color='black' onClick={() => setModalOpen(!modalOpen)}>
								Cancel
							</Button>
							<Button
								positive
								content='Yes'
								onClick={(e) => {
									deleteProgram(e);
								}}
							/>
						</Modal.Actions>
					</>
				)}

				{action === 'edit' && (
					<>
						<Modal.Header>{lang.edit_program}</Modal.Header>
						<Modal.Content>
							<Form>
								<Form.Group grouped>
									<Form.Field>
										<label>{lang.name}</label>
										<input
											onChange={(e) => setProgramName(e.target.value)}
											value={programName}
										/>
									</Form.Field>
									<Form.Field>
										<label>{lang.program_budget_code}</label>
										<input
											onChange={(e) => setProgramBudgetCode(e.target.value)}
											value={programBudgetCode}
										/>
									</Form.Field>
								</Form.Group>
							</Form>
						</Modal.Content>
						<Modal.Actions>
							<Button color='black' onClick={() => setModalOpen(!modalOpen)}>
								Cancel
							</Button>
							<Button
								positive
								content='Yes'
								onClick={(e) => {
									editProgram(e);
								}}
							/>
						</Modal.Actions>
					</>
				)}

				{action === 'add' && (
					<>
						<Modal.Header>{lang.add_program}</Modal.Header>
						<Modal.Content>
							<Form>
								<Form.Group grouped>
									<Form.Field required>
										<label>{lang.name}</label>
										<input
											onChange={(e) => {
												setProgram({ ...program, name: e.target.value });
												setValidationError({ name: false });
											}}
											value={program.name}
											className={errObj.name ? 'error' : ''}
										/>
									</Form.Field>
									<Form.Field>
										<label>{lang.program_budget_code}</label>
										<input
											onChange={(e) =>
												setProgram({
													...program,
													program_budget_code: e.target.value,
												})
											}
											value={program.program_budget_code}
										/>
									</Form.Field>

									{user.role !== 'npcAdmin' ? (
										<Form.Field required>
											<label>{lang.ministry}</label>
											<input
												value={
													user.ministry
														? user.ministry.name
														: lang.no_ministry_associated
												}
												disabled
											/>
										</Form.Field>
									) : (
										<Form.Field required>
											<label>{lang.ministry}</label>
											<Form.Field>
												<Select
													options={ministries}
													value={program.ministry_id}
													onChange={(selectedOption) => {
														setProgram((program) => {
															return {
																...program,
																ministry_id: selectedOption,
															};
														});
														setValidationError({
															...errObj,
															ministry: false,
														});
													}}
													placeholder={lang.select_ministry}
													className={errObj.ministry ? 'error' : ''}
												/>
											</Form.Field>
										</Form.Field>
									)}
								</Form.Group>
							</Form>
						</Modal.Content>
						<Modal.Actions>
							<Button
								color='black'
								onClick={() => {
									setModalOpen(!modalOpen);
									setProgram({
										name: '',
										program_budget_code: '',
										ministry_id: user.role === 'npcAdmin' ? '' : program.ministry_id,
									});
									setValidationError({ name: false, ministry: false });
								}}
							>
								Cancel
							</Button>
							<Button
								positive
								content='Yes'
								onClick={(e) => {
									createProgram(e);
								}}
							/>
						</Modal.Actions>
					</>
				)}
			</Modal>
		</>
	);
}

export default ProgramList;
