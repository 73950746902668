function SectorHandle(lang, sectors) {
	if (sectors.length < 1) {
		return [
			{
				displayLabel:
					lang.general_information.agriculture_management_forest_area,
				label: 'Agriculture ,Land Management,Cooperative and Forest Area',
				value: false,
				subSector: [
					{
						displayLabel:
							lang.general_information.agriculture_vetenary_development,
						label: 'Agriculture and Vetenary Development',
						value: false
					},
					{
						displayLabel: lang.general_information.land_management,
						label: 'Land Management',
						value: false
					},
					{
						displayLabel:
							lang.general_information.cooperative_poverty_aleviation,
						label: 'Cooperative & Poverty Alleviation',
						value: false
					},
					{
						displayLabel: lang.general_information.forest_environment,
						label: 'Forest and Environment',
						value: false
					}
				]
			},
			{
				displayLabel: lang.general_information.economic,
				label: 'Economic',
				value: false,
				subSector: [
					{
						displayLabel: lang.general_information.financial,
						label: 'Financial',
						value: false
					},
					{
						displayLabel: lang.general_information.industry,
						label: 'Industry',
						value: false
					},
					{
						displayLabel: lang.general_information.commerce_trade,
						label: 'Commerce And Trade',
						value: false
					},
					{
						displayLabel:
							lang.general_information.culture_tourism_civil_aviation,
						label: 'Culture Tourism Civil Aviation',
						value: false
					}
				]
			},
			{
				displayLabel: lang.general_information.social,
				label: 'Social',
				value: false,
				subSector: [
					{
						displayLabel: lang.general_information.health_population,
						label: 'Health Population',
						value: false
					},
					{
						displayLabel: lang.general_information.education_science_technology,
						label: 'Education Science And Technology',
						value: false
					},
					{
						displayLabel: lang.general_information.water_sanitation,
						label: 'Water Sanitation',
						value: false
					},
					{
						displayLabel: lang.general_information.youth_ports,
						label: 'Youth and Sports',
						value: false
					},
					{
						displayLabel:
							lang.general_information.women_children_senior_citizens,
						label: 'Women Children And Senior Citizens',
						value: false
					},
					{
						displayLabel:
							lang.general_information.labour_employment_social_security,
						label: 'Labour Employment Social Security',
						value: false
					}
				]
			},
			{
				displayLabel: lang.general_information.infrastructure,
				label: 'Infrastructure',
				value: false,
				subSector: [
					{
						displayLabel: lang.general_information.energy,
						label: 'Energy',
						value: false
					},
					{
						displayLabel: lang.general_information.irrigation,
						label: 'Irrigation',
						value: false
					},
					{
						displayLabel: lang.general_information.water_resource_research,
						label: 'Water Resource Research',
						value: false
					},
					{
						displayLabel: lang.general_information.hydrology_heteorology,
						label: 'Hydrology Meteorology',
						value: false
					},
					{
						displayLabel: lang.general_information.road,
						label: 'Road',
						value: false
					},
					{
						displayLabel: lang.general_information.railway,
						label: 'Railway',
						value: false
					},
					{
						displayLabel: lang.general_information.water_transport,
						label: 'Water Transport',
						value: false
					},
					{
						displayLabel: lang.general_information.transport_management,
						label: 'Transport Management',
						value: false
					},
					{
						displayLabel: lang.general_information.communication_it,
						label: 'Communication IT',
						value: false
					},
					{
						displayLabel: lang.general_information.urban_development,
						label: 'Urban Development',
						value: false
					},
					{
						displayLabel: lang.general_information.reconstruction,
						label: 'Reconstruction',
						value: false
					}
				]
			},
			{
				displayLabel: lang.general_information.governance_areas,
				label: ' Governance and interrelated areas',
				value: false,
				subSector: []
			}
		];
	}

	sectors.forEach((s, index) => {
		switch (index) {
			case 0:
				s.displayLabel =
					lang.general_information.agriculture_management_forest_area;
				s.subSector[0].displayLabel =
					lang.general_information.agriculture_vetenary_development;
				s.subSector[1].displayLabel = lang.general_information.land_management;
				s.subSector[2].displayLabel =
					lang.general_information.cooperative_poverty_aleviation;
				s.subSector[3].displayLabel =
					lang.general_information.forest_environment;

				break;

			case 1:
				s.displayLabel = lang.general_information.economic;
				s.subSector[0].displayLabel = lang.general_information.financial;
				s.subSector[1].displayLabel = lang.general_information.industry;
				s.subSector[2].displayLabel = lang.general_information.commerce_trade;
				s.subSector[3].displayLabel =
					lang.general_information.culture_tourism_civil_aviation;
				break;

			case 2:
				s.displayLabel = lang.general_information.social;
				s.subSector[0].displayLabel =
					lang.general_information.health_population;
				s.subSector[1].displayLabel =
					lang.general_information.education_science_technology;
				s.subSector[2].displayLabel = lang.general_information.water_sanitation;
				s.subSector[3].displayLabel = lang.general_information.youth_ports;
				s.subSector[4].displayLabel =
					lang.general_information.women_children_senior_citizens;
				s.subSector[5].displayLabel =
					lang.general_information.labour_employment_social_security;
				break;

			case 3:
				s.displayLabel = lang.general_information.infrastructure;
				s.subSector[0].displayLabel = lang.general_information.energy;
				s.subSector[1].displayLabel = lang.general_information.irrigation;
				s.subSector[2].displayLabel =
					lang.general_information.water_resource_research;
				s.subSector[3].displayLabel =
					lang.general_information.hydrology_heteorology;
				s.subSector[4].displayLabel = lang.general_information.road;
				s.subSector[5].displayLabel = lang.general_information.railway;
				s.subSector[6].displayLabel = lang.general_information.water_transport;
				s.subSector[7].displayLabel =
					lang.general_information.transport_management;
				s.subSector[8].displayLabel = lang.general_information.communication_it;
				s.subSector[9].displayLabel =
					lang.general_information.urban_development;
				s.subSector[10].displayLabel = lang.general_information.reconstruction;
				break;

			case 4:
				s.displayLabel = lang.general_information.governance_areas;
				break;
			default:
				break;
		}
	});
	return sectors;
}

export default SectorHandle;
