import React, { useRef, useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

function StackedBarChart({ data, id, yAxisTitle, xAxisTitle, titleHeader, seriesInfo }) {
  const chart1 = useRef(null);

  useLayoutEffect(() => {
    // Create chart instance
    let chart = am4core.create(id, am4charts.XYChart);
    chart.logo.disabled = true;

    let title = chart.titles.create();
    title.text = titleHeader;
    title.fontSize = 25;
    title.marginBottom = 18;

    // Add data
    chart.data = data;

    // defining colors
    chart.colors.list = [
      am4core.color('#B49BEC'),
      am4core.color('#B8CFFC'),
      am4core.color('#7CA7F8'),
      am4core.color('#d5c6f7'),
      am4core.color('#9BE8EC'),
      am4core.color('#5ACBD2'),
    ];

    chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarY.parent = chart.leftAxesContainer;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'xLabel';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.title.text = xAxisTitle;
    categoryAxis.title.fontSize = 16;
    let label = categoryAxis.renderer.labels.template;
    label.truncate = false;
    // label.template.maxWidth = 100;
    categoryAxis.events.on('sizechanged', function (ev) {
      var axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      axis.renderer.labels.template.maxWidth = cellWidth;
      axis.renderer.labels.template.horizontalCenter = cellWidth < 120 ? 'right' : 'middle';
      axis.renderer.labels.template.verticalCenter = cellWidth < 120 ? 'middle' : 'top';
      label.rotation = cellWidth < 120 ? -45 : 0;
    });
    label.tooltipText = '{category}';

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.title.text = yAxisTitle;
    valueAxis.title.fontSize = 16;

    // Create series
    function createSeries(field, name) {
      // Set up series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = 'xLabel';
      series.sequencedInterpolation = true;

      // Make it stacked
      series.stacked = true;

      // Configure columns
      // series.columns.template.width = am4core.percent(60);
      series.columns.template.tooltipText = '[bold]{name}[/]\n{categoryX}: {valueY}';
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fillOpacity = 1;
      series.tooltip.background.fill = am4core.color('#fff');
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = am4core.color('#697588');

      // Add label
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = '{valueY}';
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;

      return series;
    }

    seriesInfo.map((data) => {
      for (const [key, value] of Object.entries(data)) {
        return createSeries(key, value);
      }
    });

    // Legend
    chart.legend = new am4charts.Legend();
    chart.legend.paddingTop = 20;

    chart1.current = chart;

    return () => {
      chart.dispose();
    };
  }, [data, titleHeader, xAxisTitle]);

  return <div id={id} style={{ width: '100%', height: '500px' }}></div>;
}

export default StackedBarChart;
