import React from 'react';
import { Form, Input, Dropdown } from 'semantic-ui-react';

const ExampleCurrency = () => {
  return (
    <>
      {/* Form group start */}
      <Form.Group>
        <Form.Field
          control={Input}
          label="Amount"
          width={8}
          className="field-align-bottom input-with-select"
        >
          <Input
            label={
              <Dropdown
                defaultValue="usd"
                options={[
                  { key: 'usd', text: 'usd', value: 'usd' },
                  { key: 'npr', text: 'npr', value: 'npr' },
                ]}
              />
            }
            labelPosition="left"
            placeholder="Eg: 30000"
          />
        </Form.Field>
        <Form.Field
          control={Input}
          label="Exchange rate"
          placeholder="Eg: 80"
          width={4}
          className="field-align-bottom input-with-select"
        />
        <Form.Field
          control={Input}
          label="Amount in words"
          placeholder="Thirty thousand only"
          width={4}
          className="field-align-bottom input-with-select"
        />
      </Form.Group>
      {/* Form group end */}
    </>
  );
};

export default ExampleCurrency;
