const initialState = {
  title: '',
  documentFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'ERROR':
      return {};

    case 'REMARKS_FETCHED':
      state = { ...payload };
      return state;

    case 'NULL_REMARKS_FETCHED':
      return initialState;

    default:
      return state;
  }
}
