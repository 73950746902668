import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Header, Tab, Grid, Table } from 'semantic-ui-react';
import { get } from 'lodash';
import moment from 'moment';

import { API_URL } from '../../../helpers';
import { auth } from '../../../helpers';

import { localization } from '../../../localization';

const LandAcquisitionSection = ({ gate }) => {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['land_acquisition_view'];
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const [projectData, setProjectData] = useState();

  useEffect(() => {
    setProjectData(projectInfo[gate]);
  }, [gate]);

  return (
    <div name="land-acquisition">
      <div className="project-section" id="land-acquisition">
        <div className="header medium">{lang.section_name}</div>
        <Tab.Pane>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column width={16}>
                {get(projectData, 'land_acquisition_status.landAcquisitionStatus') ? (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{lang.land_type}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.required_land}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.total_acquired_land}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.remaining_acquired_land}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.estimated_date}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {projectData &&
                        projectData.land_acquisition_status.landAcquisitionStatus &&
                        projectData.land_acquisition_status.landAcquisitionStatus.map(
                          (landAcquisitionItem, index) => {
                            return (
                              <Table.Row key={index}>
                                <Table.Cell>{landAcquisitionItem.landType}</Table.Cell>
                                <Table.Cell>{landAcquisitionItem.requiredLand}</Table.Cell>
                                <Table.Cell>{landAcquisitionItem.totalAcquiredLand}</Table.Cell>
                                <Table.Cell>{landAcquisitionItem.remainingAcquiredLand}</Table.Cell>
                                <Table.Cell>
                                  {landAcquisitionItem.estimatedDateToAcquireLand &&
                                    moment(landAcquisitionItem.estimatedDateToAcquireLand).format(
                                      'YYYY-MM-DD'
                                    )}
                                </Table.Cell>
                              </Table.Row>
                            );
                          }
                        )}
                    </Table.Body>
                  </Table>
                ) : (
                  ''
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.issues} :</p>
              </Grid.Column>
              <Grid.Column>
                <p
                  className="project-info"
                  dangerouslySetInnerHTML={{
                    __html: get(projectData, 'land_acquisition_status.remainingAcquisitionIssues'),
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.supporting_document} :</p>
              </Grid.Column>
              <Grid.Column>
                <div className="project-info file-download">
                  {projectData &&
                    projectData.land_acquisition_status &&
                    projectData.land_acquisition_status.remainingLandAcquisitionFile.map(
                      (item, index) => {
                        return (
                          Object.keys(item).length !== 0 && (
                            <p key={index}>
                              {item.originalName ? item.originalName: item.fileName}
                              {item.fileName.length > 0 && (
                                <>
                                {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                <a
                                    href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download
                                  >
                                    (Download)
                                  </a>
                                </>
                              )}
                            </p>
                          )
                        );
                      }
                    )}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.file_description} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info file-download">
                  {projectData &&
                    projectData.land_acquisition_status &&
                    projectData.land_acquisition_status.remainingLandAcquisitionFile.map(
                      (item, index) => {
                        return (
                          Object.keys(item).length !== 0 && (
                            <span key={index}>{item.fileDescription}</span>
                          )
                        );
                      }
                    )}
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.number_of_affected_areas} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'land_acquisition_status.noOfAffectedAreas')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.number_of_hhs} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'land_acquisition_status.noOfRelocated')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.number_of_remaining_hhs} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'land_acquisition_status.noOfRemainingRelocated')}
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.supporting_document} :</p>
              </Grid.Column>
              <Grid.Column>
                <div className="project-info file-download">
                  {projectData &&
                    projectData.land_acquisition_status &&
                    projectData.land_acquisition_status.resettlementPlanFile.map((item, index) => {
                      return (
                        Object.keys(item).length !== 0 && (
                          <p key={index}>
                            {item.originalName ? item.originalName: item.fileName}
                            {item.fileName.length > 0 && (
                              <>
                              {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                <a
                                  href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  download
                                >
                                  (Download)
                                </a>
                              </>
                            )}
                          </p>
                        )
                      );
                    })}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.file_description} :</p>
              </Grid.Column>
              <Grid.Column>
                {projectData &&
                  projectData.land_acquisition_status &&
                  projectData.land_acquisition_status.resettlementPlanFile.map((item, index) => {
                    return (
                      Object.keys(item).length !== 0 && <p key={index}>{item.fileDescription}</p>
                    );
                  })}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      </div>
    </div>
  );
};

export default LandAcquisitionSection;
