import React from 'react';
// import { Container, Grid } from 'semantic-ui-react';
import ProjectList from './ProjectList';

function ParentProject({ history }) {
  return (
    <>
      <div className="body-container">
        <ProjectList history={history} />
      </div>
    </>
  );
}

export default ParentProject;
