const initialState = {
  governmentNepalShare: '',
  loan: '',
  grant: '',
  privateEquity: '',
  others: '',
  financingStatus: '',
  proposedPlan: '',
  governmentProject: {
    gon: [
      {
        fundingAgency: '',
        fundCurrency: 'npr',
        fundInWord: '',
        fundExchangeRate: '',
      },
    ],
    internalLoan: [
      {
        fundingAgency: '',
        fundCurrency: 'npr',
        fundInWord: '',
        fundExchangeRate: '',
      },
    ],
    externalLoan: [
      {
        fundingAgency: '',
        fundCurrency: 'npr',
        fundInWord: '',
        fundExchangeRate: '',
      },
    ],
    externalGrant: [
      {
        fundingAgency: '',
        fundCurrency: 'npr',
        fundInWord: '',
        fundExchangeRate: '',
      },
    ],
  },
  privateProject: {
    internalLoan: [
      {
        fundingAgency: '',
        fundCurrency: 'npr',
        fundInWord: '',
        fundExchangeRate: '',
      },
    ],
    externalLoan: [
      {
        fundingAgency: '',
        fundCurrency: 'npr',
        fundInWord: '',
        fundExchangeRate: '',
      },
    ],
    foreignPrivateEquity: [
      {
        fundingAgency: '',
        fundCurrency: 'npr',
        fundInWord: '',
        fundExchangeRate: '',
      },
    ],
    localPrivateEquity: [
      {
        fundingAgency: '',
        fundCurrency: 'npr',
        fundInWord: '',
        fundExchangeRate: '',
      },
    ],
    gon: [
      {
        fundingAgency: '',
        fundCurrency: 'npr',
        fundInWord: '',
        fundExchangeRate: '',
      },
    ],
    others: [
      {
        fundingAgency: '',
        fundCurrency: 'npr',
        fundInWord: '',
        fundExchangeRate: '',
      },
    ],
  },
  briefDescription: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'ERROR':
      return {};
    case 'PROJECT_STEP':
      return {
        ...state,
        step: payload.step,
      };
    case 'FINANCING_ARRANGEMENT_FETCHED':
      state = { ...payload };
      return state;

    case 'NULL_FINANCING_ARRANGEMENT_FETCHED':
      return initialState;

    default:
      return state;
  }
}
