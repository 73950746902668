import { ad2bs } from "ad-bs-converter";
import moment from "moment";


function fiscalYear() {
  let formattedDate = moment().format("YYYY/MM/DD");

  let year;
  let nextYear;
  let previousYear;
  let yearRunningMonth;
  let yearRunningDay;
  let runningFullYear;
  let fiscalYear = '';


  let date = ad2bs(formattedDate);
  year = date.en.year;
  nextYear = year + 1;
  previousYear = year - 1;
  yearRunningMonth = date.en.month;
  yearRunningDay = date.en.day;
  runningFullYear = `${year}-${yearRunningMonth}-${yearRunningDay}`;

  if (runningFullYear > `${year}-3-31`) {
    fiscalYear = `${year}/${nextYear}`;
  } else {
    fiscalYear = `${previousYear}/${year}`;
  }

  return fiscalYear
}

export default fiscalYear;




