const initialState = {
  projectHead: {
    fullName: '',
    designation: '',
    phoneNumber: '',
    email: '',
  },
  projectFocalPerson: {
    fullNameFocal: '',
    designationFocal: '',
    phoneNumberFocal: '',
    emailFocal: '',
  },
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'ERROR':
      return {};
    case 'PROJECT_STEP':
      return {
        ...state,
        step: payload.step,
      };
    case 'CONTACT_FETCHED':
      state = { ...payload };
      return state;

    case 'NULL_CONTACT_FETCHED':
      return initialState;

    default:
      return state;
  }
}
