import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
	Table,
	Modal,
	Button,
	Pagination,
	Form,
	Input,
} from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
// import Select from 'react-select';

import DepartmentService from '../../services/Department';
import MinistryService from '../../services/Ministry';

import { auth } from '../../helpers';

import { localization } from '../../localization';
import Filter from '../../components/DepartmentFilter/index';
import LoaderNP from '../../components/Loader/Loader';

function DepartmentList({ history }) {
	const language = useSelector(auth.getSelectedLanguage);
	const lang = localization[language]['department'];
	const user = useSelector(auth.getCurrentUser);
	const [isLoading, setIsLoading] = useState(true)

	const [modalOpen, setModalOpen] = useState(false);
	const [departmentList, setDepartmentList] = useState([]);
	const [totalPage, setTotalPage] = useState(0);
	const [totalSize, setTotalSize] = useState(0);
	const [totalDepartments, setTotalDepartments] = useState();
	const [departmentId, setDepartmentId] = useState('');
	const [errors, setErrorMessage] = useState({
		success: false,
		message: '',
	});

	const [activePage, setActivePage] = useState(1);
	const [search, setSearch] = useState('');
	const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
	const [filterBy, setFilterBy] = useState({
		ministry_id: { value: 0 },
		department_id: { value: 0 },
	});
	const [queryString, setQueryString] = useState({
		// column: null,
		// direction:null,
		page: 0,
	});
	const [ministries, setMinistries] = useState([]);
	const [departments, setDepartments] = useState([]);

	const allDepartments = (query) => {
		DepartmentService.listAllDepartments(queryString)
			.then((response) => {
				if (response.success) {
					let d = response.data.departments;
					setDepartmentList(d.lists);
					setTotalPage(d.totalPage);
					setTotalSize(d.totalSize);
					setTotalDepartments(d.count);
					setIsLoading(false);
				} else {
					throw new Error(response.error.message);
				}
			})
			.catch((err) => {
				setErrorMessage({
					success: true,
					message: err.message,
				});
				toastr.error('', `${err.message}`);
			});
	};

	useEffect(() => {
		// allDepartments(`?page=0`);
		allDepartments();
		if (user.role === 'npcAdmin') {
			listMinistries();
			listDepartments({ ministryId: user.ministry.id });
		} else {
			listDepartments({ ministryId: user.ministry.id });
		}
	}, [queryString]);

	const deleteDepartment = (e) => {
		setModalOpen(!modalOpen);
		DepartmentService.deleteDepartment({ departmentId })
			.then((response) => {
				if (response.success) {
					// allDepartments(`?page=0`);
					setQueryString({ page: activePage - 1 });
					toastr.success('', `${response.message}`);
				} else {
					throw new Error(response.error.message);
				}
			})
			.catch((err) => {
				toastr.error('', `${err.message}`);
			});
	};

	const listMinistries = () => {
		MinistryService.listAllMinistries(`?isDashboard=true&`)
			.then((response) => {
				let ministries = [];
				response.data.ministries.lists.forEach((m) => {
					ministries.push({ label: m.name, value: m.id });
				});
				setMinistries(ministries);
			})
			.catch((err) => {
				toastr.error('', `${err.message}`);
			});
	};

	const listDepartments = (params) => {
		DepartmentService.listDepartmentByMinistry(params)
			.then((response) => {
				let departments = [];
				response.data.department.forEach((m) => {
					departments.push({
						label: m.name,
						value: m.id,
						ministry_id: m.ministry_id,
					});
				});
				setDepartments(departments);
				if (user.role !== 'npcAdmin') {
					setFilterBy({
						...filterBy,
						ministry_id: { value: user.ministry.id },
					});
				}
			})
			.catch((err) => {
				toastr.error('', `${err.message}`);
			});
	};

	const handlePaginationChange = (e, { activePage }) => {
		// allDepartments(`?page=${activePage - 1}`);
		setQueryString({ ...queryString, page: activePage - 1 });
		setActivePage(activePage);
	};

	const handleSearchInputChange = (e) => {
		setSearch(e.target.value);
	};

	// const checkDepartmentDisplay = () => {
	// 	if (user.role === 'npcAdmin' && filterBy.ministry_id) {
	// 		return true;
	// 	}
	// 	if (user.role !== 'npcAdmin') {
	// 		return true;
	// 	}
	// 	return false;
	// };

	const showFiltertag = (filter, label) => {
		return (
			<div className='tag'>
				<span onClick={() => handleRemoveFilter(filter)}>
					<i className='material-icons close'>close</i>
				</span>
				{label}
			</div>
		);
	};

	const handleRemoveFilter = (filterLabel) => {
		filterBy[filterLabel] = { value: 0 };
		setFilterBy(filterBy);
		handleSearch(filterLabel);
	};

	const handleSearch = (label = null, e = null) => {
		if (e) e.preventDefault();
		let filter = {};
		let clonedQueryString = { ...queryString };

		if (label) {
			clonedQueryString = Object.keys(clonedQueryString).reduce(
				(object, key) => {
					if (key !== label) {
						object[key] = clonedQueryString[key];
					}
					return object;
				},
				{}
			);
		}

		// delete queryString.ministry_id;
		// delete queryString.department_id;

		if (filterBy.ministry_id.value !== 0) {
			filter.ministry_id = filterBy.ministry_id.value;
		}
		if (filterBy.department_id.value !== 0) {
			filter.department_id = filterBy.department_id.value;
		}
		setQueryString({
			...clonedQueryString,
			page: 0,
			search: search.trim(),
			...filter,
		});
		setActivePage(1);
	};

	const handleReset = () => {
		// setQueryString({...queryString, page: activePage -1,search:"",filterBy:{}});
		setQueryString({ page: 0 });
		setSearch('');
		setFilterBy({
			ministry_id: { value: 0 },
			department_id: { value: 0 },
		});
	};

	// const handleFilterBy = () => {
	// 	setQueryString((queryString) => {
	// 		return {
	// 			...queryString,
	// 			page: activePage - 1,
	// 			...filterBy,
	// 			search: search.trim(),
	// 		};
	// 	});
	// 	setShowAdvanceFilter(!showAdvanceFilter);
	// };

	const handleFilterOptionsBy = (name, selectedOption) => {
		setFilterBy((filter) => {
			return { ...filter, [name]: selectedOption };
		});
		if (name === 'ministry_id') {
			// filterBy.id && setFilterBy(filterBy => { return { ...filterBy, 'id': null } })
			// listDepartments({ ministryId: selectedOption.value });
			let filteredDepartments = departments.filter(
				(department) => +department.ministry_id === +selectedOption.value
			);
			setDepartments(filteredDepartments);
		}
	};

	const handleApplyFilterStatus = () => {
		setShowAdvanceFilter(true);
	};

	return (
		<>
			{errors.success ? (
				<div>{errors.message}</div>
			) : (
				<>
					<div className='dashboard-header justify-between'>
						<div className='flex'>
							<h2 className='header-main'>{lang.department}</h2>
							<div className='project-stats flex'>
								<div className='card'>
									<p className='card-info'>
										{totalDepartments && totalDepartments.toLocaleString()}
									</p>
									<p className='card-label'>{lang.department}</p>
								</div>
							</div>
						</div>
					</div>
					<div className='export-add-button'>
						<button
							onClick={() => history.push('/add/departments')}
							className='btn-normal btn-blue btn-shadow--blue btn-transition btn-export'
						>
							<i className='material-icons md-18 add md-bold mr-6'>add</i>
							{lang.add_department}
						</button>
					</div>
					<Filter
						ministries={ministries}
						departments={departments}
						language={lang}
						handleSelect={handleFilterOptionsBy}
						filterValues={filterBy || {}}
						handleSearch={handleSearch}
						handleReset={handleReset}
						handleApplyFilterStatus={handleApplyFilterStatus}
						role={user.role}
					/>

					<div className='flex align-center chart-search-wrapper'>
						<div>
							<form onSubmit={(e) => handleSearch(null, e)}>
								<div className='form-search'>
									<Input
										iconPosition='left'
										type='text'
										placeholder='Search by typing name'
										onChange={(e) => handleSearchInputChange(e)}
										value={search}
										action={lang.search}
										className='input-search'
									/>
									<i className='material-icons md-24'>search</i>
								</div>
							</form>
						</div>
					</div>
					<div className='filter-container'>
						<Form>
							{showAdvanceFilter && (
								<div className='filter-tags'>
									{filterBy.department_id.value !== 0 ||
									(filterBy.ministry_id.value !== 0 &&
										user.role === 'npcAdmin') ? (
										<p>{lang.filter_applied}: </p>
									) : (
										''
									)}

									<div className='tags-list'>
										{filterBy.department_id.value !== 0
											? showFiltertag(
													'department_id',
													filterBy.department_id.label
											  )
											: ''}
										{filterBy.ministry_id.value !== 0 &&
										user.role === 'npcAdmin'
											? showFiltertag('ministry_id', filterBy.ministry_id.label)
											: ''}
									</div>

									{filterBy.department_id.value !== 0 ||
									(filterBy.ministry_id.value !== 0 &&
										user.role === 'npcAdmin') ? (
										<button
											className='btn-outline btn-outline--red'
											onClick={handleReset}
										>
											{lang.clear_filter}
										</button>
									) : (
										''
									)}
								</div>
							)}
						</Form>
					</div>
					<div className="table-container">
					{isLoading ? <LoaderNP /> : (
						departmentList.length > 0 ? (
							<>    
								<Table padded className='project-table no-action'>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell width={1} className='id-header'>
												{lang.s_n}
											</Table.HeaderCell>
											<Table.HeaderCell width={3}>{lang.name}</Table.HeaderCell>
											<Table.HeaderCell width={5}>
												{lang.ministry}
											</Table.HeaderCell>
											<Table.HeaderCell width={5}>
												{lang.department_code }
											</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
	
									<Table.Body>
										{departmentList.map((d, index) => {
											return (
												<Table.Row key={d.id}>
													<Table.Cell>
														{' '}
														{(activePage - 1) * totalSize + index + 1}
													</Table.Cell>
													<Table.Cell>
														<p className='project-title'>{d.name}</p>
													</Table.Cell>
													<Table.Cell>
														{d.ministry.name}
													</Table.Cell>
													<Table.Cell>
														{d.code}
														<div className='actions'>
															<Link
																to={`/add/departments?departmentId=${d.id}`}
																className='btn-outline btn-outline--small btn-outline--blue'
															>
																<i className='material-icons md-12 mr-2'>edit</i>
																Edit
															</Link>{' '}
															{d.canDeleteAble ? (
																<span
																	className='btn-outline btn-outline--small btn-outline--red'
																	onClick={() => {
																		setModalOpen(!modalOpen);
																		setDepartmentId(d.id);
																	}}
																>
																	<i className='material-icons md-12 mr-2 material-delete'>
																		delete_outline
																	</i>
																	Delete{' '}
																</span>
															) : (
																<span
																	className='btn-outline btn-outline--small btn-disabled-delete'
																	data-tooltip={
																		lang.department_associated_message
																	}
																>
																	<i className='material-icons md-12 mr-2 material-delete'>
																		delete_outline
																	</i>
																	Delete {''}
																</span>
															)}
														</div>
													</Table.Cell>
												</Table.Row>
											);
										})}
									</Table.Body>
								</Table>
								<div className='justify-center'>
									<Pagination
										defaultActivePage={activePage}
										onPageChange={handlePaginationChange}
										totalPages={totalPage}
										firstItem={{ 'aria-label': 'First item', content: 'First' }}
										lastItem={{ 'aria-label': 'Last item', content: 'Last' }}
										prevItem={{
											'aria-label': 'Previous item',
											content: 'Previous',
										}}
										nextItem={{ 'aria-label': 'First item', content: 'Next' }}
									/>
								</div>
							</>
						) : (
							<>
								<div className='empty'>
									<p as='p' size=''>
										{lang.no_department_found}
									</p>
								</div>
							</>
						)
					)}
					</div>
				</>
			)}

			<Modal
				dimmer='blurring'
				open={modalOpen}
				onClose={() => setModalOpen(!modalOpen)}
				size='mini'
			>
				<Modal.Header>{lang.delete_department}</Modal.Header>
				<Modal.Content>
					<p>{lang.are_you_sure_delete_department}</p>
				</Modal.Content>
				<Modal.Actions>
					<Button color='black' onClick={() => setModalOpen(!modalOpen)}>
						Cancel
					</Button>
					<Button
						positive
						content='Yes'
						onClick={(e) => {
							deleteDepartment(e);
						}}
					/>
				</Modal.Actions>
			</Modal>
		</>
	);
}

export default DepartmentList;
