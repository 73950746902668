import React from 'react';

const Faq = () => (
  <div className="body-container flex flex-wrap">
    <div className="header large full-width">FAQ</div>
    <div className="inner-contents">
      <p>Coming Soon!!!</p>
    </div>
  </div>
);

export default Faq;
