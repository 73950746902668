import Api from './api';
import ExternalApi from './externalApi'
import Http from 'axios';

import ReportUrlConstant from '../helpers/constant/ReportApiURL';
import { API_URL, EXTERNAL_API_URL } from '../helpers';

class ReportApi {
    async getProjectDetails(query) {
        try {
            const res = await ExternalApi.get(await ReportUrlConstant.GET_PROJECT_DETAILS(), null, query);
            // const res = await Api.get(await ReportUrlConstant.GET_PROJECT_DETAILS(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getProjectList(query) {
        try {
            const res = await ExternalApi.get(await ReportUrlConstant.GET_PROJECT_LIST(), null, query);
            // const res = await Api.get(await ReportUrlConstant.GET_PROJECT_LIST(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getCostDetails(query) {
        try {
            // const res = await ExternalApi.get(await ReportUrlConstant.GET_COST_DETAILS(), null, query);
            const res = await ExternalApi.get(await ReportUrlConstant.GET_COST_DETAILS(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getBasicAreaDetails(query) {
        try {
            const res = await ExternalApi.get(await ReportUrlConstant.GET_BASIC_AREA_DETAILS(), null, query);
            // const res = await Api.get(await ReportUrlConstant.GET_BASIC_AREA_DETAILS(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getBasicArea(query) {
        try {
            const res = await ExternalApi.get(await ReportUrlConstant.GET_BASIC_AREA(), null, query);
            // const res = await Api.get(await ReportUrlConstant.GET_BASIC_AREA(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getProjectDuration(query) {
        try {
            const res = await ExternalApi.get(await ReportUrlConstant.GET_PROJECT_DURATION(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getAnnualRecommendation(query) {
        try {
            const res = await ExternalApi.get(await ReportUrlConstant.GET_ANNUAL_RECOMMENDATION(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getAllocatedRecommended(query) {
        try {
            const res = await ExternalApi.get(await ReportUrlConstant.GET_ALLOCATED_RECOMMENDED(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getMapData(query, isHomePage = false) {
        try {
            if (!isHomePage) {
                const res = await Api.get(await ReportUrlConstant.GET_MAP_DATA(), null, query);
                // const res = await ExternalApi.get(await ReportUrlConstant.GET_MAP_DATA(), null, query);
                return res.body;

            }
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                params: query 
            };
            let res = await Http.get(`${API_URL}2/map`, config);
            return res.data
        } catch (error) {
            return error;
        }
    }

    async getDetailData(query) {
        try {
            const res = await ExternalApi.get(await ReportUrlConstant.GET_DETAIL_DATA(), null, query);
            // const res = await Api.get(await ReportUrlConstant.GET_DETAIL_DATA(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getProvinceList(query) {
        try {
            const res = await ExternalApi.get('Common/province');
            // const res = await Http.get('http://202.45.146.108:8080/api/Common/province');
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getBasicAreaList(query) {
        try {
            const res = await ExternalApi.get(await ReportUrlConstant.GET_BASIC_AREA_LIST(query));
            // const res = await Api.get(await ReportUrlConstant.GET_BASIC_AREA_LIST());
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getFiscalYearList(query) {
        try {
            const res = await ExternalApi.get(await ReportUrlConstant.GET_FISCAL_YEAR_LIST());
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async exportSamapurakExcel(query) {
        try {
            const res = await ExternalApi.get(await ReportUrlConstant.EXPORT_SAMAPURAK_EXCEL(), 'arraybuffer', query);
            return res.body;
        } catch (error) {
            return error;
        }
    }
}

export default new ReportApi();