import React, { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import Can from '../../../components/Can/can';
import { Link } from 'react-router-dom';

import { lists } from '../../../reducers/form/QuarterlyAssessment/action';
import { auth, API_URL } from '../../../helpers';
import { localization } from '../../../localization';

import ProjectApi from '../../../services/Gate/Project';

const QuarterlyAssessmentSection = ({ projectId, gate }) => {
  const dispatch = useDispatch();

  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['quarterly_assessments'];

  const [expand, setExpand] = useState(true);
  const [currentAcc, setCurrentAcc] = useState(0);

  const handleExpand = (i) => {
    if (i == currentAcc || currentAcc == null) {
      setExpand(!expand);
    } else {
      setExpand(true);
    }
    setCurrentAcc(i);
  };

  const { assessments } = useSelector((state) => state.quarterlyAssessment);

  useEffect(() => {
    if (projectId && gate) {
      listAllQuarterlyAssessment();
    }
  }, [projectId, gate]);

  const listAllQuarterlyAssessment = () => {
    const stage =
      gate === 'project_identification'
        ? 'identification'
        : gate === 'project_appraisal'
        ? 'appraisal'
        : '';

    ProjectApi.listQuarterlyAssessment({ id: projectId }, stage)
      .then((response) => {
        dispatch(lists(response));
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const user = useSelector(auth.getCurrentUser);

  const print = () => {
    var element = document.getElementById('project-detail-container');
    element.classList.add('printable');
    window.print();
  };

  return (
    <div id="quarterly-assessments" className="no-print">
      {Object.keys(assessments).length > 0 && (
        <div className="form-group-container">
          <p className="heading-title body-2 header medium">{lang.project_assessment}</p>
          {Object.keys(assessments).length > 0 &&
            Object.keys(assessments).map((assessment, index) => {
              return (
                <div className="qassessment" key={index}>
                  <div
                    className={`qassessment__heading flex justify-between align-center ${
                      expand ? 'expand' : ''
                    }`}
                    onClick={() => handleExpand(index)}
                  >
                    <p className="heading-title body-2">
                      <button className="btn-text text-bold">{assessment}</button>
                    </p>
                  </div>

                  {expand && index === currentAcc && (
                    <>
                      <div className="print-cont">
                        <button
                          type="button"
                          className="btn-transition btn-outline btn-outline--small btn-outline--blue no-print"
                          onClick={print}
                        >
                          <i className="material-icons mr-6">print</i>
                          Print
                        </button>
                      </div>
                      {assessments[assessment].map((assess, index) => {
                        return (
                          <div className="qassessment__qitems" key={index}>
                            <div className="qassessment__qitem">
                              <div className="submission-cont">
                                <p className="text-bold label-1 assessed-on">
                                  {'Submitted on: '}
                                  <span>{moment(assess.createdAt).format('MMM DD, YYYY')}</span>
                                </p>
                                <div>
                                  {/* <button
                                  type="button"
                                  className="btn-transition btn-outline btn-outline--small btn-outline--blue"
                                  onClick={() => window.print()}
                                >
                                  <i className="material-icons mr-6">print</i>
                                  Print
                                </button> */}
                                  <Can
                                    role={user.role}
                                    perform="action:edit-delete"
                                    data={true}
                                    yes={() => (
                                      <>
                                        <Link
                                          to={`/assessment/${projectId}?gate=${gate}&assessmentId=${assess.id}`}
                                          onClick={(e) => e.stopPropagation()}
                                          className="btn-outline btn-outline--small btn-outline--blue no-print"
                                        >
                                          <i className="material-icons md-12 mr-2">edit</i> Edit
                                        </Link>{' '}
                                        {/*<span*/}
                                        {/*className="btn-outline btn-outline--small btn-outline--red"*/}
                                        {/*onClick={(e) => {*/}
                                        {/*}}*/}
                                        {/*>*/}
                                        {/*<i className="material-icons md-12 mr-2 material-delete">*/}
                                        {/*delete_outline*/}
                                        {/*</i>*/}
                                        {/*Delete*/}
                                        {/*</span>*/}
                                      </>
                                    )}
                                    no={() => <></>}
                                  />
                                </div>
                              </div>
                              <Grid columns={2} padded className="grid-cont">
                                <Grid.Row>
                                  <Grid.Column width={6}>
                                    <p className="label">{lang.financial_progress_view}</p>
                                  </Grid.Column>
                                  <Grid.Column width={10}>
                                    <p className="project-info">
                                      {assess.financial_progress.fProgress} {'%'}
                                    </p>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column width={6}>
                                    <p className="label">{lang.financial_progress_amt}</p>
                                  </Grid.Column>
                                  <Grid.Column width={10}>
                                    <p className="project-info">
                                      <span style={{ textTransform: 'uppercase' }}>
                                        {assess.financial_progress.progressAmountCurrency}{' '}
                                      </span>
                                      {assess.financial_progress.progressAmount}
                                    </p>
                                  </Grid.Column>
                                </Grid.Row>
                                {assess.financial_progress.progressAmountCurrency === 'usd' &&
                                  assess.financial_progress.progressAmountExchangeRate &&
                                  assess.financial_progress.progressAmountExchangeRate.length >
                                    0 && (
                                    <Grid.Row>
                                      <Grid.Column width={6}>
                                        <p className="label">Exchange Rate</p>
                                      </Grid.Column>
                                      <Grid.Column width={10}>
                                        <p className="project-info">
                                          {assess.financial_progress.progressAmountExchangeRate}
                                        </p>
                                      </Grid.Column>
                                    </Grid.Row>
                                  )}
                                <Grid.Row>
                                  <Grid.Column width={6}>
                                    <p className="label">{lang.financial_progress_words}</p>
                                  </Grid.Column>
                                  <Grid.Column width={10}>
                                    <p className="project-info">
                                      {assess.financial_progress.progressAmountWord}
                                    </p>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column width={6}>
                                    <p className="label">{lang.financial_docs}</p>
                                  </Grid.Column>
                                  <Grid.Column width={10}>
                                    <div className="files-cont">
                                      {assess.financial_progress.financialDocumentFile &&
                                        assess.financial_progress.financialDocumentFile[0].fileName
                                          .length > 0 &&
                                        assess.financial_progress.financialDocumentFile.map(
                                          (item, index) => {
                                            return (
                                              Object.keys(item).length !== 0 && (
                                                <p key={index}>
                                                  {item.originalName
                                                    ? item.originalName
                                                    : item.fileName}
                                                  {item.fileName.length > 0 && (
                                                    <a
                                                      href={`${API_URL}assets/uploads/gates/${projectId}/${item.fileName}`}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      download
                                                    >
                                                      (Download)
                                                    </a>
                                                  )}
                                                </p>
                                              )
                                            );
                                          }
                                        )}
                                    </div>
                                  </Grid.Column>
                                </Grid.Row>
                                {assess.serial_number ==
                                  process.env.REACT_APP_LIMIT_FOR_QUARTERLY_ASSESSMENT && (
                                  <>
                                    <Grid.Row>
                                      <Grid.Column width={16}>
                                        <p className="label-heading label-content">
                                          <span>{lang.expenditure_current_fiscal_year}</span>
                                        </p>
                                      </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                      <Grid.Column width={6}>
                                        <p className="label">{lang.fiscal_year}</p>
                                      </Grid.Column>
                                      <Grid.Column width={6}>
                                        <p className="project-info">
                                          {assess.current_fiscal_year_expenditure.fiscalYear}
                                        </p>
                                      </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                      <Grid.Column width={6}>
                                        <p className="label">{lang.allocated_budget}</p>
                                      </Grid.Column>
                                      <Grid.Column width={3}>
                                        <p className="project-info">
                                          <span style={{ textTransform: 'uppercase' }}>
                                            {
                                              assess.current_fiscal_year_expenditure
                                                .allocatedBudgetCurrency
                                            }
                                          </span>{' '}
                                          {assess.current_fiscal_year_expenditure.allocatedBudget}
                                        </p>
                                      </Grid.Column>
                                    </Grid.Row>
                                    {assess.current_fiscal_year_expenditure
                                      .allocatedBudgetCurrency === 'usd' &&
                                      assess.current_fiscal_year_expenditure
                                        .allocatedBudgetExchangeRate &&
                                      assess.current_fiscal_year_expenditure
                                        .allocatedBudgetExchangeRate.length > 0 && (
                                        <Grid.Row>
                                          <Grid.Column width={6}>
                                            <p className="label">Exchange Rate</p>
                                          </Grid.Column>
                                          <Grid.Column width={10}>
                                            <p className="project-info">
                                              {
                                                assess.current_fiscal_year_expenditure
                                                  .allocatedBudgetExchangeRate
                                              }
                                            </p>
                                          </Grid.Column>
                                        </Grid.Row>
                                      )}
                                    <Grid.Row>
                                      <Grid.Column width={6}>
                                        <p className="label">{lang.allocated_budget_in_word}</p>
                                      </Grid.Column>
                                      <Grid.Column width={3}>
                                        <p className="project-info">
                                          {
                                            assess.current_fiscal_year_expenditure
                                              .allocatedBudgetWord
                                          }
                                        </p>
                                      </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                      <Grid.Column width={6}>
                                        <p className="label">{lang.current_docs}</p>
                                      </Grid.Column>
                                      <Grid.Column width={10}>
                                        <div className="files-cont">
                                          {assess.current_fiscal_year_expenditure
                                            .currentDocumentFile &&
                                            assess.current_fiscal_year_expenditure
                                              .currentDocumentFile[0].fileName.length > 0 &&
                                            assess.current_fiscal_year_expenditure.currentDocumentFile.map(
                                              (item, index) => {
                                                return (
                                                  Object.keys(item).length !== 0 && (
                                                    <p key={index}>
                                                      {item.originalName
                                                        ? item.originalName
                                                        : item.fileName}
                                                      {item.fileName.length > 0 && (
                                                        <a
                                                          href={`${API_URL}assets/uploads/gates/${projectId}/${item.fileName}`}
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                          download
                                                        >
                                                          (Download)
                                                        </a>
                                                      )}
                                                    </p>
                                                  )
                                                );
                                              }
                                            )}
                                        </div>
                                      </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                      <Grid.Column width={6}>
                                        <p className="label">{lang.total_expenditure}</p>
                                      </Grid.Column>
                                      <Grid.Column width={3}>
                                        <p className="project-info">
                                          <span style={{ textTransform: 'uppercase' }}>
                                            {
                                              assess.current_fiscal_year_expenditure
                                                .totalExpenditureCurrency
                                            }
                                          </span>{' '}
                                          {assess.current_fiscal_year_expenditure.totalExpenditure}
                                        </p>
                                      </Grid.Column>
                                    </Grid.Row>
                                    {assess.current_fiscal_year_expenditure
                                      .totalExpenditureCurrency === 'usd' &&
                                      assess.current_fiscal_year_expenditure
                                        .totalExpenditureExchangeRate &&
                                      assess.current_fiscal_year_expenditure
                                        .totalExpenditureExchangeRate.length > 0 && (
                                        <Grid.Row>
                                          <Grid.Column width={6}>
                                            <p className="label">Exchange Rate</p>
                                          </Grid.Column>
                                          <Grid.Column width={10}>
                                            <p className="project-info">
                                              {
                                                assess.current_fiscal_year_expenditure
                                                  .totalExpenditureExchangeRate
                                              }
                                            </p>
                                          </Grid.Column>
                                        </Grid.Row>
                                      )}
                                    <Grid.Row>
                                      <Grid.Column width={6}>
                                        <p className="label">{lang.total_expenditure_in_word}</p>
                                      </Grid.Column>
                                      <Grid.Column width={3}>
                                        <p className="project-info">
                                          {
                                            assess.current_fiscal_year_expenditure
                                              .totalExpenditureWord
                                          }
                                        </p>
                                      </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                      <Grid.Column width={16}>
                                        <p className="label-heading label-content">
                                          <span>{lang.budget_coming_fiscal_year}</span>
                                        </p>
                                      </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                      <Grid.Column width={6}>
                                        <p className="label">{lang.fiscal_year}</p>
                                      </Grid.Column>
                                      <Grid.Column width={6}>
                                        <p className="project-info">
                                          {assess.coming_fiscal_year_expenditure.fiscalYear}
                                        </p>
                                      </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                      <Grid.Column width={6}>
                                        <p className="label">{lang.estimated_annual_budget}</p>
                                      </Grid.Column>
                                      <Grid.Column width={3}>
                                        <p className="project-info">
                                          <span style={{ textTransform: 'uppercase' }}>
                                            {
                                              assess.coming_fiscal_year_expenditure
                                                .estimatedAnnualBudgetCurrency
                                            }
                                          </span>{' '}
                                          {
                                            assess.coming_fiscal_year_expenditure
                                              .estimatedAnnualBudget
                                          }
                                        </p>
                                      </Grid.Column>
                                    </Grid.Row>
                                    {assess.coming_fiscal_year_expenditure
                                      .estimatedAnnualBudgetCurrency === 'usd' &&
                                      assess.coming_fiscal_year_expenditure
                                        .estimatedAnnualBudgetExchangeRate &&
                                      assess.coming_fiscal_year_expenditure
                                        .estimatedAnnualBudgetExchangeRate.length > 0 && (
                                        <Grid.Row>
                                          <Grid.Column width={6}>
                                            <p className="label">Exchange Rate</p>
                                          </Grid.Column>
                                          <Grid.Column width={10}>
                                            <p className="project-info">
                                              {
                                                assess.coming_fiscal_year_expenditure
                                                  .estimatedAnnualBudgetExchangeRate
                                              }
                                            </p>
                                          </Grid.Column>
                                        </Grid.Row>
                                      )}
                                    <Grid.Row>
                                      <Grid.Column width={6}>
                                        <p className="label">{lang.estimated_annual_budget_word}</p>
                                      </Grid.Column>
                                      <Grid.Column width={3}>
                                        <p className="project-info">
                                          {
                                            assess.coming_fiscal_year_expenditure
                                              .estimatedAnnualBudgetWord
                                          }
                                        </p>
                                      </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                      <Grid.Column width={6}>
                                        <p className="label">{lang.coming_docs}</p>
                                      </Grid.Column>
                                      <Grid.Column width={10}>
                                        <div className="files-cont">
                                          {assess.coming_fiscal_year_expenditure
                                            .comingDocumentFile &&
                                            assess.coming_fiscal_year_expenditure
                                              .comingDocumentFile[0].fileName.length > 0 &&
                                            assess.coming_fiscal_year_expenditure.comingDocumentFile.map(
                                              (item, index) => {
                                                return (
                                                  Object.keys(item).length !== 0 && (
                                                    <p key={index}>
                                                      {item.originalName
                                                        ? item.originalName
                                                        : item.fileName}
                                                      {item.fileName.length > 0 && (
                                                        <a
                                                          href={`${API_URL}assets/uploads/gates/${projectId}/${item.fileName}`}
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                          download
                                                        >
                                                          (Download)
                                                        </a>
                                                      )}
                                                    </p>
                                                  )
                                                );
                                              }
                                            )}
                                        </div>
                                      </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                      <Grid.Column width={16}>
                                        <p className="label-heading label-content">
                                          <span>{lang.two_fiscal_year}</span>
                                        </p>
                                      </Grid.Column>
                                    </Grid.Row>
                                    {assess.next_fiscal_year_project_budget_requirement.map(
                                      (nextData, index) => {
                                        return (
                                          <React.Fragment key={index}>
                                            <Grid.Row>
                                              <Grid.Column width={6}>
                                                <p className="label">{lang.fiscal_year}</p>
                                              </Grid.Column>
                                              <Grid.Column width={6}>
                                                <p className="project-info">
                                                  {nextData.fiscalYear}
                                                </p>
                                              </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                              <Grid.Column width={6}>
                                                <p className="label">
                                                  {lang.two_fiscal_year_projected}
                                                </p>
                                              </Grid.Column>
                                              <Grid.Column width={6}>
                                                <p className="project-info">
                                                  <span style={{ textTransform: 'uppercase' }}>
                                                    {nextData.projectedBudgetRequirementCurrency}
                                                  </span>
                                                  {nextData.projectedBudgetRequirement}
                                                </p>
                                              </Grid.Column>
                                            </Grid.Row>
                                            {nextData.projectedBudgetRequirementCurrency ===
                                              'usd' &&
                                              nextData.projectedBudgetRequirementExchangeRate &&
                                              nextData.projectedBudgetRequirementExchangeRate
                                                .length > 0 && (
                                                <Grid.Row>
                                                  <Grid.Column width={6}>
                                                    <p className="label">Exchange Rate</p>
                                                  </Grid.Column>
                                                  <Grid.Column width={10}>
                                                    <p className="project-info">
                                                      {
                                                        assess.coming_fiscal_year_expenditure
                                                          .estimatedAnnualBudgetExchangeRate
                                                      }
                                                    </p>
                                                  </Grid.Column>
                                                </Grid.Row>
                                              )}
                                            <Grid.Row>
                                              <Grid.Column width={6}>
                                                <p className="label">
                                                  {lang.two_fiscal_year_projected_word}
                                                </p>
                                              </Grid.Column>
                                              <Grid.Column width={6}>
                                                <p className="project-info">
                                                  {nextData.projectedBudgetRequirementWord}
                                                </p>
                                              </Grid.Column>
                                            </Grid.Row>
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                    <Grid.Row>
                                      <Grid.Column width={6}>
                                        <p className="label">{lang.projected_docs}</p>
                                      </Grid.Column>
                                      <Grid.Column width={10}>
                                        <div className="files-cont">
                                          {assess.physical_progress.projectBudgetFile &&
                                            assess.physical_progress.projectBudgetFile[0].fileName
                                              .length > 0 &&
                                            assess.physical_progress.projectBudgetFile.map(
                                              (item, index) => {
                                                return (
                                                  Object.keys(item).length !== 0 && (
                                                    <p key={index}>
                                                      {item.originalName
                                                        ? item.originalName
                                                        : item.fileName}
                                                      {item.fileName.length > 0 && (
                                                        <a
                                                          href={`${API_URL}assets/uploads/gates/${projectId}/${item.fileName}`}
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                          download
                                                        >
                                                          (Download)
                                                        </a>
                                                      )}
                                                    </p>
                                                  )
                                                );
                                              }
                                            )}
                                        </div>
                                      </Grid.Column>
                                    </Grid.Row>
                                  </>
                                )}

                                <Grid.Row>
                                  <p className="label-heading" />
                                  <Grid.Column width={6}>
                                    <p className="label">{lang.physical_progress}</p>
                                  </Grid.Column>
                                  <Grid.Column width={6}>
                                    <p className="project-info">
                                      {assess.physical_progress.pProgress} {'%'}
                                    </p>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column width={6}>
                                    <p className="label">{lang.physical_docs}</p>
                                  </Grid.Column>
                                  <Grid.Column width={10}>
                                    <div className="files-cont">
                                      {assess.physical_progress.documentFile &&
                                        assess.physical_progress.documentFile[0].fileName.length >
                                          0 &&
                                        assess.physical_progress.documentFile.map((item, index) => {
                                          return (
                                            Object.keys(item).length !== 0 && (
                                              <p key={index}>
                                                {item.originalName
                                                  ? item.originalName
                                                  : item.fileName}
                                                {item.fileName.length > 0 && (
                                                  <a
                                                    href={`${API_URL}assets/uploads/gates/${projectId}/${item.fileName}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    download
                                                  >
                                                    (Download)
                                                  </a>
                                                )}
                                              </p>
                                            )
                                          );
                                        })}
                                    </div>
                                  </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                  <p className="label-heading" />
                                  <Grid.Column width={6}>
                                    <p className="label">{lang.remarks_view}</p>
                                  </Grid.Column>
                                  <Grid.Column width={10}>
                                    <p
                                      className="project-info"
                                      dangerouslySetInnerHTML={{
                                        __html: assess.remarks,
                                      }}
                                    ></p>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <p className="label-heading" />
                                  <Grid.Column width={6}>
                                    <p className="label">{lang.risks_view}</p>
                                  </Grid.Column>
                                  <Grid.Column width={10}>
                                    <p
                                      className="project-info"
                                      dangerouslySetInnerHTML={{
                                        __html: assess.risks.text,
                                      }}
                                    ></p>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column width={6}>
                                    <p className="label">{lang.risks_docs}</p>
                                  </Grid.Column>
                                  <Grid.Column width={10}>
                                    <div className="files-cont">
                                      {assess.risks.riskDocumentFile &&
                                        assess.risks.riskDocumentFile[0].fileName.length > 0 &&
                                        assess.risks.riskDocumentFile.map((item, index) => {
                                          return (
                                            Object.keys(item).length !== 0 && (
                                              <p key={index}>
                                                {item.originalName
                                                  ? item.originalName
                                                  : item.fileName}
                                                {item.fileName.length > 0 && (
                                                  <a
                                                    href={`${API_URL}assets/uploads/gates/${projectId}/${item.fileName}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    download
                                                  >
                                                    (Download)
                                                  </a>
                                                )}
                                              </p>
                                            )
                                          );
                                        })}
                                    </div>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default QuarterlyAssessmentSection;
