import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import Cookies from 'js-cookie';
import { auth } from '../../helpers';
import jwtDecode from 'jwt-decode';
import { setCurrentUser } from '../../services/Auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector(auth.checkIfAuthenticated);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();

  const init = () => {
    try {
      const data = JSON.parse(localStorage.getItem('npbmisToken'));
      if (data) {
        const bearerToken = data.token;
        const token = bearerToken.split(' ')[1];
        const decoded = jwtDecode(token);

        Cookies.set('npbmisToken', token);
        dispatch(setCurrentUser(decoded));
      }
      setIsLoggedIn(true);
    } catch (e) {
      setIsLoggedIn(false);
      console.log(e);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated || isLoggedIn ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default PrivateRoute;
