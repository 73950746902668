import React from "react";
import { withRouter } from "react-router-dom";


import GateSectorList from "./SectorList";


function GateSector({history}) {

  return (
    <div className='body-container'>
      <GateSectorList history={history}/>
    </div>
  );
}

export default withRouter(GateSector);
