import fiscalYear from '../../../helpers/fiscalYear';

const initialState = {
  financial_progress: {
    fProgress: "",
    progressAmount: "",
    progressAmountWord: "",
    progressAmountCurrency: "npr",
    progressAmountExchangeRate: "",
    financialDocumentFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }]
  },
  fiscal_year : fiscalYear(),
  current_fiscal_year_expenditure: {
    fiscalYear: fiscalYear(),
    allocatedBudget: "",
    allocatedBudgetCurrency: "npr",
    allocatedBudgetExchangeRate: "",
    allocatedBudgetWord: "",
    totalExpenditure: "",
    totalExpenditureCurrency: "npr",
    totalExpenditureExchangeRate: "",
    totalExpenditureWord: "",
    currentDocumentFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }]
  },
  coming_fiscal_year_expenditure: {
    fiscalYear: fiscalYear(),
    estimatedAnnualBudget: "",
    estimatedAnnualBudgetCurrency: "npr",
    estimatedAnnualBudgetExchangeRate: "",
    estimatedAnnualBudgetWord: "",
    comingDocumentFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }]
  },
  next_fiscal_year_project_budget_requirement: [{
    fiscalYear: fiscalYear(),
    projectedBudgetRequirement: "",
    projectedBudgetRequirementCurrency: "npr",
    projectedBudgetRequirementWord: "",
    projectedBudgetRequirementExchangeRate:""
  }],
  physical_progress: {
    pProgress: "",
    documentFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
    projectedBudgetFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }]
  },
  risks: {
    text: "",
    riskDocumentFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }]
  },
  remarks: "",
  projectId: null,
  assessments: {},
  stage: ''
};

export default function (state = initialState, {type, payload}) {
  switch (type) {
    case 'QUARTERLY_ASSESSMENT_FETCHED':
      state = {...state, ...payload};
      return state;

    case 'LIST_ALL_QUARTERLY_ASSESSMENT':
      return {
        ...state,
        assessments: payload.data.assessments
      };
    case 'QUARTERLY_ASSESSMENT_REST':
      return {...initialState};

    default:
      return state;
  }
}
