import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Grid } from 'semantic-ui-react';

import { cloneDeep } from 'lodash';

import { localization } from '../../../../localization';

import { handleInputChange, handleSaveAndExit } from '../../../../actions';
import { fetched, fetchedNullData } from '../../../../reducers/form/Contact/action';
import * as ProjectAction from '../../../../reducers/form/action';

const Contact = ({ urlParams }) => {
  const selectedLanguage = useSelector((state) => state.localization.selectedLanguage);
  const lang = localization[selectedLanguage].contact;
  const { contact } = useSelector((state) => state);

  const [errors, setError] = useState({
    fullName_error: false,
    designation_error: false,
    phoneNumber_error: false,
    email_error: false,
    fullNameFocal_error: false,
    designationFocal_error: false,
    phoneNumberFocal_error: false,
    emailFocal_error: false,
  });

  const dispatch = useDispatch();

  const { projectInfo } = useSelector((state) => state.projectBasicInfo);

  useEffect(() => {
    if (projectInfo.contact) {
      dispatch(
        fetched({
          ...projectInfo.contact,
        })
      );
    }
    if (!projectInfo.contact) {
      dispatch(fetchedNullData());
    }
  }, [projectInfo]);

  const handleSaveAndContinue = (type) => {
    let canSubmit = true;
    const errors = [];
    const data = cloneDeep(contact);
    delete data['step'];

    const requiredFieldProjectHead = ['fullName', 'designation', 'phoneNumber', 'email'];

    const requiredFieldFocalPerson = [
      'fullNameFocal',
      'designationFocal',
      'phoneNumberFocal',
      'emailFocal',
    ];

    for (let d in data) {
      if (d === 'projectHead') {
        for (let p in data[d]) {
          if (requiredFieldProjectHead.includes(p)) {
            if (data[d][p] === '' || data[d][p].length === 0) {
              canSubmit = false;
              errors[`${p}_error`] = true;
            }
          }
        }
      }

      if (d === 'projectFocalPerson') {
        for (let p in data[d]) {
          if (requiredFieldFocalPerson.includes(p)) {
            if (data[d][p] === '' || data[d][p].length === 0) {
              canSubmit = false;
              errors[`${p}_error`] = true;
            }
          }
        }
      }
    }
    setError(errors);

    if (canSubmit) {
      handleSaveAndExit({ contact: data }, type, dispatch, 13, urlParams);
    }
  };

  return (
    <Form className="form-new">
      <div className="form-group-container">
        <Grid columns={2}>
          <Grid.Column>
            <p class="form-info">{lang.project_head}</p>
            <Form.Group grouped>
              <Form.Field
                required
                control={Input}
                label={lang.full_name}
                name="fullName"
                type="text"
                value={contact.projectHead.fullName}
                width={16}
                className={errors.fullName_error ? 'error' : ''}
                onChange={(event) => {
                  dispatch(
                    handleInputChange(
                      {
                        event,
                        field: 'projectHead',
                        info: contact,
                      },
                      fetched
                    )
                  );
                  setError({ ...errors, fullName_error: false });
                }}
              />

              <Form.Field
                required
                control={Input}
                label={lang.designation}
                name="designation"
                type="text"
                value={contact.projectHead.designation}
                width={16}
                className={errors.designation_error ? 'error' : ''}
                onChange={(event) => {
                  dispatch(
                    handleInputChange(
                      {
                        event,
                        field: 'projectHead',
                        info: contact,
                      },
                      fetched
                    )
                  );
                  setError({ ...errors, designation_error: false });
                }}
              />

              <Form.Field
                required
                control={Input}
                label={lang.number}
                name="phoneNumber"
                value={contact.projectHead.phoneNumber}
                type="text"
                width={16}
                className={errors.phoneNumber_error ? 'error' : ''}
                onChange={(event) => {
                  dispatch(
                    handleInputChange(
                      {
                        event,
                        field: 'projectHead',
                        info: contact,
                      },
                      fetched
                    )
                  );
                  setError({ ...errors, phoneNumber_error: false });
                }}
              />

              <Form.Field
                required
                control={Input}
                label={lang.email}
                name="email"
                type="email"
                value={contact.projectHead.email}
                width={16}
                className={errors.email_error ? 'error' : ''}
                onChange={(event) => {
                  dispatch(
                    handleInputChange(
                      {
                        event,
                        field: 'projectHead',
                        info: contact,
                      },
                      fetched
                    )
                  );
                  setError({ ...errors, email_error: false });
                }}
              />
            </Form.Group>
          </Grid.Column>
          <Grid.Column>
            <p class="form-info">{lang.project_focal_person}</p>

            <Form.Group grouped>
              <Form.Field
                required
                control={Input}
                label={lang.full_name}
                name="fullNameFocal"
                type="text"
                value={contact.projectFocalPerson.fullNameFocal}
                width={16}
                className={errors.fullNameFocal_error ? 'error' : ''}
                onChange={(event) => {
                  dispatch(
                    handleInputChange(
                      {
                        event,
                        field: 'projectFocalPerson',
                        info: contact,
                      },
                      fetched
                    )
                  );
                  setError({ ...errors, fullNameFocal_error: false });
                }}
              />

              <Form.Field
                required
                control={Input}
                label={lang.designation}
                name="designationFocal"
                type="text"
                value={contact.projectFocalPerson.designationFocal}
                width={16}
                className={errors.designationFocal_error ? 'error' : ''}
                onChange={(event) => {
                  dispatch(
                    handleInputChange(
                      {
                        event,
                        field: 'projectFocalPerson',
                        info: contact,
                      },
                      fetched
                    )
                  );
                  setError({ ...errors, designationFocal_error: false });
                }}
              />

              <Form.Field
                required
                control={Input}
                label={lang.number}
                name="phoneNumberFocal"
                type="text"
                className={errors.phoneNumberFocal_error ? 'error' : ''}
                value={contact.projectFocalPerson.phoneNumberFocal}
                width={16}
                onChange={(event) => {
                  dispatch(
                    handleInputChange(
                      {
                        event,
                        field: 'projectFocalPerson',
                        info: contact,
                      },
                      fetched
                    )
                  );
                  setError({ ...errors, phoneNumberFocal_error: false });
                }}
              />

              <Form.Field
                required
                control={Input}
                label={lang.email}
                name="emailFocal"
                type="email"
                value={contact.projectFocalPerson.emailFocal}
                width={16}
                className={errors.emailFocal_error ? 'error' : ''}
                onChange={(event) => {
                  dispatch(
                    handleInputChange(
                      {
                        event,
                        field: 'projectFocalPerson',
                        info: contact,
                      },
                      fetched
                    )
                  );
                  setError({ ...errors, emailFocal_error: false });
                }}
              />
            </Form.Group>
          </Grid.Column>
        </Grid>
      </div>
      <div className="form-actions flex">
        <button
          type="submit"
          onClick={() => handleSaveAndContinue('continue')}
          className="btn-rect btn-rect--small btn-blue"
        >
          Save and continue
        </button>
        <button
          className="btn-rect btn-rect--small btn-dark"
          onClick={() => handleSaveAndContinue('exit')}
        >
          Save and exit
        </button>
        <button
          className="btn-link"
          onClick={() => {
            let step = urlParams.stage === 'appraisal' ? 11 : 10;
            dispatch(ProjectAction.projectStep({ step }));
          }}
        >
          Back to previous form
        </button>
      </div>
    </Form>
  );
};

export default Contact;
