import React from 'react';
import { Grid, Form, Checkbox, Radio, Select, Input } from 'semantic-ui-react';
import Editor from '../../../components/CkEditor/Editor';
import ExampleInput from './exampleInput';
import ExampleCheckbox from './exampleCheckbox';
import ExampleRadio from './exampleRadio';
import ExampleSelect from './exampleSelect';
import ExampleUpload from './exampleUpload';
import ExampleRepeatField from './exampleRepeatField';
import ExampleDatePicker from './exampleDatePicker';
import ExampleCurrency from './exampleCurrency';

const GateExample = () => {
  return (
    <>
      <Grid columns="equal">
        <Grid.Column className="form-container">
          <div className="header large">Project name</div>
          <div className="project-detail">
            <div className="project-detail-container">
              <div className="flex justify-between align-center project-detail-info">
                <div className="info-status project-metadata bold">
                  <span className={`project-status dark`}>Publication</span>
                </div>
                <div className="flex align-center">
                  <div className="info-status project-metadata bold">
                    <span className={`project-status draft`}>Draft</span>
                  </div>
                </div>    
              </div>

              <Form className="form-new">
              <div className="form-group-container">
                  {/* Main header */}
                  <p className="form-info">Form currency selector</p>
                  
                  <ExampleCurrency />
                </div>
              <div className="form-group-container">
                  {/* Main header */}
                  <p className="form-info">Form section heading</p>
                  
                  <ExampleRepeatField />
                </div>

                <div className="form-group-container">
                  {/* Main header */}
                  <p className="form-info">
                    यस खण्डमा तपाईले योजनाको बारेमा जानकारी राख्न सक्नुहुनेछ। यस्ताे जानकारी चालु र
                    प्रस्तावित आयाेजना दुवैका लागि लागू हुन्छ।
                  </p>

                  <ExampleInput />
                </div>

                {/* Checkbox */}
                <div className="form-group-container">
                  {/* Main header */}
                  <p className="form-info">Form section heading</p>

                  <ExampleCheckbox />
                </div>

                {/* Radio button */}
                <div className="form-group-container">
                  {/* Main header */}
                  <p className="form-info">Form section heading</p>

                  <ExampleRadio />
                </div>

                <div className="form-group-container">
                  {/* Main header */}
                  <p className="form-info">Form section heading</p>

                  <ExampleSelect />
                </div>

                <div className="form-group-container">
                  {/* Main header */}
                  <p className="form-info">Form section heading</p>
                  
                  <ExampleUpload />
                </div>

                <div className="form-group-container">
                  {/* Main header */}
                  <p className="form-info">Form section heading</p>
                  
                  <ExampleDatePicker />
                </div>



                <div className="form-actions flex">
                  <button className="btn-rect btn-rect--small btn-blue">Save and continue</button>
                  <button className="btn-rect btn-rect--small btn-dark">Save and exit</button>
                  <button className="btn-link">Back to previous form</button>

                </div>
              </Form>
            </div>

            {/* Sidebar */}
            <div className="project-detail-dashboard">
              <ul>
                <li class=" active">
                  <a href="" className="active">
                    General Information
                  </a>
                </li>
                <li class=" ">
                  <a href="">Project Specific</a>
                </li>
                <li class=" ">
                  <a href="">Physical/Financial Progress</a>
                </li>
                <li class=" ">
                  <a href="">Project Readiness</a>
                </li>
                <li class=" ">
                  <a href="">Basis Of Project Selection</a>
                </li>
                <li class=" ">
                  <a href="">Project Prioritization Score</a>
                </li>
              </ul>
            </div>
          </div>
          {/* Form field select end */}
        </Grid.Column>
      </Grid>
    </>
  );
};

export default GateExample;
