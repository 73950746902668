import currentYear from '../../../helpers/constant/CurrentYear';
const initialState = {
  deskStudy: '',
  deskStudyFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  preFeasibility: '',
  preFeasibilityFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  annualProcurementComing: '',
  annualProcurementComingFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  projectConcept: '',
  projectConceptFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  projectProposal: '',
  projectProposalFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  feasibilityStudy: '',
  feasibilityStudyFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  dpr: '',
  dprFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  eia: '',
  eiaFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  iee: '',
  ieeFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  logFrame: '',
  logFrameFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  riskManagement: '',
  riskManagementFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  masterProcurement: '',
  masterProcurementFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  annualProcurement: '',
  annualProcurementFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  implementationPlan: '',
  implementationPlanFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  procurementAward: '',
  procurementAwardFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  monitorEvaluation: '',
  monitorEvaluationFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  landAcquisition: '',
  landAcquisitionFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  landAcquisitionComplete: '',
  otherDocumentFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  anyOtherDocumentFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  riskAndIssue: '',
  riskAndIssueFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  feasibilityStudyDateAD: '',
  feasibilityStudyDateBS: '',
  dprDateAD: '',
  dprDateBS: '',
  eiaDateAD: '',
  eiaDateBS: '',
  ieeDateAD: '',
  ieeDateBS: '',
  masterProcurementDateAD: '',
  masterProcurementDateBS: '',
  annualProcurementDateAD: '',
  annualProcurementDateBS: '',
  implementationPlanDateAD: '',
  implementationPlanDateBS: '',
  procurementAwardDateAD: '',
  procurementAwardDateBS: '',
  riskManagementDateAD: '',
  riskManagementDateBS: '',
  logFrameDateAD: '',
  logFrameDateBS: '',
  monitorEvaluationDateAD: '',
  monitorEvaluationDateBS: '',
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'ERROR':
      return {};
    case 'PROJECT_STEP':
      return {
        ...state,
        step: payload.step,
      };
    case 'READINESS_FETCHED':
      state = payload;
      return state;

    case 'NULL_READINESS_FETCHED':
      return initialState;

    default:
      return state;
  }
}
