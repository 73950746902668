import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Form, Grid, Header } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import 'react-circular-progressbar/dist/styles.css';
import { useUrlSearchParams } from 'use-url-search-params';

import MinistryService from '../../../services/Ministry';

import Can from '../../../components/Can/can';
import PageNotFound from '../../PageNotFound';

import { localization } from '../../../localization';

const AddMinistry = ({ history }) => {
  const language = useSelector((state) => state.localization.selectedLanguage);
  const lang = localization[language]['ministry'];

  const [ministryDetail, setMinistryDetail] = useState({
    name: '',
    code: null
  });

  const [errObj, setValidationError] = useState({
    name: false,
    code: false
  });

  const [params] = useUrlSearchParams();

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (params && params.ministryId) {
      getMinistryDetail();
    }
  }, [params, user]);

  const getMinistryDetail = () => {
    MinistryService.getMinistry(params)
      .then((response) => {
        if (response.success) {
          let d = response.data.ministry;
          setMinistryDetail({
            ...ministryDetail,
            name: d.name,
            code: d.code
          });
        } else {
          throw new Error(response.error.message);
        }
      })
      .catch((err) => {
        history.push('/ministries');
        toastr.error('', `${err.message}`);
      });
  };

  const ministryAction = () => {
    let sendUser = true;
    let obj = { ...errObj };

    if (ministryDetail.name.trim() === '') {
      obj.name = true;
      sendUser = false;
    }

    if (!ministryDetail.code) {
      obj.code = true;
      sendUser = false;
    }

    setValidationError(obj);

    if (sendUser) {
      if (params && params.ministryId) {
        MinistryService.updateMinistry({ ministryId: params.ministryId }, ministryDetail)
          .then((response) => {
            if (response.success) {
              toastr.success('', `${response.message}`);
              history.push('/ministries');
            } else {
              throw new Error(response.error.message);
            }
          })
          .catch((err) => {
            toastr.error('', `${err.message}`);
          });
      } else {
        MinistryService.createMinistry(ministryDetail)
          .then((response) => {
            if (response.success) {
              toastr.success('', `${response.message}`);
              history.push('/ministries');
            } else {
              throw new Error(response.error.message);
            }
          })
          .catch((err) => {
            toastr.error('', `${err.message}`);
          });
      }
    }
  };

  return (
    <Can
      role={user.role}
      perform="ministries:action"
      yes={() => (
        <>
          <Grid columns="equal">
            <Grid.Column className="form-container">
              <Container text className="add-project-form">
                {params && params.ministryId ? (
                  <Header size="medium">{lang.edit_ministry}</Header>
                ) : (
                  <Header size="medium">{lang.add_ministry}</Header>
                )}
                <Form>
                  <Form.Group>
                    <Form.Field required width={10}>
                      <label>{lang.ministry_name} </label>
                      <Form.Input
                        value={ministryDetail.name}
                        onChange={(e) => {
                          setMinistryDetail({ ...ministryDetail, name: e.target.value });
                          setValidationError({ ...errObj, name: false });
                        }}
                        error={
                          errObj.name
                            ? {
                                content: lang.ministry_name_required,
                                pointing: 'below',
                              }
                            : null
                        }
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group>
                    <Form.Field required width={10}>
                      <label>{lang.ministry_code} </label>
                      <Form.Input
                        value={ministryDetail.code}
                        onChange={(e) => {
                          setMinistryDetail({ ...ministryDetail, code: e.target.value });
                          setValidationError({ ...errObj, code: false });
                        }}
                        error={
                          errObj.code
                            ? {
                              content: lang.ministry_code_required,
                              pointing: 'below',
                            }
                            : null
                        }
                      />
                    </Form.Field>
                  </Form.Group>

                  <Button primary onClick={ministryAction}>
                    Save
                  </Button>

                  <Button basic onClick={() => history.push('/ministries')}>
                    Cancel
                  </Button>
                </Form>
              </Container>
            </Grid.Column>
          </Grid>
        </>
      )}
      no={() => <PageNotFound message={`You can't access this interface`} />}
    />
  );
};

export default withRouter(AddMinistry);
