import React from 'react';
import Bises from './Gate/Bises';

const BisesProjects = () => {
  return (
    <>
      <Bises isHomePage={true} />
    </>
  );
};

export default BisesProjects;
