import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Radio } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';

import Editor from '../CkEditor/index';
import { localization } from '../../../../localization';

import CurrencyField from '../CurrencyField';
import FileUpload from '../FileUpload/index';

import {
  addRepeaterField,
  handleInputChange,
  handleRepeaterChange,
  handleSaveAndExit,
  removeRepeaterField,
  handleRadioChange,
  handleEditorChange,
  repeaterFieldOptimise,
} from '../../../../actions';
import { fetched, fetchedNullData } from '../../../../reducers/form/FinancingArrangement/action';
import * as ProjectAction from '../../../../reducers/form/action';

const FinancingArrangement = ({ urlParams }) => {
  const selectedLanguage = useSelector((state) => state.localization.selectedLanguage);
  const lang = localization[selectedLanguage]['arrangement'];

  const dispatch = useDispatch();

  const { financingArrangement } = useSelector((state) => state);
  const { projectInfo } = useSelector((state) => state.projectBasicInfo);

  const [errors, setError] = useState({
    governmentNepalShare_error: false,
    loan_error: false,
    grant_error: false,
    privateEquity_error: false,
    others_error: false,
  });

  useEffect(() => {
    if (projectInfo.project_financing_arrangement) {
      dispatch(
        fetched({
          ...projectInfo.project_financing_arrangement,
        })
      );
    }
    if (!projectInfo.project_financing_arrangement) {
      dispatch(fetchedNullData());
    }
  }, [projectInfo]);

  const handleSaveAndContinue = () => {
    const data = cloneDeep(financingArrangement);
    delete data['step'];

    let canSubmit = true;

    const validateFields = ['governmentNepalShare', 'loan', 'grant', 'privateEquity', 'others'];

    const errors = {};

    for (let key in data) {
      if (validateFields.includes(key)) {
        const regexNumber = /^[+]?\d+(\.\d+)?$/;
        if (data[key] !== '' || data[key].length !== 0) {
          if (!regexNumber.test(data[key])) {
            canSubmit = false;
            errors[`${key}_error`] = true;
          }
        }
      }
    }

    setError(errors);

    if (canSubmit) {
      let repeaterFieldInFinance = {
        governmentProject: {
          gon: [
            {
              fundingAgency: '',
              fundCurrency: 'npr',
              fundInWord: '',
              fundExchangeRate: '',
            },
          ],
          internalLoan: [
            {
              fundingAgency: '',
              fundCurrency: 'npr',
              fundInWord: '',
              fundExchangeRate: '',
            },
          ],
          externalLoan: [
            {
              fundingAgency: '',
              fundCurrency: 'npr',
              fundInWord: '',
              fundExchangeRate: '',
            },
          ],
          externalGrant: [
            {
              fundingAgency: '',
              fundCurrency: 'npr',
              fundInWord: '',
              fundExchangeRate: '',
            },
          ],
        },
        privateProject: {
          internalLoan: [
            {
              fundingAgency: '',
              fundCurrency: 'npr',
              fundInWord: '',
              fundExchangeRate: '',
            },
          ],
          externalLoan: [
            {
              fundingAgency: '',
              fundCurrency: 'npr',
              fundInWord: '',
              fundExchangeRate: '',
            },
          ],
          foreignPrivateEquity: [
            {
              fundingAgency: '',
              fundCurrency: 'npr',
              fundInWord: '',
              fundExchangeRate: '',
            },
          ],
          localPrivateEquity: [
            {
              fundingAgency: '',
              fundCurrency: 'npr',
              fundInWord: '',
              fundExchangeRate: '',
            },
          ],
          gon: [
            {
              fundingAgency: '',
              fundCurrency: 'npr',
              fundInWord: '',
              fundExchangeRate: '',
            },
          ],
          others: [
            {
              fundingAgency: '',
              fundCurrency: 'npr',
              fundInWord: '',
              fundExchangeRate: '',
            },
          ],
        },
      };
      const keysInFinance = Object.keys(repeaterFieldInFinance);
      keysInFinance.map((item) => {
        for (let field in repeaterFieldInFinance[item]) {
          let newFinance = repeaterFieldOptimise(
            data,
            item,
            repeaterFieldInFinance[item][field],
            field
          );
          data[item][field] = [...newFinance];
        }
      });
      handleSaveAndExit(
        { project_financing_arrangement: data },
        'continue',
        dispatch,
        9,
        urlParams
      );
    }
  };

  return (
    <Form className="form-new">
      <div className="form-group-container">
        <p className="form-info">{lang.financing_structure}</p>
        <Form.Group>
          <Form.Field
            width={8}
            error={errors.governmentNepalShare_error}
            className={
              errors.governmentNepalShare_error ? 'error field-align-bottom' : 'field-align-bottom'
            }
          >
            <label>{lang.government}</label>
            {errors.governmentNepalShare_error ? (
              <div class="ui pointing below prompt label" role="alert" aria-atomic="true">
                Government of Nepal allow only a number!
              </div>
            ) : (
              ''
            )}

            <Input
              value={financingArrangement.governmentNepalShare}
              name="governmentNepalShare"
              onChange={(event) => {
                dispatch(handleInputChange({ event, info: financingArrangement }, fetched));
                setError({ ...errors, governmentNepalShare_error: false });
              }}
              label="%"
              labelPosition="right"
            />
          </Form.Field>
          <Form.Field
            width={8}
            className={errors.loan_error ? 'error field-align-bottom' : 'field-align-bottom'}
            error={errors.loan_error}
          >
            <label>{lang.loan}</label>
            {errors.loan_error ? (
              <div class="ui pointing below prompt label" role="alert" aria-atomic="true">
                Loan allow only a number!
              </div>
            ) : (
              ''
            )}
            <Input
              value={financingArrangement.loan}
              name="loan"
              onChange={(event) => {
                dispatch(handleInputChange({ event, info: financingArrangement }, fetched));
                setError({ ...errors, loan_error: false });
              }}
              label="%"
              labelPosition="right"
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field
            width={8}
            className={errors.grant_error ? 'error field-align-bottom' : 'field-align-bottom'}
            error={errors.grant_error}
          >
            <label>{lang.grant}</label>
            {errors.grant_error ? (
              <div class="ui pointing below prompt label" role="alert" aria-atomic="true">
                Grant allow only a number!
              </div>
            ) : (
              ''
            )}
            <Input
              value={financingArrangement.grant}
              name="grant"
              onChange={(event) => {
                dispatch(handleInputChange({ event, info: financingArrangement }, fetched));
                setError({ ...errors, grant_error: false });
              }}
              label="%"
              labelPosition="right"
            />
          </Form.Field>
          <Form.Field
            width={8}
            className={
              errors.privateEquity_error ? 'error field-align-bottom' : 'field-align-bottom'
            }
            error={errors.privateEquity_error}
          >
            <label>{lang.private_equity}</label>
            {errors.privateEquity_error ? (
              <div class="ui pointing below prompt label" role="alert" aria-atomic="true">
                Private Equity allow only a number!
              </div>
            ) : (
              ''
            )}
            <Input
              value={financingArrangement.privateEquity}
              name="privateEquity"
              onChange={(event) => {
                dispatch(handleInputChange({ event, info: financingArrangement }, fetched));
                setError({ ...errors, privateEquity_error: false });
              }}
              label="%"
              labelPosition="right"
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field
            width={8}
            className={errors.others_error ? 'error' : 'field-align-bottom'}
            error={errors.others_error}
          >
            <label>{lang.other}</label>
            {errors.others_error ? (
              <div class="ui pointing below prompt label" role="alert" aria-atomic="true">
                Others allow only a number!
              </div>
            ) : (
              ''
            )}
            <Input
              value={financingArrangement.others}
              name="others"
              onChange={(event) => {
                dispatch(handleInputChange({ event, info: financingArrangement }, fetched));
                setError({ ...errors, others_error: false });
              }}
              label="%"
              labelPosition="right"
            />
          </Form.Field>
        </Form.Group>
      </div>

      <div className="form-group-container">
        <Form.Group>
          <Form.Field label={lang.is_the_financing_for_the_project} />
          <Form.Group inline>
            <Form.Field
              label={lang.yes}
              control={Radio}
              name="financingStatus"
              value="Yes"
              checked={financingArrangement.financingStatus === 'Yes'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: financingArrangement,
                      value,
                      name,
                    },
                    fetched
                  )
                );
              }}
            />
            <Form.Field
              label={lang.no}
              control={Radio}
              value="No"
              name="financingStatus"
              checked={financingArrangement.financingStatus === 'No'}
              onChange={(event, { value, name }) => {
                dispatch(
                  handleRadioChange(
                    {
                      info: financingArrangement,
                      value,
                      name,
                    },
                    fetched
                  )
                );
              }}
            />
          </Form.Group>
        </Form.Group>
        {financingArrangement.financingStatus === 'No' && (
          <>
            <Form.Group>
              <Form.Field width={16}>
                <label>{lang.what_is_proposed_plan}</label>
                <Editor
                  formData={financingArrangement.proposedPlan}
                  formInfo={financingArrangement}
                  field={false}
                  name="proposedPlan"
                  handleEditorChange={handleEditorChange}
                  dispatch={dispatch}
                  fetched={fetched}
                />
              </Form.Field>
            </Form.Group>
            <FileUpload
              formName="arrangement"
              label="upload_brief_desc"
              fileData={financingArrangement.briefDescription}
              name="briefDescription"
              form={financingArrangement}
              fetched={fetched}
              projectId={urlParams.projectId}
            />
          </>
        )}
      </div>

      <div className="form-group-container">
        {/* <p className="form-info">{lang.source_of_funding}</p> */}

        <p className="form-info">{lang.source_of_funding_for_government_project}</p>
        <div className="form-group-inner-container">
          <Form.Field label={lang.gon} className="form-group-inner-title" />
          {/* For Government */}
          {financingArrangement.governmentProject.gon.map((value, index) => {
            return (
              <div className={index === 0 ? 'field-repeat' : 'field-repeat'} key={index}>
                <Form.Group className="flex-wrap fourth-child">
                  <Form.Field
                    control={Input}
                    label={lang.funding_agency}
                    placeholder={lang.funding_agency}
                    width={4}
                    className="field-align-bottom"
                    name="fundingAgency"
                    value={value.fundingAgency || ''}
                    onChange={(event) => {
                      dispatch(
                        handleRepeaterChange(
                          {
                            event,
                            info: financingArrangement,
                            subField: 'gon',
                            index: index,
                            field: 'governmentProject',
                          },
                          fetched
                        )
                      );
                    }}
                  />

                  <CurrencyField
                    costLabel="Fund"
                    costName="fund"
                    exchangeRateLabel="Exchange rate"
                    exchangeRateName="fundExchangeRate"
                    costInWordsLabel="Fund In word"
                    costInWordsName="fundInWord"
                    formInfo={financingArrangement}
                    dispatch={dispatch}
                    fetched={fetched}
                    field="gon"
                    mainField="governmentProject"
                    index={index}
                  />
                </Form.Group>
                {index > 0 && (
                  <div
                    className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                    onClick={(event) => {
                      dispatch(
                        removeRepeaterField(
                          {
                            event,
                            info: financingArrangement,
                            field: 'governmentProject',
                            subField: 'gon',
                            index,
                          },
                          fetched
                        )
                      );
                    }}
                  >
                    <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                    Delete
                  </div>
                )}
              </div>
            );
          })}
          <Form.Group className="field-repeat-btn">
            <Form.Field>
              <button
                className="btn-text"
                onClick={(event) => {
                  dispatch(
                    addRepeaterField(
                      {
                        event,
                        info: financingArrangement,
                        field: 'governmentProject',
                        subField: 'gon',
                      },
                      fetched
                    )
                  );
                }}
              >
                + Add more
              </button>
            </Form.Field>
          </Form.Group>
        </div>
        {/* For internal loan */}
        <div className="form-group-inner-container">
          <Form.Field label={lang.internal_loan} className="form-group-inner-title" />
          {financingArrangement.governmentProject.internalLoan.map((value, index) => {
            return (
              <div className={index === 0 ? 'field-repeat' : 'field-repeat'} key={index}>
                <Form.Group className="flex-wrap fourth-child">
                  <Form.Field
                    control={Input}
                    className="field-align-bottom"
                    label={lang.funding_agency}
                    placeholder={lang.funding_agency}
                    width={4}
                    name="fundingAgency"
                    value={value.fundingAgency || ''}
                    onChange={(event) => {
                      dispatch(
                        handleRepeaterChange(
                          {
                            event,
                            info: financingArrangement,
                            subField: 'internalLoan',
                            index: index,
                            field: 'governmentProject',
                          },
                          fetched
                        )
                      );
                    }}
                  />

                  <CurrencyField
                    costLabel="Fund"
                    costName="fund"
                    exchangeRateLabel="Exchange rate"
                    exchangeRateName="fundExchangeRate"
                    costInWordsLabel="Fund In word"
                    costInWordsName="fundInWord"
                    formInfo={financingArrangement}
                    dispatch={dispatch}
                    fetched={fetched}
                    field="internalLoan"
                    mainField="governmentProject"
                    index={index}
                  />
                </Form.Group>
                {index > 0 && (
                  <div
                    className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                    onClick={(event) => {
                      dispatch(
                        removeRepeaterField(
                          {
                            event,
                            info: financingArrangement,
                            field: 'governmentProject',
                            subField: 'internalLoan',
                            index,
                          },
                          fetched
                        )
                      );
                    }}
                  >
                    <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                    Delete
                  </div>
                )}
              </div>
            );
          })}
          <Form.Group className="field-repeat-btn">
            <Form.Field>
              <button
                className="btn-text"
                onClick={(event) => {
                  dispatch(
                    addRepeaterField(
                      {
                        event,
                        info: financingArrangement,
                        field: 'governmentProject',
                        subField: 'internalLoan',
                      },
                      fetched
                    )
                  );
                }}
              >
                + Add more
              </button>
            </Form.Field>
          </Form.Group>
        </div>

        {/* For external Source */}
        <div className="form-group-inner-container">
          <Form.Field label={lang.external_source} className="form-group-inner-title" />
          {financingArrangement.governmentProject.externalLoan.map((value, index) => {
            return (
              <div className={index === 0 ? 'field-repeat' : 'field-repeat'} key={index}>
                <Form.Group className="flex-wrap fourth-child">
                  <Form.Field
                    control={Input}
                    className="field-align-bottom"
                    label={lang.funding_agency}
                    placeholder={lang.funding_agency}
                    width={4}
                    name="fundingAgency"
                    value={value.fundingAgency || ''}
                    onChange={(event) => {
                      dispatch(
                        handleRepeaterChange(
                          {
                            event,
                            info: financingArrangement,
                            subField: 'externalLoan',
                            index: index,
                            field: 'governmentProject',
                          },
                          fetched
                        )
                      );
                    }}
                  />
                  <CurrencyField
                    costLabel="Fund"
                    costName="fund"
                    exchangeRateLabel="Exchange rate"
                    exchangeRateName="fundExchangeRate"
                    costInWordsLabel="Fund In word"
                    costInWordsName="fundInWord"
                    formInfo={financingArrangement}
                    dispatch={dispatch}
                    fetched={fetched}
                    field="externalLoan"
                    mainField="governmentProject"
                    index={index}
                  />
                </Form.Group>
                {index > 0 && (
                  <div
                    className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                    onClick={(event) => {
                      dispatch(
                        removeRepeaterField(
                          {
                            event,
                            info: financingArrangement,
                            field: 'governmentProject',
                            subField: 'externalLoan',
                            index,
                          },
                          fetched
                        )
                      );
                    }}
                  >
                    <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                    Delete
                  </div>
                )}
              </div>
            );
          })}
          <Form.Group className="field-repeat-btn">
            <Form.Field>
              <button
                className="btn-text"
                onClick={(event) => {
                  dispatch(
                    addRepeaterField(
                      {
                        event,
                        info: financingArrangement,
                        field: 'governmentProject',
                        subField: 'externalLoan',
                      },
                      fetched
                    )
                  );
                }}
              >
                + Add more
              </button>
            </Form.Field>
          </Form.Group>
        </div>

        {/* External Grant */}
        <div className="form-group-inner-container">
          <Form.Field label={lang.external_grant} className="form-group-inner-title" />
          {financingArrangement.governmentProject.externalGrant.map((value, index) => {
            return (
              <div className={index === 0 ? 'field-repeat' : 'field-repeat'} key={index}>
                <Form.Group className="flex-wrap fourth-child">
                  <Form.Field
                    control={Input}
                    className="field-align-bottom"
                    label={lang.funding_agency}
                    placeholder={lang.funding_agency}
                    width={4}
                    name="fundingAgency"
                    value={value.fundingAgency || ''}
                    onChange={(event) => {
                      dispatch(
                        handleRepeaterChange(
                          {
                            event,
                            info: financingArrangement,
                            subField: 'externalGrant',
                            index: index,
                            field: 'governmentProject',
                          },
                          fetched
                        )
                      );
                    }}
                  />
                  <CurrencyField
                    costLabel="Fund"
                    costName="fund"
                    exchangeRateLabel="Exchange rate"
                    exchangeRateName="fundExchangeRate"
                    costInWordsLabel="Fund In word"
                    costInWordsName="fundInWord"
                    formInfo={financingArrangement}
                    dispatch={dispatch}
                    fetched={fetched}
                    field="externalGrant"
                    mainField="governmentProject"
                    index={index}
                  />
                </Form.Group>

                {index > 0 && (
                  <div
                    className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                    onClick={(event) => {
                      dispatch(
                        removeRepeaterField(
                          {
                            event,
                            info: financingArrangement,
                            field: 'governmentProject',
                            subField: 'externalGrant',
                            index,
                          },
                          fetched
                        )
                      );
                    }}
                  >
                    <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                    Delete
                  </div>
                )}
              </div>
            );
          })}
          <Form.Group className="field-repeat-btn">
            <Form.Field>
              <button
                className="btn-text"
                onClick={(event) => {
                  dispatch(
                    addRepeaterField(
                      {
                        event,
                        info: financingArrangement,
                        field: 'governmentProject',
                        subField: 'externalGrant',
                      },
                      fetched
                    )
                  );
                }}
              >
                + Add more
              </button>
            </Form.Field>
          </Form.Group>
        </div>
      </div>

      <div className="form-group-container">
        {/* For Private Project */}
        <p className="form-info">{lang.source_of_funding_for_ppp_private_project}</p>
        {/* Internal Loan (PP) */}
        <div className="form-group-inner-container">
          <Form.Field label={lang.internal_loan_ppp} className="form-group-inner-title" />
          {financingArrangement.privateProject.internalLoan.map((value, index) => {
            return (
              <div className={index === 0 ? 'field-repeat' : 'field-repeat'} key={index}>
                <Form.Group className="flex-wrap fourth-child">
                  <Form.Field
                    control={Input}
                    className="field-align-bottom"
                    label={lang.funding_agency}
                    placeholder={lang.funding_agency}
                    width={4}
                    name="fundingAgency"
                    value={value.fundingAgency || ''}
                    onChange={(event) => {
                      dispatch(
                        handleRepeaterChange(
                          {
                            event,
                            info: financingArrangement,
                            subField: 'internalLoan',
                            index: index,
                            field: 'privateProject',
                          },
                          fetched
                        )
                      );
                    }}
                  />
                  <CurrencyField
                    costLabel="Fund"
                    costName="fund"
                    exchangeRateLabel="Exchange rate"
                    exchangeRateName="fundExchangeRate"
                    costInWordsLabel="Fund In word"
                    costInWordsName="fundInWord"
                    formInfo={financingArrangement}
                    dispatch={dispatch}
                    fetched={fetched}
                    field="internalLoan"
                    mainField="privateProject"
                    index={index}
                  />
                </Form.Group>
                {index > 0 && (
                  <div
                    className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                    onClick={(event) => {
                      dispatch(
                        removeRepeaterField(
                          {
                            event,
                            info: financingArrangement,
                            field: 'privateProject',
                            subField: 'internalLoan',
                            index,
                          },
                          fetched
                        )
                      );
                    }}
                  >
                    <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                    Delete
                  </div>
                )}
              </div>
            );
          })}
          <Form.Group className="field-repeat-btn">
            <Form.Field>
              <button
                className="btn-text"
                onClick={(event) => {
                  dispatch(
                    addRepeaterField(
                      {
                        event,
                        info: financingArrangement,
                        field: 'privateProject',
                        subField: 'internalLoan',
                      },
                      fetched
                    )
                  );
                }}
              >
                + Add more
              </button>
            </Form.Field>
          </Form.Group>
        </div>

        {/* For External Loan (PP) */}
        <div className="form-group-inner-container">
          <Form.Field label={lang.external_loan_ppp} className="form-group-inner-title" />
          {financingArrangement.privateProject.externalLoan.map((value, index) => {
            return (
              <div className={index === 0 ? 'field-repeat' : 'field-repeat'} key={index}>
                <Form.Group className="flex-wrap fourth-child">
                  <Form.Field
                    control={Input}
                    className="field-align-bottom"
                    label={lang.funding_agency}
                    placeholder={lang.funding_agency}
                    width={4}
                    name="fundingAgency"
                    value={value.fundingAgency || ''}
                    onChange={(event) => {
                      dispatch(
                        handleRepeaterChange(
                          {
                            event,
                            info: financingArrangement,
                            subField: 'externalLoan',
                            index: index,
                            field: 'privateProject',
                          },
                          fetched
                        )
                      );
                    }}
                  />
                  <CurrencyField
                    costLabel="Fund"
                    costName="fund"
                    exchangeRateLabel="Exchange rate"
                    exchangeRateName="fundExchangeRate"
                    costInWordsLabel="Fund In word"
                    costInWordsName="fundInWord"
                    formInfo={financingArrangement}
                    dispatch={dispatch}
                    fetched={fetched}
                    field="externalLoan"
                    mainField="privateProject"
                    index={index}
                  />
                </Form.Group>

                {index > 0 && (
                  <div
                    className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                    onClick={(event) => {
                      dispatch(
                        removeRepeaterField(
                          {
                            event,
                            info: financingArrangement,
                            field: 'privateProject',
                            subField: 'externalLoan',
                            index,
                          },
                          fetched
                        )
                      );
                    }}
                  >
                    <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                    Delete
                  </div>
                )}
              </div>
            );
          })}
          <Form.Group className="field-repeat-btn">
            <Form.Field>
              <button
                className="btn-text"
                onClick={(event) => {
                  dispatch(
                    addRepeaterField(
                      {
                        event,
                        info: financingArrangement,
                        field: 'privateProject',
                        subField: 'externalLoan',
                      },
                      fetched
                    )
                  );
                }}
              >
                + Add more
              </button>
            </Form.Field>
          </Form.Group>
        </div>

        {/* Foreign Private Equity */}
        <div className="form-group-inner-container">
          <Form.Field label={lang.private_equity_foreign} className="form-group-inner-title" />
          {financingArrangement.privateProject.foreignPrivateEquity.map((value, index) => {
            return (
              <div className={index === 0 ? 'field-repeat' : 'field-repeat'} key={index}>
                <Form.Group className="flex-wrap fourth-child">
                  <Form.Field
                    control={Input}
                    className="field-align-bottom"
                    label={lang.funding_agency}
                    placeholder={lang.funding_agency}
                    width={4}
                    name="fundingAgency"
                    value={value.fundingAgency || ''}
                    onChange={(event) => {
                      dispatch(
                        handleRepeaterChange(
                          {
                            event,
                            info: financingArrangement,
                            subField: 'foreignPrivateEquity',
                            index: index,
                            field: 'privateProject',
                          },
                          fetched
                        )
                      );
                    }}
                  />
                  <CurrencyField
                    costLabel="Fund"
                    costName="fund"
                    exchangeRateLabel="Exchange rate"
                    exchangeRateName="fundExchangeRate"
                    costInWordsLabel="Fund In word"
                    costInWordsName="fundInWord"
                    formInfo={financingArrangement}
                    dispatch={dispatch}
                    fetched={fetched}
                    field="foreignPrivateEquity"
                    mainField="privateProject"
                    index={index}
                  />
                </Form.Group>

                {index > 0 && (
                  <div
                    className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                    onClick={(event) => {
                      dispatch(
                        removeRepeaterField(
                          {
                            event,
                            info: financingArrangement,
                            field: 'privateProject',
                            subField: 'foreignPrivateEquity',
                            index,
                          },
                          fetched
                        )
                      );
                    }}
                  >
                    <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                    Delete
                  </div>
                )}
              </div>
            );
          })}
          <Form.Group className="field-repeat-btn">
            <Form.Field>
              <button
                className="btn-text"
                onClick={(event) => {
                  dispatch(
                    addRepeaterField(
                      {
                        event,
                        info: financingArrangement,
                        field: 'privateProject',
                        subField: 'foreignPrivateEquity',
                      },
                      fetched
                    )
                  );
                }}
              >
                + Add more
              </button>
            </Form.Field>
          </Form.Group>
        </div>

        {/* Local Private Equity */}
        <div className="form-group-inner-container">
          <Form.Field label={lang.private} className="form-group-inner-title" />
          {financingArrangement.privateProject.localPrivateEquity.map((value, index) => {
            return (
              <div className={index === 0 ? 'field-repeat' : 'field-repeat'} key={index}>
                <Form.Group className="flex-wrap fourth-child">
                  <Form.Field
                    control={Input}
                    className="field-align-bottom"
                    label={lang.funding_agency}
                    placeholder={lang.funding_agency}
                    width={4}
                    name="fundingAgency"
                    value={value.fundingAgency || ''}
                    onChange={(event) => {
                      dispatch(
                        handleRepeaterChange(
                          {
                            event,
                            info: financingArrangement,
                            subField: 'localPrivateEquity',
                            index: index,
                            field: 'privateProject',
                          },
                          fetched
                        )
                      );
                    }}
                  />
                  <CurrencyField
                    costLabel="Fund"
                    costName="fund"
                    exchangeRateLabel="Exchange rate"
                    exchangeRateName="fundExchangeRate"
                    costInWordsLabel="Fund In word"
                    costInWordsName="fundInWord"
                    formInfo={financingArrangement}
                    dispatch={dispatch}
                    fetched={fetched}
                    field="localPrivateEquity"
                    mainField="privateProject"
                    index={index}
                  />
                </Form.Group>

                {index > 0 && (
                  <div
                    className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                    onClick={(event) => {
                      dispatch(
                        removeRepeaterField(
                          {
                            event,
                            info: financingArrangement,
                            field: 'privateProject',
                            subField: 'localPrivateEquity',
                            index,
                          },
                          fetched
                        )
                      );
                    }}
                  >
                    <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                    Delete
                  </div>
                )}
              </div>
            );
          })}
          <Form.Group className="field-repeat-btn">
            <Form.Field>
              <button
                className="btn-text"
                onClick={(event) => {
                  dispatch(
                    addRepeaterField(
                      {
                        event,
                        info: financingArrangement,
                        field: 'privateProject',
                        subField: 'localPrivateEquity',
                      },
                      fetched
                    )
                  );
                }}
              >
                + Add more
              </button>
            </Form.Field>
          </Form.Group>
        </div>

        {/* For Government Private Project */}
        <div className="form-group-inner-container">
          <Form.Field label={lang.gon} className="form-group-inner-title" />
          {financingArrangement.privateProject.gon.map((value, index) => {
            return (
              <div className={index === 0 ? 'field-repeat' : 'field-repeat'} key={index}>
                <Form.Group className="flex-wrap fourth-child">
                  <Form.Field
                    control={Input}
                    className="field-align-bottom"
                    label={lang.funding_agency}
                    placeholder={lang.funding_agency}
                    width={4}
                    name="fundingAgency"
                    value={value.fundingAgency || ''}
                    onChange={(event) => {
                      dispatch(
                        handleRepeaterChange(
                          {
                            event,
                            info: financingArrangement,
                            subField: 'gon',
                            index: index,
                            field: 'privateProject',
                          },
                          fetched
                        )
                      );
                    }}
                  />
                  <CurrencyField
                    costLabel="Fund"
                    costName="fund"
                    exchangeRateLabel="Exchange rate"
                    exchangeRateName="fundExchangeRate"
                    costInWordsLabel="Fund In word"
                    costInWordsName="fundInWord"
                    formInfo={financingArrangement}
                    dispatch={dispatch}
                    fetched={fetched}
                    field="gon"
                    mainField="privateProject"
                    index={index}
                  />
                </Form.Group>

                {index > 0 && (
                  <div
                    className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                    onClick={(event) => {
                      dispatch(
                        removeRepeaterField(
                          {
                            event,
                            info: financingArrangement,
                            field: 'privateProject',
                            subField: 'gon',
                            index,
                          },
                          fetched
                        )
                      );
                    }}
                  >
                    <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                    Delete
                  </div>
                )}
              </div>
            );
          })}
          <Form.Group className="field-repeat-btn">
            <Form.Field>
              <button
                className="btn-text"
                onClick={(event) => {
                  dispatch(
                    addRepeaterField(
                      {
                        event,
                        info: financingArrangement,
                        field: 'privateProject',
                        subField: 'gon',
                      },
                      fetched
                    )
                  );
                }}
              >
                + Add more
              </button>
            </Form.Field>
          </Form.Group>
        </div>

        {/* For others private Project */}
        <div className="form-group-inner-container">
          <Form.Field label={lang.others} className="form-group-inner-title" />
          {financingArrangement.privateProject.others.map((value, index) => {
            return (
              <div className={index === 0 ? 'field-repeat' : 'field-repeat'} key={index}>
                <Form.Group className="flex-wrap fourth-child">
                  <Form.Field
                    control={Input}
                    className="field-align-bottom"
                    label={lang.funding_agency}
                    placeholder={lang.funding_agency}
                    width={4}
                    name="fundingAgency"
                    value={value.fundingAgency || ''}
                    onChange={(event) => {
                      dispatch(
                        handleRepeaterChange(
                          {
                            event,
                            info: financingArrangement,
                            subField: 'others',
                            index: index,
                            field: 'privateProject',
                          },
                          fetched
                        )
                      );
                    }}
                  />
                  <CurrencyField
                    costLabel="Fund"
                    costName="fund"
                    exchangeRateName="fundExchangeRate"
                    exchangeRateLabel="Exchange rate"
                    costInWordsLabel="Fund In word"
                    costInWordsName="fundInWord"
                    formInfo={financingArrangement}
                    dispatch={dispatch}
                    fetched={fetched}
                    field="others"
                    mainField="privateProject"
                    index={index}
                  />
                </Form.Group>
                {index > 0 && (
                  <div
                    className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                    onClick={(event) => {
                      dispatch(
                        removeRepeaterField(
                          {
                            event,
                            info: financingArrangement,
                            field: 'privateProject',
                            subField: 'others',
                            index,
                          },
                          fetched
                        )
                      );
                    }}
                  >
                    <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                    Delete
                  </div>
                )}
              </div>
            );
          })}
          <Form.Group className="field-repeat-btn">
            <Form.Field>
              <button
                className="btn-text"
                onClick={(event) => {
                  dispatch(
                    addRepeaterField(
                      {
                        event,
                        info: financingArrangement,
                        field: 'privateProject',
                        subField: 'others',
                      },
                      fetched
                    )
                  );
                }}
              >
                + Add more
              </button>
            </Form.Field>
          </Form.Group>
        </div>
      </div>
      <div className="form-actions flex">
        <button
          type="submit"
          onClick={handleSaveAndContinue}
          className="btn-rect btn-rect--small btn-blue"
        >
          Save and continue
        </button>
        <button className="btn-rect btn-rect--small btn-dark">Save and exit</button>
        <button
          type="button"
          className="btn-link"
          onClick={() => dispatch(ProjectAction.projectStep({ step: 7 }))}
        >
          Back to previous form
        </button>
      </div>
    </Form>
  );
};

export default FinancingArrangement;
