import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { useSelector } from 'react-redux';
import { Button, Modal, Table, Pagination, Form } from 'semantic-ui-react';

import EmailApi from '../../../services/Email';
import LoaderNP from '../../../components/Loader/Loader';
import { auth } from '../../../helpers';
import { localization } from '../../../localization';
import Can from '../../../components/Can/can';
import PageNotFound from '../../PageNotFound';

const EmailList = ({ history }) => {
  const user = useSelector(auth.getCurrentUser);
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['config'];
  const [loading, setLoading] = useState(true);
  const [emailList, setEmailList] = useState([]);
  const [queryString, setQueryString] = useState({ page: 0 });
  const [activePage, setActivePage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [emailId, setEmailId] = useState();
  const [email, setEmail] = useState();

  const [action, setAction] = useState('');

  useEffect(() => {
    allEmails();
  }, [queryString]);

  const allEmails = () => {
    setLoading(true);
    EmailApi.listAllEmails()
      .then((response) => {
        if (response.success) {
          setEmailList(response.data);
          setLoading(false);
        } else {
          throw new Error(response.err.message);
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const handlePaginationChange = (e, { activePage }) => {
    setQueryString({ ...queryString, page: activePage - 1 });
    setActivePage(activePage);
  };

  const deleteEmail = (e) => {
    EmailApi.deleteEmail({ id: emailId })
      .then((response) => {
        if (response.success) {
          setLoading(false);
          setModalOpen(false);
          setEmailId('');
          setQueryString({ page: 0 });
          toastr.success('', `${response.message}`);
        } else {
          throw new Error(response.err.message);
        }
      })
      .catch((err) => {
        setModalOpen(false);
        toastr.error('', `${err.message}`);
      });
  };

  const editEmail = () => {
    EmailApi.updateEmail({ id: emailId }, { email })
      .then((response) => {
        if (response.success) {
          setLoading(false);
          setModalOpen(false);
          setQueryString({ page: 0 });
          setEmail('');
          setEmailId('');
          toastr.success('', `${response.message}`);
        } else {
          throw new Error(response.err.message);
        }
      })
      .catch((err) => {
        setModalOpen(false);
        toastr.error('', `${err.message}`);
      });
  };
  return (
    <Can
      role={user.role}
      perform="email:list:edit:delete"
      yes={() => (
        <>
          <div className="body-container no-print">
            <div className="dashboard-header justify-between">
              <div className="flex">
                <h2 className="header-main">{lang.email}</h2>
              </div>
              <button
                className="btn-transition btn-normal btn-normal--medium btn-normal--br4 btn-blue btn-shadow--blue view-proj"
                onClick={() => history.push(`/config`)}
                style={{ marginLeft: '20px' }}
              >
                Add new email
              </button>
            </div>

            {loading ? (
              <LoaderNP />
            ) : emailList && emailList.length > 0 ? (
              <>
                <Table sortable className="project-table no-action">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={2}>{lang.s_n} </Table.HeaderCell>
                      <Table.HeaderCell width={6}>{lang.email}</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {emailList.map((list, index) => {
                      return (
                        <>
                          <Table.Row key={list.id}>
                            <Table.Cell>
                              <p className="project-title">{index + 1}</p>
                            </Table.Cell>
                            <Table.Cell>{list.email}</Table.Cell>
                            <Table.Cell>
                              <Can
                                role={user.role}
                                perform="email:list:edit:delete"
                                yes={() => (
                                  <div className="actions">
                                    {/* <Link to={`/add/programs?id=${program.id}`}>Edit |</Link> */}
                                    <span
                                      onClick={() => {
                                        setModalOpen(!modalOpen);
                                        setEmailId(list.id);
                                        setEmail(list.email);
                                        setAction('edit');
                                      }}
                                      className="btn-outline btn-outline--small btn-outline--blue"
                                    >
                                      <i className="material-icons md-12 mr-2">edit</i>
                                      Edit{' '}
                                    </span>
                                    <span
                                      className="btn-outline btn-outline--small btn-outline--red"
                                      onClick={() => {
                                        setModalOpen(!modalOpen);
                                        setEmailId(list.id);
                                        setAction('delete');
                                      }}
                                    >
                                      <i className="material-icons md-12 mr-2 material-delete delete">
                                        delete_outline
                                      </i>
                                      Delete
                                    </span>
                                  </div>
                                )}
                                no={() => ''}
                              />
                            </Table.Cell>
                          </Table.Row>
                        </>
                      );
                    })}
                  </Table.Body>
                </Table>
                {/* <div className="justify-center">
                  <Pagination
                    defaultActivePage={activePage}
                    totalPages={totalPage}
                    onPageChange={handlePaginationChange}
                    firstItem={{
                      'aria-label': 'First item',
                      content: 'First',
                    }}
                    lastItem={{
                      'aria-label': 'Last item',
                      content: 'Last',
                    }}
                    prevItem={{
                      'aria-label': 'Previous item',
                      content: 'Previous',
                    }}
                    nextItem={{
                      'aria-label': 'First item',
                      content: 'Next',
                    }}
                  />
                </div> */}
              </>
            ) : (
              <>
                <div className="empty">
                  <p as="p" size="">
                    Email List is empty
                  </p>
                </div>
              </>
            )}
          </div>

          <Modal
            dimmer="blurring"
            open={modalOpen}
            onClose={() => setModalOpen(!modalOpen)}
            size="mini"
          >
            {action === 'delete' && (
              <>
                <Modal.Header>{lang.delete_email}</Modal.Header>
                <Modal.Content>
                  <p>{lang.are_you_sure_delete_email}</p>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    color="black"
                    onClick={() => {
                      setModalOpen(!modalOpen);
                      setEmailId('');
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    positive
                    content="Yes"
                    onClick={(e) => {
                      deleteEmail(e);
                    }}
                  />
                </Modal.Actions>
              </>
            )}

            {action === 'edit' && (
              <>
                <Modal.Header>{lang.edit_email}</Modal.Header>
                <Modal.Content>
                  <Form>
                    <Form.Group grouped>
                      <Form.Field>
                        <label>{lang.email}</label>
                        <input value={email} onChange={(e) => setEmail(e.target.value)} />
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    color="black"
                    onClick={() => {
                      setModalOpen(!modalOpen);
                      setEmail('');
                      setEmailId('');
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    positive
                    content="Yes"
                    onClick={(e) => {
                      editEmail(e);
                    }}
                  />
                </Modal.Actions>
              </>
            )}
          </Modal>
        </>
      )}
      no={() => <PageNotFound message={'You have no permission to visit this site'} />}
    />
  );
};

export default EmailList;
