import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Radio } from 'semantic-ui-react';
import { handleRadioChange } from '../../../../actions';
import { fetched } from '../../../../reducers/form/ProjectReadiness/action';
import FileUpload from '../FileUpload/index';
import DatePicker from '../DatePicker';
import NepaliDatePicker from '../DatePicker/NepaliDatePicker';

const ConditionalRadioButton = ({
  projectId,
  name,
  label,
  uploadLabel,
  uploadName,
  lang,
  dateLabel = null,
  required = false,
  error = null,
}) => {
  const dispatch = useDispatch();
  const { projectReadiness } = useSelector((state) => state);
  return (
    <>
      <Form.Group>
        <Form.Field
          label={lang[label]}
          required={required}
          className={error && error[`${name}Error`] ? 'error' : ''}
        />
        <Form.Group inline>
          <Form.Field
            control={Radio}
            label={lang.yes}
            name={name}
            value="Yes"
            onChange={(event, { value, name }) => {
              dispatch(
                handleRadioChange(
                  {
                    info: projectReadiness,
                    value,
                    name,
                  },
                  fetched
                )
              );
            }}
            checked={projectReadiness[name] === 'Yes'}
          />
          <Form.Field
            label={lang.no}
            control={Radio}
            name={name}
            value="No"
            onChange={(event, { value, name }) => {
              dispatch(
                handleRadioChange(
                  {
                    info: projectReadiness,
                    value,
                    name,
                  },
                  fetched
                )
              );
            }}
            checked={projectReadiness[name] === 'No'}
          />
          <Form.Field
            label={lang.not_required}
            control={Radio}
            name={name}
            value="Not Required"
            onChange={(event, { value, name }) => {
              dispatch(
                handleRadioChange(
                  {
                    info: projectReadiness,
                    value,
                    name,
                  },
                  fetched
                )
              );
            }}
            checked={projectReadiness[name] === 'Not Required'}
          />
        </Form.Group>
      </Form.Group>
      {projectReadiness[name] === 'Yes' && (
        <FileUpload
          formName="readiness"
          label={uploadLabel}
          fileData={projectReadiness[uploadName]}
          name={uploadName}
          form={projectReadiness}
          fetched={fetched}
          projectId={projectId}
          required={required}
          error={error ? error[`${name}FileError`] : false}
        />
      )}
      {dateLabel && projectReadiness[name] === 'No' && (
        <>
          <Form.Group>
            <Form.Field label={lang[dateLabel]} />
          </Form.Group>
          <Form.Group>
            <DatePicker
              lang={lang}
              label="date_ad"
              name={`${name}DateAD`}
              formName={projectReadiness}
              fetched={fetched}
              changeField={`${name}DateBS`}
            />
            <NepaliDatePicker
              lang={lang}
              label="date_bs"
              name={`${name}DateBS`}
              formName={projectReadiness}
              fetched={fetched}
              changeField={`${name}DateAD`}
              formSection={projectReadiness}
            />
          </Form.Group>
        </>
      )}
    </>
  );
};

export default ConditionalRadioButton;
