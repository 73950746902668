/* eslint-disable no-redeclare */
/* global process */
import * as authHelper from './AuthHelper';

export const call = (handler, ...data) => {
  if (handler) {
    handler(...data);
  }
};

export const auth = authHelper;
export const user = authHelper;
export const API_URL = process.env.REACT_APP_API_URL;
export const EXTERNAL_API_URL = process.env.REACT_APP_EXTERNAL_API_URL;
