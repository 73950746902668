import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import ScrollArea from 'react-scrollbar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SectorHandle from '../GeneralInformation/sectorHandleList';

import { localization } from '../../localization';

import FilterSection from './FilterSection';
import FilterMultiSection from './FilterMultiSection';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.state = {
      showSidebar: false,
      showMinistryOption: false,
      showDepartmentOption: false,
      showProgramOption: false,
      showProjectStatusOption: false,
      showProjectTypeOption: false,
      showProjectPriorityOption: false,
      showProjectRankingOption: false,
      showSectorsOption: false,
      showSubSectorsOption: false,
      showYearOnYearOption: false,
      showModerationStatusOption: false,
      showProjectDateOption: false,
      showAIEOption: false,
      showProvinceOption: false,
      showDistrictOption: false,
      showProjectReadinessOption: false,
      sectors: [],
      selectedLanguage: '',
      filterValues: {},
      collapseAll: false,
      collapseStatus: false,
    };
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleOutsideClick = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showSidebar: false });
    }
  };

  escFunction = (event) => {
    if (event.keyCode === 27) {
      this.setState({ showSidebar: false });
    }
  };

  componentDidMount() {
    const lang = localization[this.props.selectedLanguage];

    const sectors = SectorHandle(lang, []);

    this.setState({
      sectors,
    });

    document.addEventListener('mousedown', this.handleOutsideClick, false);
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
    document.removeEventListener('keydown', this.escFunction, false);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let data = {};
    if (nextProps.ministries !== prevState.ministries) {
      if (['dataViewer', 'npcAdmin'].indexOf(nextProps.auth.user.role) === -1) {
        data.ministry_id = {
          label: nextProps.auth.user.ministry.name,
          value: nextProps.auth.user.ministry.id,
        };
      }
      data.ministries = nextProps.ministries;
    }
    if (nextProps.departments !== prevState.departments) {
      data.departments = nextProps.departments;
    }

    if (nextProps.provinces !== prevState.provinces) {
      data.provinces = nextProps.provinces;
    }

    if (nextProps.filterValues !== prevState.filterValues) {
      data.filterValues = nextProps.filterValues;
    }

    if (nextProps.programs !== prevState.programs) {
      data.programs = nextProps.programs;
    }

    return data;
  }

  toggleSidebar = () => {
    this.setState({ showSidebar: !this.state.showSidebar });
  };

  toggleFilterOption = (filter) => {
    this.setState({
      [filter]: !this.state[filter],
      collapseAll: true,
    });
  };

  collapseAll = () => {
    this.setState({
      showMinistryOption: !this.state.collapseAll,
      showDepartmentOption: !this.state.collapseAll,
      showProgramOption: !this.state.collapseAll,
      showProjectStatusOption: !this.state.collapseAll,
      showProjectTypeOption: !this.state.collapseAll,
      showProjectPriorityOption: !this.state.collapseAll,
      showProjectRankingOption: !this.state.collapseAll,
      showSectorsOption: !this.state.collapseAll,
      showSubSectorsOption: !this.state.collapseAll,
      showYearOnYearOption: !this.state.collapseAll,
      showModerationStatusOption: !this.state.collapseAll,
      showProjectDateOption: !this.state.collapseAll,
      showAIEOption: !this.state.collapseAll,
      showProvinceOption: !this.state.collapseAll,
      showDistrictOption: !this.state.collapseAll,
      showProjectReadinessOption: !this.state.collapseAll,
      collapseAll: !this.state.collapseAll,
    });
  };

  readinessChildComp = (title, filterLabel, selectedVal, options) => {
    return (
      <div className="options-multiple">
        <p className="option-title">{title}</p>
        <label className="radio-btn filter-label-inline">
          <input
            type="radio"
            onChange={(event) => {
              this.props.handleChange(filterLabel, 'Yes');
            }}
            checked={selectedVal === 'Yes'}
            name={title}
          />
          {options.yes}
          <span className="checkmark"></span>
        </label>
        <label className="radio-btn filter-label-inline">
          <input
            type="radio"
            onChange={(event) => {
              this.props.handleChange(filterLabel, 'No');
            }}
            checked={selectedVal === 'No'}
            name={title}
          />
          {options.no}
          <span className="checkmark"></span>
        </label>
        <label className="radio-btn filter-label-inline">
          <input
            type="radio"
            onChange={(event) => {
              this.props.handleChange(filterLabel, 'Not Required');
            }}
            checked={selectedVal === 'Not Required'}
            name={title}
          />
          {options.nor}
          <span className="checkmark"></span>
        </label>
      </div>
    );
  };

  render() {
    const { sectors, departments, ministries, filterValues, programs, provinces } = this.state;
    const { language } = this.props;

    const listDepartment = [];
    const listMinistries = [];

    // console.log(filterValues)

    if (Object.keys(ministries).length) {
      for (let m of ministries) {
        let ministry = { label: m.name, value: m.id };
        listMinistries.push(ministry);
      }
    }

    if (Object.keys(departments).length > 0 && filterValues.ministry_id[0].value !== 0) {
      let ministry = [...filterValues.ministry_id].map((m) => m.value);
      let dpt = departments.department.filter((d) => ministry.includes(d.ministry_id));
      for (let d of dpt) {
        let check = listDepartment.find((a) => d.title === a.label);
        if (!check)
          listDepartment.push({
            label: d.title,
            value: d.id,
            ministry_id: d.ministry_id,
          });
      }
    }

    const status = [
      { label: 'Save as draft', value: 'draft' },
      { label: 'Send to verification', value: 'published' },
      { label: 'Submit', value: 'completed' },
    ];

    const yearOnYearProject = [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ];

    const projectStatusOptions = [
      { label: 'New', value: 'new' },
      { label: 'Ongoing', value: 'ongoing' },
    ];

    const projectTypeOptions = [
      { label: 'Service Oriented', value: 'service oriented' },
      { label: 'Construction', value: 'construction' },
      { label: 'Production', value: 'production' },
      { label: 'Trade', value: 'trade' },
      { label: 'Others', value: 'others' },
    ];

    const priorityProject = [
      { label: 'National Pride Project', value: 'national pride project' },
      { label: 'Priority 1', value: 'priority 1' },
      { label: 'Priority 2', value: 'priority 2' },
      { label: 'Game Changer', value: 'game changer' },
      { label: 'Others', value: 'other' },
    ];

    const projectRankingOptions = [
      { label: 'A', value: 'A' },
      { label: 'A-', value: 'A-' },
      { label: 'B', value: 'B' },
      { label: 'B-', value: 'B-' },
      { label: 'C', value: 'C' },
      { label: 'C-', value: 'C-' },
      { label: 'D', value: 'D' },
      { label: 'D-', value: 'D-' },
      { label: 'E', value: 'E' },
    ];

    let subSectors = [];

    if (filterValues.sector[0].value !== 0) {
      for (let sector of filterValues.sector) {
        for (let subsector of sector.subSector) {
          subsector.sector = sector.label;
        }
        subSectors = [...subSectors, ...sector.subSector];
      }
    }

    let districts = [];

    if (filterValues.province[0].value !== 0) {
      let provinces = [...filterValues.province].map((m) => m.value);

      let filter = filterValues.provinces.filter((p) => provinces.includes(p.value));

      for (let province of filter) {
        for (let district of province.districts) {
          district.province = province.value;
        }
        districts = [...districts, ...province.districts];
      }
    }

    const ids = [...filterValues.ranking].map((d) => d.value);

    return (
      <div ref={this.setWrapperRef}>
        <div className={`sidebar-filter ${this.state.showSidebar ? '' : 'hidden'}`}>
          <div className="close-button" onClick={this.toggleSidebar}>
            <i className="material-icons close">close</i>
          </div>
          <div className="filter-container">
            <div
              className={`filter-action
										${
                      filterValues.program_id[0].value !== 0 ||
                      filterValues.ministry_id[0].value !== 0 ||
                      filterValues.department[0].value !== 0 ||
                      filterValues.projectStatus.value !== 0 ||
                      filterValues.projectType[0].value !== 0 ||
                      filterValues.priorityProjectOptions[0].value !== 0 ||
                      filterValues.sector[0].value !== 0 ||
                      filterValues.subSector[0].value !== 0 ||
                      filterValues.department[0].value !== 0 ||
                      filterValues.province[0].value !== 0 ||
                      filterValues.district[0].value !== 0 ||
                      filterValues.ranking[0].value !== 0 ||
                      filterValues.isYearlyFunding.value !== 0 ||
                      filterValues.moderationStatus.value !== 0 ||
                      (filterValues.projectCostFrom && filterValues.projectCostTo) ||
                      filterValues.projectStartDateFrom ||
                      filterValues.projectStartDateTo ||
                      filterValues.feasibility_study ||
                      filterValues.detailed_project_report ||
                      filterValues.eia ||
                      filterValues.iee ||
                      filterValues.monitor_evaluation_plan ||
                      filterValues.risk_management_and_mitigation_plan ||
                      filterValues.development_of_logical_frameWork ||
                      filterValues.desk_study ||
                      filterValues.project_concept_note ||
                      filterValues.project_proposal ||
                      filterValues.master_procurement_plan ||
                      filterValues.annual_procurement_plan_for_this_FY ||
                      filterValues.annual_procurement_plan_for_this_coming_FY ||
                      filterValues.project_implementation_plan ||
                      filterValues.procurement_and_contract_award ||
                      filterValues.completion_of_the_legal_process_of_land_acquisition
                        ? 'visibility-visible'
                        : 'visibility-hidden'
                    }
									`}
            >
              <button
                className="btn-rect btn-blue"
                onClick={() => {
                  this.props.searchProject();
                  this.toggleSidebar();
                  this.props.handleApplyFilterStatus();
                }}
              >
                {language.apply_filter}
              </button>
              <button className="btn-text" onClick={this.props.clearFilter}>
                {language.clear_filter}
              </button>
            </div>
            <div className="header">
              <p className="title">{language.filter}</p>
              <p className="collapse" onClick={this.collapseAll}>
                {/* {language.collapse_all_filter} */}
                {!this.state.showMinistryOption &&
                !this.state.showDepartmentOption &&
                !this.state.showProgramOption &&
                !this.state.showProjectStatusOption &&
                !this.state.showProjectTypeOption &&
                !this.state.showProjectPriorityOption &&
                !this.state.showProjectRankingOption &&
                !this.state.showSectorsOption &&
                !this.state.showSubSectorsOption &&
                !this.state.showYearOnYearOption &&
                !this.state.showModerationStatusOption &&
                !this.state.showProjectDateOption &&
                !this.state.showAIEOption &&
                !this.state.showProjectDateOption &&
                !this.state.showDistrictOption &&
                !this.state.showProjectReadinessOption
                  ? language.expand_all
                  : language.collapse_all_filter}
                <i className="material-icons ml-2 md-bold md-blue">{`${
                  !this.state.showMinistryOption &&
                  !this.state.showDepartmentOption &&
                  !this.state.showProgramOption &&
                  !this.state.showProjectStatusOption &&
                  !this.state.showProjectTypeOption &&
                  !this.state.showProjectPriorityOption &&
                  !this.state.showProjectRankingOption &&
                  !this.state.showSectorsOption &&
                  !this.state.showSubSectorsOption &&
                  !this.state.showYearOnYearOption &&
                  !this.state.showModerationStatusOption &&
                  !this.state.showProjectDateOption &&
                  !this.state.showAIEOption &&
                  !this.state.showProjectDateOption &&
                  !this.state.showDistrictOption &&
                  !this.state.showProjectReadinessOption
                    ? 'add'
                    : 'remove'
                }`}</i>
              </p>
            </div>
            {/* Filter Section for Ministries */}
            {['dataViewer', 'npcAdmin'].indexOf(this.props.role) !== -1 && (
              <FilterMultiSection
                title={language.line_ministry}
                toggleFilterOption={() => this.toggleFilterOption('showMinistryOption')}
                showOption={this.state.showMinistryOption}
                placeholder="Type a ministry name ..."
                data={listMinistries}
                handleSelect={this.props.handleSelect}
                selectedVal={filterValues.ministry_id}
                filterLabel={'ministry_id'}
                showSearch={true}
                applied={this.props.applied}
              />
            )}
            {/* Filter Section for Implementing Agency */}
            {filterValues.ministry_id && filterValues.ministry_id[0].value !== 0 && (
              <FilterMultiSection
                title={language.implementing_agency}
                toggleFilterOption={() => this.toggleFilterOption('showDepartmentOption')}
                showOption={this.state.showDepartmentOption}
                placeholder="Type a department name ..."
                data={listDepartment}
                handleSelect={this.props.handleSelect}
                selectedVal={filterValues.department}
                filterLabel={'department'}
                showSearch={true}
                applied={this.props.applied}
              />
            )}
            {/* Filter Section for Programs */}
            <FilterMultiSection
              title={language.program}
              toggleFilterOption={() => this.toggleFilterOption('showProgramOption')}
              showOption={this.state.showProgramOption}
              placeholder="Type a program title..."
              data={programs}
              handleSelect={this.props.handleSelect}
              selectedVal={filterValues.program_id}
              filterLabel={'program_id'}
              showSearch={true}
              applied={this.props.applied}
            />
            {/* Filter Section for Project Status */}
            <FilterSection
              title={language.project_status}
              toggleFilterOption={() => this.toggleFilterOption('showProjectStatusOption')}
              showOption={this.state.showProjectStatusOption}
              placeholder="Type a project status ..."
              data={projectStatusOptions}
              handleSelect={this.props.handleChange}
              selectedVal={filterValues.projectStatus}
              filterLabel={'projectStatus'}
              showSearch={false}
            />
            {/* Filter Section for Project type */}
            <FilterMultiSection
              title={language.project_type}
              toggleFilterOption={() => this.toggleFilterOption('showProjectTypeOption')}
              showOption={this.state.showProjectTypeOption}
              placeholder="Type a project-type title ..."
              data={projectTypeOptions}
              handleSelect={this.props.handleSelect}
              selectedVal={filterValues.projectType}
              filterLabel={'projectType'}
              showSearch={false}
              applied={this.props.applied}
            />
            {/* Filter Section for Project Priority */}
            <FilterMultiSection
              title={language.project_priority}
              toggleFilterOption={() => this.toggleFilterOption('showProjectPriorityOption')}
              showOption={this.state.showProjectPriorityOption}
              placeholder="Type a project priority ..."
              data={priorityProject}
              handleSelect={this.props.handleSelect}
              selectedVal={filterValues.priorityProjectOptions}
              filterLabel={'priorityProjectOptions'}
              showSearch={false}
              applied={this.props.applied}
            />
            {/* Filter Section for Project Ranking */}
            <div className="filter-section">
              <div
                className="header"
                onClick={() => this.toggleFilterOption('showProjectRankingOption')}
              >
                <p className="title">{language.project_ranking}</p>
                <div className="collapse">
                  <i className="material-icons md-bold md-blue">{`${
                    this.state.showProjectRankingOption ? 'remove' : 'add'
                  }`}</i>
                </div>
              </div>
              <div className={`contents ${this.state.showProjectRankingOption ? '' : 'hide'}`}>
                <div className="options">
                  <div className="filter-ranking">
                    {projectRankingOptions.map((r) => (
                      <div key={r.label} className={`bold ${r.label}`}>
                        <label>
                          {r.value}
                          <input
                            type="checkbox"
                            onChange={(event) => {
                              this.props.handleSelect('ranking', r);
                            }}
                            checked={ids.includes(r.value)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Filter section for project readiness */}
            <div className="filter-section">
              <div
                className="header"
                onClick={() => this.toggleFilterOption('showProjectReadinessOption')}
              >
                <p className="title">{language.project_readiness}</p>
                <div className="collapse">
                  <i className="material-icons md-bold md-blue">{`${
                    this.state.showProjectReadinessOption ? 'remove' : 'add'
                  }`}</i>
                </div>
              </div>
              <div className={`contents ${this.state.showProjectReadinessOption ? '' : 'hide'}`}>
                <div className="options">
                  <ScrollArea speed={0.8} horizontal={false}>
                    {this.readinessChildComp(
                      language.feasibility_study,
                      'feasibility_study',
                      filterValues.feasibility_study,
                      {
                        yes: language.yes,
                        no: language.no,
                        nor: language.not_required,
                      }
                    )}
                    {this.readinessChildComp(
                      language.detailed_project_report,
                      'detailed_project_report',
                      filterValues.detailed_project_report,
                      {
                        yes: language.yes,
                        no: language.no,
                        nor: language.not_required,
                      }
                    )}
                    {this.readinessChildComp(language.eia, 'eia', filterValues.eia, {
                      yes: language.yes,
                      no: language.no,
                      nor: language.not_required,
                    })}
                    {this.readinessChildComp(language.iee, 'iee', filterValues.iee, {
                      yes: language.yes,
                      no: language.no,
                      nor: language.not_required,
                    })}
                    {this.readinessChildComp(
                      language.monitor_evaluation_plan,
                      'monitor_evaluation_plan',
                      filterValues.monitor_evaluation_plan,
                      {
                        yes: language.yes,
                        no: language.no,
                        nor: language.not_required,
                      }
                    )}
                    {this.readinessChildComp(
                      language.risk_management_and_mitigation_plan,
                      'risk_management_and_mitigation_plan',
                      filterValues.risk_management_and_mitigation_plan,
                      {
                        yes: language.yes,
                        no: language.no,
                        nor: language.not_required,
                      }
                    )}
                    {this.readinessChildComp(
                      language.development_of_logical_frameWork,
                      'development_of_logical_frameWork',
                      filterValues.development_of_logical_frameWork,
                      {
                        yes: language.yes,
                        no: language.no,
                        nor: language.not_required,
                      }
                    )}
                    {this.readinessChildComp(
                      language.desk_study,
                      'desk_study',
                      filterValues.desk_study,
                      {
                        yes: language.yes,
                        no: language.no,
                        nor: language.not_required,
                      }
                    )}
                    {this.readinessChildComp(
                      language.project_concept_note,
                      'project_concept_note',
                      filterValues.project_concept_note,
                      {
                        yes: language.yes,
                        no: language.no,
                        nor: language.not_required,
                      }
                    )}
                    {this.readinessChildComp(
                      language.project_proposal,
                      'project_proposal',
                      filterValues.project_proposal,
                      {
                        yes: language.yes,
                        no: language.no,
                        nor: language.not_required,
                      }
                    )}
                    {this.readinessChildComp(
                      language.master_procurement_plan,
                      'master_procurement_plan',
                      filterValues.master_procurement_plan,
                      {
                        yes: language.yes,
                        no: language.no,
                        nor: language.not_required,
                      }
                    )}
                    {this.readinessChildComp(
                      language.annual_procurement_plan_for_this_FY,
                      'annual_procurement_plan_for_this_FY',
                      filterValues.annual_procurement_plan_for_this_FY,
                      {
                        yes: language.yes,
                        no: language.no,
                        nor: language.not_required,
                      }
                    )}
                    {this.readinessChildComp(
                      language.annual_procurement_plan_for_this_coming_FY,
                      'annual_procurement_plan_for_this_coming_FY',
                      filterValues.annual_procurement_plan_for_this_coming_FY,
                      {
                        yes: language.yes,
                        no: language.no,
                        nor: language.not_required,
                      }
                    )}
                    {this.readinessChildComp(
                      language.project_implementation_plan,
                      'project_implementation_plan',
                      filterValues.project_implementation_plan,
                      {
                        yes: language.yes,
                        no: language.no,
                        nor: language.not_required,
                      }
                    )}
                    {this.readinessChildComp(
                      language.procurement_and_contract_award,
                      'procurement_and_contract_award',
                      filterValues.procurement_and_contract_award,
                      {
                        yes: language.yes,
                        no: language.no,
                        nor: language.not_required,
                      }
                    )}
                    {this.readinessChildComp(
                      language.completion_of_the_legal_process_of_land_acquisition,
                      'completion_of_the_legal_process_of_land_acquisition',
                      filterValues.completion_of_the_legal_process_of_land_acquisition,
                      {
                        yes: language.yes,
                        no: language.no,
                        nor: language.not_required,
                      }
                    )}
                  </ScrollArea>
                </div>
              </div>
            </div>

            {/* Filter Section for sector */}
            <FilterMultiSection
              label="Sectors"
              title={language.sector}
              toggleFilterOption={() => this.toggleFilterOption('showSectorsOption')}
              showOption={this.state.showSectorsOption}
              placeholder="Type a Sector ..."
              data={sectors}
              handleSelect={this.props.handleSelect}
              selectedVal={filterValues.sector}
              filterLabel={'sector'}
              showSearch={false}
              applied={this.props.applied}
            />
            {/* Filter Section for subsector */}
            {subSectors.length > 0 && (
              <FilterMultiSection
                label="Sub Sectors"
                title={language.sub_sector}
                toggleFilterOption={() => this.toggleFilterOption('showSubSectorsOption')}
                showOption={this.state.showSubSectorsOption}
                placeholder="Type a Sub Sector ..."
                data={subSectors}
                handleSelect={this.props.handleSelect}
                selectedVal={filterValues.subSector}
                filterLabel={'subSector'}
                showSearch={false}
                applied={this.props.applied}
              />
            )}
            {/* Filter Section for Province */}
            <FilterMultiSection
              title={language.province}
              toggleFilterOption={() => this.toggleFilterOption('showProvinceOption')}
              showOption={this.state.showProvinceOption}
              placeholder="Type a Province name ..."
              data={provinces}
              handleSelect={this.props.handleSelect}
              selectedVal={filterValues.province}
              filterLabel={'province'}
              showSearch={false}
              applied={this.props.applied}
            />
            {/* Filter Section for districts */}
            {districts.length > 0 && (
              <FilterMultiSection
                title={language.district}
                toggleFilterOption={() => this.toggleFilterOption('showDistrictOption')}
                showOption={this.state.showDistrictOption}
                placeholder="Type a district name ..."
                data={districts}
                handleSelect={this.props.handleSelect}
                selectedVal={filterValues.district}
                filterLabel={'district'}
                showSearch={true}
                applied={this.props.applied}
              />
            )}
            {/* Filter Section for Year-on-year */}
            <FilterSection
              title={language.is_yearly_funding}
              toggleFilterOption={() => this.toggleFilterOption('showYearOnYearOption')}
              showOption={this.state.showYearOnYearOption}
              placeholder="Type a year-on-year ..."
              data={yearOnYearProject}
              handleSelect={this.props.handleChange}
              selectedVal={filterValues.isYearlyFunding}
              filterLabel={'isYearlyFunding'}
            />
            {/* Filter Section for Moderation status */}
            <FilterSection
              title={language.status}
              toggleFilterOption={() => this.toggleFilterOption('showModerationStatusOption')}
              showOption={this.state.showModerationStatusOption}
              placeholder="Type a moderation status ..."
              data={status}
              handleSelect={this.props.handleChange}
              selectedVal={filterValues.moderationStatus}
              filterLabel={'moderationStatus'}
            />
            <div className="filter-section">
              <div
                className="header"
                onClick={() => this.toggleFilterOption('showProjectDateOption')}
              >
                <p className="title">{language.project_date}</p>
                <div className="collapse">
                  <i className="material-icons md-bold md-blue">{`${
                    this.state.showProjectDateOption ? 'remove' : 'add'
                  }`}</i>
                </div>
              </div>
              <div className={`contents ${this.state.showProjectDateOption ? '' : 'hide'}`}>
                <div className="options">
                  <div className="option-form">
                    <label>From</label>
                    <Input
                      type="date"
                      id="projectStartDateAD"
                      onChange={(e) =>
                        this.props.handleDateChange('projectStartDateFrom', e.target.value)
                      }
                      value={filterValues.projectStartDateFrom}
                    />
                  </div>
                  <div className="option-form">
                    <label>To</label>
                    <Input
                      type="date"
                      id="projectEndDateAD"
                      onChange={(e) =>
                        this.props.handleDateChange('projectStartDateTo', e.target.value)
                      }
                      value={filterValues.projectStartDateTo}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-section">
              <div className="header" onClick={() => this.toggleFilterOption('showAIEOption')}>
                <p className="title">{language.original_cost_estimate}</p>
                <div className="collapse">
                  <i className="material-icons md-bold md-blue">{`${
                    this.state.showAIEOption ? 'remove' : 'add'
                  }`}</i>
                </div>
              </div>
              <div className={`contents ${this.state.showAIEOption ? '' : 'hide'}`}>
                <div className="option-form">
                  <label>From</label>
                  <Input
                    placeholder="From: Project cost"
                    type="text"
                    id="projectStartDateAD"
                    onChange={(e) => this.props.handleDateChange('projectCostFrom', e.target.value)}
                    value={filterValues.projectCostFrom || ''}
                    width={4}
                  />
                </div>
                <div className="option-form">
                  <label>To</label>
                  <Input
                    placeholder="To: Project cost"
                    type="text"
                    id="projectEndDateAD"
                    onChange={(e) => this.props.handleDateChange('projectCostTo', e.target.value)}
                    value={filterValues.projectCostTo || ''}
                    width={4}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="btn-normal btn-blue btn-shadow--blue btn-transition btn-filter"
          onClick={this.toggleSidebar}
        >
          <i className="material-icons md-18 md-bold mr-6">filter_list</i>
          {language.filter}
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedLanguage: state.localization.selectedLanguage,
  auth: state.auth,
  ministries: state.ministry.ministries,
  departments: state.department.departments,
  provinces: state.project.provinces,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Filter));
