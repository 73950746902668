export function fetching() {
  return { type: 'FETCHING' };
}

export function fetched(payload) {
  return { type: 'GENERAL_INFO_FETCHED', payload };
}

export function fetchedNullData(payload) {
  return { type: 'NULL_GENERAL_INFO_FETCHED' ,payload};
}
