import fiscalYear from "../../components/data/fiscalYear";
import DateConvert from "../../components/DateConverter/DateConverter";

function calculateCurrentFiscalYear(year = null) {
	let currentYear;
	fiscalYear.map((year, index) => {
		const currentDate = new Date();
		let currentYearBool = year.value.includes(
			DateConvert({ englishDate: currentDate }).en.year
		);
		if (currentYearBool) {
			currentYear = year;
		}
	});
	return currentYear;
}

export default calculateCurrentFiscalYear;
