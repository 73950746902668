import React, { Component } from 'react';
import { Button, Header, Modal, Pagination, Table } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { cloneDeep, map } from 'lodash';
import query from 'query-string';

import * as ProjectAction from '../../reducers/form/action';
import * as MinistryAction from '../../reducers/ministry/action';

import empty from '../../assets/images/no-data.svg';
import LoaderNP from '../Loader/Loader';
import { localization } from '../../localization';

import * as DepartmentAction from '../../reducers/department/action';
import MinsitryApi from '../../services/Ministry';
import ProjectService from '../../services/Form';
import ProgramApi from '../../services/Program';
import DepartmentApi from '../../services/Department';

import SectorHandle from '../GeneralInformation/sectorHandleList';
import Can from '../Can/can';

import Filter from '../filter-new/FilterNew';
import PieChart from '../Chart/PieChart';
import PieChartLegend from '../Chart/PieChartLegend';
import { PROJECT_STATUS } from '../../helpers/constant/ProjectStatus';
import ROLES from '../../helpers/constant/Roles';

// import ProjectMap from '../../pages/ProjectMap';

class ProjectList extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.child = React.createRef();
  }

  state = {
    modalOpen: false,
    chartModalOpen: false,
    modalData: {},
    projectId: '',
    selectedLanguage: '',
    language: {},
    program_id: [{ label: 'Select a Program', value: 0 }],
    programMsg: null,
    ministry_id: [{ label: 'Select a Ministry', value: 0 }],
    projectStatus: { label: 'Select Project Status', value: 0 },
    moderationStatus: { label: 'Select Moderation Status', value: 0 },
    projectType: [{ label: 'Select Project Type', value: 0 }],
    priorityProjectOptions: [{ label: 'Select Project Priority', value: 0 }],
    ministries: {},
    activePage: 1,
    column: null,
    direction: null,
    sector: [{ label: 'Select a Sector', value: 0 }],
    sectors: [],
    subSector: [{ label: 'Select a Sub-Sector', value: 0 }],
    subSectors: [],
    department: [{ label: 'Select a Agency', value: 0 }],
    province: [{ label: 'Select a Province', value: 0 }],
    district: [{ label: 'Select a District', value: 0 }],
    isYearlyFunding: { label: 'Select an year-on-year', value: 0 },
    ranking: [{ label: '', value: 0 }],
    projectCostFrom: null,
    projectCostTo: null,
    projectStartDateFrom: '',
    projectStartDateTo: '',
    showAdvanceFilter: false,
    operationOption: '',
    showOperationOptions: false,
    feasibility_study: '',
    detailed_project_report: '',
    eia: '',
    iee: '',
    monitor_evaluation_plan: '',
    risk_management_and_mitigation_plan: '',
    development_of_logical_frameWork: '',
    desk_study: '',
    project_concept_note: '',
    project_proposal: '',
    master_procurement_plan: '',
    annual_procurement_plan_for_this_FY: '',
    annual_procurement_plan_for_this_coming_FY: '',
    project_implementation_plan: '',
    procurement_and_contract_award: '',
    completion_of_the_legal_process_of_land_acquisition: '',
    showView: 'table',
  };

  componentDidMount() {
    const keyValue = query.parse(this.props.history.location.search);

    if (keyValue && keyValue.ranking) {
      this.searchProject(null, {
        ranking: keyValue.ranking,
        status: keyValue.status,
      });
    } else {
      this.props.listProjects(`?page=0&pageSize=50`);
      this.props.listProjectsForMap();
    }

    this.props.listProvinces();
    this.props.listDepartments({
      ministryId: this.props.auth.user.ministry.id,
    });

    if ([ROLES.dataViewer, ROLES.npcAdmin].indexOf(this.props.auth.user.role) !== -1) {
      this.props.listMinistries();
    }

    this.fetchProgramList();

    const lang = localization[this.props.selectedLanguage];

    const sectors = SectorHandle(lang, []);
    sectors.unshift({ label: 'Select a Sector', value: 0 });

    this.setState({
      language: lang.project_filter,
      selectedLanguage: this.props.selectedLanguage,
      sectors,
    });
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  componentDidUpdate(nextProps, prevState) {
    if (this.state.selectedLanguage !== this.props.selectedLanguage) {
      const lang = localization[this.props.selectedLanguage];
      this.setState({
        language: lang.project_filter,
        selectedLanguage: this.props.selectedLanguage,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleOutsideClick = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showAdvanceFilter: false });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const data = {};
    if (nextProps.ministries && nextProps.ministries !== prevState.ministries) {
      if ([ROLES.dataViewer, ROLES.npcAdmin].indexOf(nextProps.auth.user.role) === -1) {
        data.ministry_id = [
          {
            label: nextProps.auth.user.ministry.name,
            value: nextProps.auth.user.ministry.id,
          },
        ];
      }
      data.ministries = nextProps.ministries;
    }
    if (nextProps.departments !== prevState.departments) {
      data.departments = nextProps.departments;
    }

    if (nextProps.provinces !== prevState.provinces) {
      data.provinces = nextProps.provinces;
    }

    const keyValue = query.parse(nextProps.history.location.search);

    if (keyValue && keyValue.ranking && keyValue.ranking !== prevState.ranking) {
      data.ranking = [{ label: keyValue.ranking, value: keyValue.ranking }];
      data.applied = true;
    }

    if (keyValue && keyValue.status && keyValue.status !== prevState.status) {
      data.projectStatus = { label: keyValue.status, value: keyValue.status };
    }

    return data;
  }

  fetchProgramList = () => {
    ProgramApi.listPrograms({ ministryId: this.props.auth.user.ministry.id })
      .then((response) => {
        this.setState({ programs: response });
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  deleteProject(e) {
    e.preventDefault();
    this.props.deleteProject({ id: this.state.projectId });
    this.setState({ modalOpen: !this.state.modalOpen });
  }

  getQueryString = () => {
    const {
      search,
      program_id,
      ministry_id,
      projectStatus,
      projectType,
      priorityProjectOptions,
      projectStartDateFrom,
      projectStartDateTo,
      subSector,
      sector,
      department,
      province,
      district,
      isYearlyFunding,
      projectCostFrom,
      projectCostTo,
      moderationStatus,
      ranking,
      status,
      feasibility_study,
      detailed_project_report,
      eia,
      iee,
      monitor_evaluation_plan,
      risk_management_and_mitigation_plan,
      development_of_logical_frameWork,
      desk_study,
      project_concept_note,
      project_proposal,
      master_procurement_plan,
      annual_procurement_plan_for_this_FY,
      annual_procurement_plan_for_this_coming_FY,
      project_implementation_plan,
      procurement_and_contract_award,
      completion_of_the_legal_process_of_land_acquisition,
    } = this.state;

    const joinValues = (arr, type = false) => {
      return type ? map(arr, 'label').join(':') : map(arr, 'value').join(':');
    };

    const appliedFilters = [];

    let queryString = '?';
    if (search && search !== '') {
      queryString = `${queryString}search=${search.trim()}&`;
    }
    if (program_id[0].value !== 0) {
      appliedFilters.push({ program_id });
      queryString = `${queryString}program_id=${joinValues(program_id)}&`;
    }
    if (projectStatus.value !== 0) {
      queryString = `${queryString}project_status=${projectStatus.value}&`;
    }
    if (ministry_id[0].value !== 0) {
      appliedFilters.push({ ministry_id });
      queryString = `${queryString}ministry_id=${joinValues(ministry_id)}&`;
    }
    if (projectType[0].value !== 0) {
      appliedFilters.push({ projectType });
      queryString = `${queryString}projectType=${joinValues(projectType)}&`;
    }
    if (priorityProjectOptions[0].value !== 0) {
      appliedFilters.push({ priorityProjectOptions });
      queryString = `${queryString}priorityProjectOptions=${joinValues(priorityProjectOptions)}&`;
    }
    if (projectStartDateFrom && projectStartDateFrom !== '') {
      queryString = `${queryString}projectStartDateFrom=${projectStartDateFrom}&`;
    }
    if (projectStartDateTo && projectStartDateTo !== '') {
      queryString = `${queryString}projectStartDateTo=${projectStartDateTo}&`;
    }
    if (sector[0].value !== 0) {
      appliedFilters.push({ sector });
      queryString = `${queryString}sector=${joinValues(sector, true)}&`;
    }
    if (subSector[0].value !== 0) {
      appliedFilters.push({ subSector });
      queryString = `${queryString}subSector=${joinValues(subSector, true)}&`;
    }
    if (department[0].value !== 0) {
      appliedFilters.push({ department });
      queryString = `${queryString}department=${joinValues(department)}&`;
    }
    if (province[0].value !== 0) {
      appliedFilters.push({ province });
      queryString = `${queryString}province=${joinValues(province)}&`;
    }
    if (district[0].value !== 0) {
      appliedFilters.push({ district });
      queryString = `${queryString}district=${joinValues(district)}&`;
    }
    if (isYearlyFunding.value !== 0) {
      queryString = `${queryString}isYearlyFunding=${isYearlyFunding.value}&`;
    }
    if (projectCostFrom && projectCostTo) {
      queryString = `${queryString}projectCostFrom=${projectCostFrom}&projectCostTo=${projectCostTo}&`;
    }
    if (moderationStatus.value !== 0) {
      queryString = `${queryString}moderationStatus=${moderationStatus.value}&`;
    }
    if (ranking[0].value !== 0) {
      appliedFilters.push({ ranking });
      queryString = `${queryString}ranking=${joinValues(ranking)}&`;
    }
    if (feasibility_study !== '') {
      queryString = `${queryString}feasibility_study=${feasibility_study}&`;
    }
    if (detailed_project_report !== '') {
      queryString = `${queryString}detailed_project_report=${detailed_project_report}&`;
    }
    if (eia !== '') {
      queryString = `${queryString}eia=${eia}&`;
    }
    if (iee !== '') {
      queryString = `${queryString}iee=${iee}&`;
    }
    if (monitor_evaluation_plan !== '') {
      queryString = `${queryString}monitor_evaluation_plan=${monitor_evaluation_plan}&`;
    }
    if (risk_management_and_mitigation_plan !== '') {
      queryString = `${queryString}risk_management_and_mitigation_plan=${risk_management_and_mitigation_plan}&`;
    }
    if (development_of_logical_frameWork !== '') {
      queryString = `${queryString}development_of_logical_frameWork=${development_of_logical_frameWork}&`;
    }

    if (desk_study !== '') {
      queryString = `${queryString}desk_study=${desk_study}&`;
    }
    if (project_concept_note !== '') {
      queryString = `${queryString}project_concept_note=${project_concept_note}&`;
    }
    if (project_proposal !== '') {
      queryString = `${queryString}project_proposal=${project_proposal}&`;
    }
    if (master_procurement_plan !== '') {
      queryString = `${queryString}master_procurement_plan=${master_procurement_plan}&`;
    }
    if (annual_procurement_plan_for_this_FY !== '') {
      queryString = `${queryString}annual_procurement_plan_for_this_FY=${annual_procurement_plan_for_this_FY}&`;
    }
    if (annual_procurement_plan_for_this_coming_FY !== '') {
      queryString = `${queryString}annual_procurement_plan_for_this_coming_FY=${annual_procurement_plan_for_this_coming_FY}&`;
    }
    if (project_implementation_plan !== '') {
      queryString = `${queryString}project_implementation_plan=${project_implementation_plan}&`;
    }
    if (procurement_and_contract_award !== '') {
      queryString = `${queryString}procurement_and_contract_award=${procurement_and_contract_award}&`;
    }
    if (completion_of_the_legal_process_of_land_acquisition !== '') {
      queryString = `${queryString}completion_of_the_legal_process_of_land_acquisition=${completion_of_the_legal_process_of_land_acquisition}&`;
    }

    this.setState({ appliedFilters });

    return queryString;
  };

  searchProject = (e) => {
    if (e) e.preventDefault();
    const { activePage, column, direction, showView } = this.state;

    let queryString = this.getQueryString();

    if (showView === 'map') {
      this.props.listProjectsForMap(queryString);
    }
    if (this.props.handleParams) this.props.handleParams(queryString);

    if (column) {
      queryString = `${queryString}column=${column}&sort=${direction}&`;
    }

    queryString = `${queryString}page=${activePage - 1}&pageSize=50`;
    this.props.listProjects(queryString);
  };

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage }, () => {
      this.searchProject(e);
    });
  };

  handleSelect = (key, option) => {
    if (key === 'ranking' && query.parse(this.props.history.location.search)) {
      this.props.history.push({
        pathname: '/dashboard',
        search: '',
      });
    }
    let arr = [];
    const childFilters = {
      ministry_id: 'department',
      province: 'district',
      sector: 'subSector',
    };

    if (key === 'sector' || key === 'subSector') {
      if (typeof this.state[key][0].value !== 'number') {
        arr = [...this.state[key]];
        const check = arr.find((d) => d.label === option.label);
        if (check) arr = arr.filter((d) => d.label !== option.label);
        else arr.push(option);
        if (arr.length < 1) arr = [{ value: 0 }];
      } else {
        arr.push(option);
      }
    } else if (this.state[key][0].value !== 0) {
      arr = [...this.state[key]];
      const check = arr.find((d) => d.value === option.value);
      if (check) arr = arr.filter((d) => d.value !== option.value);
      else arr.push(option);
      if (arr.length < 1) arr = [{ value: 0 }];
    } else {
      arr.push(option);
    }
    if (key === 'ministry_id' || key === 'province' || key === 'sector') {
      const childFilter = this.state[childFilters[key]].filter((d) =>
        key === 'sector' ? d[key] !== option.label : d[key] !== option.value
      );
      if (childFilter.length === 0) this.setState({ [childFilters[key]]: [{ value: 0 }] });
      else {
        this.setState({ [childFilters[key]]: childFilter });
      }
    }

    this.setState({ [key]: arr, activePage: 1 });
  };

  handleChange = (key, option) => {
    if (key === 'projectStatus' && query.parse(this.props.history.location.search)) {
      this.props.history.push({
        pathname: '/dashboard',
        search: '',
      });
    }
    this.setState({ [key]: option, activePage: 1 });
  };

  handleSort = (clickedColumn) => () => {
    const { column, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState(
        {
          column: clickedColumn,
          direction: 'ascending',
          activePage: 1,
        },
        () => this.searchProject(null)
      );

      return;
    }

    this.setState(
      {
        direction: direction === 'ascending' ? 'descending' : 'ascending',
        activePage: 1,
      },
      () => this.searchProject(null)
    );
  };

  handleDateChange = (key, value) => {
    this.setState({ [key]: value });
  };

  clearFilter = () => {
    if (query.parse(this.props.history.location.search)) {
      this.props.history.push({
        pathname: '/dashboard',
        search: '',
      });
    }
    const { user } = this.props.auth;
    let ministry_id = [{ label: 'Select a Ministry', value: 0 }];
    if ([ROLES.dataViewer, ROLES.npcAdmin].indexOf(user.role) === -1) {
      ministry_id = [
        {
          label: user.ministry.name,
          value: user.ministry.id,
        },
      ];
    }
    this.setState(
      {
        applied: false,
        program_id: [{ label: 'Select a Program', value: 0 }],
        ministry_id,
        projectStatus: { label: 'Select Project Status', value: 0 },
        moderationStatus: { label: 'Select Moderation Status', value: 0 },
        projectType: [{ label: 'Select Project Type', value: 0 }],
        priorityProjectOptions: [{ label: 'Select Project Priority', value: 0 }],
        sector: [{ label: 'Select a Sector', value: 0 }],
        subSector: [{ label: 'Select a Sub-Sector', value: 0 }],
        department: [{ label: 'Select a Agency', value: 0 }],
        province: [{ label: 'Select a Province', value: 0 }],
        district: [{ label: 'Select a District', value: 0 }],
        isYearlyFunding: { label: 'Select an year-on-year', value: 0 },
        ranking: [{ label: '', value: 0 }],
        projectCostFrom: null,
        projectCostTo: null,
        projectStartDateFrom: '',
        projectStartDateTo: '',
        feasibility_study: '',
        detailed_project_report: '',
        eia: '',
        iee: '',
        monitor_evaluation_plan: '',
        risk_management_and_mitigation_plan: '',
        development_of_logical_frameWork: '',
        desk_study: '',
        project_concept_note: '',
        project_proposal: '',
        master_procurement_plan: '',
        annual_procurement_plan_for_this_FY: '',
        annual_procurement_plan_for_this_coming_FY: '',
        project_implementation_plan: '',
        procurement_and_contract_award: '',
        completion_of_the_legal_process_of_land_acquisition: '',
      },
      () => {
        this.searchProject(null);
        // this.props.handleParams('');
      }
    );
  };

  exportExcel = async () => {
    const queryString = this.getQueryString();

    const data = await ProjectService.exportProjects(queryString);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('type', 'hidden');
    link.setAttribute('download', 'projectList.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  handleRoute = (projectID) => {
    this.props.history.push(`/project/${projectID}`);
  };

  showFiltertag = (filter, label) => {
    const filterWithLabel = {
      isYearlyFunding: 'Year-on-Year Project',
      feasibility_study: 'Feasibility study',
      detailed_project_report: 'Detailed project plan',
      eia: 'EIA',
      iee: 'IEE',
      monitor_evaluation_plan: 'Monitoring evaluation plan',
      risk_management_and_mitigation_plan: 'Risk management and mitigation plan',
      development_of_logical_frameWork: 'Development of Logical framework',
      desk_study: 'Desk Study',
      project_concept_note: 'Project concept note',
      project_proposal: 'Project Proposal',
      master_procurement_plan: 'Master Procurement Plan',
      annual_procurement_plan_for_this_FY: 'Annual Procurement Plan For This Fiscal Year',
      annual_procurement_plan_for_this_coming_FY:
        'Annual Procurement Plan For This Upcoming Fiscal Year',
      project_implementation_plan: 'Project Implementation Plan',
      procurement_and_contract_award: 'Procurement and Contract Award',
      completion_of_the_legal_process_of_land_acquisition:
        'Completion of the legal process of land acquisition',
      projectStatus: 'Project Status',
      moderationStatus: 'Moderation Status',
    };

    const multiSelectFilter = {
      ministry_id: 'Ministry',
      department: 'Agency',
      projectType: 'Project Type',
      province: 'Province',
      district: 'District',
      program_id: 'Program',
      priorityProjectOptions: 'Project Priority',
      sector: 'Sector',
      subSector: 'Sub-Sector',
      ranking: 'Ranking',
    };

    return filterWithLabel[filter] ? (
      <div className="tag">
        <span onClick={() => this.handleRemoveFilter(filter)}>
          <i className="material-icons">close</i>
        </span>
        {`${filterWithLabel[filter]}: ${label}`}
      </div>
    ) : Array.isArray(label) ? (
      <div key={filter} className="tag">
        {multiSelectFilter[filter]}:
        {label.map((l, i) => (
  <div key={i} className="sub-tag">
            <span
      onClick={() =>
                this.handleRemoveFilter(
                  filter,
                  filter === 'sector' || filter === 'subSector' ? l.label : l.value
                )
              }
    >
      <i className="material-icons">close</i>
    </span>
            {l.label}
          </div>
        ))}
      </div>
    ) : (
      <div className="tag">
        <span onClick={() => this.handleRemoveFilter(filter)}>
          <i className="material-icons">close</i>
        </span>
        {label}
      </div>
    );
  };

  handleRemoveFilter = (filter, value = 0) => {
    if (query.parse(this.props.history.location.search)) {
      this.props.history.push({
        pathname: '/dashboard',
        search: '',
      });
    }
    const readiness = [
      'feasibility_study',
      'detailed_project_report',
      'eia',
      'iee',
      'monitor_evaluation_plan',
      'risk_management_and_mitigation_plan',
      'development_of_logical_frameWork',
      'desk_study',
      'project_concept_note',
      'project_proposal',
      'master_procurement_plan',
      'annual_procurement_plan_for_this_FY',
      'annual_procurement_plan_for_this_coming_FY',
      'project_implementation_plan',
      'procurement_and_contract_award',
      'completion_of_the_legal_process_of_land_acquisition',
    ];
    const multiSelect = [
      'province',
      'district',
      'ministry_id',
      'department',
      'program_id',
      'projectType',
      'priorityProjectOptions',
      'sector',
      'subSector',
      'ranking',
    ];
    const childFilters = {
      ministry_id: 'department',
      province: 'district',
      sector: 'subSector',
    };

    if (filter === 'removeCostFilter') {
      this.setState({ projectCostFrom: null, projectCostTo: null }, () => this.searchProject(null));
    } else if (filter === 'removeDateFilter') {
      this.setState({ projectStartDateFrom: '', projectStartDateTo: '' }, () =>
        this.searchProject(null)
      );
    } else if (readiness.includes(filter)) {
      this.setState({ [filter]: '' }, () => this.searchProject(null));
    } else if (multiSelect.includes(filter)) {
      let arr =
        filter === 'sector' || filter === 'subSector'
          ? this.state[filter].filter((f) => f.label !== value)
          : this.state[filter].filter((f) => f.value !== value);
      if (arr.length === 0) arr = [{ value: 0 }];

      if (filter === 'ministry_id' || filter === 'province' || filter === 'sector') {
        const childFilter = this.state[childFilters[filter]].filter((d) => d[filter] !== value);
        if (childFilter.length === 0) this.setState({ [childFilters[filter]]: [{ value: 0 }] });
        else {
          this.setState({ [childFilters[filter]]: childFilter });
        }
      }

      this.setState({ [filter]: arr }, () => this.searchProject(null));
    } else {
      this.setState({ [filter]: { value: 0 } }, () => this.searchProject(null));
    }
  };

  handleApplyFilterStatus = () => {
    const queryString = this.getQueryString();
    this.props.listProjectsForMap(queryString);
    this.setState({ applied: true });
  };

  checkForAppliedFilters = () => {
    return (
      (this.state.appliedFilters && this.state.appliedFilters.length) ||
      this.state.projectStatus.value !== 0 ||
      this.state.isYearlyFunding.value !== 0 ||
      this.state.moderationStatus.value !== 0 ||
      (this.state.projectCostFrom && this.state.projectCostTo) ||
      this.state.projectStartDateFrom ||
      this.state.projectStartDateTo ||
      this.state.feasibility_study !== '' ||
      this.state.detailed_project_report !== '' ||
      this.state.eia !== '' ||
      this.state.iee !== '' ||
      this.state.monitor_evaluation_plan !== '' ||
      this.state.risk_management_and_mitigation_plan !== '' ||
      this.state.development_of_logical_frameWork !== '' ||
      this.state.desk_study !== '' ||
      this.state.project_concept_note !== '' ||
      this.state.project_proposal !== '' ||
      this.state.master_procurement_plan !== '' ||
      this.state.annual_procurement_plan_for_this_FY !== '' ||
      this.state.annual_procurement_plan_for_this_coming_FY !== '' ||
      this.state.project_implementation_plan !== '' ||
      this.state.procurement_and_contract_award !== '' ||
      this.state.completion_of_the_legal_process_of_land_acquisition !== ''
    );
  };

  setView = (view) => {
    this.setState({ showView: view }, () => {
      if (view === 'map') {
        const queryString = this.getQueryString();
        this.props.listProjectsForMap(queryString);
      }
    });
  };

  render() {
    const { projects, count } = this.props.projectList;
    const { user } = this.props.auth;
    const {
      language,
      projectStatus,
      ministry_id,
      programs,
      activePage,
      column,
      direction,
      projectStartDateFrom,
      projectStartDateTo,
      isYearlyFunding,
      projectCostFrom,
      projectCostTo,
      moderationStatus,
      programMsg,
      applied,
      feasibility_study,
      detailed_project_report,
      eia,
      iee,
      monitor_evaluation_plan,
      risk_management_and_mitigation_plan,
      development_of_logical_frameWork,
      desk_study,
      project_concept_note,
      project_proposal,
      master_procurement_plan,
      annual_procurement_plan_for_this_FY,
      annual_procurement_plan_for_this_coming_FY,
      project_implementation_plan,
      procurement_and_contract_award,
      completion_of_the_legal_process_of_land_acquisition,
      appliedFilters,
      showView,
    } = this.state;

    const {
      projectYearOnYear,
      totalProjectYearOnYear,
      projectStatusChartInfo,
      projectsStatus,
      yearOnYearLoading,
      statusLoading,
    } = this.props;

    let programsList = programs;

    if (programs && ministry_id[0].value !== 0) {
      const ministry = [...ministry_id].map((m) => m.value);
      const filter = programs.filter((p) => ministry.includes(p.ministry_id));
      programsList = cloneDeep(filter);
    }

    return (
      <>
        <button
          className="btn-normal btn-dark btn-shadow--dark btn-transition btn-export"
          onClick={this.exportExcel}
        >
          <i className="material-icons md-normal mr-6">system_update_alt</i>
          {language.export_data}
        </button>

        <Filter
          handleSelect={this.handleSelect}
          handleChange={this.handleChange}
          filterValues={this.state}
          programs={programsList}
          handleDateChange={this.handleDateChange}
          searchProject={this.searchProject}
          clearFilter={this.clearFilter}
          language={language}
          handleApplyFilterStatus={this.handleApplyFilterStatus}
          role={this.props.auth.user.role}
          applied={this.state.applied}
        />
        <div className="flex align-center chart-search-wrapper">
          <div>
            <form onSubmit={this.searchProject}>
              <div className="form-search">
                <div className="field-search">
                  <input
                    className="input-text input-icon"
                    onChange={(e) => this.setState({ search: e.target.value, activePage: 1 })}
                    type="text"
                    placeholder="Search by typing project id, title or budget code..."
                  />
                  <i className="material-icons md-24">search</i>
                  <button className="button" type="submit">
                    {language.search}
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="pie-chart-loader">
            {yearOnYearLoading ? (
              <LoaderNP />
            ) : (
              <>
                {projectYearOnYear && projectYearOnYear.length > 0 ? (
                  <>
                    <div
                      className="pie-chart-wrapper flex align-center"
                      onClick={() => {
                        this.setState({
                          chartModalOpen: !this.state.chartModalOpen,
                          modalData: {
                            data: projectYearOnYear,
                            title: language.is_yearly_funding,
                            colors: ['#CACFD7', '#9AA2AF'],
                          },
                        });
                      }}
                    >
                      <PieChart data={projectYearOnYear} colors={['#CACFD7', '#9AA2AF']} />
                      <div className="flex">
                        {projectYearOnYear &&
                          projectYearOnYear.map((stat, index) => {
                            if (stat.name === 'Yes') {
                              return (
                                <div key={index} className="chart-stat flex">
                                  <div>
                                    <p className="chart-stat-info">
                                      {parseFloat(
                                        (stat.count / totalProjectYearOnYear) * 100
                                      ).toFixed(2)}
                                      % <span className="label">year-on-year projects</span>
                                    </p>
                                    <p className="chart-stat-amount">({stat.count})</p>
                                  </div>
                                </div>
                              );
                            }
                          })}
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </>
            )}
          </div>

          <div className="pie-chart-loader">
            {statusLoading ? (
              <LoaderNP />
            ) : (
              <>
                {projectsStatus && projectsStatus.length > 0 ? (
                  <>
                    <div
                      className="pie-chart-wrapper flex align-center"
                      onClick={() => {
                        this.setState({
                          chartModalOpen: !this.state.chartModalOpen,
                          modalData: {
                            data: projectsStatus,
                            title: language.project_status,
                            colors: ['#EE8282', '#F9BB81', '#88C26D'],
                          },
                        });
                      }}
                    >
                      <PieChart data={projectsStatus} colors={['#EE8282', '#F9BB81', '#88C26D']} />
                      <div className="flex">
                        {projectStatusChartInfo &&
                          projectStatusChartInfo.map((stat, index) => {
                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <div key={index} className="chart-stat flex">
                                <i className={`indicator ${stat.label}`} />
                                <div>
                                  <p className="chart-stat-info">
                                    {stat.percentage}
%
<span className="label">{stat.label}</span>
                                  </p>
                                  <p className="chart-stat-amount">({stat.count})</p>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        </div>

        {applied && (
          <div className="filter-tags">
            {this.checkForAppliedFilters() ? <p>{language.filter_applied}: </p> : ''}

            <div className="tags-list">
              {appliedFilters
                ? appliedFilters.map((a, i) =>
                    this.showFiltertag(Object.keys(a)[0], a[Object.keys(a)[0]])
                  )
                : ''}
              {projectStatus.value !== 0
                ? this.showFiltertag('projectStatus', projectStatus.label)
                : ''}
              {isYearlyFunding.value !== 0
                ? this.showFiltertag('isYearlyFunding', isYearlyFunding.label)
                : ''}
              {moderationStatus.value !== 0
                ? this.showFiltertag('moderationStatus', moderationStatus.label)
                : ''}
              {projectCostFrom &&
                projectCostTo &&
                this.showFiltertag(
                  'removeCostFilter',
                  `Budget: ${projectCostFrom} to ${projectCostTo}`
                )}
              {projectStartDateFrom &&
                projectStartDateTo &&
                this.showFiltertag(
                  'removeDateFilter',
                  `Project start date: ${projectStartDateFrom} to ${projectStartDateTo}`
                )}
              {projectStartDateFrom &&
                !projectStartDateTo &&
                this.showFiltertag(
                  'removeDateFilter',
                  `Project start date: From ${projectStartDateFrom}`
                )}
              {projectStartDateTo &&
                !projectStartDateFrom &&
                this.showFiltertag(
                  'removeDateFilter',
                  `Project start date: Till ${projectStartDateTo}`
                )}
              {feasibility_study !== ''
                ? this.showFiltertag('feasibility_study', feasibility_study)
                : ''}
              {detailed_project_report !== ''
                ? this.showFiltertag('detailed_project_report', detailed_project_report)
                : ''}
              {eia !== '' ? this.showFiltertag('eia', eia) : ''}
              {iee !== '' ? this.showFiltertag('iee', iee) : ''}
              {monitor_evaluation_plan !== ''
                ? this.showFiltertag('monitor_evaluation_plan', monitor_evaluation_plan)
                : ''}
              {risk_management_and_mitigation_plan !== ''
                ? this.showFiltertag(
                    'risk_management_and_mitigation_plan',
                    risk_management_and_mitigation_plan
                  )
                : ''}
              {development_of_logical_frameWork !== ''
                ? this.showFiltertag(
                    'development_of_logical_frameWork',
                    development_of_logical_frameWork
                  )
                : ''}

              {desk_study !== '' ? this.showFiltertag('desk_study', desk_study) : ''}
              {project_concept_note !== ''
                ? this.showFiltertag('project_concept_note', project_concept_note)
                : ''}
              {project_proposal !== ''
                ? this.showFiltertag('project_proposal', project_proposal)
                : ''}
              {master_procurement_plan !== ''
                ? this.showFiltertag('master_procurement_plan', master_procurement_plan)
                : ''}
              {annual_procurement_plan_for_this_FY !== ''
                ? this.showFiltertag(
                    'annual_procurement_plan_for_this_FY',
                    annual_procurement_plan_for_this_FY
                  )
                : ''}
              {annual_procurement_plan_for_this_coming_FY !== ''
                ? this.showFiltertag(
                    'annual_procurement_plan_for_this_coming_FY',
                    annual_procurement_plan_for_this_coming_FY
                  )
                : ''}
              {project_implementation_plan !== ''
                ? this.showFiltertag('project_implementation_plan', project_implementation_plan)
                : ''}
              {procurement_and_contract_award !== ''
                ? this.showFiltertag(
                    'procurement_and_contract_award',
                    procurement_and_contract_award
                  )
                : ''}
              {completion_of_the_legal_process_of_land_acquisition !== ''
                ? this.showFiltertag(
                    'completion_of_the_legal_process_of_land_acquisition',
                    completion_of_the_legal_process_of_land_acquisition
                  )
                : ''}
            </div>

            {this.checkForAppliedFilters() ? (
              <button className="btn-outline btn-outline--red" onClick={this.clearFilter}>
                Clear all
              </button>
            ) : (
              ''
            )}
          </div>
        )}

        <div className="flex justify-center">
          <div className="btn-toggle-wrapper flex label-2">
            <button
              className={`btn-toggle btn-transition ${showView === 'table' ? 'active' : ''}`}
              onClick={() => this.setView('table')}
            >
              <i className="material-icons md-18 mr-6">grid_on</i>
              {language.table}
            </button>
            <button
              className={`btn-toggle btn-transition ${showView === 'map' ? 'active' : ''}`}
              onClick={() => this.setView('map')}
            >
              <i className="material-icons md-18 mr-6">map</i>
              {language.map}
            </button>
          </div>
        </div>

        {showView === 'table' ? (
          <div className="project-list-container">
            {this.props.loading ? (
              <LoaderNP />
            ) : projects && projects.length > 0 ? (
              <>
                {programMsg && (
                  <p className="text-right" style={{ paddingRight: '15px' }}>
                    {programMsg}
                  </p>
                )}
                <Table padded className="project-table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={1} className="id-header">
                        {language.project_id}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={column === 'projectName' ? direction : null}
                        onClick={this.handleSort('projectName')}
                        width={4}
                      >
                        {language.project_name}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={3}>{language.line_ministry}</Table.HeaderCell>
                      {/* <Table.HeaderCell width={2}> */}
                      {/* {language.agency} */}
                      {/* </Table.HeaderCell> */}
                      <Table.HeaderCell width={2}>{language.department}</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {projects.map((project, index) => {
                      return (
                        <Table.Row
                          key={project.id}
                          onClick={() => {
                            this.handleRoute(project.id);
                          }}
                        >
                          <Table.Cell className="text-bold project-id">{project.id}</Table.Cell>
                          <Table.Cell>
                            <p className="project-title">{project.project_name_in_english}</p>
                            <div className="project-metadata">
                              <span
                                className={`project-status ${
                                  PROJECT_STATUS[project.projectStatus]
                                    ? PROJECT_STATUS[project.projectStatus]
                                    : PROJECT_STATUS['Save as Draft']
                                }
                                  `}
                              >
                                {PROJECT_STATUS[project.projectStatus]
                                  ? PROJECT_STATUS[project.projectStatus]
                                  : PROJECT_STATUS['Save as Draft']}
                              </span>
                              {project.programs.budgetCode ? (
                                <>
                                  <span>
                                    Budget code:
                                    {project.programs.budgetCode}
                                  </span>
                                  <span className="divider">|</span>
                                </>
                              ) : (
                                ''
                              )}
                              {project.ranking ? (
                                <>
                                  <span>
                                    Ranking:
                                    {project.ranking}
                                  </span>
                                  <span className="divider">|</span>
                                </>
                              ) : (
                                ''
                              )}

                              <span>
                                Last updated:
                                {project.projectUpdatedDate}
                              </span>
                            </div>
                          </Table.Cell>
                          <Table.Cell>{project.ministry.name}</Table.Cell>
                          {/* <Table.Cell> */}
                          {/* {project.general_information ? ( */}
                          {/* project.general_information.agency */}
                          {/* ) : ( */}
                          {/* <span className="not-available"> */}
                          {/* Agency not available */}
                          {/* </span> */}
                          {/* )} */}
                          {/* </Table.Cell> */}
                          <Table.Cell>
                            <div className="column-action">
                              {project.department ? (
                                project.department.name
                              ) : (
                                <span className="not-available">Dept. not available</span>
                              )}
                            </div>
                            <div className="actions">
                              <Can
                                role={user.role}
                                perform="action:edit-delete"
                                data={project.isActionable}
                                yes={() => (
                                  <>
                                    <Link
                                      to={`/add/project?projectId=${project.id}`}
                                      onClick={(e) => e.stopPropagation()}
                                      className="btn-outline btn-outline--small btn-outline--blue"
                                    >
                                      <i className="material-icons md-12 mr-2">edit</i> Edit
                                    </Link>
{' '}
                                    <span
                                      className="btn-outline btn-outline--small btn-outline--red"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                          modalOpen: !this.state.modalOpen,
                                          projectId: project.id,
                                          showOperationOptions: false,
                                        });
                                      }}
                                    >
                                      <i className="material-icons md-12 mr-2 material-delete">
                                        delete_outline
                                      </i>
                                      Delete
                                    </span>
                                  </>
                                )}
                                no={() => <></>}
                              />
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
                <div className="justify-center">
                  <Pagination
                    defaultActivePage={activePage}
                    onPageChange={this.handlePaginationChange}
                    totalPages={Math.ceil(count / 50)}
                    firstItem={{ 'aria-label': 'First item', content: 'First' }}
                    lastItem={{ 'aria-label': 'Last item', content: 'Last' }}
                    prevItem={{
                      'aria-label': 'Previous item',
                      content: 'Previous',
                    }}
                    nextItem={{ 'aria-label': 'First item', content: 'Next' }}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="empty">
                  <img src={empty} alt="no data found" />
                  <p as="p" size="">
                    No projects found.
                    <Can
                      role={user.role}
                      perform="project:create"
                      yes={() => <Link to="/project">Please Add Project</Link>}
                      no={() => ''}
                    />
                  </p>
                </div>
              </>
            )}
          </div>
        ) : showView === 'map' ? (
          <div className="map-container">
            <div className="map-wrapper">{/* <ProjectMap /> */}</div>
          </div>
        ) : (
          ''
        )}
        <Modal
          dimmer="blurring"
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: !this.state.modalOpen })}
          size="mini"
        >
          <Modal.Header>Delete program?</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete the project?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="black"
              onClick={() => this.setState({ modalOpen: !this.state.modalOpen })}
            >
              Cancel
            </Button>
            <Button
              positive
              content="Yes"
              onClick={(e) => {
                this.deleteProject(e);
              }}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.chartModalOpen}
          onClose={() => this.setState({ chartModalOpen: !this.state.chartModalOpen })}
          closeIcon
          className="chart-modal"
        >
          <div className="chart-wrapper bar-chart">
            <div className="bar-chart-header flex justify-between align-center">
              <Header size="tiny">{this.state.modalData.title}</Header>
              <div className="no-print">
                <button className="btn-outline btn-outline--blue" onClick={() => window.print()}>
                  <i className="material-icons mr-6">print</i>
                  Print
                </button>
                <button
                  className="btn-outline btn-outline--blue"
                  onClick={() => this.child.current.exportPdf()}
                >
                  <i className="material-icons mr-6">cloud_download</i>
                  Download graph
                </button>
              </div>
            </div>
            <PieChartLegend
              ref={this.child}
              data={this.state.modalData.data}
              colors={this.state.modalData.colors}
              title={this.state.modalData.title}
            />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedLanguage: state.localization.selectedLanguage,
  auth: state.auth,
  projectList: state.project.projectList,
  loading: state.project.loading,
  ministries: state.ministry.ministries,
  departments: state.department.departments,
  provinces: state.project.provinces,
  mapProjectList: state.project.mapProjectList,
  mapLoading: state.project.mapLoading,
  yearOnYearLoading: state.dashboard.yearOnYearLoading,
  statusLoading: state.dashboard.statusLoading,
});

const mapDispatchToProps = (dispatch) => ({
  listProjects: (val) => {
    dispatch(ProjectAction.checkLoading(true));
    dispatch(ProjectAction.fetching());
    ProjectService.listProjects(val)
      .then((response) => {
        if (response.success) {
          dispatch(ProjectAction.projectList(response));
          dispatch(ProjectAction.projectStep({ step: 1 }));
          dispatch(ProjectAction.checkLoading(false));
        } else {
          dispatch(ProjectAction.error(response.error.message));
          dispatch(ProjectAction.checkLoading(false));
        }
      })
      .catch((err) => dispatch(ProjectAction.error(err)));
  },
  deleteProject: (params) => {
    ProjectService.deleteProject(params)
      .then((response) => {
        if (response.success) {
          toastr.success('', `${response.message}`);
          dispatch(ProjectAction.projectList(response));
        } else {
          toastr.error('', `${response.error.message}`);
          dispatch(ProjectAction.error(response.error.message));
        }
      })
      .catch((err) => dispatch(ProjectAction.error(err)));
  },
  listMinistries: () => {
    dispatch(MinistryAction.fetchingMinstries());
    MinsitryApi.listAllMinistries(`?isDashboard=true&`)
      .then((response) => {
        if (response.success) {
          dispatch(MinistryAction.fetchMinistries(response));
        } else {
          dispatch(MinistryAction.errorMinistries(response));
        }
      })
      .catch((err) => dispatch(MinistryAction.errorMinistries(err)));
  },
  listDepartments: (param) => {
    dispatch(DepartmentAction.fetchingDepartment());
    DepartmentApi.listDepartments(param)
      .then((response) => {
        if (response.success) {
          dispatch(DepartmentAction.fetchDepartment(response));
        } else {
          dispatch(DepartmentAction.errorDepartment(response));
        }
      })
      .catch((err) => dispatch(DepartmentAction.errorDepartment(err)));
  },
  listProvinces: () => {
    dispatch(ProjectAction.fetchingProvince());
    ProjectService.listProvinces()
      .then((response) => {
        if (response.success) {
          dispatch(ProjectAction.fetchProvince(response));
        } else {
          dispatch(ProjectAction.errorProvince(response));
        }
      })
      .catch((err) => dispatch(ProjectAction.errorProvince(err)));
  },
  listProjectsForMap: (query) => {
    dispatch(ProjectAction.checkLoadingMap(true));
    ProjectService.listProjectMap(query)
      .then((response) => {
        if (response.success) {
          dispatch(ProjectAction.mapProjectList(response));
          dispatch(ProjectAction.checkLoadingMap(false));
        } else {
          dispatch(ProjectAction.checkLoadingMap(false));
        }
      })
      .catch((err) => console.log(err));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectList));
