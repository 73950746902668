import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Select,Modal,Button } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';


import ProjectApi from '../../../services/Gate/Project';

const StatusChangeSection = ({ projectId, getProject }) => {
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const [status, setStatus] = useState('');
  const [modalOpen,setModalOpen] = useState(false);
  const [error, setError] = useState(false);

  const statusOption = () => { 
    if (projectInfo.project_status === 'ongoing') {
      return [{ key: 'completed', text: 'Completed', value: 'closed' }];
    }

    if (projectInfo.project_status === 'new') {
      return [
        { key: 'ongoing', text: 'Ongoing', value: 'ongoing' },
        // { key: 'completed', text: 'Completed', value: 'closed' },
      ];
    }

    if(projectInfo.project_status === 'closed'){
      return [ { key: 'ongoing', text: 'Ongoing', value: 'ongoing' },
    ]
    }
  };

  const changeStatus = () => {
    setModalOpen((modalOpen)=>!modalOpen)
    let canSubmit = true;
    if (canSubmit) {
      let data = { project_status: status };
      ProjectApi.updateStatusOfProject(data, {
        projectId,
      })
        .then((response) => {
          if (response.success) {
            getProject();
            setStatus('');
            setError(false);
            toastr.success('', `${response.message}`);
          } else {
            toastr.error('', `${response.error.message}`);
          }
        })
        .catch((err) => console.log('---error--', err));
    }
  };

  const handleSelect = (value) => {
    setStatus(value);
  };

  return (
    <div name="contact">
      <div className="project-section" id="contact">
        {/* <div className="header medium">{lang.section_name}</div> */}
        {/* <Tab.Pane> */}
        <div>
          <Form.Field
            control={Select}
            options={statusOption()}
            placeholder="Select Project Status"
            width={8}
            onChange={(event, { value }) => {
              handleSelect(value);
            }}
            value={status}
          />
          <div className="accordion-button">
            <button
              type="submit"
              onClick={()=>setModalOpen(modalOpen => !modalOpen)}
              className="btn-rect btn-rect--small btn-blue"
            >
              Change Status
            </button>
          </div>
        </div>
        {/* </Tab.Pane> */}
      </div>
      <Modal
        dimmer="blurring"
        open={modalOpen}
        onClose={() => setModalOpen(!modalOpen)}
        size="mini"
      >
        <Modal.Header>Status of  project?</Modal.Header>
        <Modal.Content>
          <p>Are you sure for changing the project status from {status === 'ongoing' && projectInfo.project_status ==='new' ? 'Appraisal to Ongoing' : projectInfo.project_status==='closed' ? 'Completed to Ongoing' : 'Ongoing to Completed'} ?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => {setModalOpen(!modalOpen); setStatus('')}}>
            Cancel
          </Button>
          <Button
            positive
            content="Yes"
            onClick={() => {
              changeStatus();
            }}
          />
        </Modal.Actions>
      </Modal>
    </div>

  );
};

export default StatusChangeSection;
