import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Can from '../../../components/Can/can';

import { auth } from '../../../helpers';

import MinistryList from './MinistryList';

import PageNotFound from '../../PageNotFound';

function Ministry({ history }) {
  const { role } = useSelector(auth.getCurrentUser);

  return (
    <Can
      role={role}
      perform="ministries:list"
      yes={() => (
        <>
          <div className="body-container">
            <MinistryList history={history} />
          </div>
        </>
      )}
      no={() => <PageNotFound message={`You can't access this interface`} />}
    />
  );
}

export default withRouter(Ministry);
