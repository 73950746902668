import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Grid } from 'semantic-ui-react';
import { get } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { auth } from '../../../helpers';
import { localization } from '../../../localization';
import ProjectApi from '../../../services/Gate/Project';

const GeneralInformationSection = ({ gate }) => {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['general_information_view'];
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const [projectData, setProjectData] = useState();
  const [sectors, setSectors] = useState([]);
  const [locations, setLocations] = useState();

  useEffect(() => {
    setProjectData(projectInfo[gate]);
  }, [gate]);

  useEffect(() => {
    fetchLocation();
    fetchSectors();
  }, []);
  useEffect(() => {
    selectedSector();
    selectedLocation();
  }, []);
  const categorization = get(projectData, 'general_information.categorization');

  let categorizationContent;

  switch (categorization) {
    case 'belowThree':
      categorizationContent = 'Below 3 years';
      break;

    case 'threeToFive':
      categorizationContent = '3-5 years';
      break;

    case 'fiveToSeven':
      categorizationContent = '5-7 years';
      break;

    case 'sevenToTen':
      categorizationContent = '7-10 years';
      break;

    case 'moreThanTen':
      categorizationContent = 'More than 10 years';
      break;

    default:
      break;
  }

  const priorities = [
    {
      key: 'nationalPrideProject',
      text: 'National Pride Project',
      value: 'nationalPrideProject',
    },
    { key: 'priority1', text: 'Priority 1', value: 'priority1' },
    { key: 'priority2', text: 'Priority 2', value: 'priority2' },
    { key: 'gameChanger', text: 'Game Changer', value: 'gameChanger' },
    { key: 'others', text: 'Other', value: 'others' },
  ];
  const projectType = [
    {
      key: 'serviceOriented',
      text: 'Service Oriented',
      value: 'serviceOriented',
    },
    { key: 'construction', text: 'Construction', value: 'construction' },
    { key: 'production', text: 'Production', value: 'production' },
    { key: 'trade', text: 'Trade', value: 'trade' },
    { key: 'others', text: 'Other', value: 'others' },
  ];

  const fetchSectors = () => {
    ProjectApi.getAllsectors()
      .then((response) => {
        if (response.success) {
          setSectors(response.data.sectorList);
        }
      })
      .catch((err) => toastr.error('', err.message));
  };

  const fetchLocation = () => {
    ProjectApi.getAllLocation()
      .then((response) => {
        if (response.success) {
          let districts = [];
          for (let d of response.data.districts) {
            districts.push({
              key: d.id,
              text: d.name,
              value: d.id,
              parent_id: d.parent_id,
            });
          }
          setLocations({ provinces: response.data.provinces, districts });
        }
      })
      .catch((err) => toastr.error('', err.message));
  };

  const selectedSector = () => {
    let sectorId = projectData && projectData.general_information.sectors;
    let sectorData = [];
    sectors.filter((sector) => {
      sectorId.filter((id) => {
        if (+id === sector.value) sectorData.push(sector);
      });
    });
    return sectorData;
  };
  const selectedLocation = () => {
    let provinceId = projectData && projectData.general_information.provinces;
    let districtId = projectData && projectData.general_information.districts;
    let municipalityId = projectData && projectData.general_information.municipalities;

    let provinceData = [];
    let districtData = [];
    let municipalityData = [];

    locations &&
      locations.provinces.filter((locate) => {
        provinceId.filter((id) => {
          if (+id === +locate.id) provinceData.push(locate);
        });
      });
    locations &&
      locations.districts.filter((locate) => {
        districtId.filter((id) => {
          if (+id === +locate.key) districtData.push(locate);
        });
        municipalityId.filter((id) => {
          if (+id === +locate.key) municipalityData.push(locate);
        });
      });

    return { provinceData, districtData, municipalityData };
  };

  const getFormattedValue = (filteringArray, value) => {
    let filteredVal = filteringArray.filter((type) => value === type.value);
    if (filteredVal.length > 0) {
      return filteredVal[0].text;
    }
  };

  return (
    <div name="general-information">
      <div className="project-section" id="general-information">
        <div className="header medium">{lang.section_name}</div>
        <Tab.Pane>
          <Grid columns={2}>
            {/* Line Ministry and department */}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.project_id} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectData, 'uniqueProjectId')}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.line_ministry} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectInfo, 'ministry.name')}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.implementing_agency} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectInfo, 'department.name')}</p>
              </Grid.Column>
            </Grid.Row>

            {/* Part of Program */}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.is_project_part_of_program} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectInfo, 'part_of_program')}</p>
              </Grid.Column>
            </Grid.Row>
            {projectInfo.part_of_program === 'Yes' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.program_name} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">{get(projectInfo, 'program.name')}</p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.program_budget_code} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectInfo, 'program.program_budget_code')}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              ''
            )}

            {/* Parent Project info */}
            {projectInfo.parent_id && (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.parent_name} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectInfo, 'gate.project_name_in_english')}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.parent_budget_code} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">{get(projectInfo, 'gate.project_budget_code')}</p>
                  </Grid.Column>
                </Grid.Row>
              </>
            )}

            {/* Project information */}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.project_name_in_english} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectInfo, 'project_name_in_english')}</p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.project_name_in_nepali} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectInfo, 'project_name_in_nepali')}</p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.project_budget_code} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectInfo, 'project_budget_code')}</p>
              </Grid.Column>
            </Grid.Row>

            {/* Project status,stage,sectorial area */}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.project_status} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectInfo, 'project_status') === 'new' ? 'New' : 'Ongoing'}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.stage} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {projectInfo && projectInfo.project_status === 'ongoing'
                    ? ''
                    : projectInfo.sent_for_appraisal
                    ? 'Appraisal'
                    : 'Identification'}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.sectorial_area} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectInfo, 'sectorial_area') === 'infrastructure'
                    ? 'Infrastructure'
                    : 'Service/Social'}
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.project_type} :</p>
              </Grid.Column>
              <Grid.Column>
                {get(projectData, 'general_information.projectType') === 'others' ? (
                  <p
                    className="project-info"
                    dangerouslySetInnerHTML={{
                      __html: get(projectData, 'general_information.projectTypeOther'),
                    }}
                  />
                ) : (
                  <p className="project-info">
                    {getFormattedValue(
                      projectType,
                      get(projectData, 'general_information.projectType')
                    )}
                  </p>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.priority_of_project} :</p>
              </Grid.Column>
              <Grid.Column>
              {get(projectData, 'general_information.projectPriority') === 'others' ? (
                  <p
                    className="project-info"
                    dangerouslySetInnerHTML={{
                      __html: get(projectData, 'general_information.projectPriorityOther'),
                    }}
                  />
                ) : (
                  <p className="project-info">
                    {getFormattedValue(
                      priorities,
                      get(projectData, 'general_information.projectPriority')
                    )}
                  </p>
                )}
              </Grid.Column>
            </Grid.Row>
            {gate === 'project_identification' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.categorization_of_project} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">{categorizationContent}</p>
                    {/* belowThree, threeToFive, fiveToSeven, sevenToTen, moreThanTen */}
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              ''
            )}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.sector} :</p>
              </Grid.Column>
              <Grid.Column>
                <div className="project-info">
                  {projectData &&
                    projectData.general_information &&
                    selectedSector().map((sector, index) => {
                      return (
                        <div key={index}>
                          <p>{sector.label}</p>
                          <ul className="sub-sector">
                            {projectData.general_information.subSector.map((subSector, index) => {
                              let filteredSector = sector.subSector.filter(
                                (subSectorId) => +subSectorId.value === +subSector
                              );
                              if (filteredSector.length > 0) {
                                return <li key={index}>{filteredSector[0].label}</li>;
                              }
                            })}
                          </ul>
                        </div>
                      );
                    })}
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.project_province} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {projectData &&
                    projectData.general_information &&
                    selectedLocation().provinceData.map((province, index) => {
                      return (
                        <span key={index} className="general-array">
                          {province.name}
                        </span>
                      );
                    })}
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.project_district} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {projectData &&
                    projectData.general_information &&
                    selectedLocation().districtData.map((district, index) => {
                      return (
                        <span key={index} className="general-array">
                          {district.text}
                        </span>
                      );
                    })}
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.project_municipality} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {projectData &&
                    projectData.general_information &&
                    selectedLocation().municipalityData.map((municipality, index) => {
                      return (
                        <span key={index} className="general-array">
                          {municipality.text}
                        </span>
                      );
                    })}
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      </div>
    </div>
  );
};

export default GeneralInformationSection;
