import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { useSelector } from 'react-redux';
import { Form, Input } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';

import EmailApi from '../../../services/Email';
import { auth } from '../../../helpers';
import { localization } from '../../../localization';
import Can from '../../../components/Can/can';
import PageNotFound from '../../PageNotFound';

const Config = ({ history }) => {
  const user = useSelector(auth.getCurrentUser);
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['config'];
  const [email, setEmail] = useState([{ email: '' }]);
  const [emailList, setEmailList] = useState([]);
  const [emailErr, setEmailErr] = useState({});
  const [validateErr, setValidateErr] = useState({});
  const [emptyErr, setEmptyErr] = useState(false);

  const handleAddEmail = () => {
    setEmail((prevEmail) => [...prevEmail, {}]);
  };

  const removeEmail = (index) => {
    let clonedEmail = cloneDeep(email);
    clonedEmail.splice(index, 1);
    setEmail(clonedEmail);
  };

  const changeEmail = (e, index) => {
    let clonedEmail = cloneDeep(email);
    let clonedValidErr = cloneDeep(validateErr);
    let clonedErr = cloneDeep(emailErr);
    clonedEmail[index][e.target.name] = e.target.value;
    clonedValidErr[`err_${index}`] = false;
    clonedErr[`err_${index}`] = false;
    setEmail([...clonedEmail]);
    setValidateErr(clonedValidErr);
    setEmailErr(clonedErr);
    setEmptyErr(false);
  };

  const handleSaveEmail = () => {
    let canSubmit = true;
    let errors = {};
    let repeatErrs = {};
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    email.filter((item, index) => {
      if (email.length === 1 && index === 0 && item.email.trim() === '') {
        canSubmit = false;
        setEmptyErr(true);
      }
      if (item.email && !regexEmail.test(item.email.trim())) {
        canSubmit = false;
        errors[`err_${index}`] = true;
      }
    });

    emailList.filter((list) => {
      email.map((reqEmail, index) => {
        if (reqEmail.email && reqEmail.email.trim() === list.email.trim()) {
          canSubmit = false;
          repeatErrs[`err_${index}`] = true;
        }
      });
    });
    setEmailErr(repeatErrs);
    setValidateErr(errors);

    if (canSubmit) {
      let data = email.filter((list) => list.email);
      EmailApi.createEmail(data)
        .then((response) => {
          if (response.success) {
            toastr.success('', `${response.message}`);
            history.push('/emails');
          } else {
            throw new Error(response.error.message);
          }
        })
        .catch((err) => {
          toastr.error('', `${err.message}`);
        });
    }
  };

  useEffect(() => {
    allEmails();
  }, []);

  const allEmails = () => {
    EmailApi.listAllEmails()
      .then((response) => {
        if (response.success) {
          setEmailList(response.data);
        } else {
          throw new Error(response.err.message);
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  return (
    <Can
      role={user.role}
      perform="email:list:edit:delete"
      yes={() => (
        <>
          <div className="body-container no-print">
            <div className="dashboard-header justify-between">
              <div className="flex">
                <h2 className="header-main">{lang.config}</h2>
              </div>
              <button
                className="btn-transition btn-normal btn-normal--medium btn-normal--br4 btn-blue btn-shadow--blue view-proj"
                onClick={() => history.push(`/emails`)}
                style={{ marginLeft: '20px' }}
              >
                View
              </button>
            </div>
            <div className="form-group-container">
              <h2 className="header-18">{lang.title}</h2>
              <p className="form-info">{lang.to_email}</p>
              <Form>
                {email.map((value, index) => {
                  return (
                    <div className={index === 0 ? 'field-repeat--first' : 'field-repeat'} key={index}>
                      <Form.Group>
                        <Form.Field
                          control={Input}
                          label={lang.email}
                          type="email"
                          placeholder="testemail@gmail.com"
                          value={value.email}
                          name="email"
                          width={8}
                          onChange={(e) => changeEmail(e, index)}
                          error={
                            emailErr[`err_${index}`]
                              ? {
                                  content: 'Email already exists',
                                  pointing: 'below',
                                }
                              : validateErr[`err_${index}`]
                              ? { content: 'Enter valid email', pointing: 'below' }
                              : emptyErr
                              ? { content: 'Email field is empty', pointing: 'below' }
                              : null
                          }
                        />
                        <Form.Field width={2}>
                          {index > 0 && (
                            <div
                              className="btn-remove btn-outline btn-outline--small btn-outline--red"
                              onClick={() => removeEmail(index)}
                              style={{marginTop: "30px"}}
                            >
                              <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                              Delete
                            </div>
                          )}
                        </Form.Field>
                      </Form.Group>
                    </div>
                  );
                })}

                <Form.Group className="field-repeat-btn">
                  <Form.Field>
                    <button className="btn-text" onClick={handleAddEmail}>
                      + Add more
                    </button>
                  </Form.Field>
                </Form.Group>
              </Form>
            </div>
            <div className="form-actions flex">
              <button
                type="submit"
                onClick={() => handleSaveEmail()}
                className="btn-rect btn-rect--small btn-blue"
              >
                {lang.save_button}
              </button>
            </div>
          </div>
        </>
      )}
      no={() => <PageNotFound message={`You can't access this interface`} />}
    />
  );
};

export default Config;
