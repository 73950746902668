import React from 'react';
import { Form, Input } from 'semantic-ui-react';

const ExampleUpload = () => {
  return (
    <>
      {/* Upload field start */}
      <div>
        <Form.Field
          label="Upload brief description of project including salient features"
          width={8}
        />

        <div className="form-field__upload-files">
          {/* Uploaded file detail */}
          <Form.Group grouped className="file-item">
            <div>
              <Form.Field>
                <p className="file-name">Filename.png</p>
              </Form.Field>
              <Form.Field
                label="File description"
                placeholder="Add file description"
                control={Input}
              />
              <div className=" btn-remove btn-outline btn-outline--small btn-outline--red">
                <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                Delete
              </div>
            </div>
          </Form.Group>
          {/* Uploaded file detail end*/}

          {/* Uploaded file detail */}
          <Form.Group grouped className="file-item">
            <div>
              <Form.Field>
                <p className="file-name">Filename.png</p>
              </Form.Field>
              <Form.Field
                label="File description"
                placeholder="Add file description"
                control={Input}
              />
              <div className=" btn-remove btn-outline btn-outline--small btn-outline--red">
                <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                Delete
              </div>
            </div>
          </Form.Group>
          {/* Uploaded file detail end*/}

          {/* Uploaded file detail */}
          <Form.Group grouped className="file-item">
            <div>
              <Form.Field>
                <p className="file-name">Filename.png</p>
              </Form.Field>
              <Form.Field
                label="File description"
                placeholder="Add file description"
                control={Input}
              />
              <div className=" btn-remove btn-outline btn-outline--small btn-outline--red">
                <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                Delete
              </div>
            </div>
          </Form.Group>
          {/* Uploaded file detail end*/}

          {/* Uploaded file detail */}
          <Form.Group grouped className="file-item">
            <div>
              <Form.Field>
                <p className="file-name">Filename.png</p>
              </Form.Field>
              <Form.Field
                label="File description"
                placeholder="Add file description"
                control={Input}
              />
              <div className=" btn-remove btn-outline btn-outline--small btn-outline--red">
                <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                Delete
              </div>
            </div>
          </Form.Group>
          {/* Uploaded file detail end*/}
        </div>

        <Form.Group>
          <Form.Field width={8}>
            <div className="form-field__upload-btn">
              <input type="file" className="input-file" id="file-input" />
              <label className="btn-outline btn-outline--blue" for="file-input">
                Upload file
              </label>
            </div>
          </Form.Field>
        </Form.Group>
      </div>
      {/* Upload field end */}
    </>
  );
};

export default ExampleUpload;
