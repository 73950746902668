import React, { Component } from 'react';
// import { Icon } from 'semantic-ui-react';

import FilterSection from '../filter-new/FilterSection';
// import Can from '../Can/can';


class Filter extends Component {
	constructor(props) {
		super(props);
		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.state = {
			showSidebar: false,
			showMinistryOption: false,
			collapseAll: false,
		};
	}

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	handleOutsideClick = (event) => {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ showSidebar: false });
		}
	};

	escFunction = (event) => {
		if (event.keyCode === 27) {
			this.setState({ showSidebar: false });
		}
	};

	componentDidMount() {
		document.addEventListener('mousedown', this.handleOutsideClick, false);
		document.addEventListener('keydown', this.escFunction, false);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleOutsideClick, false);
		document.removeEventListener('keydown', this.escFunction, false);
	}

	toggleSidebar = () => {
		this.setState({ showSidebar: !this.state.showSidebar });
	};

	toggleFilterOption = (filter) => {
		this.setState({ 
			[filter]: !this.state[filter],
			collapseAll: true,
		 });
	};

	collapseAll = () => {
		this.setState({
			showMinistryOption: !this.state.collapseAll,
			collapseAll: !this.state.collapseAll
		});
	};

	render() {
		const {
			ministries,
			language,
			filterValues,
			handleSearch,
			handleReset,
		} = this.props;
		return (
			<div ref={this.setWrapperRef}>
				<div
					className={`sidebar-filter ${this.state.showSidebar ? '' : 'hidden'}`}
				>
						<div className='close-button' onClick={this.toggleSidebar}>
							<i className='material-icons'>close</i>
						</div>
					<div className='filter-container'>
						<div
							className={`filter-action
							${
								filterValues.ministry_id.value !== 0 
									? 'visibility-visible'
									: 'visibility-hidden'
							}
									`}
						>
							<button
								className='btn-rect btn-blue'
								onClick={() => {
									handleSearch();
									this.toggleSidebar();
									this.props.handleApplyFilterStatus();
								}}
							>
								{language.apply_filter}
							</button>
							<button className='btn-text' onClick={handleReset}>
								{language.clear_filter}
							</button>
						</div>
						<div className='header'>
							<p className='title'>{language.filter}</p>
							<p className='collapse' onClick={this.collapseAll}>
								{!this.state.showMinistryOption ? language.expand_all : language.collapse_all_filter}
								<i className='material-icons md-bold md-blue ml-2'>{`${this.state.showMinistryOption ? 'remove' : 'add'}`}</i>
							</p>
						</div>

						{/* Filter Section for Ministries */}

                        <FilterSection
							title={language.ministry}
							toggleFilterOption={() =>
								this.toggleFilterOption('showMinistryOption')
							}
							showOption={this.state.showMinistryOption}
							placeholder='Type a ministry name ...'
							data={ministries}
							handleSelect={this.props.handleSelect}
							selectedVal={filterValues.ministry_id}
							filterLabel={'ministry_id'}
						/>

					</div>
				</div>
				<button
					className='btn-normal btn-blue btn-shadow--blue btn-transition btn-filter'
					onClick={this.toggleSidebar}
				>
					<i className='material-icons md-bold md-18 mr-6'>filter_list</i>
					{language.filter}
				</button>
			</div>
		);
	}
}

export default Filter;
