const initialState = {
  economicIndicators: {
    rateOfReturn: '',
    NPV: '',
    BCRatio: '',
    other: '',
  },
  financialIndicators: {
    rateOfReturn: '',
    NPV: '',
    BCRatio: '',
    IRREquity: '',
    payback: '',
    other: '',
  },
  socialIndicators: {
    costEffectiveness: '',
    employmentGeneration: '',
    BCRatio: '',
    other: '',
  },
  socialIndicatorOutputFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'ERROR':
      return {};
    case 'PROJECT_STEP':
      return {
        ...state,
        step: payload.step,
      };
    case 'VIABILITY_FETCHED':
      state = { ...payload };
      return state;

    case 'NULL_VIABILITY_FETCHED':
      return initialState;

    default:
      return state;
  }
}
