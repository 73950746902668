const initialState = {
  step: 11,
  justification: '',
  justificationFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'ERROR':
      return {};
    case 'PROJECT_STEP':
      return {
        ...state,
        step: payload.step,
      };
    case 'JUSTIFICATION_FETCHED':
      state = { ...payload };
      return state;

    case 'NULL_JUSTIFICATION_FETCHED':
      return initialState;

    default:
      return state;
  }
}
