import React, { useEffect, useState, useRef } from 'react';
import { Table, Modal, Button, Pagination, Header } from 'semantic-ui-react';

import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import query from 'query-string';
import { map, cloneDeep } from 'lodash';
import moment from 'moment';

import empty from './../../../assets/images/no-data.svg';

import { auth } from '../../../helpers';

import { localization } from '../../../localization';

import LoaderNP from '../../../components/Loader/Loader';
import Can from '../../../components/Can/can';
import PieChart from '../../../components/Chart/PieChart';
import PieChartLegend from '../../../components/Chart/PieChartLegend';

import ProjectMap from '../../../pages/ProjectMap';

import ProjectService from '../../../services/Gate/Project';

import * as ProjectAction from '../../../reducers/form/action';

import Filter from './Filter';
import { filter } from '@amcharts/amcharts4/.internal/core/utils/Iterator';

const ProjectList = ({ handleParams, history, showFilter }) => {
  const child = useRef();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    search: '',
    province: { label: 'Select a Province', value: 0 },
    applied: false,
  });
  const [showView, setShowView] = useState('table');

  const [appliedFilters, setAppliedFilters] = useState([]);
  const user = useSelector(auth.getCurrentUser);
  const selectedLanguage = useSelector(auth.getSelectedLanguage);
  const lang = localization[selectedLanguage];
  const language = lang.project_filter;
  const [pagination, setPagination] = useState({
    activePage: 1,
    column: null,
    direction: null,
  });
  const [projectData, setProjectData] = useState({
    projects: null,
    count: 0,
  });
  const [isExporting, setIsExporting] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const mapLoading = useSelector((state) => state.project.mapLoading);

  const listProjectsForMap = (query) => {
    dispatch(ProjectAction.checkLoadingMap(true));
    ProjectService.listProjectsProvince(query)
      .then((response) => {
        if (response.success) {
          dispatch(ProjectAction.mapProjectList({ data: response.data.mapData }));
          dispatch(ProjectAction.checkLoadingMap(false));
        } else {
          dispatch(ProjectAction.checkLoadingMap(false));
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    searchProject()(null);
  }, [pagination.activePage]);

  useEffect(() => {
    if (!['npcAdmin', 'dataViewer'].includes(user.role)) {
      setFilters((filters) => {
        return {
          ...filters,
          ministry_id: [{ label: user.ministry.name, value: user.ministry.id }],
        };
      });
    }
  }, [user]);

  const getQueryString = (appFilters = null) => {
    const joinValues = (arr, type = false) => {
      return type ? map(arr, 'label').join(':') : map(arr, 'value').join(':');
    };
    let f = filters;

    const appliedFilters = [];
    let queryString = '?';

    if (appFilters) {
      f = appFilters;
    }

    for (const filter in f) {
      if (typeof f[filter] === 'string' && f[filter] !== '') {
        queryString = `${queryString}${filter}=${f[filter].trim()}&`;
      } else if (f[filter] instanceof Array && f[filter][0].value !== 0) {
        queryString = `${queryString}${filter}=${joinValues(f[filter])}&`;
        appliedFilters.push({ [filter]: f[filter] });
      } else if (
        typeof f[filter] === 'object' &&
        !Array.isArray(f[filter]) &&
        f[filter].value !== 0
      ) {
        queryString = f[filter].value ? `${queryString}${filter}=${f[filter].value}&` : queryString;
      }
    }

    setAppliedFilters(appliedFilters);
    return queryString;
  };

  const searchProject = (appFilters = null) => (e) => {
    if (e) e.preventDefault();
    let { activePage, column, direction } = pagination;
    activePage = activePage - 1;
    let queryString;
    if (appFilters) {
      queryString = getQueryString(appFilters);
      setFilters({ ...filters, ...appFilters });
    } else {
      queryString = getQueryString();
    }
    if (showView === 'map') {
      listProjectsForMap(queryString);
    }

    if (handleParams) handleParams(queryString);

    if (column) {
      queryString = `${queryString}column=${column}&sort=${direction}&`;
    }

    queryString = `${queryString}page=${activePage}&pageSize=25`;

    listProjects(queryString);
  };

  const handlePaginationChange = (e, { activePage }) => {
    setPagination({ ...pagination, activePage });
  };

  const handleSort = (clickedColumn) => () => {
    const { column, direction } = pagination;
    if (column !== clickedColumn) {
      setPagination({
        column: clickedColumn,
        direction: 'ascending',
        activePage: 1,
      });

      return;
    }
    setPagination({
      ...pagination,
      direction: direction === 'ascending' ? 'descending' : 'ascending',
      activePage: 1,
    });
  };

  const exportExcel = async () => {
    setIsExporting(true);
    let queryString = getQueryString();
    const data = await ProjectService.exportProvinceProjects(queryString);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('type', 'hidden');
    link.setAttribute('download', 'PbmisProjectList.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
    setIsExporting(false);
  };

  const listProjects = (queryString) => {
    setIsLoading(true);
    let qs = query.parse(queryString);
    let qString = queryString;
    if (qs.projectStatus && qs.projectStatus === 'ongoing' && qs.projectStage) {
      delete qs['projectStage'];
      setFilters({ ...filters, projectStage: { label: 'Select Project Stage', value: 0 } });
      qString = '?' + query.stringify(qs);
    }
    ProjectService.listProjectsProvince(`${qString}`)
      .then((response) => {
        if (response.success) {
          setProjectData({
            projects: response.data.projectList.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            ),
            // count: response.data.count,
            count: response.data.projectList.length,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toastr.error('', `${err.message}`);
      });
  };
  // const loading = false;

  const setView = (view) => {
    setShowView(view, () => {
      // if (view === 'map') {
      //   let queryString = getQueryString();
      //   listProjectsForMap(queryString);
      // }
    });
    if (view === 'map') {
      let queryString = getQueryString();
      listProjectsForMap(queryString);
    }
  };

  const handleSelect = (key, option) => {
    let arr = [];

    if (filters[key][0].value !== 0) {
      arr = [...filters[key]];
      let check = arr.find((d) => d.value === option.value);
      if (check) arr = arr.filter((d) => d.value !== option.value);
      else arr.push(option);
      if (arr.length < 1) arr = [{ value: 0 }];
    } else {
      arr.push(option);
    }

    setFilters({ ...filters, [key]: arr });
  };

  const handleChange = (key, option) => {
    if (key === 'projectStatus' && query.parse(history.location.search)) {
      history.push({
        pathname: '/dashboard',
        search: '',
      });
    }
    setFilters({ ...filters, [key]: option });
  };

  const handleDateChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const clearFilter = () => {
    setFilters({
      applied: false,
      province: { label: 'Select a Province', value: 0 },
    });
    searchProject({ province: { label: 'Select a Province', value: 0 } })(null);
  };

  const handleApplyFilterStatus = () => {
    let queryString = getQueryString();
    listProjectsForMap(queryString);
    setFilters({ ...filters, applied: true });
    // searchProject(null);
  };

  const handleRemoveFilter = (filter, value = 0) => {
    if (query.parse(history.location.search)) {
      history.push({
        pathname: '/dashboard',
        search: '',
      });
    }

    let multiSelect = ['province'];
    let reset = { ...filters };

    setFilters(reset);
    searchProject(reset)(null);
  };

  return (
    <>
      {!isExporting ? (
        <button
          className="btn-normal btn-dark btn-shadow--dark btn-transition btn-export"
          onClick={exportExcel}
        >
          <i className="material-icons md-normal mr-6">system_update_alt</i>
          {language.export_data}
        </button>
      ) : (
        <button className="btn-normal btn-dark btn-shadow--dark btn-transition btn-export">
          <i className="ui secondary loading button" />
          {language.export_data}
        </button>
      )}
      {showFilter && (
        <Filter
          handleSelect={handleSelect}
          handleChange={handleChange}
          filterValues={filters}
          handleDateChange={handleDateChange}
          searchProject={searchProject}
          clearFilter={clearFilter}
          language={language}
          handleApplyFilterStatus={handleApplyFilterStatus}
          user={user}
        />
      )}
      <div className="flex align-center chart-search-wrapper">
        <div>
          {/* <form onSubmit={searchProject()}>
            <div className="form-search">
              <div className="field-search">
                <input
                  className="input-text input-icon"
                  onChange={(e) => {
                    setFilters({ ...filters, search: e.target.value });
                    setPagination({ ...pagination, activePage: 1 });
                  }}
                  type="text"
                  placeholder="Search by typing name..."
                />
                <i className="material-icons md-24">search</i>
                <button className="button" type="submit">
                  {language.search}
                </button>
              </div>
            </div>
          </form> */}
        </div>
      </div>

      <div className="flex justify-center">
        <div className="btn-toggle-wrapper flex label-2">
          <button
            className={`btn-toggle btn-transition ${showView === 'table' ? 'active' : ''}`}
            onClick={() => setView('table')}
          >
            <i className="material-icons md-18 mr-6">grid_on</i>
            {language.table}
          </button>
          <button
            className={`btn-toggle btn-transition ${showView === 'map' ? 'active' : ''}`}
            onClick={() => setView('map')}
          >
            <i className="material-icons md-18 mr-6">map</i>
            {language.map}
          </button>
        </div>
      </div>

      {showView === 'table' ? (
        <div className="project-list-container">
          {loading ? (
            <LoaderNP />
          ) : projectData.projects && projectData.projects.length > 0 ? (
            <>
              {/* {programMsg && (
                <p className="text-right" style={{ paddingRight: '15px' }}>
                  {programMsg}
                </p>
              )} */}
              <Table padded className="project-table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1} className="id-header  width-8">
                      {language.project_id}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={pagination.column === 'projectName' ? pagination.direction : null}
                      onClick={handleSort('projectName')}
                      width={4}
                    >
                      {language.project_name}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={1}>{language.province}</Table.HeaderCell>
                    <Table.HeaderCell width={3}>{language.line_ministry}</Table.HeaderCell>
                    {/*<Table.HeaderCell width={2}>*/}
                    {/*{language.agency}*/}
                    {/*</Table.HeaderCell>*/}
                    <Table.HeaderCell width={2}>{language.department}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {projectData.projects.map((project, index) => {
                    return (
                      <Table.Row key={index} className="link-row">
                        <Table.Cell className="text-bold project-id">
                          {project.uniqueProjectId}
                        </Table.Cell>
                        <Table.Cell>
                          <p className="project-title">
                            {project.hasPublished ? (
                              <span className="icon-done">
                                <i className="material-icons">done</i>
                              </span>
                            ) : (
                              ''
                            )}{' '}
                            {project.project_name_in_english}
                          </p>
                          <div className="project-metadata">
                            {project.project_status === 'new' ? (
                              <span className="gate-label project-status dark">
                                {project.project_stage}
                              </span>
                            ) : (
                              <span className="gate-label project-status dark">Ongoing</span>
                            )}
                            {project.moderation_status && (
                              <span
                                className={`project-status
																		${
                                      project &&
                                      (project.moderation_status === 'published'
                                        ? 'verification'
                                        : project.moderation_status === 'completed'
                                        ? 'submitted'
                                        : 'draft')
                                    }`}
                              >
                                {project.moderation_status === 'published'
                                  ? 'verification'
                                  : project.moderation_status === 'completed'
                                  ? 'submitted'
                                  : 'draft'}
                              </span>
                            )}
                            {project.parent && (
                              <>
                                <span>Parent: {project.parent.project_name_in_english}</span>
                                <span className="divider">|</span>
                              </>
                            )}
                            {project.project_budget_code ? (
                              <>
                                <span>Budget code: {project.project_budget_code}</span>
                                <span className="divider">|</span>
                              </>
                            ) : (
                              ''
                            )}
                            {project.program && (
                              <>
                                <span
                                // onClick={(e) => {
                                //   handleSelect('program_id', {
                                //     label: project.p,
                                //     value: project.program_id,
                                //   });
                                //   searchProject()(null);
                                // }}
                                >
                                  Program: {project?.program?.name ?? ''}
                                </span>
                                <span className="divider">|</span>
                              </>
                            )}
                            {project.ranking ? (
                              <>
                                <span>Ranking: {project.ranking}</span>
                                <span className="divider">|</span>
                              </>
                            ) : (
                              ''
                            )}

                            <span>
                              Last updated:{' '}
                              {project.updated_at
                                ? moment(project.updated_at).format('MMM DD, YYYY')
                                : moment(project.updatedAt).format('MMM DD, YYYY')}
                            </span>
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          {project.provinceNo && (
                            <>
                              <span>{language[`province_${project.provinceNo}`]}</span>
                            </>
                          )}
                        </Table.Cell>

                        <Table.Cell>{project?.ministry?.name ?? ''}</Table.Cell>
                        <Table.Cell>
                          <div className="column-action">
                            {project.department ? (
                              project?.department?.name ?? ''
                            ) : (
                              <span className="not-available">Dept. not available</span>
                            )}
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              <div className="justify-center">
                <Pagination
                  defaultActivePage={pagination.activePage}
                  onPageChange={handlePaginationChange}
                  totalPages={Math.ceil(projectData.count / 25)}
                  firstItem={{ 'aria-label': 'First item', content: 'First' }}
                  lastItem={{ 'aria-label': 'Last item', content: 'Last' }}
                  prevItem={{
                    'aria-label': 'Previous item',
                    content: 'Previous',
                  }}
                  nextItem={{ 'aria-label': 'First item', content: 'Next' }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="empty">
                <img src={empty} alt="no data found" />
                <p as="p" size="">
                  No projects found.
                </p>
              </div>
            </>
          )}
        </div>
      ) : showView === 'map' ? (
        <div className="project-list-container">
          {mapLoading ? (
            <div>
              <LoaderNP />
            </div>
          ) : (
            <div className="map-container">
              <div className="map-wrapper">
                <ProjectMap isHomePage={true} provinceNo={filters.province.value} />
              </div>
            </div>
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
};
export default ProjectList;
