import React, { useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { localization } from '../../localization';
import { auth } from '../../helpers';

am4core.useTheme(am4themes_animated);

function DonutChart({ data, id, titleHeader, dataFieldCategory, dataFieldValue }) {
  const chart1 = useRef(null);
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['samapurak'];
  let newData = data.map((chartData) => {
    return { ...chartData, [dataFieldCategory]: chartData[dataFieldCategory] };
  });

  useLayoutEffect(() => {
    // Create chart instance
    let chart = am4core.create(id, am4charts.PieChart);
    chart.logo.disabled = true;

    let title = chart.titles.create();
    title.text = titleHeader;
    title.fontSize = 25;
    title.marginBottom = 18;

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = dataFieldValue;
    pieSeries.dataFields.category = dataFieldCategory;
    pieSeries.labels.template.text = "{value.formatNumber('#.0')}%";
    pieSeries.labels.template.radius = am4core.percent(-40);
    pieSeries.tooltip.getFillFromObject = false;
    pieSeries.tooltip.background.fillOpacity = 1;
    pieSeries.tooltip.background.fill = am4core.color('#fff');
    pieSeries.tooltip.autoTextColor = false;
    pieSeries.tooltip.label.fill = am4core.color('#697588');

    // Let's cut a hole in our Pie chart the size of 30% the radius
    chart.innerRadius = am4core.percent(30);

    const colors = [
      am4core.color('#B8CFFC'),
      am4core.color('#d5c6f7'),
      am4core.color('#7CA7F8'),
      am4core.color('#B49BEC'),
      am4core.color('#9BE8EC'),
      am4core.color('#5ACBD2'),
    ];

    // // Put a thick white border around each Slice
    // pieSeries.slices.template.stroke = am4core.color("#fff");
    // pieSeries.slices.template.strokeWidth = 2;
    // pieSeries.slices.template.strokeOpacity = 1;
    // change the cursor on hover to make it apparent the object can be interacted with
    pieSeries.slices.template.cursorOverStyle = [
      {
        property: 'cursor',
        value: 'pointer',
      },
    ];

    pieSeries.alignLabels = false;
    // pieSeries.labels.template.bent = true;
    // pieSeries.labels.template.radius = 3;
    pieSeries.labels.template.padding(0, 0, 0, 0);

    pieSeries.ticks.template.disabled = true;

    pieSeries.colors.list = colors.map((color) => {
      return am4core.color(color);
    });

    // Create a base filter effect (as if it's not there) for the hover to return to
    let shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter());
    shadow.opacity = 0;

    // Create hover state
    let hoverState = pieSeries.slices.template.states.getKey('hover'); // normally we have to create the hover state, in this case it already exists

    // Slightly shift the shadow and make it more prominent on hover
    let hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
    hoverShadow.opacity = 0.7;
    hoverShadow.blur = 5;

    // Add a legend
    chart.legend = new am4charts.Legend();
    // chart.legend.labels.template.text = "{basicArea}"

    chart.data = newData;

    return () => {
      chart.dispose();
    };
  }, [data, titleHeader]);

  return <div id={id} style={{ width: '100%', height: '500px' }}></div>;
}

export default DonutChart;
