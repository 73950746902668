const initialState = {
  projectInfo: {},
  programList: [],
  projectError: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'GET_PROJECT_BASIC_INFO':
      return {
        ...state,
        projectInfo: payload.data.project,
        programList: payload.data.programList,
      };
    case 'PROJECT_BASIC_INFO_ERROR':
      return {
        ...state,
        projectError: payload,
      };

    default:
      return state;
  }
}
