import React, { useState, useEffect } from 'react';
import ScrollArea from 'react-scrollbar';

const FilterMultiSection = ({
  title,
  toggleFilterOption,
  showOption,
  placeholder,
  data,
  selectedVal,
  handleSelect,
  filterLabel,
  label,
  showSearch,
  applied,
}) => {
  const [value, setInputValue] = useState('');
  const [filterResult, setSFilterResult] = useState(data);

  useEffect(() => {
    setSFilterResult(data);
    if (applied === false) {
      setInputValue('');
    }
  }, [data, applied]);

  const handleSearchText = (e) => {
    let searchText = e.target.value.toLowerCase();
    let result = data.filter((d) => d.label.toLowerCase().includes(searchText));
    setSFilterResult(result);
  };

  const ids = [...selectedVal].map((d) => d.value);

  return (
    <div className="filter-section">
      <div className="header" onClick={toggleFilterOption}>
        <p className="title">{title}</p>
        <div className="collapse">
          <i className="material-icons md-bold md-blue">{`${showOption ? 'remove' : 'add'}`}</i>
        </div>
      </div>
      <div className={`contents ${showOption ? '' : 'hide'}`}>
        {showSearch && (
          <input
            type="text"
            value={value}
            placeholder={placeholder}
            onChange={(e) => {
              handleSearchText(e);
              setInputValue(e.target.value);
            }}
          />
        )}
        <div className="options">
          <ScrollArea speed={0.8} horizontal={false}>
            {filterResult &&
              filterResult.length > 0 &&
              filterResult.map((d) => (
                <label className="checkbox-btn" key={d.value}>
                  <input
                    type="checkbox"
                    onChange={(event) => {
                      handleSelect(filterLabel, d);
                    }}
                    checked={ids.includes(d.value)}
                  />
                  {d.label}
                  <span className="checkmark"></span>
                </label>
              ))}
          </ScrollArea>
        </div>
      </div>
    </div>
  );
};

export default FilterMultiSection;
