export function fetching() {
    return { type: 'FETCHING' };
  }
  
  export function fetched(payload) {
    return { type: 'SELECTION_FETCHED', payload };
  }

  export function fetchedNullData() {
    return { type: 'NULL_SELECTION_FETCHED' };
  }  