import React from 'react';
import { useSelector } from 'react-redux';

import Ranking from './Ranking';

import PageNotFound from '../../PageNotFound';
import Can from '../../../components/Can/can';

const NewAppraisalRanking = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <Can
      role={user.role}
      perform="project:ranking"
      yes={() => <Ranking status="new" stage="appraisal" project="appraisal"/>}
      no={() => <PageNotFound message={"You have'nt permission to edit this project"} />}
    />
  );
};

export default NewAppraisalRanking;
