import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function Editor({ id, formName, field, formData, handleEditorChange }) {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={formData}
      // onInit={ editor => {
      //     // You can store the "editor" and use when it is needed.
      //     console.log( 'Editor is ready to use!', editor );
      // } }
      // onChange={ ( event, editor ) => {
      //     const data = editor.getData();
      //     console.log(event,data);
      // } }
      onChange={(event, editor) => {
        const data = editor.getData();
        handleEditorChange(id, formName, field, data);
      }}
      onBlur={(event, editor) => {
        // console.log( 'Blur.', editor );
      }}
      onFocus={(event, editor) => {
        // console.log( 'Focus.', editor );
      }}
      config={{
        toolbar: ['Heading','bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote','link' ,'insertTable']
      }}
    />
  );
}
