import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Http from 'axios';
import HeroImg from '../../assets/images/hero-img.svg';
import ProjectMap from '../ProjectMap';
// import DashboardService from '../../services/Dashboard';
import { API_URL } from '../../helpers';
import * as ProjectAction from '../../reducers/form/action';

import { ParallaxProvider, Parallax, ParallaxBanner } from 'react-scroll-parallax';
import * as HeroImgs from '../../assets/images/hero';

const HomePage = () => {
  const [stats, setStats] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    projectStats();
  }, []);

  const projectStats = async () => {
    let config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      let res = await Http.get(`${API_URL}homepage`, config);
      setStats(res.data.stats);
      dispatch(ProjectAction.mapProjectList({data: res.data.projects}));
    } catch (error) {
      console.log('---eror', error);
    }
  };
  return (
    <ParallaxProvider>
      <div className="body-container">
        <div className="hero-container flex">
          <div className="img-wrapper">
            {/* <img src={HeroImg} alt="Hero img" /> */}
            <Parallax y={['-20px', '20px']}>
              <img src={HeroImgs.main} className="hero-main" />
            </Parallax>
            <Parallax y={['80px', '-80px']} className="hero-chart-wrapper">
              <img src={HeroImgs.chart} className="hero-chart" />
            </Parallax>
            <Parallax y={['10px', '-10px']}>
              <img src={HeroImgs.grid} className="hero-grid" />
            </Parallax>
            <Parallax y={['30px', '-30px']}>
              <img src={HeroImgs.bars} className="hero-bars" />
            </Parallax>
            <Parallax y={['50px', '-50px']}>
              <img src={HeroImgs.table} className="hero-table" />
            </Parallax>
          </div>

          <div>
            <Parallax y={['-30px', '30px']}>
              <div className="hero-title">
                <h1 className="heading-1 normal">Data inventory for government funded projects</h1>
                <p className="body-2">
                  Providing ministries and development agencies the required tools to make
                  well-informed strategic development decisions based on evidence based assessment,
                  prioritization and planning
                </p>
              </div>

              <div className="hero-stats flex">
                <div>
                  <p className="heading-1 bold">{stats.totalProject}</p>
                  <p className="label-2">Total projects submitted</p>
                </div>
                <div>
                  <p className="heading-2 bold">{stats.onGoingProject}</p>
                  <p className="label-2">Ongoing projects</p>
                </div>
                <div>
                  <p className="heading-2 bold">{stats.futureProject}</p>
                  <p className="label-2">Future projects</p>
                </div>
              </div>
            </Parallax>
          </div>
        </div>
        <div className="hero-video-container flex">
          <>
            <iframe
              className="video"
              // width="512"
              // height="315"
              src="https://www.youtube.com/embed/-qfQw2gisT0"
            ></iframe>
          </>
        </div>

        <div>
          <h2 className="heading-2">
            Explore through {stats.totalProject} projects all over Nepal
          </h2>
          <div className="map-container">
            <div className="map-wrapper">
              <ProjectMap isHomePage={true} />
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
};

export default HomePage;
