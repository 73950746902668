import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon, Modal, Pagination, Table } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import MinistryService from '../../../services/Ministry';

import { localization } from '../../../localization';
import LoaderNP from '../../../components/Loader/Loader';
import Can from '../../../components/Can/can';

import { auth } from '../../../helpers';

function GateMinistryList({ history }) {
  const { role } = useSelector(auth.getCurrentUser);
  let submitBtn = useRef(null);
  const language = useSelector((state) => state.localization.selectedLanguage);
  const lang = localization[language]['ministry'];

  const [modalOpen, setModalOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [obj, setValues] = useState({
    ministryList: [],
    totalPage: 0,
    totalMinistries: 0,
    totalProjects: 0,
    isLoading: true,
    search: '',
    activePage: 1,
    ministryId: '',
    queryString: {
      page: 0,
    },
  });

  const [errors, setErrorMessage] = useState({
    success: false,
    message: '',
  });

  const allMinistries = () => {
    MinistryService.listAllGateMinistries(obj.queryString)
      .then((response) => {
        if (response.success) {
          const m = response.data.ministries;
          const lists = m.lists.sort(function (a, b) {
            return b.totalProjects - a.totalProjects;
          });

          if (submitBtn) submitBtn.removeAttribute('disabled');
          setValues({
            ...obj,
            ministryList: lists,
            totalPage: m.totalPage,
            totalMinistries: m.count,
            totalProjects: m.totalProjects,
            isLoading: false,
          });
        } else {
          throw new Error(response.error.message);
        }
      })
      .catch((err) => {
        setErrorMessage({
          success: true,
          message: err.message,
        });
        toastr.error('', `${err.message}`);
      });
  };

  useEffect(() => {
    allMinistries();
  }, [obj.queryString]);

  const deleteMinistry = (e) => {
    setModalOpen(!modalOpen);
    MinistryService.deleteMinistry({ ministryId: obj.ministryId })
      .then((response) => {
        if (response.success) {
          setValues({ ...obj, queryString: { ...obj.queryString, page: 0 } });
          toastr.success('', `${response.message}`);
        } else {
          throw new Error(response.error.message);
        }
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const handlePaginationChange = (e, { activePage }) => {
    setValues({
      ...obj,
      activePage: activePage,
      queryString: { ...obj.queryString, page: activePage - 1 },
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    submitBtn.setAttribute('disabled', 'disabled');
    setValues({ ...obj, queryString: { ...obj.queryString, search: obj.search } });
  };

  const handleSearchInputChange = (e) => {
    setValues({ ...obj, search: e.target.value });
  };

  const exportMinistryData = async () => {
    setIsExporting(true);
    let string = { ...obj.queryString, download: 1 };

    const data = await MinistryService.listAllGateMinistries(string);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('type', 'hidden');
    link.setAttribute('download', 'ministries.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
    setIsExporting(false);
  };

  const getPercentage = (value, total) => {
    let percentage = (value / total) * 100;
    return percentage % 1 !== 0 ? percentage.toFixed(2) : percentage;
  };

  const renderRows = () => {
    let totalOngoing = 0,
      totalNew = 0,
      totalBudget = 0,
      totalNextFiscalBudget = 0,
      totalIsYearlyFundingYes = 0,
      totalIsYearlyFundingNo = 0,
      totalDraft = 0,
      totalVerification = 0,
      totalSubmitted = 0,
      totalIdentification = 0,
      totalAppraisal = 0;

    let ministries = cloneDeep([...obj.ministryList]);

    ministries.push([]);

    return ministries.map((m, index) => {
      totalOngoing += m.totalOngoing ? parseInt(m.totalOngoing) : 0;
      totalIdentification += m.totalIdentificationProject
        ? parseInt(m.totalIdentificationProject)
        : 0;
      totalAppraisal += m.totalAppraisalProject ? parseInt(m.totalAppraisalProject) : 0;
      totalNew += m.totalNew ? parseInt(m.totalNew) : 0;
      totalBudget += m.totalBudget ? parseInt(m.totalBudget) : 0;
      totalNextFiscalBudget += m.totalNextFiscalBudget ? parseInt(m.totalNextFiscalBudget) : 0;
      totalIsYearlyFundingYes += m.totalIsYearlyFundingYes
        ? parseInt(m.totalIsYearlyFundingYes)
        : 0;
      totalIsYearlyFundingNo += m.totalIsYearlyFundingNo ? parseInt(m.totalIsYearlyFundingNo) : 0;
      totalDraft += m.totalDraft ? parseInt(m.totalDraft) : 0;
      totalVerification += m.totalVerification ? parseInt(m.totalVerification) : 0;
      totalSubmitted += m.totalSubmitted ? parseInt(m.totalSubmitted) : 0;

      return index < obj.ministryList.length ? (
        <Table.Row key={m.id}>
          <Table.Cell>
            <p className="project-title">{m.name}</p>
          </Table.Cell>
          <Table.Cell>{`${m.totalProjects} ${
            parseInt(m.totalProjects) !== 0
              ? '(' + getPercentage(m.totalProjects, obj.totalProjects) + '%)'
              : ''
          }`}</Table.Cell>
          <Table.Cell>{`${m.totalOngoing} ${
            parseInt(m.totalOngoing) !== 0
              ? '(' + getPercentage(m.totalOngoing, m.totalProjects) + '%)'
              : ''
          }`}</Table.Cell>
          <Table.Cell>{`${m.totalNew} ${
            parseInt(m.totalNew) !== 0
              ? '(' + getPercentage(m.totalNew, m.totalProjects) + '%)'
              : ''
          }`}</Table.Cell>
          <Table.Cell>{`${m.totalIdentificationProject} ${
            parseInt(m.totalIdentificationProject) !== 0
              ? '(' + getPercentage(m.totalIdentificationProject, obj.totalProjects) + '%)'
              : ''
          }`}</Table.Cell>
          <Table.Cell>{`${m.totalAppraisalProject} ${
            parseInt(m.totalAppraisalProject) !== 0
              ? '(' + getPercentage(m.totalAppraisalProject, obj.totalProjects) + '%)'
              : ''
          }`}</Table.Cell>
          <Table.Cell>{m.totalBudget ? m.totalBudget.toLocaleString() : 0}</Table.Cell>
          <Table.Cell>
            {m.totalNextFiscalBudget ? m.totalNextFiscalBudget.toLocaleString() : 0}
          </Table.Cell>
          <Table.Cell>{`${m.totalIsYearlyFundingYes} ${
            parseInt(m.totalIsYearlyFundingYes) !== 0
              ? '(' + getPercentage(m.totalIsYearlyFundingYes, m.totalProjects) + '%)'
              : ''
          }`}</Table.Cell>
          <Table.Cell>{`${m.totalIsYearlyFundingNo} ${
            parseInt(m.totalIsYearlyFundingNo) !== 0
              ? '(' + getPercentage(m.totalIsYearlyFundingNo, m.totalProjects) + '%)'
              : ''
          }`}</Table.Cell>
          <Table.Cell>{`${m.totalDraft} ${
            parseInt(m.totalDraft) !== 0
              ? '(' + getPercentage(m.totalDraft, m.totalProjects) + '%)'
              : ''
          }`}</Table.Cell>
          <Table.Cell>{`${m.totalVerification} ${
            parseInt(m.totalVerification) !== 0
              ? '(' + getPercentage(m.totalVerification, m.totalProjects) + '%)'
              : ''
          }`}</Table.Cell>

          <Table.Cell className="table-bottom-spacing">
            {`${m.totalSubmitted} ${
              parseInt(m.totalSubmitted) !== 0
                ? '(' + getPercentage(m.totalSubmitted, m.totalProjects) + '%)'
                : ''
            }`}

            <Can
              role={role}
              perform="ministries:action"
              yes={() => (
                <div className="actions flex">
                  <Link
                    to={`/add/ministry?ministryId=${m.id}`}
                    className="btn-outline btn-outline--small btn-outline--blue"
                  >
                    <i className="material-icons md-12 mr-2 edit">edit</i>
                    Edit
                  </Link>{' '}
                  {m.canDeleteAble ? (
                    <span
                      className="btn-outline btn-outline--small btn-outline--red"
                      onClick={() => {
                        setModalOpen(!modalOpen);
                        setValues({ ...obj, ministryId: m.id });
                      }}
                    >
                      <i className="material-icons md-12 mr-2 material-delete delete">
                        delete_outline
                      </i>
                      Delete{' '}
                    </span>
                  ) : (
                    <span
                      className="btn-outline btn-outline--small btn-disabled-delete"
                      data-tooltip={lang.ministry_associated_message}
                    >
                      <i className="material-icons md-12 mr-2 material-delete delete">
                        delete_outline
                      </i>
                      Delete {''}
                    </span>
                  )}
                </div>
              )}
              no={() => ''}
            />
          </Table.Cell>
        </Table.Row>
      ) : (
        <Table.Row key={index + 2}>
          <Table.Cell>
            <p className="project-title">Total</p>
          </Table.Cell>
          <Table.Cell>
            <p className="project-title">{`${obj.totalProjects} (100%)`}</p>
          </Table.Cell>
          <Table.Cell>
            <p className="project-title">{`${totalOngoing} ${
              parseInt(totalOngoing) !== 0
                ? '(' + getPercentage(totalOngoing, obj.totalProjects) + '%)'
                : ''
            }`}</p>
          </Table.Cell>
          <Table.Cell>
            <p className="project-title">{`${totalNew} ${
              parseInt(totalNew) !== 0
                ? '(' + getPercentage(totalNew, obj.totalProjects) + '%)'
                : ''
            }`}</p>
          </Table.Cell>
          <Table.Cell>
            <p className="project-title">{`${totalIdentification} ${
              parseInt(totalIdentification) !== 0
                ? '(' + getPercentage(totalIdentification, obj.totalProjects) + '%)'
                : ''
            }`}</p>
          </Table.Cell>

          <Table.Cell>
            <p className="project-title">{`${totalAppraisal} ${
              parseInt(totalAppraisal) !== 0
                ? '(' + getPercentage(totalAppraisal, obj.totalProjects) + '%)'
                : ''
            }`}</p>
          </Table.Cell>
          <Table.Cell>
            <p className="project-title">{`${totalBudget.toLocaleString()}`}</p>
          </Table.Cell>
          <Table.Cell>
            <p className="project-title">{`${totalNextFiscalBudget.toLocaleString()} `}</p>
          </Table.Cell>
          <Table.Cell>
            <p className="project-title">{`${totalIsYearlyFundingYes} ${
              parseInt(totalIsYearlyFundingYes) !== 0
                ? '(' + getPercentage(totalIsYearlyFundingYes, obj.totalProjects) + '%)'
                : ''
            }`}</p>
          </Table.Cell>
          <Table.Cell>
            <p className="project-title">{`${totalIsYearlyFundingNo} ${
              parseInt(totalIsYearlyFundingNo) !== 0
                ? '(' + getPercentage(totalIsYearlyFundingNo, obj.totalProjects) + '%)'
                : ''
            }`}</p>
          </Table.Cell>

          <Table.Cell>
            <p className="project-title">{`${totalDraft} ${
              parseInt(totalDraft) !== 0
                ? '(' + getPercentage(totalDraft, obj.totalProjects) + '%)'
                : ''
            }`}</p>
          </Table.Cell>
          <Table.Cell>
            <p className="project-title">{`${totalVerification} ${
              parseInt(totalVerification) !== 0
                ? '(' + getPercentage(totalVerification, obj.totalProjects) + '%)'
                : ''
            }`}</p>
          </Table.Cell>
          <Table.Cell>
            <p className="project-title">{`${totalSubmitted} ${
              parseInt(totalSubmitted) !== 0
                ? '(' + getPercentage(totalSubmitted, obj.totalProjects) + '%)'
                : ''
            }`}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <>
      {errors.success ? (
        <div>{errors.message}</div>
      ) : (
        <>
          <div className="dashboard-header justify-between">
            <div className="flex">
              <h2 className="header-main">{lang.ministries}</h2>
              <div className="project-stats flex">
                <div className="card">
                  <p className="card-info">
                    {obj.totalMinistries && obj.totalMinistries.toLocaleString()}
                  </p>
                  <p className="card-label">{lang.ministries}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex align-center justify-between chart-search-wrapper no-print">
            <div>
              <form onSubmit={(e) => handleSearch(e)}>
                <div className="form-search">
                  <div className="field-search">
                    <input
                      className="input-text input-icon"
                      onChange={(e) => handleSearchInputChange(e)}
                      type="text"
                      placeholder="Search by typing ministry name..."
                    />
                    <i className="material-icons md-24">search</i>
                    <button ref={(el) => (submitBtn = el)} className="button" type="submit">
                      {lang.search}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div>
              <button
                className="btn-transition btn-outline btn-outline--blue"
                onClick={() => window.print()}
              >
                <i className="material-icons mr-6">print</i>
                Print
              </button>
            </div>
          </div>
          <div className="export-add-button no-print">
            {!isExporting ? (
              <button
                className="btn-normal btn-dark btn-shadow--dark btn-transition export"
                onClick={exportMinistryData}
              >
                <i className="material-icons md-normal download mr-6">system_update_alt</i>
                {lang.export_data}
              </button>
            ) : (
              <button className="btn-normal btn-dark btn-shadow--dark btn-transition export">
                <i className="ui secondary loading button" />
                {lang.export_data}
              </button>
            )}
            <Can
              role={role}
              perform="ministries:action"
              yes={() => (
                <button
                  onClick={() => history.push('/add/ministry')}
                  className="btn-normal btn-blue btn-shadow--blue btn-transition"
                >
                  <Icon name="plus" />
                  {lang.add_ministry}
                </button>
              )}
              no={() => ''}
            />
          </div>

          <div className="table-container">
            {obj.isLoading ? (
              <LoaderNP />
            ) : obj.ministryList.length > 0 ? (
              <>
                <Table padded className="project-table no-action">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={3}>{lang.name}</Table.HeaderCell>
                      <Table.HeaderCell>{lang.total_projects}</Table.HeaderCell>
                      <Table.HeaderCell>{lang.ongoing}</Table.HeaderCell>
                      <Table.HeaderCell>{lang.future}</Table.HeaderCell>
                      <Table.HeaderCell>{lang.total_Identification}</Table.HeaderCell>
                      <Table.HeaderCell>{lang.total_appraisal}</Table.HeaderCell>
                      <Table.HeaderCell>{lang.total_budget}</Table.HeaderCell>
                      <Table.HeaderCell>{lang.next_yr_budget}</Table.HeaderCell>
                      <Table.HeaderCell>{lang.salbarsali}</Table.HeaderCell>
                      <Table.HeaderCell>{lang.no_salbarsali}</Table.HeaderCell>
                      <Table.HeaderCell>{lang.draft}</Table.HeaderCell>
                      <Table.HeaderCell>{lang.verification}</Table.HeaderCell>
                      <Table.HeaderCell>{lang.submitted}</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>{renderRows()}</Table.Body>
                </Table>
                <div className="justify-center no-print">
                  <Pagination
                    defaultActivePage={obj.activePage}
                    onPageChange={handlePaginationChange}
                    totalPages={obj.totalPage}
                    firstItem={{ 'aria-label': 'First item', content: 'First' }}
                    lastItem={{ 'aria-label': 'Last item', content: 'Last' }}
                    prevItem={{
                      'aria-label': 'Previous item',
                      content: 'Previous',
                    }}
                    nextItem={{ 'aria-label': 'First item', content: 'Next' }}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="empty">
                  <p as="p" size="">
                    {lang.no_ministry_found}
                  </p>
                </div>
              </>
            )}
          </div>
        </>
      )}

      <Modal
        dimmer="blurring"
        open={modalOpen}
        onClose={() => setModalOpen(!modalOpen)}
        size="mini"
      >
        <Modal.Header>{lang.delete_ministry}</Modal.Header>
        <Modal.Content>
          <p>{lang.are_you_sure_delete_ministry}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setModalOpen(!modalOpen)}>
            Cancel
          </Button>
          <Button
            positive
            content="Yes"
            onClick={(e) => {
              deleteMinistry(e);
            }}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default GateMinistryList;
