import Api from './api';
import ApiUrlConstant from '../helpers/constant/api-url';

class ProjectApi {
  async createProject(data) {
    try {
      const res = await Api.post(await ApiUrlConstant.PROJECT(), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async updateProject(data, params) {
    try {
      const res = await Api.patch(await ApiUrlConstant.PROJECT_ACTION(params), data);
      // let projectData =  await Api.get(await)
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async listProjects(val) {
    try {
      const res = await Api.get(await ApiUrlConstant.PROJECT(val));
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async uploadFile(data, params) {
    try {
      const res = await Api.patch(await ApiUrlConstant.UPLOAD_PROJECT_FILES_ACTION(params), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async getProject(params) {
    try {
      const res = await Api.get(await ApiUrlConstant.PROJECT_ACTION(params));
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async deleteProject(params) {
    try {
      const res = await Api.destroy(await ApiUrlConstant.PROJECT_ACTION(params));
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async deleteUploadedFile(params, data) {
    try {
      const res = await Api.put(await ApiUrlConstant.UPLOAD_PROJECT_FILES_ACTION(params), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async validateProjectData(params, data) {
    try {
      const res = await Api.post(await ApiUrlConstant.VALIDATE_PROJECT_DATA(params), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async updateAndGetProject(data, params) {
    try {
      const response = await Api.patch(await ApiUrlConstant.PROJECT_ACTION(params), data);
      if (response.body.success) {
        const res = await Api.get(await ApiUrlConstant.PROJECT_ACTION(params));
        return res.body;
      }
      return response.body;
    } catch (error) {
      return error;
    }
  }

  async listProvinces() {
    try {
      const response = await Api.get(await ApiUrlConstant.PROVINCE_LIST());

      return response.body;
    } catch (error) {
      return error;
    }
  }

  async exportProjects(val) {
    try {
      const res = await Api.get(await ApiUrlConstant.EXPORT_PROJECT(val), 'arraybuffer');
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async downloadZip(val) {
    try {
      const res = await Api.get(await ApiUrlConstant.DOWNLOAD_PROJECT_FILES(val), 'arraybuffer');
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async verificationMail(params, data) {
    try {
      const res = await Api.post(await ApiUrlConstant.VERIFICATION_MAIL(params), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async listProjectRanking(val) {
    try {
      const res = await Api.get(await ApiUrlConstant.PROJECT_RANKING(val));
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async listProjectMap(query) {
    try {
      const response = await Api.get(await ApiUrlConstant.PROJECT_MAP(query));
      return response.body;
    } catch (error) {
      return error;
    }
  }
}

export default new ProjectApi();
