import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, Redirect, useHistory } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';
import crypto from 'crypto-js';
import query from 'query-string';

import { auth } from '../../helpers';
import { login, setLoginError } from '../../services/Auth';

function Login({ location }) {
  const [username, setIdentification] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const dispatch = useDispatch();

  const isAuthenticated = useSelector(auth.checkIfAuthenticated);
  const loginError = useSelector(auth.loginError);

  const handleLogin = () => {
    let qs = query.parse(location.search);
    // console.log(qs.token, typeof qs.token)
    if (qs.token && qs.token === 'U2FsdGVkX1XoargPVr1byoJY6Hg9jmGByKalIGClAKnb8EcXBSGBK17eZvc7B8E0PYovp5p9iZnkb1otlUJQ==') {
        let username = 'npcviewer';
        let password = 'npcviewer@123'
        const formData = {data:crypto.AES.encrypt(JSON.stringify({ username, password }), process.env.REACT_APP_CIPHER_SECRET).toString()};
    //   const formData = {
    //     data: qs.token
    //   };

      dispatch(
        login(formData),
        () => {
          toastr.success('', `Login Successfully`);
          location.pathname('/dashboard');
        },
        (error) => {}
      );
    } else {
      history.push('/login');
    }
  };

  useEffect(() => {
    handleLogin();
  }, []);

  if (isAuthenticated) {
    toastr.success('', `Login Successfully`);
    return <Redirect to="/dashboard" />;
  }

  if (loginError && loginError.isError) {
    toastr.error('', `${loginError.error.message}`);
    dispatch(setLoginError());
  }

  return <div className="login-container"></div>;
}

export default withRouter(Login);
