const initialState = {
  economicGrowth: '',
  achievementOfSDG: '',
  participationLevel: '',
  sectoralInclusiveness: '',
  socialInclusiveness: '',
  sectoralGoal: '',
  achievementOfSectoralSDG: '',
  projectPrioritizationFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  prioritizationScore: 0
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'ERROR':
      return {};
    case 'PROJECT_STEP':
      return {
        ...state,
        step: payload.step,
      };
    case 'PRIORITIZATION_FETCHED':
      state = {...payload};
      let total;
      let totalScore = 0;

      for (let [key, val] of Object.entries(state)) {
        switch (key) {
          case 'economicGrowth':
            total = 15;
            totalScore = totalScore + ((total * +val) / 3);
            break;
          case 'achievementOfSDG':
            total = 15;
            totalScore = totalScore + ((total * +val) / 3);
            break;
          case 'participationLevel':
            total = 5;
            totalScore = totalScore + ((total * +val) / 3);
            break;
          case 'sectoralInclusiveness':
            total = 5;
            totalScore = totalScore + ((total * +val) / 3);
            break;
          case 'socialInclusiveness':
            total = 5;
            totalScore = totalScore + ((total * +val) / 3);
            break;
          case 'sectoralGoal':
            total = 20;
            totalScore = totalScore + ((total * +val) / 3);
            break;
          case 'achievementOfSectoralSDG':
            total = 15;
            totalScore = totalScore + ((total * +val) / 3);
            break;
          default:
            return {...state, prioritizationScore: ((totalScore * 100) / 80).toFixed(3)};
            break;
        }
      }
      return state;

    case 'NULL_PRIORITIZATION_FETCHED':
      return initialState;
      
    default:
      return state;
  }
}
