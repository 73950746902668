import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { useState } from 'react';

const ExampleRepeatField = () => {
  const[newFields, setNewFields] = useState([])

  const handleClick = ()=>{
    setNewFields([...newFields, <RepeatField/>])
  }

  const RepeatField = () => {
    return (
      // Repeat form field
      <div className="field-repeat">
        <Form.Group>
          <Form.Field
            control={Input}
            label="First name"
            placeholder="First name"
            width={4}
            className="field-align-bottom"
          />
          <Form.Field
            control={Input}
            label="Middle name"
            placeholder="Middle name"
            width={4}
            className="field-align-bottom"
          />
          <Form.Field
            control={Input}
            label="Last name"
            placeholder="Last name"
            width={4}
            className="field-align-bottom"
          />
          <Form.Field
            control={Input}
            label="Nick name test"
            placeholder="Nick name"
            width={4}
            className="field-align-bottom"
          />
        </Form.Group>
        <Form.Group>
          <Form.Field
            control={Input}
            label="First name"
            placeholder="First name"
            width={4}
            className="field-align-bottom"
          />
        </Form.Group>
        <div className=" btn-remove btn-outline btn-outline--small btn-outline--red">
          <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
          Delete
        </div>
      </div>
    );
  };
  return (
    <>
      {/* Form group start */}
      <Form.Group>
        <Form.Field
          control={Input}
          label="First name This is a very long label that you have ever seen"
          placeholder="First name"
          width={4}
          className="field-align-bottom"
        />
        <Form.Field
          control={Input}
          label="Middle name"
          placeholder="Middle name"
          width={4}
          className="field-align-bottom"
        />
        <Form.Field
          control={Input}
          label="Last name"
          placeholder="Last name"
          width={4}
          className="field-align-bottom"
        />
        <Form.Field
          control={Input}
          label="Nick name test"
          placeholder="Nick name"
          width={4}
          className="field-align-bottom"
        />
      </Form.Group>
      {/* Form group end */}

      {newFields}

      <Form.Group className="field-repeat-btn">
        <Form.Field>
          <button className="btn-text" onClick={handleClick} >+ Add more</button>
        </Form.Field>
      </Form.Group>
    </>
  );
};

export default ExampleRepeatField;
