const initialState = {
  note : {
    text: "",
    documentFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }]
  },
  development_strategy:"",
  development_strategy_remarks: "",
  developmental_impacts: "",
  developmental_impacts_remarks: "",
  economically_viable: "",
  economically_viable_remarks: "",
  financially_affordable: "",
  financially_affordable_remarks: "",
  institutional_capacity: "",
  institutional_capacity_remarks: "",
  technology_appropriate: "",
  technology_appropriate_remarks: "",
  other: "",
  action: "",
  assessments: [],
  projectId: null,
  stage: ''
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'ASSESSMENT_ERROR':
      return {};

    case 'ASSESSMENT_FETCHED':
      state = { ...payload };
      return state;

    case 'LIST_ALL_ASSESSMENT':
      return {
        ...state,
        assessments:  payload.data.assessments
      };

    default:
      return state;
  }
}
