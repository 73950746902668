export function fetching() {
  return { type: 'FETCHING' };
}

export function fetched(payload) {
  return { type: 'MODALITY_FETCHED', payload };
}

export function fetchedNullData() {
  return { type: 'NULL_MODALITY_FETCHED' };
}