import React, { useRef, useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

function ClusteredBarChart({
  data,
  titleHeader,
  seriesInfo,
  category,
  xAxisTitle,
  id,
  yAxisTitle,
}) {
  const chart1 = useRef(null);

  useLayoutEffect(() => {
    let chart = am4core.create(id, am4charts.XYChart);
    chart.colors.step = 3;
    chart.logo.disabled = true;

    let title = chart.titles.create();
    title.text = titleHeader;
    title.fontSize = 25;
    title.marginBottom = 18;
    // console.log(title.text);

    chart.legend = new am4charts.Legend();
    // chart.legend.position = 'top'
    chart.legend.paddingTop = 20;
    chart.legend.labels.template.maxWidth = 95;
    chart.numberFormatter.numberFormat = '#.';

    let infoLabel = chart.createChild(am4core.Label);
    infoLabel.text = '* C = Crore(करोड)';
    infoLabel.fontSize = 12;
    infoLabel.align = 'right';
    // infoLabel.isMeasured = false;
    // infoLabel.x = 0;
    // infoLabel.y = -20;

    chart.colors.list = [
      am4core.color('#B49BEC'),
      am4core.color('#B8CFFC'),
      am4core.color('#7CA7F8'),
      am4core.color('#C5F3F5'),
      am4core.color('#9BE8EC'),
      am4core.color('#5ACBD2'),
    ];

    let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = category;
    xAxis.title.text = xAxisTitle;
    xAxis.renderer.minGridDistance = 30;
    xAxis.renderer.cellStartLocation = 0.1;
    xAxis.renderer.cellEndLocation = 0.9;
    xAxis.renderer.grid.template.location = 0;
    xAxis.renderer.grid.template.disabled = true;
    let label = xAxis.renderer.labels.template;
    label.truncate = false;
    label.maxWidth = 100;
    xAxis.events.on('sizechanged', function (ev) {
      var axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      axis.renderer.labels.template.maxWidth = cellWidth;
      axis.renderer.labels.template.horizontalCenter = cellWidth < 120 ? 'right' : 'middle';
      axis.renderer.labels.template.verticalCenter = cellWidth < 120 ? 'middle' : 'top';
      label.rotation = cellWidth < 120 ? -45 : 0;
    });
    label.tooltipText = '{category}';

    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;
    yAxis.title.text = yAxisTitle;
    yAxis.title.fontSize = 16;
    yAxis.renderer.labels.template.fontSize = 12;
    
    if(title.text !== 'आयोजनाको अवधि') {
      yAxis.numberFormatter = new am4core.NumberFormatter();
      yAxis.numberFormatter.numberFormat = '#a!';
      yAxis.numberFormatter.bigNumberPrefixes = [
        { number: 1e+7, suffix: 'C' },
      ];
    } else {
      yAxis.numberFormatter.numberFormat = '#a';
    }

    function createSeries(value, name, title) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = value;
      series.dataFields.categoryX = category;
      series.name = name;

      series.events.on('hidden', arrangeColumns);
      series.events.on('shown', arrangeColumns);

      series.columns.template.tooltipText = '[bold]{name}\n[/]{categoryX}: {valueY}';
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fillOpacity = 1;
      series.tooltip.background.fill = am4core.color('#fff');
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = am4core.color('#697588');

      let bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.interactionsEnabled = false;
      bullet.dy = -15;
      bullet.contentHeight = '300px';
      bullet.rotation = title === 'वार्षिक विनियोजन' ? -90 : 0;
      bullet.width = '300px';
      bullet.marginTop = '300px';
      bullet.height = '100px';
      bullet.truncate = false;
      
      if(title !== 'आयोजनाको अवधि') {
        chart.numberFormatter.bigNumberPrefixes = [
          { number: 1e+7, suffix: 'C' },
        ];
        bullet.label.text = '{valueY.formatNumber("#.#a!")}';
      } else {
        bullet.label.text = '{valueY.formatNumber("#.a")}'; 
      }

      bullet.label.fill = am4core.color('#000');

      return series;
    }
    chart.data = data;

    seriesInfo.legend.map((data) => {
      for (const [key, value] of Object.entries(data)) {
        return createSeries(key, value, title.text);
      }
    });

    function arrangeColumns() {
      let series = chart.series.getIndex(0);

      let w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        let x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX');
        let x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX');
        let delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          let middle = chart.series.length / 2;

          let newIndex = 0;
          chart.series.each(function (series) {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex++;
            } else {
              series.dummyData = chart.series.indexOf(series);
            }
          });
          let visibleCount = newIndex;
          let newMiddle = visibleCount / 2;

          chart.series.each(function (series) {
            let trueIndex = chart.series.indexOf(series);
            let newIndex = series.dummyData;

            let dx = (newIndex - trueIndex + middle - newMiddle) * delta;

            series.animate(
              { property: 'dx', to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
            series.bulletsContainer.animate(
              { property: 'dx', to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
          });
        }
      }
    }

    chart1.current = chart;

    return () => {
      chart.dispose();
    };
  }, [data, category, titleHeader, xAxisTitle, yAxisTitle]);

  return <div id={id} style={{ width: '100%', height: '500px' }}></div>;
}

export default ClusteredBarChart;
