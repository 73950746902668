const initialState = {
	userFetching: true,
	userFetched: false,
	userError: null,
	user: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case 'USER_FETCHING':
			state = {
				...state,
				...{
          userError: null,
					userFetching: true,
					userFetched: false
				}
			};
			break;

		case 'USER_FETCHED':
			state = {
				...state,
				...{
					userError: null,
					user: payload.data,
					userFetching: false,
					userFetched: true
				}
			};
			break;
		case 'USER_ERROR':
			state = {
				...state,
				...{
					userError: payload,
					userFetching: false,
					userFetched: true
				}
			};
			break;

		default:
			break;
	}
	return state;
};

export default reducer;
