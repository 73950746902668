import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Grid } from 'semantic-ui-react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { cloneDeep } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { useUrlSearchParams } from 'use-url-search-params';

import FileUpload from '../FormType/FileUpload/index';

import ProjectApi from '../../../services/Gate/Project';

import fiscalYear from '../../../helpers/fiscalYear';
import { auth } from '../../../helpers';
import { localization } from '../../../localization';

import {
  addRepeaterField,
  handleEditorChange,
  handleInputChange,
  removeRepeaterField,
} from '../../../actions';
import { fetched, reset } from '../../../reducers/form/QuarterlyAssessment/action';

import CurrencyField from '../FormType/CurrencyField';
import Can from '../../../components/Can/can';
import PageNotFound from '../../PageNotFound';
import Loader from '../../../components/Loader/Loader'

const QuarterlyAssessment = ({ history }) => {
  const dispatch = useDispatch();
  const [params] = useUrlSearchParams();

  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['quarterly_assessments'];

  let { quarterlyAssessment } = useSelector((state) => state);
  const user = useSelector(auth.getCurrentUser);
  const [isDisplay, setDisplay] = useState(false);
  const [assessmentIndex, setAssessmentLength] = useState(0);
  const [loading, setLoading] = useState(true);

  const [visible,setVisible] = useState(false);

  const stage =
    params && params.gate === 'project_identification'
      ? 'identification'
      : params.gate === 'project_appraisal'
      ? 'appraisal'
      : '';

  const project = history.location.pathname;

  const projectId = project.split('/')[2];

  const [errors, setError] = useState({
    financial_progress_error: {
      fProgress: false,
      progressAmount: false,
      progressAmountWord: false,
    },
    pProgress_error: false,
    progressAmount_error: false,
  });

  useEffect(() => {
    if (projectId && stage) {
      if (params && params.assessmentId) {
        fetchQuarterlyAssessment();
      } else {
        listAllQuarterlyAssessment();
      }
    }
  }, [projectId, stage, params]);

  const fetchQuarterlyAssessment = () => {
    ProjectApi.fetchQuarterlyAssessment({ assessmentId: params.assessmentId, projectId: projectId })
      .then((response) => {
        dispatch(
          fetched({
            ...response.data.assessment,
          })
        );
        if (
          response.data.assessment.serial_number ===
          process.env.REACT_APP_LIMIT_FOR_QUARTERLY_ASSESSMENT
        ) {
          setDisplay(true);
          setAssessmentLength(process.env.REACT_APP_LIMIT_FOR_QUARTERLY_ASSESSMENT);
        }
        if(response.success){
          setVisible(response.data.hasPublished);
          setLoading(false)
        }
      })
      .catch((err) => {
        setVisible(false);
        setLoading(false);
        toastr.error('', `${err.message}`);
      });
  };

  const listAllQuarterlyAssessment = () => {
    ProjectApi.listQuarterlyAssessment({ id: projectId }, stage, fiscalYear())
      .then((response) => {
        dispatch(reset());
        setLoading(false);
        if (
          response.data.assessments.length ===
          process.env.REACT_APP_LIMIT_FOR_QUARTERLY_ASSESSMENT - 1
        ) {
          setDisplay(true);
          setAssessmentLength(process.env.REACT_APP_LIMIT_FOR_QUARTERLY_ASSESSMENT);
        }
        if(response.data.hasPublished){
          setVisible(response.data.hasPublished);
        }
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };


  const handleCreate = (e) => {
    e.preventDefault();

    const data = cloneDeep(quarterlyAssessment);

    let canSubmit = true;
    const errors = {};

    const requiredFields = [
      'fProgress',
      'pProgress',
      'progressAmount',
      'allocatedBudget',
      'allocatedBudgetWord',
      'totalExpenditure',
      'totalExpenditureWord',
      'estimatedAnnualBudget',
      'estimatedAnnualBudgetWord',
      'projectedBudgetRequirement',
      'projectedBudgetRequirementWord',
    ];

    const regexCheckNumberFields = [
      'fProgress',
      'pProgress',
      'allocatedBudget',
      'totalExpenditure',
      'allocatedBudgetExchangeRate',
      'totalExpenditureExchangeRate',
      'estimatedAnnualBudget',
      'estimatedAnnualBudgetExchangeRate',
      'projectedBudgetRequirement',
      'projectedBudgetRequirementExchangeRate',
      'estimatedAnnualBudget',
      'estimatedAnnualBudgetExchangeRate',
      'projectedBudgetRequirement',
      'projectedBudgetRequirementExchangeRate',
      'progressAmount',
    ];

    const regexCheckWordFields = [
      'allocatedBudgetWord',
      'totalExpenditureWord',
      'estimatedAnnualBudgetWord',
      'projectedBudgetRequirementWord',
      'estimatedAnnualBudgetWord',
      'projectedBudgetRequirementWord',
    ];

    let regexNumber = /^[+]?\d+(\.\d+)?$/;
    let regexWord = /^[^[0-9!@*,_\.~`$#%\^\(\)-+='""{}><\?\\\/||\+]*$/;
    let regexPercentage = /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/;

    for (let d in data) {
      if (d === 'physical_progress') {
        errors[`${d}_error`] = {};
        if (d === 'financial_progress') {
          if (!regexPercentage.test(data[d]['fProgress'])) {
            errors[`fProgress_error`] = true;
            canSubmit = false;
          }
        }
        if (d === 'physical_progress') {
          if (!regexPercentage.test(data[d]['pProgress'])) {
            errors[`pProgress_error`] = true;
            canSubmit = false;
          }
        }

        for (let key in data[d]) {
          if (
            data[d][key] !== '' &&
            regexCheckNumberFields.includes(key) &&
            !regexNumber.test(data[d][key])
          ) {
            errors[`${key}_error`] = true;
            canSubmit = false;
          }

          if (
            data[d][key] !== '' &&
            regexCheckWordFields.includes(key) &&
            !regexWord.test(data[d][key])
          ) {
            errors[`${key}_error`] = true;
            canSubmit = false;
          }

          if (requiredFields.includes(key) && (data[d][key] === '' || data[d][key].length === 0)) {
            errors[`${key}_error`] = true;
            canSubmit = false;
          }
        }
      }
      if (d === 'financial_progress') {
        errors[`${d}_error`] = {};

        for (let key in data[d]) {
          if (
            data[d][key] !== '' &&
            regexCheckNumberFields.includes(key) &&
            !regexNumber.test(data[d][key])
          ) {
            errors[`${d}_error`][`${key}`] = true;
            canSubmit = false;
          }

          if (
            data[d][key] !== '' &&
            regexCheckWordFields.includes(key) &&
            !regexWord.test(data[d][key])
          ) {
            errors[`${d}_error`][`${key}`] = true;
            canSubmit = false;
          }

          if (requiredFields.includes(key) && (data[d][key] === '' || data[d][key].length === 0)) {
            errors[`${d}_error`][`${key}`] = true;
            canSubmit = false;
          }
        }
      }

      if (assessmentIndex === process.env.REACT_APP_LIMIT_FOR_QUARTERLY_ASSESSMENT) {
        if (
          d === 'current_fiscal_year_expenditure' ||
          d === 'coming_fiscal_year_expenditure' ||
          d === 'next_fiscal_year_project_budget_requirement'
        ) {
          errors[`${d}_error`] = {};

          for (let key in data[d]) {
            if (
              data[d][key] !== '' &&
              regexCheckNumberFields.includes(key) &&
              !regexNumber.test(data[d][key])
            ) {
              errors[`${d}_error`][`${key}`] = true;
              canSubmit = false;
            }

            if (
              data[d][key] !== '' &&
              regexCheckWordFields.includes(key) &&
              !regexWord.test(data[d][key])
            ) {
              errors[`${key}_error`] = true;
              canSubmit = false;
            }

            if (
              requiredFields.includes(key) &&
              (data[d][key] === '' || data[d][key].length === 0)
            ) {
              errors[`${d}_error`][`${key}`] = true;
              canSubmit = false;
            }
          }
        }
      }
    }

    setError(errors);

    quarterlyAssessment.projectId = projectId;
    quarterlyAssessment.stage = stage;

    if (canSubmit) {
      if (params.assessmentId) {
        ProjectApi.updateQuarterlyAssessment(
          { assessmentId: params.assessmentId },
          quarterlyAssessment
        )
          .then((response) => {
            if (response.success) {
              dispatch(reset());
              history.push(`/project/${projectId}#quarterly-assessments`);
              toastr.success('', `Quarterly Assessment Updated successfully!`);
            } else {
              throw new Error(response.error.message);
            }
          })
          .catch((err) => {
            toastr.error('', `${err.message}`);
          });
      } else {
        ProjectApi.quarterlyAssessment(quarterlyAssessment)
          .then((response) => {
            if (response.success) {
              dispatch(reset());
              history.push(`/project/${projectId}#quarterly-assessments`);
              toastr.success('', `Quarterly Assessment sent successfully!`);
            } else {
              throw new Error(response.error.message);
            }
          })
          .catch((err) => {
            toastr.error('', `${err.message}`);
          });
      }
    }
  };

  return loading ? <Loader/> : (
    <Can
      role={user.role}
      perform="quarterly:action"
      data={visible}
      yes={() => (
        <Grid columns="equal">
          <Grid.Column className="form-container print">
            <div className="project-detail">
              <div className="project-detail-container">
                <Form className="form-new">
                  {/* Financial Progress section */}
                  <div className="form-group-container">
                    <Form.Group>
                      <Form.Field
                        control={Input}
                        required={true}
                        label={lang.financial_progress}
                        width={4}
                        className={
                          errors.financial_progress_error.fProgress
                            ? 'error field-align-bottom'
                            : 'field-align-bottom'
                        }
                      >
                        <Input
                          name="fProgress"
                          value={quarterlyAssessment.financial_progress.fProgress}
                          placeholder="Financial progress till date in %"
                          onChange={(event) => {
                            dispatch(
                              handleInputChange(
                                {
                                  event,
                                  info: quarterlyAssessment,
                                  field: 'financial_progress',
                                },
                                fetched
                              )
                            );
                            setError({
                              ...errors,
                              financial_progress_error: {
                                ...errors.financial_progress_error,
                                fProgress: false,
                              },
                            });
                          }}
                          label="%"
                          labelPosition="right"
                        />
                      </Form.Field>
                      {/* <Form.Field
                      control={Input}
                      required={true}
                      label="Financial progress In Amount"
                      placeholder="Financial progress in amount"
                      width={4}
                      className={errors.progressAmount_error ? 'error' : 'field-align-bottom'}
                      name="progressAmount"
                      value={quarterlyAssessment.financial_progress.progressAmount}
                      onChange={(event) => {
                        dispatch(
                          handleInputChange(
                            {
                              event,
                              info: quarterlyAssessment,
                              field: 'financial_progress',
                            },
                            fetched
                          )
                        );
                        setError({ ...errors, progressAmount_error: false });
                      }}
                    /> */}
                      <CurrencyField
                        costLabel={lang.financial_progress_amt}
                        costName="progressAmount"
                        exchangeRateLabel="Exchange rate"
                        exchangeRateName="progressAmountExchangeRate"
                        costInWordsLabel={lang.financial_progress_words}
                        costInWordsName="progressAmountWord"
                        formInfo={quarterlyAssessment}
                        dispatch={dispatch}
                        fetched={fetched}
                        field="financial_progress"
                        required={true}
                        amountWordRequired={false}
                        error={errors.financial_progress_error}
                      />
                    </Form.Group>
                    <FileUpload
                      formName="progress"
                      label="upload_document_financial"
                      field="financial_progress"
                      fileData={quarterlyAssessment.financial_progress.financialDocumentFile}
                      name="financialDocumentFile"
                      form={quarterlyAssessment}
                      fetched={fetched}
                      projectId={projectId}
                    />
                  </div>

                  {/*  Expenditure of current fiscal year section */}

                  {isDisplay && (
                    <div className="form-group-container">
                      <p className="form-info">{lang.expenditure_current_fiscal_year}</p>
                      <Form.Group>
                        <Form.Field
                          control={Input}
                          width={4}
                          name="fiscalYear"
                          label={lang.fiscal_year}
                          value={quarterlyAssessment.current_fiscal_year_expenditure.fiscalYear}
                        />
                        <CurrencyField
                          costLabel={lang.allocated_budget}
                          costName="allocatedBudget"
                          exchangeRateLabel="Exchange rate"
                          exchangeRateName="allocatedBudgetExchangeRate"
                          costInWordsLabel={lang.allocated_budget_in_word}
                          costInWordsName="allocatedBudgetWord"
                          formInfo={quarterlyAssessment}
                          dispatch={dispatch}
                          fetched={fetched}
                          field="current_fiscal_year_expenditure"
                          required={true}
                          error={errors.current_fiscal_year_expenditure_error}
                        />
                      </Form.Group>
                      <Form.Group>
                        <CurrencyField
                          costLabel={lang.total_expenditure}
                          costName="totalExpenditure"
                          exchangeRateLabel="Exchange rate"
                          exchangeRateName="totalExpenditureExchangeRate"
                          costInWordsLabel={lang.total_expenditure_in_word}
                          costInWordsName="totalExpenditureWord"
                          formInfo={quarterlyAssessment}
                          dispatch={dispatch}
                          fetched={fetched}
                          field="current_fiscal_year_expenditure"
                          required={true}
                          error={errors.current_fiscal_year_expenditure_error}
                        />
                      </Form.Group>
                      <FileUpload
                        formName="progress"
                        label="upload_document_justification"
                        field="current_fiscal_year_expenditure"
                        fileData={
                          quarterlyAssessment.current_fiscal_year_expenditure.currentDocumentFile
                        }
                        name="currentDocumentFile"
                        form={quarterlyAssessment}
                        fetched={fetched}
                        projectId={projectId}
                      />
                    </div>
                  )}

                  {/* Expenditure Budgeted Requirement for coming fiscal year section */}

                  {isDisplay && (
                    <div className="form-group-container">
                      <p className="form-info">{lang.budget_coming_fiscal_year}</p>
                      <Form.Group>
                        <Form.Field
                          control={Input}
                          width={4}
                          name="fiscalYear"
                          label={lang.fiscal_year}
                          value={quarterlyAssessment.coming_fiscal_year_expenditure.fiscalYear}
                          className="field-align-bottom"
                        />
                        <CurrencyField
                          costLabel={lang.estimated_annual_budget}
                          costName="estimatedAnnualBudget"
                          exchangeRateLabel="Exchange rate"
                          exchangeRateName="estimatedAnnualBudgetExchangeRate"
                          costInWordsLabel={lang.estimated_annual_budget_word}
                          costInWordsName="estimatedAnnualBudgetWord"
                          formInfo={quarterlyAssessment}
                          dispatch={dispatch}
                          fetched={fetched}
                          field="coming_fiscal_year_expenditure"
                          required={true}
                          error={errors.coming_fiscal_year_expenditure_error}
                        />
                      </Form.Group>
                      <FileUpload
                        formName="progress"
                        label="upload_document_total_expenditure"
                        field="coming_fiscal_year_expenditure"
                        fileData={
                          quarterlyAssessment.coming_fiscal_year_expenditure.comingDocumentFile
                        }
                        name="comingDocumentFile"
                        form={quarterlyAssessment}
                        fetched={fetched}
                        projectId={projectId}
                      />
                    </div>
                  )}

                  {/* Expenditure Budgeted Requirement for following 2 fiscal year section */}

                  {isDisplay && (
                    <div className="form-group-container">
                      <p className="form-info">{lang.two_fiscal_year}</p>
                      {quarterlyAssessment.next_fiscal_year_project_budget_requirement.map(
                        (value, index) => {
                          return (
                            <div
                              className={index === 0 ? 'field-repeat--first' : 'field-repeat'}
                              key={index}
                            >
                              <Form.Group>
                                <Form.Field
                                  control={Input}
                                  width={4}
                                  name="fiscalYear"
                                  label={lang.fiscal_year}
                                  className="field-align-bottom"
                                  value={value.fiscalYear}
                                />
                                <CurrencyField
                                  costLabel={lang.two_fiscal_year_projected}
                                  costName="projectedBudgetRequirement"
                                  exchangeRateLabel="Exchange rate"
                                  exchangeRateName="projectedBudgetRequirementExchangeRate"
                                  costInWordsLabel={lang.two_fiscal_year_projected_word}
                                  costInWordsName="projectedBudgetRequirementWord"
                                  formInfo={quarterlyAssessment}
                                  dispatch={dispatch}
                                  fetched={fetched}
                                  field="next_fiscal_year_project_budget_requirement"
                                  required={true}
                                  index={index}
                                />
                              </Form.Group>
                              {index > 0 && (
                                <div
                                  className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                                  onClick={(event) => {
                                    dispatch(
                                      removeRepeaterField(
                                        {
                                          event,
                                          info: quarterlyAssessment,
                                          field: 'next_fiscal_year_project_budget_requirement',
                                          index: index,
                                        },
                                        fetched
                                      )
                                    );
                                  }}
                                >
                                  <i className="material-icons md-12 mr-2 material-delete">
                                    delete_outline
                                  </i>
                                  Delete
                                </div>
                              )}
                            </div>
                          );
                        }
                      )}
                      <Form.Group className="field-repeat-btn">
                        <Form.Field>
                          <button
                            className="btn-text"
                            onClick={(event) => {
                              dispatch(
                                addRepeaterField(
                                  {
                                    event,
                                    info: quarterlyAssessment,
                                    field: 'next_fiscal_year_project_budget_requirement',
                                  },
                                  fetched
                                )
                              );
                            }}
                          >
                            + Add More
                          </button>
                        </Form.Field>
                      </Form.Group>
                      <FileUpload
                        formName="progress"
                        label="upload_document_budget_justification"
                        field="physical_progress"
                        fileData={quarterlyAssessment.physical_progress.projectedBudgetFile}
                        name="projectedBudgetFile"
                        form={quarterlyAssessment}
                        fetched={fetched}
                        projectId={projectId}
                      />
                    </div>
                  )}

                  {/*  Physical Progress section */}
                  <div className="form-group-container">
                    <Form.Group>
                      <Form.Field
                        control={Input}
                        required={true}
                        name="pProgress"
                        placeholder="In percentage Eg:35"
                        width={8}
                        className={errors.pProgress_error ? 'error' : 'field-align-bottom'}
                        label={lang.physical_progress}
                      >
                        <Input
                          name="pProgress"
                          value={quarterlyAssessment.physical_progress.pProgress}
                          placeholder="Physical progress till date in %"
                          onChange={(event) => {
                            dispatch(
                              handleInputChange(
                                {
                                  event,
                                  info: quarterlyAssessment,
                                  field: 'physical_progress',
                                },
                                fetched
                              )
                            );
                            setError({ ...errors, pProgress_error: false });
                          }}
                          label="%"
                          labelPosition="right"
                        />
                      </Form.Field>
                    </Form.Group>
                    <FileUpload
                      formName="progress"
                      label="upload_document_physical_document"
                      field="physical_progress"
                      fileData={quarterlyAssessment.physical_progress.documentFile}
                      name="documentFile"
                      form={quarterlyAssessment}
                      fetched={fetched}
                      projectId={projectId}
                    />
                  </div>

                  {/*  Risks section */}
                  <div className="form-group-container">
                    <Form.Group>
                      <Form.Field width={16}>
                        <label>{lang.challenges_risks}</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={quarterlyAssessment.risks.text}
                          onBlur={(event, editor) => {
                            const fieldData = editor.getData();
                            dispatch(
                              handleEditorChange(
                                {
                                  name: 'text',
                                  formInfo: quarterlyAssessment,
                                  field: 'risks',
                                  fieldData,
                                },
                                fetched
                              )
                            );
                          }}
                          config={{
                            toolbar: [
                              'Heading',
                              'bold',
                              'italic',
                              'bulletedList',
                              'numberedList',
                              'blockQuote',
                              'link',
                              'insertTable',
                            ],
                          }}
                        />
                      </Form.Field>
                    </Form.Group>
                    <FileUpload
                      formName="progress"
                      label="upload_challenges_risks"
                      field="risks"
                      fileData={quarterlyAssessment.risks.riskDocumentFile}
                      name="riskDocumentFile"
                      form={quarterlyAssessment}
                      fetched={fetched}
                      projectId={projectId}
                    />
                  </div>

                  {/*  Remarks section */}
                  <div className="form-group-container">
                    <Form.Group>
                      <Form.Field width={16}>
                        <label>{lang.remarks}</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={quarterlyAssessment.remarks}
                          onBlur={(event, editor) => {
                            const fieldData = editor.getData();
                            dispatch(
                              handleEditorChange(
                                {
                                  name: 'remarks',
                                  formInfo: quarterlyAssessment,
                                  fieldData,
                                },
                                fetched
                              )
                            );
                          }}
                          config={{
                            toolbar: [
                              'Heading',
                              'bold',
                              'italic',
                              'bulletedList',
                              'numberedList',
                              'blockQuote',
                              'link',
                              'insertTable',
                            ],
                          }}
                        />
                      </Form.Field>
                    </Form.Group>
                  </div>

                  {/* Action button */}
                  <div className="form-actions flex">
                    <button className="btn-rect btn-rect--small btn-blue" onClick={handleCreate}>
                      Save
                    </button>
                    <button
                      className="btn-rect btn-rect--small btn-dark"
                      onClick={() => history.push(`/project/${projectId}`)}
                    >
                      Exit
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      )}
      no={() => <PageNotFound message={`You can't access this interface`} />}
    />
  );
};

export default QuarterlyAssessment;
