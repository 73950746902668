import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import ProjectApi from '../services/Form';
import DashboardService from '../services/Dashboard';

import { auth } from '../helpers';

import { localization } from '../localization';

const Ranking = ({ status }) => {
  const [ranking, setProjectRanking] = useState({});
  const [projectStats, setProjectStats] = useState([]);
  const language = useSelector(auth.getSelectedLanguage);

  const lang = localization[language].ranking;

  const getProjectRanking = (statusVal) => {
    ProjectApi.listProjectRanking(statusVal)
      .then((response) => {
        if (response.success) {
          setProjectRanking(response.ranking);
        } else {
          toastr.error('', `${response.error.message}`);
        }
      })
      .catch((err) => toastr.error('', `${err.message}`));
  };

  useEffect(() => {
    getProjectRanking(status);

    DashboardService.getProjectStats().then((res) => {
      setProjectStats(res);
    });
  }, [status]);

  return (
    <>
      <div className="body-container">
        <div className="dashboard-header justify-between">
          <div className="flex">
            <h2 className="header-main">{lang.project_ranking}</h2>
            <div className="project-stats flex">
              <div className="card">
                <p className="card-info">{projectStats.totalProject}</p>
                <p className="card-label">{lang.projects}</p>
              </div>
              <div className="card">
                <p className="card-info">{projectStats.onGoingProject}</p>
                <p className="card-label">{lang.ongoing}</p>
              </div>
              <div className="card">
                <p className="card-info">{projectStats.futureProject}</p>
                <p className="card-label">{lang.future}</p>
              </div>
            </div>
          </div>
          <div className="project-cost flex">
            <div className="card">
              <p className="card-info">
                <span className="currency">{lang.npr}</span>
                {projectStats.totalOriginalCost &&
                  Math.round(projectStats.totalOriginalCost).toLocaleString()}
              </p>
              <p className="card-label">{lang.project_cost}</p>
            </div>
            <div className="card">
              <p className="card-info">
                <span className="currency">{lang.npr}</span>
                {projectStats.totalEstimateBudget &&
                  Math.round(projectStats.totalEstimateBudget).toLocaleString()}
              </p>
              <p className="card-label">{lang.next_fiscal_year}</p>
            </div>
          </div>
        </div>
        <div className="ranking-container">
          <div className="ranking-details">
            <div className="ranking">
              <div className="header small">
                {status === 'new' ? lang.new_projects : lang.ongoing_projects}
              </div>
              <div className="priority-wrap">
                <div className="p-priority">
                  <p>Low</p>
                  <p>Medium </p>
                  <p>High</p>
                </div>
                <div className="plabel">
                  {lang.project_priority}
                  <i className="material-icons md-10 mt-6">arrow_right_alt</i>
                </div>
              </div>
              <div className="ranking__cards">
                <p className="hint">{lang.click_on_cards}</p>
                <ul>
                  {Object.entries(ranking).map(([key, value]) => (
                    <li
                      key={key}
                      className={`rank-card ${key} ${value.length > 0 ? 'have-link' : ''}`}
                    >
                      {value.length > 0 ? (
                        <a href={`/dashboard?ranking=${key}&status=${status}`}>
                          <div className="grade">{key}</div>
                          <div className="program-no">
                            {value.length}
                            <span>{lang.projects}</span>
                          </div>
                        </a>
                      ) : (
                        <>
                          <div className="grade">{key}</div>
                          <div className="program-no">
                            {value.length}
                            <span>{lang.projects}</span>
                          </div>
                        </>
                      )}
                    </li>
                  ))}
                </ul>
                <div className="readiness-wrap">
                  <div className="project-readiness">
                    <p>Low</p>
                    <p>Medium </p>
                    <p>High</p>
                  </div>
                  <div className={`plabel ${status}`}>
                    {status === 'new' ? lang.project_readiness : lang.project_implementation_status}
                    <i className="material-icons md-10 ml-6">arrow_right_alt</i>
                  </div>
                </div>
              </div>
            </div>

            <div className="calculation">
              <div className="question">How is it calculated?</div>
              <div className="answer">
                <p>
                  {' '}
                  Projects are ranked into a ranking matrix based on both prioritization score and
                  {status === 'new' ? 'readiness assessment.' : 'implementation status.'}
                </p>
                {status === 'new' ? (
                  <p>
                    The prioritization score is calculated based on the given score and the
                    readiness score is calculated based on the readiness sub-criteria assessment. If
                    the score obtained is
                  </p>
                ) : (
                  <p>
                    The prioritization score is calculated based on the given score and the
                    implementation status is calculated based on the project completion time. If the
                    prioritization score obtained is
                  </p>
                )}
                <ul>
                  <li> more than 75%, the project is ranked as High</li>
                  <li> between 50% to 75% as Medium and</li>
                  <li> less than 50% is ranked as Low in prioritization</li>
                </ul>

                {status === 'new' ? (
                  ''
                ) : (
                  <>
                    <p>And the project completion time is</p>
                    <ul>
                      <li>within 1 year is ranked as High</li>
                      <li>within 2 years is ranked as Medium and </li>
                      <li>take 3 or more years to complete is ranked as Low</li>
                    </ul>
                  </>
                )}

                <p>
                  {`For eg. if a project obtains more than 75% for each prioritization score and
                  ${
                    status === 'new' ? 'readiness assessment' : 'implementation status'
                  }, the project
                  is ranked as Category A in the ranking matrix. If the prioritization score is 75%
                  and
                  ${
                    status === 'new' ? 'readiness assessment' : 'implementation status'
                  } lies between
                  75% to 50%, it is ranked as Category B.Accordingly, the system automatically
                  calculates the scores based on which it categorizes all of the qualified projects
                  in the ranking matrix of variables A, A-, B, B-, C, C- D, D- and E.`}
                </p>
                <p>
                  For more information on project selection and prioritization, please&nbsp;
                  <a href="https://www.npc.gov.np/images/category/National_Project_Bank_Guidelines.pdf">
                    see the guideline
                  </a>
                  &nbsp;on Section B of subsection B.4 &quot;Project Selection and Prioritization
                  Framework&quot;.
                </p>
              </div>
            </div>
          </div>
          <div className="ranking-dashboard">
            <p className="title">Ranking Index</p>
            <ul>
              {Object.entries(ranking).map(
                ([key]) =>
                  key && (
                    <li key={key} className={`rank-card ${key} ${status}`}>
                      <div>{key}</div>
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ranking;
