import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Tab } from 'semantic-ui-react';

import { API_URL, auth } from '../../../helpers';

import { localization } from '../../../localization';
// import VerificationDocument from './VerificationDocumentSection';

const ProjectReadinessSection = ({ gate }) => {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['project_readiness_view'];
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const [projectData, setProjectData] = useState();

  useEffect(() => {
    setProjectData(projectInfo[gate]);
  }, [gate]);

  return (
    <div name="project-readiness">
      <div className="project-section" id="project-readiness">
        <div className="header medium">{lang.section_name}</div>
        <Tab.Pane>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.desk_study} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectData, 'project_readiness.deskStudy')}</p>
              </Grid.Column>
            </Grid.Row>
            {get(projectData, 'project_readiness.deskStudy') === 'Yes' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.document_desk_study}:</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData &&
                        projectData &&
                        projectData.project_readiness &&
                        projectData.project_readiness.deskStudyFile.map((item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>
                                {item.originalName ? item.originalName: item.fileName}
                                {item.fileName.length > 0 && (
                                  <>
                                    {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                    <a
                                      href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      download
                                    >
                                      (Download)
                                    </a>
                                  </>
                                )}
                              </p>
                            )
                          );
                        })}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.file_description} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData &&
                        projectData &&
                        projectData.project_readiness &&
                        projectData.project_readiness.deskStudyFile.map((item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>{item.fileDescription}</p>
                            )
                          );
                        })}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              ''
            )}

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.pre_feasibility_study} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_readiness.preFeasibility')}
                </p>
              </Grid.Column>
            </Grid.Row>
            {get(projectData, 'project_readiness.preFeasibility') === 'Yes' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.document_feasibility_study} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {/* {get(projectData, 'project_readiness.preFeasibilityFile')} */}
                      {projectData &&
                        projectData &&
                        projectData.project_readiness &&
                        projectData.project_readiness.preFeasibilityFile.map((item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>
                                {item.originalName ? item.originalName: item.fileName}
                                {item.fileName.length > 0 && (
                                  <>
                                    {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                    <a
                                      href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                      target="_blank"
                                      download
                                      rel="noopener noreferrer"
                                    >
                                      (Download)
                                    </a>
                                  </>
                                )}
                              </p>
                            )
                          );
                        })}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.file_description} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData &&
                        projectData.project_readiness &&
                        projectData.project_readiness.preFeasibilityFile.map((item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>{item.fileDescription}</p>
                            )
                          );
                        })}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              ''
            )}

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.project_concept_note} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_readiness.projectConcept')}
                </p>
              </Grid.Column>
            </Grid.Row>
            {get(projectData, 'project_readiness.projectConcept') === 'Yes' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.document_for_project_concept_study} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData &&
                        projectData.project_readiness &&
                        projectData.project_readiness.projectConceptFile.map((item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>
                                {item.originalName ? item.originalName: item.fileName}
                                {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                {item.fileName.length > 0 && (
                                  <>
                                    {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                    <a
                                      href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                      target="_blank"
                                      download
                                      rel="noopener noreferrer"
                                    >
                                      (Download)
                                    </a>
                                  </>
                                )}
                              </p>
                            )
                          );
                        })}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.file_description} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData &&
                        projectData.project_readiness &&
                        projectData.project_readiness.projectConceptFile.map((item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>{item.fileDescription}</p>
                            )
                          );
                        })}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              ''
            )}
            {gate === 'project_appraisal' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.feasibility_study} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'project_readiness.feasibilityStudy')}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                {get(projectData, 'project_readiness.feasibilityStudy') === 'No' &&
                  get(projectData, 'project_readiness.feasibilityStudyDateAD') &&
                  get(projectData, 'project_readiness.feasibilityStudyDateAD') !== '' && (
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.estimated_date} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <p className="project-info">
                          {get(projectData, 'project_readiness.feasibilityStudyDateAD')} (
                          {get(projectData, 'project_readiness.feasibilityStudyDateBS')})
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                {get(projectData, 'project_readiness.feasibilityStudy') === 'Yes' ? (
                  <>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.document_for_feasibility_study} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.feasibilityStudyFile.map(
                              (item, index) => {
                                return (
                                  Object.keys(item).length !== 0 && (
                                    <p key={index}>
                                      {item.originalName ? item.originalName: item.fileName}
                                      {item.fileName.length > 0 && (
                                        <>
                                          {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                          <a
                                            href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                            target="_blank"
                                            download
                                            rel="noopener noreferrer"
                                          >
                                            (Download)
                                          </a>
                                        </>
                                      )}
                                    </p>
                                  )
                                );
                              }
                            )}
                          {/* <div>
											Verification status:{' '}
											{projectInfo.project_readiness.feasibiltyStudyVerified ===
											'true' ? (
												<Label className='verified'>Verified</Label>
											) : (
												<Label className='not-verified'>Not verified</Label>
											)}
										</div> */}
                          {/* <VerificationDocument
											projectData={projectInfo.project_readiness}
											field='feasibiltyStudyVerified'
											projectId={projectInfo.id}
											name='Feasibility Study'
										/> */}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.file_description} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.feasibilityStudyFile.map(
                              (item, index) => {
                                return (
                                  Object.keys(item).length !== 0 && (
                                    <p key={index}>{item.fileDescription}</p>
                                  )
                                );
                              }
                            )}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                ) : (
                  ''
                )}

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.detailed_project_report} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">{get(projectData, 'project_readiness.dpr')}</p>
                  </Grid.Column>
                </Grid.Row>
                {get(projectData, 'project_readiness.dpr') === 'No' &&
                  get(projectData, 'project_readiness.dprDateAD') &&
                  get(projectData, 'project_readiness.dprDateAD') !== '' && (
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.estimated_date_to_complete_dpr} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <p className="project-info">
                          {get(projectData, 'project_readiness.dprDateAD')} (
                          {get(projectData, 'project_readiness.dprDateBS')})
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                {get(projectData, 'project_readiness.dpr') === 'Yes' ? (
                  <>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.document_for_detailed_PR} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.dprFile.map((item, index) => {
                              return (
                                Object.keys(item).length !== 0 && (
                                  <p key={index}>
                                    {item.originalName ? item.originalName: item.fileName}
                                    {item.fileName.length > 0 && (
                                      <>
                                        {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                        <a
                                          href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                          target="_blank"
                                          download
                                          rel="noopener noreferrer"
                                        >
                                          (Download)
                                        </a>
                                      </>
                                    )}
                                  </p>
                                )
                              );
                            })}
                          {/* <div>
											Verification status:{' '}
											{projectInfo.project_readiness.projectReportVerified ===
											'true' ? (
												<Label className='verified'>Verified</Label>
											) : (
												<Label className='not-verified'>Not verified</Label>
											)}
										</div> */}
                          {/* <VerificationDocument
											projectData={projectInfo.project_readiness}
											field='projectReportVerified'
											projectId={projectInfo.id}
											name='Project Report'
										/> */}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.file_description} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.dprFile.map((item, index) => {
                              return (
                                Object.keys(item).length !== 0 && (
                                  <p key={index}>{item.fileDescription}</p>
                                )
                              );
                            })}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                ) : (
                  ''
                )}
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.project_proposal} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'project_readiness.projectProposal')}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                {get(projectData, 'project_readiness.projectProposal') === 'Yes' ? (
                  <>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.document_for_PP} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.projectProposalFile.map((item, index) => {
                              return (
                                Object.keys(item).length !== 0 && (
                                  <p key={index}>
                                    {item.originalName ? item.originalName: item.fileName}
                                    {item.fileName.length > 0 && (
                                      <>
                                        {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                        <a
                                          href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                          target="_blank"
                                          download
                                          rel="noopener noreferrer"
                                        >
                                          (Download)
                                        </a>
                                      </>
                                    )}
                                  </p>
                                )
                              );
                            })}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.file_description} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.projectProposalFile.map((item, index) => {
                              return (
                                Object.keys(item).length !== 0 && (
                                  <p key={index}>{item.fileDescription}</p>
                                )
                              );
                            })}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}

            {gate === 'project_appraisal' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.iea} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">{get(projectData, 'project_readiness.eia')}</p>
                  </Grid.Column>
                </Grid.Row>
                {get(projectData, 'project_readiness.eia') === 'No' &&
                  get(projectData, 'project_readiness.eiaDateAD') &&
                  get(projectData, 'project_readiness.eiaDateAD') !== '' && (
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.estimated_date_eia} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <p className="project-info">
                          {get(projectData, 'project_readiness.eiaDateAD')} (
                          {get(projectData, 'project_readiness.eiaDateBS')})
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                {get(projectData, 'project_readiness.eia') === 'Yes' ? (
                  <>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.document_for_iea} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {/* {get(projectData, 'project_readiness.eiaFile')} */}
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.eiaFile.map((item, index) => {
                              return (
                                Object.keys(item).length !== 0 && (
                                  <p key={index}>
                                    {item.originalName ? item.originalName: item.fileName}
                                    {item.fileName.length > 0 && (
                                      <>
                                        {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                        <a
                                          href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                          target="_blank"
                                          download
                                          rel="noopener noreferrer"
                                        >
                                          (Download)
                                        </a>
                                      </>
                                    )}
                                  </p>
                                )
                              );
                            })}
                          {/* <div>
											Verification status:{' '}
											{projectInfo.project_readiness.eiaVerified === 'true' ? (
												<Label className='verified'>Verified</Label>
											) : (
												<Label className='not-verified'>Not verified</Label>
											)}
										</div> */}
                          {/* <VerificationDocument
											projectData={projectInfo.project_readiness}
											field='eiaVerified'
											projectId={projectInfo.id}
											name='EIA'
										/> */}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.file_description} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.eiaFile.map((item, index) => {
                              return (
                                Object.keys(item).length !== 0 && (
                                  <p key={index}>{item.fileDescription}</p>
                                )
                              );
                            })}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                ) : (
                  ''
                )}

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.iee} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">{get(projectData, 'project_readiness.iee')}</p>
                  </Grid.Column>
                </Grid.Row>
                {get(projectData, 'project_readiness.iee') === 'No' &&
                  get(projectData, 'project_readiness.ieeDateAD') &&
                  get(projectData, 'project_readiness.ieeDateAD') !== '' && (
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.estimated_date_iee} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <p className="project-info">
                          {get(projectData, 'project_readiness.ieeDateAD')} (
                          {get(projectData, 'project_readiness.ieeDateBS')})
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                {get(projectData, 'project_readiness.iee') === 'Yes' ? (
                  <>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.document_for_iee} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.ieeFile.map((item, index) => {
                              return (
                                Object.keys(item).length !== 0 && (
                                  <p key={index}>
                                    {item.originalName ? item.originalName: item.fileName}
                                    {item.fileName.length > 0 && (
                                      <>
                                        {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                        <a
                                          href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                          target="_blank"
                                          download
                                          rel="noopener noreferrer"
                                        >
                                          (Download)
                                        </a>
                                      </>
                                    )}
                                  </p>
                                )
                              );
                            })}
                          {/* <div>
											Verification status:{' '}
											{projectInfo.project_readiness.ieeVerified === 'true' ? (
												<Label className='verified'>Verified</Label>
											) : (
												<Label className='not-verified'>Not verified</Label>
											)}
										</div> */}
                          {/* <VerificationDocument
											projectData={projectInfo.project_readiness}
											field='ieeVerified'
											projectId={projectInfo.id}
											name='IEE'
										/> */}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.file_description} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.ieeFile.map((item, index) => {
                              return (
                                Object.keys(item).length !== 0 && (
                                  <p key={index}>{item.fileDescription}</p>
                                )
                              );
                            })}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                ) : (
                  ''
                )}

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.master_p_plan} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'project_readiness.masterProcurement')}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                {get(projectData, 'project_readiness.masterProcurement') === 'No' &&
                  get(projectData, 'project_readiness.mppDateAD') &&
                  get(projectData, 'project_readiness.mppDateAD') !== '' && (
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.estimated_date_of_mpp} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <p className="project-info">
                          {get(projectData, 'project_readiness.mppDateAD')} (
                          {get(projectData, 'project_readiness.mppDateBS')})
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                {get(projectData, 'project_readiness.masterProcurement') === 'Yes' ? (
                  <>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.document_for_mpp} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.masterProcurementFile.map(
                              (item, index) => {
                                return (
                                  Object.keys(item).length !== 0 && (
                                    <p key={index}>
                                      {item.originalName ? item.originalName: item.fileName}
                                      {item.fileName.length > 0 && (
                                        <>
                                          {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                          <a
                                            href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                            target="_blank"
                                            download
                                            rel="noopener noreferrer"
                                          >
                                            (Download)
                                          </a>
                                        </>
                                      )}
                                    </p>
                                  )
                                );
                              }
                            )}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.file_description} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.masterProcurementFile.map(
                              (item, index) => {
                                return (
                                  Object.keys(item).length !== 0 && (
                                    <p key={index}>{item.fileDescription}</p>
                                  )
                                );
                              }
                            )}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                ) : (
                  ''
                )}

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.annual_pp_fiscal_year} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'project_readiness.annualProcurement')}
                    </p>
                  </Grid.Column>
                </Grid.Row>

                {get(projectData, 'project_readiness.annualProcurement') === 'Yes' ? (
                  <>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.document_for_annual_pp_fiscal_year} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.annualProcurementFile.map(
                              (item, index) => {
                                return (
                                  Object.keys(item).length !== 0 && (
                                    <p key={index}>
                                      {item.originalName ? item.originalName: item.fileName}
                                      {item.fileName.length > 0 && (
                                        <>
                                          {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                          <a
                                            href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                            target="_blank"
                                            download
                                            rel="noopener noreferrer"
                                          >
                                            (Download)
                                          </a>
                                        </>
                                      )}
                                    </p>
                                  )
                                );
                              }
                            )}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.file_description} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.annualProcurementFile.map(
                              (item, index) => {
                                return (
                                  Object.keys(item).length !== 0 && (
                                    <p key={index}>{item.fileDescription}</p>
                                  )
                                );
                              }
                            )}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                ) : (
                  ''
                )}

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.annual_pp_coming_fy} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'project_readiness.annualProcurementComing')}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                {get(projectData, 'project_readiness.annualProcurementComing') === 'No' &&
                  get(projectData, 'project_readiness.appForComingFyDateAD') &&
                  get(projectData, 'project_readiness.appForComingFyDateAD') !== '' && (
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.estimated_date_annual_pp_coming_fy} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <p className="project-info">
                          {get(projectData, 'project_readiness.appForComingFyDateAD')} (
                          {get(projectData, 'project_readiness.appForComingFyDateBS')})
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                {get(projectData, 'project_readiness.annualProcurementComing') === 'Yes' ? (
                  <>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.document_for_annual_pp_coming_fy} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.annualProcurementComingFile.map(
                              (item, index) => {
                                return (
                                  Object.keys(item).length !== 0 && (
                                    <p key={index}>
                                      {item.originalName ? item.originalName: item.fileName}
                                      {item.fileName.length > 0 && (
                                        <>
                                          {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                          <a
                                            href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                            target="_blank"
                                            download
                                            rel="noopener noreferrer"
                                          >
                                            (Download)
                                          </a>
                                        </>
                                      )}
                                    </p>
                                  )
                                );
                              }
                            )}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.file_description} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.annualProcurementComingFile.map(
                              (item, index) => {
                                return (
                                  Object.keys(item).length !== 0 && (
                                    <p key={index}>{item.fileDescription}</p>
                                  )
                                );
                              }
                            )}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.project_implementation_plan_alternate}</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_readiness.implementationPlan')}
                </p>
              </Grid.Column>
            </Grid.Row>
            {get(projectData, 'project_readiness.implementationPlan') === 'No' &&
              get(projectData, 'project_readiness.pipDateAD') &&
              get(projectData, 'project_readiness.pipDateAD') !== '' && (
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.estimated_date_to_complete_pip} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'project_readiness.pipDateAD')} (
                      {get(projectData, 'project_readiness.pipDateBS')})
                    </p>
                  </Grid.Column>
                </Grid.Row>
              )}
            {get(projectData, 'project_readiness.implementationPlan') === 'Yes' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.document_for_pip} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData &&
                        projectData.project_readiness &&
                        projectData.project_readiness.implementationPlanFile.map((item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>
                                {item.originalName ? item.originalName: item.fileName}
                                {item.fileName.length > 0 && (
                                  <>
                                    {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                    <a
                                      href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                      target="_blank"
                                      download
                                      rel="noopener noreferrer"
                                    >
                                      (Download)
                                    </a>
                                  </>
                                )}
                              </p>
                            )
                          );
                        })}
                      {/* <div>
											Verification status:{' '}
											{projectInfo.project_readiness
												.implementationPlanVerified === 'true' ? (
												<Label className='verified'>Verified</Label>
											) : (
												<Label className='not-verified'>Not verified</Label>
											)}
										</div> */}
                      {/* <VerificationDocument
											projectData={projectInfo.project_readiness}
											field='implementationPlanVerified'
											projectId={projectInfo.id}
											name='Implementation Plan'
										/> */}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.file_description} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData &&
                        projectData.project_readiness &&
                        projectData.project_readiness.implementationPlanFile.map((item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>{item.fileDescription}</p>
                            )
                          );
                        })}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              ''
            )}
            {gate === 'project_appraisal' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.procurement_and_contract_award} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'project_readiness.procurementAward')}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                {get(projectData, 'project_readiness.procurementAward') === 'No' &&
                  get(projectData, 'project_readiness.procurementAwardDateAD') &&
                  get(projectData, 'project_readiness.procurementAwardDateAD') !== '' && (
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.estimated_date_to_complete_procurement_and_contract_award} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <p className="project-info">
                          {get(projectData, 'project_readiness.procurementAwardDateAD')} (
                          {get(projectData, 'project_readiness.procurementAwardDateBS')})
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                {get(projectData, 'project_readiness.procurementAward') === 'Yes' ? (
                  <>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.document_for_procurement_and_contract_award} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.procurementAwardFile.map(
                              (item, index) => {
                                return (
                                  Object.keys(item).length !== 0 && (
                                    <p key={index}>
                                      {item.originalName ? item.originalName: item.fileName}
                                      {item.fileName.length > 0 && (
                                        <>
                                          {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                          <a
                                            href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                            target="_blank"
                                            download
                                            rel="noopener noreferrer"
                                          >
                                            (Download)
                                          </a>
                                        </>
                                      )}
                                    </p>
                                  )
                                );
                              }
                            )}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.file_description} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.procurementAwardFile.map(
                              (item, index) => {
                                return (
                                  Object.keys(item).length !== 0 && (
                                    <p key={index}>{item.fileDescription}</p>
                                  )
                                );
                              }
                            )}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                ) : (
                  ''
                )}

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.has_risk_management}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'project_readiness.riskManagement')}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                {get(projectData, 'project_readiness.riskManagement') === 'No' &&
                  get(projectData, 'project_readiness.riskMitigationDateAD') &&
                  get(projectData, 'project_readiness.riskMitigationDateAD') !== '' && (
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.estimated_date_to_complete_risk_management} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <p className="project-info">
                          {get(projectData, 'project_readiness.riskMitigationDateAD')} (
                          {get(projectData, 'project_readiness.riskMitigationDateBS')})
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                {get(projectData, 'project_readiness.riskManagement') === 'Yes' ? (
                  <>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.document_for_risk_management} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.riskManagementFile.map((item, index) => {
                              return (
                                Object.keys(item).length !== 0 && (
                                  <p key={index}>
                                    {item.originalName ? item.originalName: item.fileName}
                                    {item.fileName.length > 0 && (
                                      <>
                                        {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                        <a
                                          href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                          target="_blank"
                                          download
                                          rel="noopener noreferrer"
                                        >
                                          (Download)
                                        </a>
                                      </>
                                    )}
                                  </p>
                                )
                              );
                            })}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.file_description} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.riskManagementFile.map((item, index) => {
                              return (
                                Object.keys(item).length !== 0 && (
                                  <p key={index}>{item.fileDescription}</p>
                                )
                              );
                            })}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.development_of_logical} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectData, 'project_readiness.logFrame')}</p>
              </Grid.Column>
            </Grid.Row>
            {get(projectData, 'project_readiness.logFrame') === 'No' &&
              get(projectData, 'project_readiness.devLogicalDateAD') &&
              get(projectData, 'project_readiness.devLogicalDateAD') !== '' && (
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.estimated_date_to_complete_the_development_of_logical} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'project_readiness.devLogicalDateAD')} (
                      {get(projectData, 'project_readiness.devLogicalDateBS')})
                    </p>
                  </Grid.Column>
                </Grid.Row>
              )}
            {get(projectData, 'project_readiness.logFrame') === 'Yes' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.document_for_development_of_logical} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData &&
                        projectData.project_readiness &&
                        projectData.project_readiness.logFrameFile.map((item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>
                                {item.originalName ? item.originalName: item.fileName}
                                {item.fileName.length > 0 && (
                                  <>
                                    {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                    <a
                                      href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                      target="_blank"
                                      download
                                      rel="noopener noreferrer"
                                    >
                                      (Download)
                                    </a>
                                  </>
                                )}
                              </p>
                            )
                          );
                        })}
                      {/* <div>
											Verification status:{' '}
											{projectInfo.project_readiness.logFrameVerified ===
											'true' ? (
												<Label className='verified'>Verified</Label>
											) : (
												<Label className='not-verified'>Not verified</Label>
											)}
										</div> */}
                      {/* <VerificationDocument
											projectData={projectInfo.project_readiness}
											field='logFrameVerified'
											projectId={projectInfo.id}
											name='Logical Result Framework'
										/> */}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.file_description} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData &&
                        projectData.project_readiness &&
                        projectData.project_readiness.logFrameFile.map((item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>{item.fileDescription}</p>
                            )
                          );
                        })}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              ''
            )}
            {gate === 'project_appraisal' && (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.monitoring_and_evaluation_plan}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'project_readiness.monitorEvaluation')}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                {get(projectData, 'project_readiness.monitorEvaluation') === 'No' &&
                  get(projectData, 'project_readiness.monitoringDateAD') &&
                  get(projectData, 'project_readiness.monitoringDateAD') !== '' && (
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.estimated_date_to_complete_monitoring_and_evaluation_plan} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <p className="project-info">
                          {get(projectData, 'project_readiness.monitoringDateAD')} (
                          {get(projectData, 'project_readiness.monitoringDateBS')})
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                {get(projectData, 'project_readiness.monitorEvaluation') === 'Yes' ? (
                  <>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.document_for_monitoring_and_evaluation_plank} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.monitorEvaluationFile.map(
                              (item, index) => {
                                return (
                                  Object.keys(item).length !== 0 && (
                                    <p key={index}>
                                      {item.originalName ? item.originalName: item.fileName}
                                      {item.fileName.length > 0 && (
                                        <>
                                          {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                          <a
                                            href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                            target="_blank"
                                            download
                                            rel="noopener noreferrer"
                                          >
                                            (Download)
                                          </a>
                                        </>
                                      )}
                                    </p>
                                  )
                                );
                              }
                            )}
                          {/* <div>
											Verification status:{' '}
											{projectInfo.project_readiness
												.monitorEvaluationVerified === 'true' ? (
												<Label className='verified'>Verified</Label>
											) : (
												<Label className='not-verified'>Not verified</Label>
											)}
										</div> */}
                          {/* <VerificationDocument
											projectData={projectInfo.project_readiness}
											field='monitorEvaluationVerified'
											projectId={projectInfo.id}
											name='Monitor and Evaluation Plan'
										/> */}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.file_description} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.logFrameFile.map((item, index) => {
                              return (
                                Object.keys(item).length !== 0 && (
                                  <p key={index}>{item.fileDescription}</p>
                                )
                              );
                            })}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                ) : (
                  ''
                )}

                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.completed_the_legal_process_of_land_acquisition}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'project_readiness.landAcquisition')}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                {get(projectData, 'project_readiness.landAcquisition') === 'Yes' ? (
                  <>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.document_for_legal_process_la} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.landAcquisitionFile.map((item, index) => {
                              return (
                                Object.keys(item).length !== 0 && (
                                  <p key={index}>
                                    {item.originalName ? item.originalName: item.fileName}
                                    {item.fileName.length > 0 && (
                                      <>
                                        {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                        <a
                                          href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                          target="_blank"
                                          download
                                          rel="noopener noreferrer"
                                        >
                                          (Download)
                                        </a>
                                      </>
                                    )}
                                  </p>
                                )
                              );
                            })}
                          {/* <div>
											Verification status:{' '}
											{projectInfo.project_readiness.landAcquisitionVerified ===
											'true' ? (
												<Label className='verified'>Verified</Label>
											) : (
												<Label className='not-verified'>Not verified</Label>
											)}
										</div> */}
                          {/* <VerificationDocument
											projectData={projectInfo.project_readiness}
											field='landAcquisitionVerified'
											projectId={projectInfo.id}
											name='Land Acquisition'
										/> */}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.file_description} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="project-info file-download">
                          {projectData &&
                            projectData.project_readiness &&
                            projectData.project_readiness.landAcquisitionFile.map((item, index) => {
                              return (
                                Object.keys(item).length !== 0 && (
                                  <p key={index}>{item.fileDescription}</p>
                                )
                              );
                            })}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.land_acquisition_status}</p>
                      </Grid.Column>
                      <Grid.Column>
                        <p className="project-info">
                          {get(projectData, 'project_readiness.landAcquisitionComplete')} %
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                ) : (
                  ''
                )}
              </>
            )}

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.document_for_other_project} :</p>
              </Grid.Column>
              <Grid.Column>
                <div className="project-info file-download">
                  {projectData &&
                    projectData.project_readiness &&
                    projectData.project_readiness.otherDocumentFile.map((item, index) => {
                      return (
                        Object.keys(item).length !== 0 && (
                          <p key={index}>
                            {item.originalName ? item.originalName: item.fileName}
                            {item.fileName.length > 0 && (
                              <>
                                {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                <a
                                  href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                  target="_blank"
                                  download
                                  rel="noopener noreferrer"
                                >
                                  (Download)
                                </a>
                              </>
                            )}
                          </p>
                        )
                      );
                    })}
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.file_description} :</p>
              </Grid.Column>
              <Grid.Column>
                <div className="project-info file-download">
                  {projectData &&
                    projectData.project_readiness &&
                    projectData.project_readiness.otherDocumentFile.map((item, index) => {
                      return (
                        Object.keys(item).length !== 0 && <p key={index}>{item.fileDescription}</p>
                      );
                    })}
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.upload_other_document_readiness} :</p>
              </Grid.Column>
              <Grid.Column>
                <div className="project-info file-download">
                  {projectData &&
                    projectData.project_readiness &&
                    projectData.project_readiness.anyOtherDocumentFile.map((item, index) => {
                      return (
                        Object.keys(item).length !== 0 && (
                          <p key={index}>
                            {item.originalName ? item.originalName: item.fileName}
                            {item.fileName.length > 0 && (
                              <>
                                {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                <a
                                  href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                  target="_blank"
                                  download
                                  rel="noopener noreferrer"
                                >
                                  (Download)
                                </a>
                              </>
                            )}
                          </p>
                        )
                      );
                    })}
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.file_description} :</p>
              </Grid.Column>
              <Grid.Column>
                <div className="project-info file-download">
                  {projectData &&
                    projectData.project_readiness &&
                    projectData.project_readiness.anyOtherDocumentFile.map((item, index) => {
                      return (
                        Object.keys(item).length !== 0 && <p key={index}>{item.fileDescription}</p>
                      );
                    })}
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.challenges_risk_issue} :</p>
              </Grid.Column>
              <Grid.Column>
                <p
                  className="project-info"
                  dangerouslySetInnerHTML={{
                    __html: get(projectData, 'project_readiness.riskAndIssue'),
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.document_for_challenges_risk_issues} :</p>
              </Grid.Column>
              <Grid.Column>
                <div className="project-info file-download">
                  {projectData &&
                    projectData.project_readiness &&
                    projectData.project_readiness.riskAndIssueFile.map((item, index) => {
                      return (
                        Object.keys(item).length !== 0 && (
                          <p key={index}>
                            {item.originalName ? item.originalName: item.fileName}
                            {item.fileName.length > 0 && (
                              <>
                                {item.fileSize ? <span>({item.fileSize})</span>: '' }
                                <a
                                  href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                  target="_blank"
                                  download
                                  rel="noopener noreferrer"
                                >
                                  (Download)
                                </a>
                              </>
                            )}
                          </p>
                        )
                      );
                    })}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.file_description} :</p>
              </Grid.Column>
              <Grid.Column>
                <div className="project-info file-download">
                  {projectData &&
                    projectData.project_readiness &&
                    projectData.project_readiness.riskAndIssueFile.map((item, index) => {
                      return (
                        Object.keys(item).length !== 0 && <p key={index}>{item.fileDescription}</p>
                      );
                    })}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      </div>
    </div>
  );
};

export default ProjectReadinessSection;
