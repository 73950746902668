const initialState = {
	ministriesFetching: true,
	ministriesFetched: false,
	ministriesError: null,
	ministries: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case 'MINISTRIES_FETCHING':
			state = {
				...state,
				...{
					ministriesError: null,
					ministriesFetching: true,
					ministriesFetched: false
				}
			};
			break;

		case 'MINISTRIES_FETCHED':
			state = {
				...state,
				...{
					ministriesError: null,
					ministries: payload.data.ministries.lists,
					ministriesFetching: false,
					ministriesFetched: true
				}
			};
			break;
		case 'MINISTRIES_ERROR':
			state = {
				...state,
				...{
					ministriesError: payload,
					ministriesFetching: false,
					ministriesFetched: true
				}
			};
			break;

		default:
			break;
	}
	return state;
};

export default reducer;
