import Api from './api';
import ApiUrlConstant from '../helpers/constant/api-url';

class DashboardApi {
  async getProjectStats(params = '') {
    try {
      const res = await Api.get(await ApiUrlConstant.DASHBOARD_STATS(params));
      return res.body.stats;
    } catch (error) {
      return error;
    }
  }

  async getProjectSector(params = '') {
    try {
      const res = await Api.get(await ApiUrlConstant.DASHBOARD_SECTOR(params));
      return res.body.data.projectBySector;
    } catch (error) {
      return error;
    }
  }

  async getProjectPriority(params = '') {
    try {
      const res = await Api.get(await ApiUrlConstant.DASHBOARD_PRIORITY(params));
      return res.body.data.project;
    } catch (error) {
      return error;
    }
  }

  async getProjectMinistry(params = '') {
    try {
      const res = await Api.get(await ApiUrlConstant.DASHBOARD_MINISTRY(params));
      return res.body.data.projectByMinistry;
    } catch (error) {
      return error;
    }
  }

  async getProjectYearOnYear(params = '') {
    try {
      const res = await Api.get(await ApiUrlConstant.DASHBOARD_YEAR_ON_YEAR(params));
      return res.body.data.project;
    } catch (error) {
      return error;
    }
  }

  async getProjectStatus(params = '') {
    try {
      const res = await Api.get(await ApiUrlConstant.DASHBOARD_STATUS(params));
      return res.body.data.project;
    } catch (error) {
      return error;
    }
  }
}

export default new DashboardApi();
