import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Grid, Divider } from 'semantic-ui-react';
import { get } from 'lodash';

import { auth, API_URL } from '../../../helpers';

import { localization } from '../../../localization';

const ProjectViabilitySection = ({ gate }) => {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['viability_view'];
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const [projectData, setProjectData] = useState();

  useEffect(() => {
    setProjectData(projectInfo[gate]);
  }, [gate]);

  return (
    <div name="project-viability">
      <div className="project-section" id="project-viability">
        <div className="header medium">
          {projectInfo.project_status === 'new' && gate === 'project_identification'
            ? lang.form_name_alternate
            : lang.section_name}
        </div>
        <Tab.Pane>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <div className="header small">{lang.economic_indicators}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.eirr} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_viability.economicIndicators.rateOfReturn')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.npv} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_viability.economicIndicators.NPV')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.bc_ratio} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_viability.economicIndicators.BCRatio')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.other} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_viability.economicIndicators.other')}
                </p>
              </Grid.Column>
            </Grid.Row>

            <Divider />

            <Grid.Row>
              <Grid.Column>
                <div className="header small">{lang.financial_indicator}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.firr} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_viability.financialIndicators.rateOfReturn')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.npv} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_viability.financialIndicators.NPV')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.bc_ratio} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_viability.financialIndicators.BCRatio')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.irr_equity} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_viability.financialIndicators.IRREquity')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.payback_period} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_viability.financialIndicators.payback')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.other} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_viability.financialIndicators.other')}
                </p>
              </Grid.Column>
            </Grid.Row>

            <Divider />

            <Grid.Row>
              <Grid.Column>
                <div className="header small">{lang.social_sector_indicators}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.cost_effectiveness} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_viability.socialIndicators.costEffectiveness')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.employment_generation} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_viability.socialIndicators.employmentGeneration')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.bc_ratio} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_viability.socialIndicators.BCRatio')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.social_output} :</p>
              </Grid.Column>
              <Grid.Column>
                <div className="project-info file-download">
                  {projectData &&
                    projectData.project_viability &&
                    projectData.project_viability.socialIndicatorOutputFile.map((item, index) => {
                      return (
                        Object.keys(item).length !== 0 && (
                          <p key={index}>
                            {item.originalName ? item.originalName : item.fileName}
                            {item.fileName.length > 0 && (
                              <>
                                {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                <a
                                  href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  download
                                >
                                  (Download)
                                </a>
                              </>
                            )}
                          </p>
                        )
                      );
                    })}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.file_description} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info file-download">
                  {projectData &&
                    projectData.project_viability &&
                    projectData.project_viability.socialIndicatorOutputFile.map((item, index) => {
                      return (
                        Object.keys(item).length !== 0 && (
                          <span key={index}>{item.fileDescription}</span>
                        )
                      );
                    })}
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      </div>
    </div>
  );
};

export default ProjectViabilitySection;
