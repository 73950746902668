import Api from './api';
import ApiUrlConstant from '../helpers/constant/api-url';

class ProgramApi {
  async createProgram(data, params) {
    try {
      const res = await Api.post(await ApiUrlConstant.PROGRAM(params), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async listPrograms(params) {
    try {
      const res = await Api.get(await ApiUrlConstant.PROGRAM(params));
      const programs = [];

      res.body.data.programs.forEach((p) => {
        programs.push({
          value: p.id,
          label: p.name,
          ministry_id: p.ministry_id,
          program_budget_code: p.program_budget_code ? p.program_budget_code : '',
        });
      });

      return programs;
    } catch (error) {
      return error;
    }
  }

  async listAllProgram(query) {
    try {
      const res = await Api.get(await ApiUrlConstant.PROGRAM_LIST(), null, query);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async deleteProgram(params) {
    try {
      const res = await Api.destroy(await ApiUrlConstant.PROGRAM_ACTION(params));
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async updateProgram(params, data) {
    try {
      const res = await Api.patch(await ApiUrlConstant.PROGRAM_ACTION(params), data);
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async downloadProgramZip(programId) {
    try {
      const res = await Api.get(
        await ApiUrlConstant.DOWNLOAD_PROGRAM_FILES(programId),
        'arraybuffer'
      );
      return res.body;
    } catch (error) {
      return error;
    }
  }

  async exportProgram(val) {
    try {
      const res = await Api.get(await ApiUrlConstant.EXPORT_PROGRAM(val), 'arraybuffer');
      return res.body;
    } catch (error) {
      return error;
    }
  }
}

export default new ProgramApi();
