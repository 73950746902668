const initialState = {
  selectedLanguage: localStorage.getItem('language') || 'ne',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      localStorage.setItem('language', action.payload); // persist for reload
      window.language = action.payload; // storing value on global storage
      return { ...state, selectedLanguage: action.payload };
    default:
      return state;
  }
};
