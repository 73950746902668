import React, { useRef, useEffect } from 'react';
// import { Icon } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import * as DepartmentAction from '../../../reducers/department/action';
import DepartmentApi from '../../../services/Department';
import FilterSection from '../Dashboard/FilterSection';
import { useState } from 'react';

const Filter = (props) => {
  let wrapperRef = useRef();
  const dispatch = useDispatch();
  const {
    ministries,
    user,
    language,
    handleApplyFilterStatus,
    handleSelect,
    filterValues,
    handleSearch,
    handleReset,
  } = props;

  const [showSidebar, setShowSidebar] = useState(false);
  // const [showMinistryOption, setShowMinistryOption] = useState(false);
  // const [showDepartmentOption, setShowDepartmentOption] = useState(false);
  // const [collapse, setCollapseAll] = useState(false);
  const [data, setData] = useState({
    departments: [],
  });
  const [showOptions, setShowOptions] = useState({
    showMinistryOption: false,
    showDepartmentOption: false,
    collapseAll: false,
  });

  const collapseAll = () => {
    setShowOptions({
      showMinistryOption: !showOptions.collapseAll,
      showDepartmentOption: !showOptions.collapseAll,
      collapseAll: !showOptions.collapseAll,
    });
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  // const toggleFilterOption = (filter) => {
  //   setCollapseAll(true);
  //   setShowMinistryOption(!showMinistryOption);
  //   setShowDepartmentOption(!showDepartmentOption);
  // };

  const toggleFilterOption = (filter) => {
    setShowOptions({
      ...showOptions,
      [filter]: !showOptions[filter],
      collapseAll: true,
    });
  };

  const handleOutsideClick = (event) => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      setShowSidebar(false);
    }
  };
  const escFunction = (event) => {
    if (event.keyCode === 27) {
      setShowSidebar(false);
    }
  };

  const listDepartments = (param) => {
    dispatch(DepartmentAction.fetchingDepartment());
    DepartmentApi.listDepartments(param)
      .then((response) => {
        if (response.success) {
          let dpt = response.data.department.filter((d) => d.ministry_id === param.ministry_id);
          let list = [];
          for (let d of dpt) {
            let check = list.find((a) => d.title === a.label);
            if (!check)
              list.push({
                label: d.title,
                value: d.id,
                ministry_id: d.ministry_id,
              });
          }

          setData((data) => {
            return { ...data, departments: list };
          });
          dispatch(DepartmentAction.fetchDepartment(response));
        } else {
          dispatch(DepartmentAction.errorDepartment(response));
        }
      })
      .catch((err) => dispatch(DepartmentAction.errorDepartment(err)));
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick, false);
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, false);
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (['npcAdmin', 'dataViewer'].includes(user.role) && filterValues.ministry_id.value !== 0) {
      listDepartments({ ministry_id: filterValues.ministry_id.value });
    }
  }, [filterValues.ministry_id.value]);

  return (
    <div ref={(el) => (wrapperRef.current = el)}>
      <div className={`sidebar-filter ${showSidebar ? '' : 'hidden'}`}>
        <div className="close-button" onClick={toggleSidebar}>
          <i className="material-icons">close</i>
        </div>
        <div className="filter-container">
          <div
            className={`filter-action
            ${filterValues.ministry_id.value !== 0 ? 'visibility-visible' : 'visibility-hidden'}
                `}
          >
            <button
              className="btn-rect btn-blue"
              onClick={() => {
                handleSearch();
                toggleSidebar();
                handleApplyFilterStatus();
              }}
            >
              {language.apply_filter}
            </button>
            <button className="btn-text" onClick={handleReset}>
              {language.clear_filter}
            </button>
          </div>
          <div className="header">
            <p className="title">{language.filter}</p>
            <p className="collapse" onClick={collapseAll}>
              {!showOptions.showMinistryOption ? language.expand_all : language.collapse_all_filter}
              <i className="material-icons md-bold md-blue ml-2">{`${
                showOptions.showMinistryOption ? 'remove' : 'add'
              }`}</i>
            </p>
          </div>

          {/* Filter Section for Ministries */}

          <FilterSection
            title={language.ministry}
            toggleFilterOption={() => toggleFilterOption('showMinistryOption')}
            showOption={showOptions.showMinistryOption}
            placeholder="Type a ministry name ..."
            data={ministries}
            handleSelect={handleSelect}
            selectedVal={filterValues.ministry_id}
            filterLabel={'ministry_id'}
            showSearch={true}
          />

          {filterValues.ministry_id && filterValues.ministry_id.value !== 0 && (
            <FilterSection
              title={language.implementing_agency || 'department'}
              toggleFilterOption={() => toggleFilterOption('showDepartmentOption')}
              showOption={showOptions.showDepartmentOption}
              placeholder="Type a department name ..."
              data={data.departments}
              handleSelect={handleSelect}
              selectedVal={filterValues.department_id}
              filterLabel={'department_id'}
              showSearch={true}
            />
          )}
        </div>
      </div>
      <button
        className="btn-normal btn-blue btn-shadow--blue btn-transition btn-filter"
        onClick={toggleSidebar}
      >
        <i className="material-icons md-bold md-18 mr-6">filter_list</i>
        {language.filter}
      </button>
    </div>
  );
};

export default Filter;
