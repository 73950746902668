import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import { Icon } from 'semantic-ui-react';
import ScrollArea from 'react-scrollbar';

import FilterSection from '../../../components/filter-new/FilterSection';
import FilterMultiSection from '../../../components/filter-new/FilterMultiSection';

import { toastr } from 'react-redux-toastr';
// const UserFilter = () => {
// 	return <Sidebar />;
// };

class BisesFilter extends React.Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.state = {
      showSidebar: false,
      showProvinceOption: false,
      showDistrictOption: false,
      showBasicAreaOption: false,
      showFiscalYearOption: false,
      showRoleOption: false,
      showStatusOption: false,
      showAIEOption: false,
      collapseAll: false,
    };
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleOutsideClick = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showSidebar: false });
    }
  };

  escFunction = (event) => {
    if (event.keyCode === 27) {
      this.setState({ showSidebar: false });
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
    document.removeEventListener('keydown', this.escFunction, false);
  }

  toggleSidebar = () => {
    this.setState({ showSidebar: !this.state.showSidebar });
  };

  toggleFilterOption = (filter) => {
    this.setState({ [filter]: !this.state[filter], collapseAll: true });
  };

  collapseAll = () => {
    this.setState({
      showProvinceOption: !this.state.collapseAll,
      showDistrictOption: !this.state.collapseAll,
      showBasicAreaOption: !this.state.collapseAll,
      showFiscalYearOption: !this.state.collapseAll,
      showRoleOption: !this.state.collapseAll,
      showStatusOption: !this.state.collapseAll,
      showAIEOption: !this.state.collapseAll,
      collapseAll: !this.state.collapseAll,
    });
  };

  render() {
    const {
      provinces,
      districts,
      basicArea,
      fiscalYear,
      level,
      status,
      roles,
      language,
      filterValues,
      handleSearch,
      handleReset,
      role,
    } = this.props;
    return (
      <div ref={this.setWrapperRef}>
        <div className={`sidebar-filter ${this.state.showSidebar ? '' : 'hidden'}`}>
          <div className="close-button" onClick={this.toggleSidebar}>
            <i className="material-icons close">close</i>
          </div>
          <div className="filter-container">
            <div
              className={`filter-action
										${
                      filterValues.districts_id.value !== 0 ||
                      filterValues.provinces_id.value !== 0 ||
                      filterValues.basic_area.value !== 0 ||
                      filterValues.year.value !== 0 ||
                      filterValues.role.value !== 0 ||
                      filterValues.status.value !== 0 ||
                      (filterValues.projectCostFrom && filterValues.projectCostTo)
                        ? 'visibility-visible'
                        : 'visibility-hidden'
                    }
									`}
            >
              <button
                className="btn-rect btn-blue"
                onClick={() => {
                  handleSearch();
                  this.toggleSidebar();
                  this.props.handleApplyFilterStatus();
                }}
              >
                {language.apply_filter}
              </button>
              <button className="btn-text" onClick={handleReset}>
                {language.clear_filter}
              </button>
            </div>
            <div className="header">
              <p className="title">{language.filter}</p>
              <p className="collapse" onClick={this.collapseAll}>
                {!this.state.showProvinceOption &&
                !this.state.showDistrictOption &&
                !this.state.showBasicAreaOption &&
                !this.state.showFiscalYearOption &&
                !this.state.showRoleOption &&
                !this.state.showAIEOption &&
                !this.state.showStatusOption
                  ? language.expand_all
                  : language.collapse_all_filter}
                <i className="material-icons md-bold md-blue remove">
                  {!this.state.showProvinceOption &&
                  !this.state.showDistrictOption &&
                  !this.state.showBasicAreaOption &&
                  !this.state.showFiscalYearOption &&
                  !this.state.showRoleOption &&
                  !this.state.showAIEOption &&
                  !this.state.showStatusOption
                    ? 'add'
                    : 'remove'}{' '}
                </i>
              </p>
            </div>

            {/* Filter Section for Provinces */}
            {level != 'LocalLevel' && (
              <FilterSection
                title={language.province}
                toggleFilterOption={() => this.toggleFilterOption('showProvinceOption')}
                showOption={this.state.showProvinceOption}
                placeholder="Type a province name ..."
                data={provinces}
                handleSelect={this.props.handleSelect}
                selectedVal={filterValues.provinces_id}
                filterLabel={'provinces_id'}
                showSearch={true}
              />
            )}

            {/* Filter Section for Districts */}
            {filterValues.provinces_id && filterValues.provinces_id.value !== 0 && (
              <FilterSection
                title={language.district}
                toggleFilterOption={() => this.toggleFilterOption('showDistrictOption')}
                showOption={this.state.showDistrictOption}
                placeholder="Type a district name ..."
                data={districts}
                handleSelect={this.props.handleSelect}
                selectedVal={filterValues.districts_id}
                filterLabel={'districts_id'}
                showSearch={true}
              />
            )}

            <FilterSection
              title={language.basic_area}
              toggleFilterOption={() => this.toggleFilterOption('showBasicAreaOption')}
              showOption={this.state.showBasicAreaOption}
              placeholder="Type a basic area name ..."
              data={basicArea}
              handleSelect={this.props.handleSelect}
              selectedVal={filterValues.basic_area}
              filterLabel={'basic_area'}
              showSearch={true}
            />

            <FilterSection
              title={language.fiscal_year}
              toggleFilterOption={() => this.toggleFilterOption('showFiscalYearOption')}
              showOption={this.state.showFiscalYearOption}
              placeholder="Type a fiscal year ..."
              data={fiscalYear}
              handleSelect={this.props.handleSelect}
              selectedVal={filterValues.year}
              filterLabel={'year'}
              showSearch={true}
            />

            <div className="filter-section">
              <div className="header" onClick={() => this.toggleFilterOption('showAIEOption')}>
                <p className="title">{language.original_cost_estimate}</p>
                <div className="collapse">
                  <i className="material-icons md-bold md-blue">{`${
                    this.state.showAIEOption ? 'remove' : 'add'
                  }`}</i>
                </div>
              </div>
              <div className={`contents ${this.state.showAIEOption ? '' : 'hide'}`}>
                <div className="option-form">
                  <label>From</label>
                  <Input
                    placeholder="From: Project cost"
                    type="text"
                    id="projectStartDateAD"
                    onChange={(e) => this.props.handleChange('projectCostFrom', e.target.value)}
                    value={filterValues.projectCostFrom || ''}
                    width={4}
                  />
                </div>
                <div className="option-form">
                  <label>To</label>
                  <Input
                    placeholder="To: Project cost"
                    type="text"
                    id="projectEndDateAD"
                    onChange={(e) => this.props.handleChange('projectCostTo', e.target.value)}
                    value={filterValues.projectCostTo || ''}
                    width={4}
                  />
                </div>
              </div>
            </div>

            {/* Filter Section for Municipalities */}

            {/* Multi filter section for districts */}
            {/* <FilterMultiSection
              title={'Province'}
              toggleFilterOption={() => this.toggleFilterOption('showProvinceOption')}
              showOption={this.state.showProvinceOption}
              placeholder="Type a province name ..."
              data={provinces}
              handleSelect={this.props.handleSelect}
              selectedVal={filterValues.provinces_id}
              filterLabel={'provinces_id'}
              showSearch={true}
              applied={this.props.applied}
            /> */}
            {/* 
            {filterValues.provinces_id && filterValues.provinces_id[0].value !== 0 && (
              <FilterMultiSection
                title={'District'}
                toggleFilterOption={() => this.toggleFilterOption('showDistrictOption')}
                showOption={this.state.showDistrictOption}
                placeholder="Type a district name ..."
                data={districts}
                handleSelect={this.props.handleSelect}
                selectedVal={filterValues.districts_id}
                filterLabel={'districts_id'}
                showSearch={true}
                applied={this.props.applied}
              />
            )} */}
          </div>
        </div>
        <button
          className="btn-normal btn-blue btn-shadow--blue btn-transition btn-filter"
          onClick={this.toggleSidebar}
        >
          <i className="material-icons md-bold md-18 mr-6">filter_list</i>
          {language.filter}
        </button>
      </div>
    );
  }
}

export default BisesFilter;
