export function fetching() {
  return { type: 'FETCHING' };
}

export function fetched(payload) {
  return { type: 'MODERATION_STATUS_FETCHED', payload };
}

export function validation(payload) {
  return { type: 'VALIDATION_FETCHED', payload };
}
