const initialState = {
  isMasterPlan: '',
  masterPlanFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  selectionCriteria: '',
  isMasterPlanApproved: '',
  anyPlanToDevelopMasterPlan: '',
  dateToMasterPlan: '',
  projectDerivation: '',
  approvingAuthority: '',
  approvalDate: '',
  selectionCriteriaFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  projectDerivationFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  targetBeneficiaries: {
    directBeneficiaries: '',
    indirectBeneficiaries: '',
    beneficiaries: '',
  },
  outcomesAndDeliverable: '',
  nationalGoal: '',
  nationalGoalFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  projectGoal: '',
  projectGoalFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  // nationalGoalPeriodicPlan: '',
  // nationalGoalPeriodicPlanFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  outcomesAndDeliverableFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
  sustainableGoals: [],
  povertySign: '',
  climateSign: '',
  genderSign: '',
  projectDescription: '',
  projectDescriptionFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'ERROR':
      return {};
    case 'PROJECT_STEP':
      return {
        ...state,
        step: payload.step,
      };
    case 'SELECTION_FETCHED':
      state = { ...payload };
      return state;

    case 'NULL_SELECTION_FETCHED':
      return initialState;

    default:
      return state;
  }
}
