export function fetchingDepartment(payload) {
	return { type: 'DEPARTMENT_FETCHING' };
}

export function fetchDepartment(payload) {
	return { type: 'DEPARTMENT_FETCHED', payload };
}

export function errorDepartment(payload) {
	return { type: 'DEPARTMENT_ERROR', payload };
}
