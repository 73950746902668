import React from 'react';
import { Form, Checkbox } from 'semantic-ui-react';

const ExampleCheckbox = () => {
  return (
    <>
      {/* Form group start */}
      <Form.Group grouped>
        <Form.Field label="Checkbox" />

        {/* Form group inline start */}
        <Form.Group inline>
          <Form.Field label="I agree to the Terms and Conditions" control={Checkbox} />
          <Form.Field label="I agree to the Terms and Conditions" control={Checkbox} />

          <Form.Field label="I agree to the Terms and Conditions" control={Checkbox} />

          <Form.Field label="I agree to the Terms and Conditions" control={Checkbox} />
        </Form.Group>
        {/* Form group inline end */}

        {/* Form group inline start */}
        <Form.Group inline>
          <Form.Field label="Yes" control={Checkbox} />
          <Form.Field label="No" control={Checkbox} />

          <Form.Field label="I agree to the Terms and Conditions" control={Checkbox} />

          <Form.Field label="I agree to the Terms and Conditions" control={Checkbox} />
        </Form.Group>
        {/* Form group inline end */}
      </Form.Group>
      {/* Form group end */}

      {/* Multi column checkbox */}
      {/* Form group start */}
      <Form.Group>
        <Form.Field width={8}>
          <Form.Field label="Checkbox" />

          {/* Form group inline start */}
          <Form.Group inline>
            <Form.Field label="I agree to the Terms and Conditions" control={Checkbox} />
            <Form.Field label="I agree to the Terms and Conditions" control={Checkbox} />
          </Form.Group>
          {/* Form group inline end */}
        </Form.Field>

        <Form.Field width={8}>
          <Form.Field label="Checkbox" />

          {/* Form group inline start */}
          <Form.Group inline>
            <Form.Field label="I agree to the Terms and Conditions" control={Checkbox} />
            <Form.Field label="I agree to the Terms and Conditions" control={Checkbox} />
          </Form.Group>
          {/* Form group inline end */}
        </Form.Field>
      </Form.Group>
      {/* Form group end */}
    </>
  );
};

export default ExampleCheckbox;
