import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';

import GuidelineApi from '../../../services/UploadGuideline';
import { auth } from '../../../helpers';
import { localization } from '../../../localization';
import Can from '../../../components/Can/can';
import PageNotFound from '../../PageNotFound';
import { fetchGuideline, fetchUserManual } from '../../../reducers/documents/action';

const UploadGuideline = ({ history }) => {
  const user = useSelector(auth.getCurrentUser);
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['upload_guideline'];
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const fileChange = (e, type) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    GuidelineApi.uploadDocument(formData, { type })
      .then((res) => {
        if (res.success) {
          setIsLoading(false);
          toastr.success('', `${res.message}`);
          GuidelineApi.getDocument({ type }).then((res) => {
            if (res.success) {
              if(type == 'user-manual'){
                dispatch(fetchUserManual(res));
              }else if(type == 'guideline'){
                dispatch(fetchGuideline(res));
              }
            }
          });
        } else {
          setIsLoading(false);
          throw new Error(res.error.message);
        }
      })
      .catch((err) => {
        toastr.error('', `${err}`);
        setIsLoading(false);
      });
  };

  return (
    <Can
      role={user.role}
      perform="upload:guideline"
      yes={() => (
        <>
          <div className="body-container no-print">
            <div className="dashboard-header justify-between">
              <div className="flex">
                <h2 className="header-main">{lang.upload_guide}</h2>
              </div>
            </div>
            <div className="form-group-container">
            <h3>{lang.user_manual_upload}</h3>
              <Form.Group className="file-upload-border">
                <Form.Field>
                  <div className="input-upload">
                    <input
                      type="file"
                      name="user-manual"
                      id="user-manual"
                      onChange={(e) => fileChange(e, 'user-manual')}
                      className="input-file"
                      accept=".pdf"
                      //   disabled={imageButtonDisabled}
                    />
                    {isLoading ? (
                      <div className="progress">
                        <div className="progress--bar">Uploading....</div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </Form.Field>
              </Form.Group>
              <p>Note: A new file upload will automatically replace the existing file.</p>
              {/* <div>Upload Guideline </div> */}
            </div>

            <div className="form-group-container">
             <h3>{lang.guideline_upload}</h3>
              <Form.Group className="file-upload-border">
                <Form.Field>
                  <div className="input-upload">
                    <input
                      type="file"
                      name="guideline"
                      id="guideline"
                      onChange={(e) => fileChange(e, 'guideline')}
                      className="input-file"
                      accept=".pdf"
                      //   disabled={imageButtonDisabled}
                    />
                    {isLoading ? (
                      <div className="progress">
                        <div className="progress--bar">Uploading....</div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    
                  </div>
                </Form.Field>
              </Form.Group>
              <p>Note: A new file upload will automatically replace the existing file.</p>
              {/* <div>Upload Guideline </div> */}
            </div>
          </div>
        </>
      )}
      no={() => <PageNotFound message={`You can't access this interface`} />}
    />
  );
};

export default UploadGuideline;
