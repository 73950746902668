import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Grid, Divider } from 'semantic-ui-react';
import { get } from 'lodash';
import moment from 'moment';

import { API_URL, auth } from '../../../helpers';

import { localization } from '../../../localization';

const ProjectSelectionSection = ({ gate }) => {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['selection_view'];
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const [projectData, setProjectData] = useState();

  useEffect(() => {
    setProjectData(projectInfo[gate]);
  }, [gate]);

  const sustainableGoals = [
    { label: '(1) No Poverty', value: 'noPoverty' },
    { label: '(2) Zero Hunger', value: 'zeroHunger' },
    { label: '(3) Good Health and Well-being', value: 'goodHealth' },
    { label: '(4) Quality Education', value: 'qualityEducation' },
    { label: '(5) Gender Equality', value: 'genderEquality' },
    { label: '(6) Clean Water and Sanitation', value: 'cleanWater' },
    { label: '(7) Affordable and Clean Energy', value: 'affordableAndCleanEnergy' },
    { label: '(8) Decent Work and Economic Growth', value: 'decentWorkAndEconomicgrowth' },
    { label: '(9) Industry, Innovation, and Infrastructure', value: 'industryInnovation' },
    { label: '(10) Reducing Inequality', value: 'reducingInequality' },
    { label: '(11) Sustainable Cities and Communities', value: 'sustainableCities' },
    { label: '(12) Responsible Consumption and Production', value: 'consumptionAndProduction' },
    { label: '(13) Climate Action', value: 'climateAction' },
    { label: '(14) Life Below Water', value: 'lifeBelowMeter' },
    { label: '(15) Life On Land', value: 'lifeOnLand' },
    { label: '(16) Peace, Justice, and Strong Institutions', value: 'peaceJustice' },
    { label: '(17) Partnerships for the Goals', value: 'partnerships' },
  ];

  const getFormattedValue = (filteringArray, value) => {
    let filteredVal = filteringArray.filter((type) => value === type.value);
    if (filteredVal.length > 0) {
      return filteredVal[0].label;
    }
  };

  return (
    <div name="project-selection">
      <div className="project-section" id="project-selection">
        <div className="header medium">{lang.section_name}</div>
        <Tab.Pane>
          <Grid columns={2}>
            {gate === 'project_appraisal' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.brief_description_of_project}</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p
                      className="project-info"
                      dangerouslySetInnerHTML={{
                        __html: get(projectData, 'basis_of_project_selection.projectDescription'),
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.supporting_document} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData &&
                        projectData.basis_of_project_selection &&
                        projectData.basis_of_project_selection.projectDescriptionFile.map(
                          (item, index) => {
                            return (
                              Object.keys(item).length !== 0 && (
                                <p key={index}>
                                  {item.originalName ? item.originalName : item.fileName}
                                  {item.fileName.length > 0 && (
                                    <>
                                      {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                      <a
                                        href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                        target="_blank"
                                        download
                                        rel="noopener noreferrer"
                                      >
                                        (Download)
                                      </a>
                                    </>
                                  )}
                                </p>
                              )
                            );
                          }
                        )}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.file_description} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData &&
                        projectData.basis_of_project_selection &&
                        projectData.basis_of_project_selection.projectDescriptionFile.map(
                          (item, index) => {
                            return (
                              Object.keys(item).length !== 0 && (
                                <p key={index}>{item.fileDescription}</p>
                              )
                            );
                          }
                        )}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              ''
            )}
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.is_project_part_of_any_sector}</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'basis_of_project_selection.isMasterPlan')}
                </p>
              </Grid.Column>
            </Grid.Row>

            {get(projectData, 'basis_of_project_selection.isMasterPlan') === 'No' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.selection_criteria} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p
                      className="project-info"
                      dangerouslySetInnerHTML={{
                        __html: get(projectData, 'basis_of_project_selection.selectionCriteria'),
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.supporting_document} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData &&
                        projectData.basis_of_project_selection &&
                        projectData.basis_of_project_selection.selectionCriteriaFile.map(
                          (item, index) => {
                            return (
                              Object.keys(item).length !== 0 && (
                                <p key={index}>
                                  {item.originalName ? item.originalName : item.fileName}
                                  {item.fileName.length > 0 && (
                                    <>
                                      {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                      <a
                                        href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                        target="_blank"
                                        download
                                        rel="noopener noreferrer"
                                      >
                                        (Download)
                                      </a>
                                    </>
                                  )}
                                </p>
                              )
                            );
                          }
                        )}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.file_description} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData &&
                        projectData.basis_of_project_selection &&
                        projectData.basis_of_project_selection.selectionCriteriaFile.map(
                          (item, index) => {
                            return (
                              Object.keys(item).length !== 0 && (
                                <p key={index}>{item.fileDescription}</p>
                              )
                            );
                          }
                        )}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.strategic_document} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p
                      className="project-info"
                      dangerouslySetInnerHTML={{
                        __html: get(projectData, 'basis_of_project_selection.projectDerivation'),
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.supporting_document} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData &&
                        projectData.basis_of_project_selection &&
                        projectData.basis_of_project_selection.projectDerivationFile.map(
                          (item, index) => {
                            return (
                              Object.keys(item).length !== 0 && (
                                <p key={index}>
                                  {item.originalName ? item.originalName : item.fileName}
                                  {item.fileName.length > 0 && (
                                    <>
                                      {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                      <a
                                        href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                        target="_blank"
                                        download
                                        rel="noopener noreferrer"
                                      >
                                        (Download)
                                      </a>
                                    </>
                                  )}
                                </p>
                              )
                            );
                          }
                        )}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.file_description} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData &&
                        projectData.basis_of_project_selection &&
                        projectData.basis_of_project_selection.projectDerivationFile.map(
                          (item, index) => {
                            return (
                              Object.keys(item).length !== 0 && (
                                <p key={index}>{item.fileDescription}</p>
                              )
                            );
                          }
                        )}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.is_there_any_plan_to_develop_master_plan}:</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'basis_of_project_selection.anyPlanToDevelopMasterPlan')}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                {get(projectData, 'basis_of_project_selection.anyPlanToDevelopMasterPlan') ===
                'Yes' ? (
                  <>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>Date to master plan:</p>
                      </Grid.Column>
                      <Grid.Column>
                        <p className="project-info">
                          {get(projectData, 'basis_of_project_selection.dateToMasterPlan') &&
                            moment(
                              get(projectData, 'basis_of_project_selection.dateToMasterPlan')
                            ).format('YYYY-MM-DD')}
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}

            {get(projectData, 'basis_of_project_selection.isMasterPlan') === 'Yes' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.is_master_plan_approved} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p className="project-info">
                      {get(projectData, 'basis_of_project_selection.isMasterPlanApproved')}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.supporting_document} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData &&
                        projectData.basis_of_project_selection &&
                        projectData.basis_of_project_selection.masterPlanFile.map((item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>
                                {item.originalName ? item.originalName : item.fileName}
                                {item.fileName.length > 0 && (
                                  <>
                                    {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                    <a
                                      href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                      target="_blank"
                                      download
                                      rel="noopener noreferrer"
                                    >
                                      (Download)
                                    </a>
                                  </>
                                )}
                              </p>
                            )
                          );
                        })}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.file_description} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="project-info file-download">
                      {projectData &&
                        projectData.basis_of_project_selection &&
                        projectData.basis_of_project_selection.masterPlanFile.map((item, index) => {
                          return (
                            Object.keys(item).length !== 0 && (
                              <p key={index}>{item.fileDescription}</p>
                            )
                          );
                        })}
                    </div>
                  </Grid.Column>
                </Grid.Row>

                {get(projectData, 'basis_of_project_selection.isMasterPlanApproved') === 'Yes' ? (
                  <>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.name_of_approving_authority} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <p className="project-info">
                          {get(projectData, 'basis_of_project_selection.approvingAuthority')}
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>{lang.approval_date} :</p>
                      </Grid.Column>
                      <Grid.Column>
                        <p className="project-info">
                          {get(projectData, 'basis_of_project_selection.approvalDate') &&
                            moment(
                              get(projectData, 'basis_of_project_selection.approvalDate')
                            ).format('YYYY-MM-DD')}
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                ) : (
                  ''
                )}

                {/* might be used later, delete after few iteration */}
                {/* {get(
                                projectData,
                                "basis_of_project_selection.isMasterPlanApproved"
                            ) === "No" ? (
                                <>
                                    <Grid.Row>
                                        <Grid.Column width={5}>
                                            <p>Is there any plan to develop master plan of the sector? :</p>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <p className="project-info">
                                                {get(
                                                    projectData,
                                                    "basis_of_project_selection.anyPlanToDevelopMasterPlan"
                                                )}
                                            </p>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {get(
                                        projectData,
                                        "basis_of_project_selection.anyPlanToDevelopMasterPlan"
                                    ) === "Yes" ? (
                                    <>
                                        <Grid.Row>
                                        <Grid.Column width={5}>
                                            <p>Date to master plan:</p>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <p className="project-info">
                                                {get(
                                                    projectData,
                                                    "basis_of_project_selection.dateToMaterPlan"
                                                )}
                                            </p>
                                        </Grid.Column>
                                    </Grid.Row>
                                    </>
                                    ): ""}
                                </>
                            ) : ""
                            } */}
              </>
            ) : (
              ''
            )}

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.national_goal} :</p>
              </Grid.Column>
              <Grid.Column>
                <p
                  className="project-info"
                  dangerouslySetInnerHTML={{
                    __html: get(projectData, 'basis_of_project_selection.nationalGoal'),
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.supporting_document} :</p>
              </Grid.Column>
              <Grid.Column>
                <div className="project-info file-download">
                  {projectData &&
                    projectData.basis_of_project_selection &&
                    projectData.basis_of_project_selection.nationalGoalFile.map((item, index) => {
                      return (
                        Object.keys(item).length !== 0 && (
                          <p key={index}>
                            {item.originalName ? item.originalName : item.fileName}
                            {item.fileName.length > 0 && (
                              <>
                                {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                <a
                                  href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                  target="_blank"
                                  download
                                  rel="noopener noreferrer"
                                >
                                  (Download)
                                </a>
                              </>
                            )}
                          </p>
                        )
                      );
                    })}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.file_description} :</p>
              </Grid.Column>
              <Grid.Column>
                <div className="project-info file-download">
                  {projectData &&
                    projectData.basis_of_project_selection &&
                    projectData.basis_of_project_selection.nationalGoalFile.map((item, index) => {
                      return (
                        Object.keys(item).length !== 0 && <p key={index}>{item.fileDescription}</p>
                      );
                    })}
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.national_goal_periodic_plan} :</p>
              </Grid.Column>
              <Grid.Column>
                <p
                  className="project-info"
                  dangerouslySetInnerHTML={{
                    __html: get(projectData, 'basis_of_project_selection.projectGoal'),
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.supporting_document} :</p>
              </Grid.Column>
              <Grid.Column>
                <div className="project-info file-download">
                  {projectData &&
                    projectData.basis_of_project_selection &&
                    projectData.basis_of_project_selection.projectGoalFile.map((item, index) => {
                      return (
                        Object.keys(item).length !== 0 && (
                          <p key={index}>
                            {item.originalName ? item.originalName : item.fileName}
                            {item.fileName.length > 0 && (
                              <>
                                {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                <a
                                  href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                  target="_blank"
                                  download
                                  rel="noopener noreferrer"
                                >
                                  (Download)
                                </a>
                              </>
                            )}
                          </p>
                        )
                      );
                    })}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.file_description} :</p>
              </Grid.Column>
              <Grid.Column>
                <div className="project-info file-download">
                  {projectData &&
                    projectData.basis_of_project_selection &&
                    projectData.basis_of_project_selection.projectGoalFile.map((item, index) => {
                      return (
                        Object.keys(item).length !== 0 && <p key={index}>{item.fileDescription}</p>
                      );
                    })}
                </div>
              </Grid.Column>
            </Grid.Row>

            <Divider />

            <Grid.Row>
              <Grid.Column>
                <div className="header small">{lang.target_beneficiaries} </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.direct_number_of_hhs} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(
                    projectData,
                    'basis_of_project_selection.targetBeneficiaries.directBeneficiaries'
                  )}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.indirect_number_of_hhs} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(
                    projectData,
                    'basis_of_project_selection.targetBeneficiaries.indirectBeneficiaries'
                  )}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.beneficiaries} :</p>
              </Grid.Column>
              <Grid.Column>
                <p
                  className="project-info"
                  dangerouslySetInnerHTML={{
                    __html: get(
                      projectData,
                      'basis_of_project_selection.targetBeneficiaries.beneficiaries'
                    ),
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            <Divider />

            <Grid.Row>
              <Grid.Column>
                <div className="header small">{lang.outcomes_deliverables}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.final_outcome} :</p>
              </Grid.Column>
              <Grid.Column>
                <p
                  className="project-info"
                  dangerouslySetInnerHTML={{
                    __html: get(projectData, 'basis_of_project_selection.outcomesAndDeliverable'),
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.supporting_document} :</p>
              </Grid.Column>
              <Grid.Column>
                <div className="project-info file-download">
                  {projectData &&
                    projectData.basis_of_project_selection &&
                    projectData.basis_of_project_selection.outcomesAndDeliverableFile.map(
                      (item, index) => {
                        return (
                          Object.keys(item).length !== 0 && (
                            <p key={index}>
                              {item.originalName ? item.originalName : item.fileName}
                              {item.fileName.length > 0 && (
                                <>
                                  {item.fileSize ? <span>({item.fileSize})</span> : ''}
                                  <a
                                    href={`${API_URL}assets/uploads/gates/${projectInfo.id}/${item.fileName}`}
                                    target="_blank"
                                    download
                                    rel="noopener noreferrer"
                                  >
                                    (Download)
                                  </a>
                                </>
                              )}
                            </p>
                          )
                        );
                      }
                    )}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.file_description} :</p>
              </Grid.Column>
              <Grid.Column>
                <div className="project-info file-download">
                  {projectData &&
                    projectData.basis_of_project_selection &&
                    projectData.basis_of_project_selection.outcomesAndDeliverableFile.map(
                      (item, index) => {
                        return (
                          Object.keys(item).length !== 0 && (
                            <p key={index}>{item.fileDescription}</p>
                          )
                        );
                      }
                    )}
                </div>
              </Grid.Column>
            </Grid.Row>

            <Divider />

            <Grid.Row>
              <Grid.Column>
                <div className="header small">{lang.sdg}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.sdg} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'basis_of_project_selection.sustainableGoals') &&
                    projectData.basis_of_project_selection.sustainableGoals.map((goal, index) => {
                      return (
                        <span key={index} className="general-array">
                          {getFormattedValue(sustainableGoals, goal)}
                        </span>
                      );
                    })}
                </p>
              </Grid.Column>
            </Grid.Row>

            <Divider />
            <Grid.Row>
              <Grid.Column width={5}>
                <div className="header small">{lang.poverty_sign} :</div>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'basis_of_project_selection.povertySign') === 'true'
                    ? 'Direct contribution to poverty alleviation'
                    : 'Indirect contribution on poverty alleviation'}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <div className="header small">{lang.gender_sign} :</div>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'basis_of_project_selection.genderSign') === 'true'
                    ? 'Direct contribution to gender equality'
                    : 'Indirect contribution to gender equality'}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <div className="header small">{lang.climate_sign} :</div>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'basis_of_project_selection.climateSign') === 'true'
                    ? 'Extremely relevant'
                    : get(projectData, 'basis_of_project_selection.climateSign') === 'false'
                    ? 'Relevant'
                    : 'Neutral'}
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      </div>
    </div>
  );
};

export default ProjectSelectionSection;
