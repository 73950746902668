export function fetchingMinstries(payload) {
	return { type: 'MINISTRIES_FETCHING' };
}

export function fetchMinistries(payload) {
	return { type: 'MINISTRIES_FETCHED', payload };
}

export function errorMinistries(payload) {
	return { type: 'MINISTRIES_ERROR', payload };
}
